import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CLEHeader, CLESider } from "../components/Layout";
import UserDetail from "../containers/User/UserAccess/UserDetail";
import "./PrimaryLayout.scss";

const { Content } = Layout;
class PrimaryLayout extends Component {
  state = {
    isMobile: false,
    collapsed: true,
    user: {},
    theme: "dark",
    isTimeZoneDisable: true,
    showDetail: false,
  };
  unlisten;
  isInitLoad = true;

  componentDidMount() {
    let path = this.props.history.location.pathname;
    this.setState(() => ({
      isTimeZoneDisable: !(
        path === "/logs/search-logs/list" ||
        path === "/" ||
        path === "/exceptions/search-exceptions/list" 
        // || path === "/dashboard" ||
        // path === "/weekly-dashboard" ||
        // path === "/monthly-dashboard"
      ),
    }));
    this.unlisten = this.props.history.listen((location) => {
      this.setState(() => ({
        isTimeZoneDisable: !(
          location.pathname === "/logs/search-logs/list" ||
          location.pathname === "/" ||
          location.pathname === "/exceptions/search-exceptions/list" 
          // || location.pathname === "/dashboard" ||
          // location.pathname === "/weekly-dashboard" ||
          // location.pathname === "/monthly-dashboard"
        ),
      }));
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onCollapseChange = (collapsed) => {
    if (!this.isInitLoad) {
      this.setState({
        collapsed,
      });
    }
    this.isInitLoad = false;
  };

  renderMainComponent = (headerProps, siderProps) => {
    return (
      <Fragment>
        <CLEHeader {...headerProps} />
        <Layout className="main-container">
          <CLESider {...siderProps} />
          <Content className="content-container">{this.props.children}</Content>
        </Layout>
      </Fragment>
    );
  };

  hanldeUserInfo = () => {
    this.setState(({ showDetail }) => ({
      showDetail: !showDetail,
    }));
  };

  render() {
    const { collapsed, theme, isTimeZoneDisable, showDetail } =
      this.state;

    const headerProps = {
      collapsed,
      username: "Vijay",
      fixed: true,
      onCollapseChange: () => this.onCollapseChange(!collapsed),
      isTimeZoneDisable,
      hanldeUserInfo: () => {
        this.hanldeUserInfo();
      },
    };
    
    const siderProps = {
      theme,
      collapsed,
      onCollapseChange: this.onCollapseChange,
    };
    let showComponent = false;
    if (this.props.oktaAuthenticatedStatus) showComponent = true;
    return (
      <Fragment>
        {showDetail && this.props.userAccessInfo && (
          <UserDetail
            userInfo
            onCloseModal={() => this.setState({ showDetail: false })}
            data={[this.props.userAccessInfo]}
            isModalOpen={this.state.showDetail}
          />
        )}
        <Layout>
          {showComponent
            ? this.renderMainComponent(headerProps, siderProps)
            : " "}
        </Layout>
      </Fragment>
    );
  }
}

PrimaryLayout.propTypes = {
  authState: PropTypes.object,
  children: PropTypes.node,
  dispatch: PropTypes.func,
  userAccessInfo: PropTypes.object,
  exceptionresponse: PropTypes.string,
  redirectURL: PropTypes.any,
  history: PropTypes.any,
  isApplicationLoading: PropTypes.bool,
  oktaAuthenticatedStatus: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userAccessInfo: state.userAccess.userAccessInfo,
  oktaAuthenticatedStatus: state.userAccess.oktaAuthenticatedStatus,
});

export default connect(mapStateToProps)(withRouter(PrimaryLayout));
