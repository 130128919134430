import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import LogStatus from "../../../components/Admin/ConfigureReports/LogStatus";
import * as actions from "../../../actions/Admin/logStatusActions";

const LogStatusConfigResults = () => {
  const dispatch = useDispatch();
  const globalLogConfigState = useSelector((state) => state.logConfig);

  useEffect(() => {
    if (globalLogConfigState.logConfigData?.length === 0) {
      dispatch(actions.getLogConfigDetails());
    }
  }, [])

  const checkIfDuplicateEntry = (data, editId) => {
    let isDuplicate = false;
    const logConfigData = _.cloneDeep(globalLogConfigState.logConfigData);
    for (let i = 0; i < logConfigData.length; i++) {
      if (
        (editId !== logConfigData[i].logConfigId) && _.isEqual(logConfigData[i].applicationName.sort(), data.applicationName.sort()) &&
        logConfigData[i].mailID.toUpperCase() ===
        data.mailID.toUpperCase()
      ) {
        isDuplicate = true;
        break;
      }
    }
    return isDuplicate;
  };

  const saveDetails = (payload) => {
    if (!checkIfDuplicateEntry(payload, payload["logConfigId"])) {
      if (payload["logConfigId"]) {
        dispatch(actions.updateData(payload));
      } else {
        dispatch(actions.addReport(payload));
      }
    } else {
      toast.error("Configuration already exist!")
    }
  };
  const onDelete = (payload) => {
    dispatch(actions.deleteDetail(payload));
  };
  return (
    <div>
      <LogStatus
        isLoading={globalLogConfigState.isLoading}
        logConfigData={globalLogConfigState.logConfigData}
        onDelete={onDelete}
        saveDetails={saveDetails}
      />
    </div>
  );
}
export default LogStatusConfigResults;
