import React, { useEffect,useState } from "react";
import { Form, Modal, Input, Radio } from "antd";
import PropTypes from "prop-types";
import { formItemsLayout, formItemsDefaultStyle } from "../../../../constants/AdminModalForm/FormStyleConfig";
import AdminModalFooter from "../../../CommonComponents/Modal/AdminModalFooter";
import SelectDropDown from '../../../CommonComponents/SelectDropDown';
import { applicationDataInstance } from "../../../../services/applicationDataService";
import MultiSelect from "../../../CommonComponents/MultiSelect/MultiSelect";
const metricTypeNames = [
  {
    label: 'Service',
    value: 'Service'
  },
  {
    label: 'TransactionDomain',
    value: 'TransactionDomain'
  },
];

const reportTypeNames = [
  {
    label: 'Daily',
    value: 'Daily'
  },
  {
    label: 'Daily Count',
    value: 'DailyCount'
  },
  {
    label: 'Hourly',
    value: 'Hourly'
  }
]

const hourlyReportOptionList = [
  {
    label: '2',
    value: 2
  },
  {
    label: '4',
    value: 4
  },
  {
    label: '6',
    value: 6
  }
]
const NewExceptionConfig = ({ ...props }) => {
  const [selectedApp, setSelectedApp] = useState([]);
  const [form] = Form.useForm();
  const applicationNames = applicationDataInstance.getApplicationNames();
  const reportTypeUpdatedValue = Form.useWatch('reportType', form);

  useEffect(() => {
    if (props.editFlag) {
      form.setFieldsValue({ ...props.data[0], reportType: props.data[0].reportType || 'Daily' });
      if (props?.data?.[0]?.applicationName?.length > 0) {
        setSelectedApp(props.data[0].applicationName.map(appItem => ({
          "label": appItem,
          "value": appItem,
        })));
      }
    }
  }, []);
  const onSaveHandler = (close) => {
    form.validateFields()
      .then(async values => {
        if (props.editFlag) {
          values = {
            "configID": props.data[0]["configID"],
            ...values
          }
        }
        props.saveData(values, close);
      })
  }
  const handleMultiSelectChange = (selectedApp) => {
    setSelectedApp(selectedApp)
    form.setFieldsValue({
      ...form.getFieldsValue(),
      "applicationName": selectedApp.map(app => app.value)
    });
  };
  return (<Modal
      open={props.isModalOpen}
      title={(props.editFlag ? "Edit" : "New") + " Exception Configuration"}
      onCancel={props.onCloseModal}
      footer={
        (props.editFlag ?
          <AdminModalFooter
            close update
            onUpdate={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
          :
          <AdminModalFooter
            save close saveAndClose
            onSaveAndClose={() => onSaveHandler(true)}
            onSave={() => onSaveHandler(false)}
            onClose={props.onCloseModal}
          />)
      }
      style={{ minWidth: 800 }}
      className="admin-modal-wrapper"
      centered
    >
      <Form
        id="report-exception-config"
        form={form}
        {...formItemsLayout}
        labelAlign="left"
        colon
        initialValues={{
          "enableNotification": false,
          "reportType": 'Daily'
        }}
      >
        <Form.Item label="Application Name" name="applicationName"
          rules={[{ required: true, message: 'Application is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <MultiSelect
            placeholder="Select Application"
            hasSelectAll={applicationNames && applicationNames.length > 2}
            disableSearch={applicationNames && applicationNames.length > 10 ? false : true}
            options={applicationNames}
            disabled={props.editFlag}
            selected={selectedApp}
            multi
            clearable
            onSelectedChanged={(app) =>
              handleMultiSelectChange(app)
            }
          />
        </Form.Item>
        <Form.Item label="Email" name="mailID"
          rules={[{ required: true, message: 'Email is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <Input />
        </Form.Item>
        <Form.Item label="Enable Notification" name="enableNotification"
          rules={[{ required: true, message: 'Enable Notification is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <Radio.Group >
            <Radio value>Enable</Radio>
            <Radio value={false}>Disable</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Metric Type" name="metricType"
          rules={[{ required: true, message: 'Metric Type is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <SelectDropDown
            options={metricTypeNames}
          />
        </Form.Item>
        <Form.Item label="Report Type" name="reportType"
          rules={[{ required: true, message: 'Report Type is Mandatory!' }]}
          style={formItemsDefaultStyle}
        >
          <SelectDropDown
            options={reportTypeNames}
          />
        </Form.Item>
        {reportTypeUpdatedValue === 'Hourly' && (
          <Form.Item label="Hours" name="hour"
            rules={[{ required: true, message: 'Hours is Mandatory!' }]}
            style={formItemsDefaultStyle}>
            <SelectDropDown
              options={hourlyReportOptionList}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>)
}
NewExceptionConfig.propTypes = {
  onCloseModal: PropTypes.func,
  saveData: PropTypes.func,
  data: PropTypes.any,
  editFlag: PropTypes.bool,
  isModalOpen: PropTypes.bool,
};
export default NewExceptionConfig;