import React, { useState } from "react";
import PropTypes from "prop-types";
import CleTable from "../../CommonComponents/CleTable/CleTable";
import CleFooter from "../../CommonComponents/CleFooter/CleFooter";
import COLUMNS from "../../../constants/ColumnConfig/Admin/ScheduleReport";
import Loader from "../../CommonComponents/Loader";
import ScheduleReportDetail from "./scheduleReportDetail";

// import NewScheduleReportConfig from "./reportConfig";

const pageStyle = {
    height: "calc(100vh - 150px)",
    width: "100%",
};
const ScheduleReport = ({ ...props }) => {
    const [selectedRow, setSelectedRow] = useState([]);
    const [showDetailModal, setShowDetailModal] = useState(false);


    return (<>
        {
            props.isLoading &&
            <Loader />
        }
        {
            !props.isLoading &&
            <>
                <div className={"admin-cle-table-wrapper"} style={pageStyle}>
                    <CleTable
                        rowSelection="multiple"
                        getRowNodeId={(data) => data.application + data.service}
                        suppressRowClickSelection
                        columnDefs={COLUMNS}
                        immutableData
                        rowData={props.reponse ? props.reponse : []}
                        onRowClicked={(params) => {
                            params.node.isSelected() ? params.node.setSelected(false) : params.node.setSelected(true);
                            setSelectedRow(params.api.getSelectedRows());
                        }}
                        onRowSelected={(params) => { setSelectedRow(params.api.getSelectedRows()); }}
                        rowMultiSelectWithClick
                    />
                </div>
                <CleFooter
                    onDetail={() => setShowDetailModal(true)}
                    deleteDisabled={selectedRow.length === 0}
                    detail
                />
            </>
        }
        {showDetailModal && (
            <ScheduleReportDetail
                isModalOpen={showDetailModal}
                onCloseModal={() => setShowDetailModal(false)}
                data={selectedRow.length > 0 ? selectedRow : []}
            />
        )}
    </>);
};

ScheduleReport.propTypes = {
    saveDetails: PropTypes.func,
    onDelete: PropTypes.func,
    reponse: PropTypes.any,
    isLoading: PropTypes.bool,
    updateDetails: PropTypes.func,
};
export default ScheduleReport;