import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "antd";
import "./MessageMatricsFooter.scss";

const formatBytes = (a, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

const getMaxData = (log, exception, tableName) => {
  let data = [];
  if (tableName.length === 2) {
    data = [...log, ...exception];
  } else if (tableName.includes("Log")) {
    data = log;
  } else if (tableName.includes("Exception")) {
    data = exception;
  }
  if (data && data.length > 0) {
    let newData = data.sort((a, b) => {
      return b.data_size - a.data_size;
    });
    return (
      formatBytes(newData[0].data_size) + ` (${newData[0].application_nm})`
    );
  }
  return "0 Bytes";
};

const MessageMatricsFooter = ({
  gridApi,
  log,
  exception,
  detail = false,
  loading = false,
  tableName,
}) => {
  return (
    <div className="footer-container">
      {loading ? (
        <Skeleton.Input style={{ width: 500 }} active size="small" />
      ) : (
        <>
          <span>
            Total Records :{" "}
            <span className="result-label">
              {gridApi?.getDisplayedRowCount()}
            </span>
          </span>{" "}
          &nbsp; &nbsp;
          <span>
            Total Logs :{" "}
            <span className="result-label">{log ? log.length : 0}</span>
          </span>{" "}
          &nbsp; &nbsp;
          <span>
            Total Exceptions :{" "}
            <span className="result-label">
              {exception ? exception.length : 0}
            </span>
          </span>{" "}
          &nbsp; &nbsp;
          {detail && (
            <span>
              Maximum data size :{" "}
              <span className="result-label">
                {getMaxData(log, exception, tableName)}
              </span>
            </span>
          )}
        </>
      )}
    </div>
  );
};

MessageMatricsFooter.propTypes = {
  gridApi: PropTypes.object,
  detail: PropTypes.bool,
  tableName: PropTypes.array,
  exception: PropTypes.array,
  loading: PropTypes.bool,
  log: PropTypes.array,
};

export default React.memo(MessageMatricsFooter);
