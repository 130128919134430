import React from "react";
import PropTypes from "prop-types";

const ReadMore = ({ data, onDownload }) => {
  return (
    <div className="details">
      <p>
        {data.substring(0, window.MAX_LENGTH)}
        ...
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onDownload();
          }}
        >
          Read more
        </a>
      </p>
    </div>
  );
};

ReadMore.propTypes = {
  data: PropTypes.string,
  onDownload: PropTypes.func,
};

export default ReadMore;
