import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Modal } from "antd";
import { getSnowServiceName } from '../../../../utils/helpers'
import "./styles.scss";

const labelColSpan = 11;
const valueColSpan = 8;
const DetailExceptionConfig = (props) => {
  const [index, setIndex] = React.useState(0);
  let data = props.data.length>0?props.data[index]:props.data;
  return (
      <Modal
        open={props.isModalOpen}
        title={<div className="config-modal-header">
          <div>Exception Configuration</div>
          {
            props.data.length > 1 &&
            (<ul className="pagination justify-content-center pagination-sm config-pagination">
              <li className={`page-item ${index === 0 && "cus_notallowed"}`}>
                <button
                  id="sel_logs_details_prev"
                  className={`btn btn-default ${index !== 0 ? "b500" : "btndisable"
                    }`}
                  aria-label="Previous"
                  onClick={() => {
                    setIndex(index - 1);
                  }}
                >
                  <span aria-hidden="true" className="icn_pre_nxt">
                    &laquo;
                  </span>
                  <span>Previous</span>
                </button>
              </li>
              <li
                className={`page-item ${index === props.data.length - 1 && "cus_notallowed"
                  }`}
              >
                <button
                  id="sel_logs_details_next"
                  className={`btn btn-default ${index !== props.data.length - 1 ? "b500" : "btndisable"
                    }`}
                  aria-label="Next"
                  onClick={() => {
                    setIndex(index + 1);
                  }}
                >
                  <span>Next</span>
                  <span aria-hidden="true" className="icn_pre_nxt">
                    &raquo;
                  </span>
                </button>
              </li>
            </ul>
            )}
        </div>}
        onCancel={props.onCloseModal}
        style={{ minWidth: 1000 }}
        className={"config-detail-modal"}
        centered
        footer={null}>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={labelColSpan}>
                Application:
              </Col>
              <Col span={valueColSpan}>
                {data.applicationName ? data.applicationName : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Category:
              </Col>
              <Col span={valueColSpan}>
                {data.exceptionCategoryName ? data.exceptionCategoryName : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Type:
              </Col>
              <Col span={valueColSpan}>
                {data.exceptionTypeName ? data.exceptionTypeName : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Maximum Auto Replay:
              </Col>
              <Col span={valueColSpan}>
                {data.maxAutoReplay ? data.maxAutoReplay : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Reply Destination:
              </Col>
              <Col span={valueColSpan}>
                {data.replyDestination ? data.replyDestination : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Assignment Group:
              </Col>
              <Col span={valueColSpan}>
                {data.snowAssignmentGroup ? data.snowAssignmentGroup : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Mail Notification
              </Col>
              <Col span={valueColSpan}>
                {data.emailAddress ? data.emailAddress : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Interval (minutes)
              </Col>
              <Col span={valueColSpan}>
                {data.autoReplayInterval ? data.autoReplayInterval : "-"}
              </Col>
            </Row>
            {data.enableSnowNotification === 1 && (
              <Row>
                <Col span={labelColSpan}>
                  Snow Service Name
                </Col>
                <Col span={valueColSpan}>
                {data.sysClassName ? getSnowServiceName(data.sysClassName) : "-"}
                </Col>
              </Row>
            )}
          </Col>
          <Col span={12}>
            <Row>
              <Col span={labelColSpan}>
                Application Domain:
              </Col>
              <Col span={valueColSpan}>
                {data.applicationDomain ? data.applicationDomain : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Severity:
              </Col>
              <Col span={valueColSpan}>
                {data.exceptionSeverityName ? data.exceptionSeverityName : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Replay Type:
              </Col>
              <Col span={valueColSpan}>
                {data.replayType ? data.replayType : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Description:
              </Col>
              <Col span={valueColSpan}>
                {data.description ? data.description : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Reply Mode:
              </Col>
              <Col span={valueColSpan}>
                {data.replyMode ? data.replyMode : "-"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Enable Mail Notification
              </Col>
              <Col span={valueColSpan}>
                {data.enableMailNotification === 1 ? "Yes" : "No"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Enable Group Notification
              </Col>
              <Col span={valueColSpan}>
                {data.enableGroupNotification === 1 ? "Yes" : "No"}
              </Col>
            </Row>
            <Row>
              <Col span={labelColSpan}>
                Enable Snow Notification
              </Col>
              <Col span={valueColSpan}>
                {data.enableSnowNotification === 1 ? "Yes" : "No"}
              </Col>
            </Row>
            {data.enableSnowNotification === 1 && (
              <Row>
                <Col span={labelColSpan}>
                  Snow Service Value
                </Col>
                <Col span={valueColSpan}>
                  {data.snowParam ? data.snowParam : "-"}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Modal>
  );
};
DetailExceptionConfig.propTypes = {
  onCloseModal: PropTypes.func,
  data: PropTypes.any,
  isModalOpen: PropTypes.bool,
};
export default DetailExceptionConfig;
