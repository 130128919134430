const logColumn = [
    {
        field: 'application_nm',
        headerName: 'Application',
    },
    {
        field: 'component_nm',
        headerName: 'Component Name',
    },
    {
        field: 'data_encoding',
        headerName: 'Data Encoding',

    },
    {
        field: 'service_nm',
        headerName: 'ServiceName',
    },

    {
        field: 'log_status',
        headerName: 'Status',
    },
    {
        field: 'trans_domain',
        headerName: 'Transaction Domain'
    },
    {
        field: 'host_nm',
        headerName: 'HostName',
    },
    {
        field: 'business_id',
        headerName: 'Business ID',
    },
    {
        field: 'business_id_two',
        headerName: 'Business ID2',
    },
    {
        field: 'trans_id',
        headerName: 'TransactionID',
    },
    {
        field: 'time_duration',
        headerName: 'Time Duration',
    },
    {
        field: 'timemark',
        headerName: 'Time Occured',
    },
    {
        field: 'trans_before',
        headerName: 'Transaction Before'
    },
    {
        field: 'jms_header',
        headerName: 'JMS Header'
    },
    {
        field: 'trans_after',
        headerName: 'Transaction After'
    },
    {
        field: 'log_msgs',
        headerName: 'Log Message'
    },
    {
        field: 'trans_type',
        headerName: 'Transaction Type'
    }
]

export default logColumn;