import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { connect } from "react-redux";
import SiderMenu from "./Menu";
import userRoutes, { adminRoutes } from "../../routes/dashboard";
import _ from "lodash";
import "./Sider.scss";

const CLESider = ({ collapsed, onCollapseChange, userAccessInfo, theme }) => {
  const [cleMenu, setCLEMenu] = useState([]);
  
  useEffect(() => {
    if (
      userAccessInfo &&
      userAccessInfo.userID !== null
    ) {
      let clonedMenu = _.cloneDeep(cleMenu);
      clonedMenu = [...clonedMenu, ...userRoutes];
      if (userAccessInfo.adminAccess === "Y") {
        clonedMenu = [...clonedMenu, ...adminRoutes];
      }

      if (userAccessInfo.bulkReply === "Y") {
        clonedMenu.splice(2, 0, {
          path: "/exceptions/bulk-replay",
          name: "Bulk Replay",
          id: "bulkReplay",
          icon: "fas fa-recycle",
          inRoute: ["/exceptions/bulk-replay"],
          state: "exceptions",
          isNavLink: true,
          className: 'pt-2'
        });
      }

      setCLEMenu(clonedMenu);
    }
  }, [])

  return (
    <Layout.Sider
      width={256}
      theme={theme}
      breakpoint="lg"
      trigger={null}
      collapsible
      collapsed={collapsed}
      onBreakpoint={onCollapseChange}
      className={`sider cle-new-font ${!collapsed ? "ant-open-menu" : ""}`}
    >
      <div className={"menuContainer"} id="cle-menu">
        <SiderMenu
          theme={theme}
          cleMenu={cleMenu}
          collapsed={collapsed}
          onCollapseChange={onCollapseChange}
        />
      </div>
    </Layout.Sider>
  );
}

CLESider.propTypes = {
  collapsed: PropTypes.bool,
  onCollapseChange: PropTypes.func,
  userAccessInfo: PropTypes.object,
  theme: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    userAccessInfo: state.userAccess.userAccessInfo,
  };
};

export default connect(mapStateToProps)(CLESider);
