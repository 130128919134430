import React from "react"
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom"
import $ from 'jquery';
const modalRoot = document.getElementById('process-root');



const ProcessModal = (props) =>{
    const [processVal,setProcessVal]=React.useState(null)

    window.openProcessModal = (val)=>{
        setProcessVal(val);
        $('#processModal').modal('show');
        $("#processModal").modal({ backdrop: 'static' });
    }
    window.closeProcessModal = ()=>{
        $('#processModal').modal('hide');
        $(".modal-backdrop").removeClass('modal-backdrop');
    }
    return ReactDOM.createPortal(
        <div className="modal fade zIndexTop" id="processModal" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Confirm
            </h5>
            </div>
            <div className="modal-body">
            Bulk {processVal} completed and no records are available in current page. Click okay to re-direct to search.
            </div>
                <div className="modal-footer">
                  <button type="button" id="sel_process_model_confirmation" autoFocus
                      ref={(button) => {  button && button.focus() }} 
                       className="btn btn-success left15"
                       data-dismiss="modal" 
                       onClick={()=>props.history.goBack()}>
                    <i className="fa fa-check" /> Ok
                </button>
                </div>
              </div>       
          </div>
        </div>,
        modalRoot
    )
    
}



ProcessModal.propTypes = {
        history: PropTypes.any
}

export default withRouter(ProcessModal)