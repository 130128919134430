import * as types from "../../constants/ActionTypes/Admin/userTypes";
import objectAssign from "object-assign";
import { formatData } from "../../utils/constantReducer";

const initialState = {
  userResults: {
    pageID: null,
    data: [],
    detectAction: null,
    totalRecords: null,
    isUserLoading: true,
  },
  roleResults: {
    pageID: null,
    data: [],
    detectAction: null,
    totalRecords: null,
    isRoleLoading: true,
  },
  roleUserResults: {
    pageID: null,
    data: [],
    totalRecords: null,
    isRoleUserLoading: true,
  },
  roleApplicationResults: {
    pageID: null,
    data: [],
    totalRecords: null,
    isRoleApplicationLoading: true,
  },
  status: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.EMPTY_VALUE:
      return {
        ...state,
        userResults: {
          pageID: null,
          data: [],
          detectAction: null,
          totalRecords: null,
          isUserLoading: true,
        },
        roleResults: {
          pageID: null,
          data: [],
          detectAction: null,
          totalRecords: null,
          isRoleLoading: true,
        },
        roleUserResults: {
          pageID: null,
          data: [],
          totalRecords: null,
          isRoleUserLoading: true,
        },
        roleApplicationResults: {
          pageID: null,
          data: [],
          totalRecords: null,
          isRoleApplicationLoading: true,
        },
      };
    case types.USER_API_STATUS: {
      return { ...state, status: action.status };
    }
    case types.STORE_USER_REPORT:
      if (action.data) {
        let stateKey = action.data.page;
        let data = [];
        if (stateKey === 'roleApplicationResults') {
          data = formatData(
            action.data,
            [...state[stateKey].data],
            action.data.id,
            'groupName',
            true
          );
        } else if (stateKey === 'roleUserResults') {
          data = formatData(
            action.data,
            [...state[stateKey].data],
            action.data.id,
            'groupDescription',
            true
          );
        } else {
          data = formatData(
            action.data,
            [...state[stateKey].data],
            action.data.id,
            "applicationNm",
            true
          );
        }
        return objectAssign({}, state, {
          [stateKey]: { data },
          isOverlayloading: false,
        });
      }
      return state;
    default:
      return state;
  }
}
