import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SapReport from '../../../components/Admin/SapReport';
import * as actions from '../../../actions/Admin/sapReportAction';
const SapReportContainer = () => {
	const dispatch = useDispatch();
	const globalReportConfigState = useSelector((state) => state.reportConfig);
	useEffect(() => {
		if (globalReportConfigState.reportData?.length === 0) {
			dispatch(actions.fetchReportData());
		}
	}, [])
	const saveDetails = (payload) => {
		dispatch(actions.addReport(payload))
	}
	const updateDetails = (payload) => {
		dispatch(actions.updateData(payload))
	}
	const onDelete = (payload) => {
		dispatch(actions.deleteDetail(payload))
	}
	return (
		<div>
			<SapReport
				isLoading={globalReportConfigState.isLoading}
				reponse={globalReportConfigState.reportData}
				onDelete={onDelete}
				saveDetails={saveDetails}
				updateDetails={updateDetails} />
		</div>);
}
export default SapReportContainer;