import * as types from '../../constants/ActionTypes/Admin/configurationTypes';
import { constructServiceUrl } from '../../services/constructUrl';

export function getApplicationExceptionCategory(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.GET_APPLICATION_EXCEPTION_CATEGORY;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_APPLICATION_EXCEPTION_CATEGORY,
    request: requestParams
  };
}

export function getExceptionCategory() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_EXCEPTION_CATEGORY;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_EXCEPTION_CATEGORY,
    request: requestParams
  };
}

export function getApplicationExceptionSeverity(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.GET_APPLICATION_EXCEPTION_SEVERITY;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_APPLICATION_EXCEPTION_SEVERITY,
    request: requestParams
  };
}

export function getExceptionSeverity() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_EXCEPTION_SEVERITY;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_EXCEPTION_SEVERITY,
    request: requestParams
  };
}

export function getApplicationExceptionType(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.GET_APPLICATION_EXCEPTION_TYPE;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_APPLICATION_EXCEPTION_TYPE,
    request: requestParams
  };
}

export function getExceptionType() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_EXCEPTION_TYPE;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_EXCEPTION_TYPE,
    request: requestParams
  };
}

export function updateExceptionData(exceptionType) {
  return {
    type: types.APPLICATION_DATA,
    exceptionType
  };
}
export function addExceptionCategory(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_EXCEPTION_CATEGORY;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = true;
  return {
    type: types.ADD_EXCEPTION_CATEGORY,
    request: requestParams
  };
}

export function updateExceptionCategories(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_EXCEPTION_CATEGORY;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = true;
  return {
    type: types.UPDATE_EXCEPTION_CATEGORY,
    request: requestParams
  };
}

export function deleteExceptionCategory(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_CATEGORY;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_CATEGORY,
    request: requestParams
  };
}

export function addExceptionSeverity(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_EXCEPTION_SEVERITY;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_EXCEPTION_SEVERITY,
    request: requestParams
  };
}

export function updateExceptionSeverities(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_EXCEPTION_SEVERITY;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.UPDATE_EXCEPTION_SEVERITY,
    request: requestParams
  };
}

export function deleteExceptionSeverity(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_SEVERITY;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_SEVERITY,
    request: requestParams
  };
}

export function addExceptionType(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_EXCEPTION_TYPE;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_EXCEPTION_TYPE,
    request: requestParams
  };
}

export function updateExceptionTypes(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_EXCEPTION_TYPES;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.UPDATE_EXCEPTION_TYPES,
    request: requestParams
  };
}

export function deleteExceptionType(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_TYPE;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_TYPE,
    request: requestParams
  };
}


export function listProgress() {
  return {
    type: types.PROGRESS_LIST_REPORT,
  };
}
