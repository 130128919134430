const cellStyles = {
  "text-align": "center",
};

const cellStyles2 = { "text-align": "left", "line-height": "20px" };

const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: false,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true,
};

const COLUMNS = [
  fieldHead,
  {
    field: "applicationName",
    headerName: "Application Name",
  },
  {
    field: "email",
    headerName: "Email",
  },
  {
    field: "emailMsg",
    headerName: "Email Message",
  },
  {
    field: "messageCode",
    headerName: "Message Code",
  },
  {
    field: "messageType",
    headerName: "Message Type",
  },
  {
    field: "messsageFct",
    headerName: "Message Fct",
  },

  {
    field: "serviceName",
    headerName: "Service",
  },
  {
    field: "thresholdDays",
    headerName: "Threshold Days",
    cellRenderer: "permissionRenderer",
    cellStyle: cellStyles2,
  },
  {
    field: "thresholdHighLimit",
    headerName: "Threshold High Limit",
    cellStyle: cellStyles,
  },
  {
    field: "thresholdLowLimit",
    headerName: "Threshold Low Limit",
    cellStyle: cellStyles,
  },
  // {
  //   field: "ticketEnabled",
  //   headerName: "Ticket Team",
  //   cellStyle: cellStyles,
  // },
  {
    field: "allowBreach",
    headerName: "Allow Breach",
  },
  {
    field: "assignmentGroup",
    headerName: "Assignment Group",
  },
  {
    field: "alertType",
    headerName: "Alert Type",
  },
];

export default COLUMNS;
