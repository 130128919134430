import URL from "../services/setEndpointURL";
import userServices from "./userServices";
import adminServices from "./adminServices";
import metricServices from "./metricServices";
import bulkReplayServices from "./bulkReplayServices";
// Application configuration
export function getAppConfig() {
  /* Configuration
   To work with diffrent envrioment(To Avoid hard coding of service urls in all service request)
  [Use "environment" key to set the envrionment, then correspoding envrioment urls will be populated in al service requests]
  */
  const config = {
    config: {
      environment: "dev",
      mockEnabled: false,
    },

    serverConfig: {
      dev: {
        Pepsico: {
          secureUrlPrefix: URL.API_URL,
          // "https://cle-web-preprod.apps.pcf.dev.azure.intra.pepsico.com/api/v1",
          // secureUrlPrefix:
          // "https://cle-web.apps.pcf.dev.azure.intra.pepsico.com/api/v1",
          // secureUrlPrefix:
          //   "https://cle-web-preprod.apps.pcf.preprod.azure.intra.pepsico.com/api/v1",
        },
      },
      qa: {
        Pepsico: {
          secureUrlPrefix:
            "https://cle-web.apps.pcf.dev.azure.intra.pepsico.com/api/v1",
        },
      },
      uat: {
        Pepsico: {
          secureUrlPrefix:
            "https://cle-web.apps.pcf.qa.azure.intra.pepsico.com/api/v1",
        },
      },
      prod: {
        Pepsico: {
          secureUrlPrefix:
            "https://cle-web.apps.pcf.dev.azure.intra.pepsico.com/api/v1",
        },
      },
    },
  };
  return config;
}

/* Configuration
Setting service parameters. To handle if any future updates in  service params
*/
export function getServiceConfig() {
  const serviceConfig = {
    defaults: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    ...adminServices,
    ...userServices,
    ...metricServices,
    ...bulkReplayServices,
    // /config/users/loggedUser
    // /user/authenticate/
    COST_ANALYSIS_EXPORT: {
      apiName: "COST_ANALYSIS_EXPORT",
      endPointUrl: "/monthlyCountDataToExcel",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    },
    EXCEPTION_CONFIG_EXPORT: {
      apiName: "EXCEPTION_CONFIG_EXPORT",
      endPointUrl: "/exception/download/config/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.DOWNLOADDATA_API_URL,
    },
    BULK_EXCEPTION_CONFIG_DATA_UPLOAD: {
      apiName: "BULK_EXCEPTION_CONFIG_DATA_UPLOAD",
      endPointUrl: "/exception/config/upload/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.DOWNLOADDATA_API_URL,
    },
    GET_EXCEPTION_DETAIL: {
      apiName: "GET_EXCEPTION_DETAIL",
      endPointUrl: "/exception/smexception/cache/",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    GET_LOG_COUNT: {
      apiName: "GET_LOG_COUNT",
      endPointUrl: "/log/count/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    GET_EXCEPTION_COUNT: {
      apiName: "GET_EXCEPTION_COUNT",
      endPointUrl: "/exception/count/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    SEARCH_EXCEPTIONS: {
      apiName: "SEARCH_EXCEPTIONS",
      endPointUrl: "/exception/search/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    ALL_SEARCH_EXCEPTIONS: {
      apiName: "ALL_SEARCH_EXCEPTIONS",
      endPointUrl: "/exception/search/all/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    GET_EXCEPTION_COUNT_ALL: {
      apiName: "GET_EXCEPTION_COUNT_ALL",
      endPointUrl: "/exception/count/all/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    SEARCH_LOGS: {
      apiName: "SEARCH_LOGS",
      endPointUrl: "/log/search/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    ALL_SEARCH_LOGS: {
      apiName: "ALL_SEARCH_LOGS",
      endPointUrl: "/log/search/all/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    GET_LOG_COUNT_ALL: {
      apiName: "GET_LOG_COUNT_ALL",
      endPointUrl: "/log/count/all/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    GET_LOG_DETAILS: {
      apiName: "GET_LOG_DETAILS",
      endPointUrl: "/log/item/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    GET_EXCEPTION_DETAILS: {
      apiName: "GET_EXCEPTION_DETAILS",
      endPointUrl: "/exception/item/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    REPLAY_EXCEPTIONS: {
      apiName: "REPLAY_EXCEPTIONS",
      endPointUrl: "/replay/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    REPLAY_EXCEPTIONS_STATUS: {
      apiName: "REPLAY_EXCEPTIONS_STATUS",
      endPointUrl: "/replay/status/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isStatus: true,
      isNoError: true,
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    EXCEPTION_SEARCH_FILTER_EXCEPTIONS: {
      apiName: "EXCEPTION_SEARCH_FILTER_EXCEPTIONS",
      endPointUrl: "/exception/filter/add/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    EXCEPTION_SEARCH_DEFILTER_EXCEPTIONS: {
      apiName: "EXCEPTION_SEARCH_DEFILTER_EXCEPTIONS",
      endPointUrl: "/exception/filter/remove/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    PROCESS_FILTER_LOGS: {
      apiName: "PROCESS_FILTER_LOGS",
      endPointUrl: "/log/filter/add/bulk/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    PROCESS_DEFILTER_LOGS: {
      apiName: "PROCESS_DEFILTER_LOGS",
      endPointUrl: "/log/filter/remove/bulk/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    LOG_SEARCH_FILTER_LOGS: {
      apiName: "LOG_SEARCH_FILTER_LOGS",
      endPointUrl: "/log/filter/add/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    LOG_SEARCH_DEFILTER_LOGS: {
      apiName: "LOG_SEARCH_DEFILTER_LOGS",
      endPointUrl: "/log/filter/remove/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    DELETE_ROLE: {
      apiName: "DELETE_ROLE",
      endPointUrl: "/role/",
      method: "DELETE",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
    },
    ADD_ROLE: {
      apiName: "ADD_ROLE",
      endPointUrl: "/role/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
    },
    EDIT_ROLE: {
      apiName: "EDIT_ROLE",
      endPointUrl: "/role/",
      method: "PUT",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
    },
    DELETE_APPLICATION_ROLE: {
      apiName: "DELETE_APPLICATION_ROLE",
      endPointUrl: "/role/application/",
      method: "DELETE",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
    },
    FILTER_EXCEPTIONS: {
      apiName: "FILTER_EXCEPTIONS",
      endPointUrl: "/exception/filter/add/bulk/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    DEFILTER_EXCEPTIONS: {
      apiName: "DEFILTER_EXCEPTIONS",
      endPointUrl: "/exception/filter/remove/bulk/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    GET_FILTERED_EXCEPTION_STATUS: {
      apiName: "GET_FILTERED_EXCEPTION_STATUS",
      endPointUrl: "/exception/request/status/",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isStatus: true,
      isNoError: true,
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    PURGE_EXCEPTIONS: {
      apiName: "PURGE_EXCEPTIONS",
      endPointUrl: "/exception/purge/",
      method: "PUT",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    PURGE_LOGS: {
      apiName: "PURGE_LOGS",
      endPointUrl: "/log/purge/",
      method: "PUT",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    FILTER_EXCEPTIONS_RESOLVE: {
      apiName: "FILTER_EXCEPTIONS_RESOLVE",
      endPointUrl: "/exception/filter/replay/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    GET_FILTERED_LOG_STATUS: {
      apiName: "GET_FILTERED_LOG_STATUS",
      endPointUrl: "/log/request/status/",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isStatus: true,
      isNoError: true,
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    LOGS_ALL_EXPORT_TO_EXCEL: {
      apiName: "LOGS_ALL_EXPORT_TO_EXCEL",
      endPointUrl: "/log/download",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    LOGS_SELECTED_EXPORT_TO_EXCEL: {
      apiName: "LOGS_SELECTED_EXPORT_TO_EXCEL",
      endPointUrl: "/log/download/selected",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.DOWNLOAD_API_URL,
    },
    EXCEPTIONS_ALL_EXPORT_TO_EXCEL: {
      apiName: "EXCEPTIONS_ALL_EXPORT_TO_EXCEL",
      endPointUrl: "/exception/download",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.DOWNLOADDATA_API_URL,
    },
    EXCEPTIONS_SELECTED_EXPORT_TO_EXCEL: {
      apiName: "EXCEPTIONS_SELECTED_EXPORT_TO_EXCEL",
      endPointUrl: "/exception/download/selected",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.DOWNLOADDATA_API_URL,
    },

    FILTER_LOGS_PURGE: {
      apiName: "FILTER_LOGS_PURGE",
      endPointUrl: "/log/filter/purge/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    FILTER_EXCEPTIONS_PURGE: {
      apiName: "FILTER_EXCEPTIONS_PURGE",
      endPointUrl: "/exception/filter/purge/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    USER_LOGOUT: {
      apiName: "USER_LOGOUT",
      endPointUrl: "/logout",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
    },
    LAST_HOUR_LOGS_REPORT: {
      apiName: "LAST_HOUR_LOGS_REPORT",
      endPointUrl: "/dashboard/logs/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isNoError: true,
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    LAST_HOUR_EXCEPTION_REPORT: {
      apiName: "LAST_HOUR_EXCEPTION_REPORT",
      endPointUrl: "/dashboard/exceptions/",
      method: "POST",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isNoError: true,
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    PAST_HOUR_LOGS_REPORT: {
      apiName: "PAST_HOUR_LOGS_REPORT",
      endPointUrl: "/dashboard/logs/",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isNoError: true,
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    PAST_HOUR_EXCEPTION_REPORT: {
      apiName: "PAST_HOUR_EXCEPTION_REPORT",
      endPointUrl: "/dashboard/exceptions/",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isNoError: true,
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    ADMIN_EXCEPTION_REPORT: {
      apiName: "ADMIN_EXCEPTION_REPORT",
      endPointUrl: "/dashboard/exceptions/",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isNoError: true,
      baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    },
    ADMIN_LOG_REPORT: {
      apiName: "ADMIN_LOG_REPORT",
      endPointUrl: "/dashboard/logs/",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
      isNoError: true,
      baseUrlPrefix: URL.LOGDATA_API_URL,
    },
    // SAVE_EXCEPTION_DETAILS: {
    //   apiName: "SAVE_EXCEPTION_DETAILS",
    //   endPointUrl: "/exception/",
    //   method: "PUT",
    //   mock_json: "",
    //   isSecured: true,
    //   server: "Pepsico",
    //   baseUrlPrefix: URL.EXCEPTIONDATA_API_URL,
    // },

    GET_SOLMAN_EXCEPTION_DETAILS: {
      apiName: "GET_SOLMAN_EXCEPTION_DETAILS",
      endPointUrl: "/solman/exception/",
      method: "GET",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
    },
    // GET_EXCEPTION_ANALYTICS: {
    //   apiName: "GET_EXCEPTION_ANALYTICS",
    //   endPointUrl: "/exception/analytics/",
    //   method: "POST",
    //   mock_json: "",
    //   isSecured: true,
    //   server: "Pepsico",
    // },
    // GET_LOG_ANALYTICS: {
    //   apiName: "GET_LOG_ANALYTICS",
    //   endPointUrl: "/log/analytics/",
    //   method: "POST",
    //   mock_json: "",
    //   isSecured: true,
    //   server: "Pepsico",
    // },
    COPY_NEW_USER: {
      apiName: "COPY_NEW_USER",
      endPointUrl: "/user/duplicate/",
      method: "PUT",
      mock_json: "",
      isSecured: true,
      server: "Pepsico",
    }
  };
  return serviceConfig;
}
