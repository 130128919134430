import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../actions/Admin/configurationActions';
import * as applicationActions from '../../../../actions/Admin/applicationConfigActions';
import Lists from '../../../../components/Admin/ApplicationConfig/Lists/lists';
const AdminLists = () => {
    const dispatch = useDispatch();
    const globalExceptionType = useSelector((state) => state.lists.exceptionType);

    useEffect(() => {
        dispatch(applicationActions.getApplicationDetails());
        if (!globalExceptionType.category || globalExceptionType.category.length===0) {
            dispatch(actions.getExceptionCategory());
        }

        if (!globalExceptionType.severity || globalExceptionType.severity.length===0) {
            dispatch(actions.getExceptionSeverity());
        }

        if (!globalExceptionType.type || globalExceptionType.type.length===0) {
            dispatch(actions.getExceptionType());
        }
    }, [])
    const saveCategoryDetails = (CategoryDetails) => {
        if (CategoryDetails.categoryID) {
            dispatch(actions.updateExceptionCategories(CategoryDetails));
        } else {
            dispatch(actions.addExceptionCategory(CategoryDetails));
        }
    }
    const saveSeverityDetails = (SeverityDetails) => {
        if (SeverityDetails.severityID) {
            dispatch(actions.updateExceptionSeverities(SeverityDetails));
        } else {
            dispatch(actions.addExceptionSeverity(SeverityDetails));
        }
    }
    const saveTypeDetails = (TypeDetails) => {
        if (TypeDetails.typeID) {
            dispatch(actions.updateExceptionTypes(TypeDetails));
        } else {
            dispatch(actions.addExceptionType(TypeDetails));
        }
    }
    const deleteExceptionCategory = (category) => {
        let id = { categoryID: category }
        dispatch(actions.deleteExceptionCategory([id]));
    }
    const deleteExceptionSeverity = (severity) => {
        let id = { severityID: severity }
        dispatch(actions.deleteExceptionSeverity([id]));
    }
    const deleteExceptionType = (type) => {
        let id = { typeID: type }
        dispatch(actions.deleteExceptionType([id]));
    }
    return (
        <Lists
            exceptionType={globalExceptionType}
            saveCategoryDetails={saveCategoryDetails}
            saveSeverityDetails={saveSeverityDetails}
            saveTypeDetails={saveTypeDetails}
            deleteExceptionCategory={deleteExceptionCategory}
            deleteExceptionSeverity={deleteExceptionSeverity}
            deleteExceptionType={deleteExceptionType} />
    )
}
export default AdminLists;