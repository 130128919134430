import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import SelectDropDown from "../../components/CommonComponents/SelectDropDown";
const UserHeader = (props) => {
  let searchVal;
  return (
    <header
      className={"d-flex justify-content-between"}
      style={{padding:"10px 0px 0px"}}
    >
      <div className="user-header d-flex justify-content-center flex-column">
        {props.headerName}
      </div>
      <div className="d-flex justify-content-end" style={{ minWidth: "45vw" }}>
        {
          props.groupOptionEnabled &&
          <SelectDropDown
            className="cle-select"
            options={props.groupOptionsList ? [{ groupName: "All" }, ...props.groupOptionsList] : []}
            placeholder={"Select Group"}
            autoFocus
            fieldNames={{ label: "groupName", value: "groupName" }}
            value={props.selectdGroupOption ? props.selectdGroupOption : null}
            onChange={(val) => {
              if (props.onSelectGroupHandler) props.onSelectGroupHandler(val);
            }}
            size={"large"}
          />
        }
        <div style={{ minWidth: 330, marginLeft: "20px" }}>
          <Input
            size="large"
            placeholder="Search"
            onPressEnter={(e) => {
              props.onSearch(e.target.value);
            }}
            allowClear
            prefix={<SearchOutlined />}
            onChange={(e) => {
              searchVal = e.target.value;
              setTimeout(() => {
                props.onSearch(searchVal);
              }, 2000);
            }}
            style={{ fontWeight: 300 }}
          />
        </div>
      </div>
    </header>
  );
};

UserHeader.propTypes = {
  headerName: PropTypes.string,
  onSearch: PropTypes.func,
  groupOptionEnabled: PropTypes.bool,
  groupOptionsList: PropTypes.array,
  selectdGroupOption: PropTypes.any,
  onSelectGroupHandler: PropTypes.func,
};

export default UserHeader;
