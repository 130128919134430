import React from "react";
import PropTypes from "prop-types";
import EventEmitter from "events";
import "./OverlayLoader.scss";

const myEmitter = new EventEmitter();

export const openLoader = () => {
  myEmitter.emit("openLoader");
};
export const closeLoader = () => {
  myEmitter.emit("closeLoader");
};
export const OverlayLoader = (props) => {
  const [show, setShow] = React.useState(false);
  myEmitter.once("openLoader", () => {
    setShow(true);
    myEmitter.removeListener("openLoader", () => {});
  });
  myEmitter.once("closeLoader", () => {
    setShow(false);
    // window.fullLoader = false;
    myEmitter.removeListener("closeLoader", () => {});
  });
  return show ? (
    <div
      className={
        window.fullLoader ? "overlay_loader_detail_full" : "overlay_loader"
      }
      style={props.fullLoader && { top: "0" }}
    >
      <div className="text-center overlay_loader_load">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

OverlayLoader.propTypes = {
  fullLoader: PropTypes.bool,
};
