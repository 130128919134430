import * as types from '../../constants/ActionTypes/Admin/configExceptionTypes';
import { constructServiceUrl } from '../../services/constructUrl';

export function getExceptionConfigDetails() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_EXCEPTION_CONFIG_DETAILS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_EXCEPTION_CONFIG_DETAILS,
    request: requestParams
  };
}
export function updateExcepConfigDetails(excepConfigData) {
  return {
    type: types.GET_EXCEPTION_CONFIG,
    excepConfigData
  };
}
export function deleteConfException(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_CONFIGURE_EXP;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_CONFIGURE_EXP,
    request: requestParams
  };
}

export function addConfigExcepFunction(newConfigExcep) {
  let serviceParams = {};
  serviceParams.payload = newConfigExcep;
  serviceParams.apiName = types.ADD_CONFIG_EXP;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_CONFIG_EXP,
    request: requestParams
  };
}
export function updateAddExcepConfig(configExcepAddResults) {
  return {
    type: types.CONFIG_EXCEP_ADD_RESULTS,
    configExcepAddResults
  };
}
export function editConfigExcepFunction(updatedConfigExcep) {
  let serviceParams = {};
  serviceParams.payload = updatedConfigExcep;
  serviceParams.apiName = types.EDIT_CONFIG_EXP;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.EDIT_CONFIG_EXP,
    request: requestParams
  };
}
export function updateEditExcepConfig(configExcepEditResults) {
  return {
    type: types.CONFIG_EXCEP_EDIT_RESULTS,
    configExcepEditResults
  };
}

export function getReplayConfigDetails() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_REPLAY_CONFIG_DETAILS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_REPLAY_CONFIG_DETAILS,
    request: requestParams
  };
}
export function updateReplayConfigDetails(replayConfigData) {
  return {
    type: types.GET_REPLAY_CONFIG,
    replayConfigData
  };
}

export function addReplayConfigFunction(newConfigReplay) {
  let serviceParams = {};
  serviceParams.payload = newConfigReplay;
  serviceParams.apiName = types.ADD_REPLAY_CONFIG_EXP;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_REPLAY_CONFIG_EXP,
    request: requestParams
  };
}
export function updateAddReplayConfig(configReplayAddResults) {
  return {
    type: types.CONFIG_REPLAY_ADD_RESULTS,
    configReplayAddResults
  };
}

export function editReplayConfigFunction(updatedConfigReplay) {
  let serviceParams = {};
  serviceParams.payload = updatedConfigReplay;
  serviceParams.apiName = types.EDIT_REPLAY_CONFIG_EXP;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.EDIT_REPLAY_CONFIG_EXP,
    request: requestParams
  };
}
export function updateEditReplayConfig(configReplayEditResults) {
  return {
    type: types.CONFIG_REPLAY_EDIT_RESULTS,
    configReplayEditResults
  };
}

export function deleteConfReplay(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_CONFIGURE_REPLAY;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_CONFIGURE_REPLAY,
    request: requestParams
  };
}
export function ExceptionConfigprogress() {

  return {
    type: types.PROGRESS_EXCEPTION_CONFIG_REPORT
  }
}

export function ExceptionConfigDataStore(data) {
  return {
    type: types.STORE_EXCEPTION_CONFIG_REPORT,
    data
  };
}

