import * as types from "../../constants/ActionTypes/Admin/solmanStagingTypes";
import { constructServiceUrl } from "../../services/constructUrl";

export function getSolmanStagingDetails() {
  let serviceParams = {};
  serviceParams.apiName = types.GET_SOLMAN_STAGING;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_SOLMAN_STAGING,
    request: requestParams,
  };
}
export function updateSolmanStagingDetails(solmanData) {
  return {
    type: types.SOLMAN_STAGING_DETAILS,
    solmanData,
  };
}
export function deleteSolmanStaging(payload, index) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_SOLMAN_STAGING;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_SOLMAN_STAGING,
    request: requestParams,
    index,
  };
}
export function addSolmanStaging(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_SOLMAN_STAGING;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_SOLMAN_STAGING,
    request: requestParams,
  };
}

export function modifySolmanStaging(payload, index) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_SOLMAN_STAGING;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.UPDATE_SOLMAN_STAGING,
    request: requestParams,
    index,
  };
}
export function Solmanprogress() {
  return {
    type: types.PROGRESS_SOLMAN_REPORT,
  };
}

export function SolmanDataStore(data) {
  return {
    type: types.STORE_SOLMAN_REPORT,
    data,
  };
}
