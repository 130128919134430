import moment from "moment";
import { default as momentZone } from "moment-timezone";

const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true,
};

const COLUMNS = [
  fieldHead,
  {
    field: "application_nm",
    headerName: "Application",
    sortColumns: "applicationName",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "component_nm",
    headerName: "Component",
    sortColumns: "componentName",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "service_nm",
    headerName: "Service",
    sortColumns: "serviceName",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "log_status",
    headerName: "Status",
    sortColumns: "status",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "timemark",
    headerName: "Time Occured",
    filter: true,
    sortColumns: "timestamp",
    sort: "desc",
    isDisabled: true,
    default: true,
    minWidth: 220,
    cellRenderer: function dateValueGettter(params) {
      if (params?.context?.timeZone)
        return momentZone(params.value)
          .tz(window.timeZone)
          .format("YYYY-MM-DD HH:mm:ss.SSS");
      return moment(params.value).local().format("YYYY-MM-DD HH:mm:ss.SSS");
    },
  },
  {
    field: "host_nm",
    headerName: "HostName",
    sortColumns: "hostname",
    filter: true,
    minWidth: 150,
  },
  {
    field: "business_id",
    headerName: "Business ID",
    sortColumns: "businessID",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "business_id_two",
    headerName: "Business ID2",
    sortColumns: "businessID2",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "trans_id",
    headerName: "TransactionID",
    sortColumns: "transactionID",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "time_duration",
    headerName: "Time Duration",
    sortColumns: "time_duration",
    filter: true,
    minWidth: 150,
  },
  {
    field: "application_domain",
    headerName: "Application Domain",
    sortColumns: "application_domain",
    filter: true,
    minWidth: 150,
  },
  {
    field: "trans_domain",
    headerName: "Transaction Domain",
    sortColumns: "trans_domain",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "trans_type",
    headerName: "Transaction Type",
    sortColumns: "trans_type",
    filter: true,
    default: true,
    minWidth: 150,
  },
  {
    field: "log_level",
    headerName: "Log Level",
    sortColumns: "log_level",
    filter: true,
    minWidth: 150,
  },
];

export default COLUMNS;
