export const GET_EXCEPTION_CATEGORY = 'GET_EXCEPTION_CATEGORY';
export const GET_EXCEPTION_SEVERITY = 'GET_EXCEPTION_SEVERITY';
export const GET_EXCEPTION_TYPE = 'GET_EXCEPTION_TYPE';
export const GET_APPLICATION_EXCEPTION_CATEGORY = 'GET_APPLICATION_EXCEPTION_CATEGORY';
export const GET_APPLICATION_EXCEPTION_SEVERITY = 'GET_APPLICATION_EXCEPTION_SEVERITY';
export const GET_APPLICATION_EXCEPTION_TYPE = 'GET_APPLICATION_EXCEPTION_TYPE';
export const APPLICATION_DATA = 'APPLICATION_DATA';
//category
export const ADD_EXCEPTION_CATEGORY='ADD_EXCEPTION_CATEGORY';
export const UPDATE_EXCEPTION_CATEGORY='UPDATE_EXCEPTION_CATEGORY';
export const DELETE_CATEGORY='DELETE_CATEGORY';
//severity
export const ADD_EXCEPTION_SEVERITY='ADD_EXCEPTION_SEVERITY';
export const UPDATE_EXCEPTION_SEVERITY='UPDATE_EXCEPTION_SEVERITY';
export const DELETE_SEVERITY='DELETE_SEVERITY';
//type
export const ADD_EXCEPTION_TYPE='ADD_EXCEPTION_TYPE';
export const UPDATE_EXCEPTION_TYPES='UPDATE_EXCEPTION_TYPES';
export const DELETE_TYPE='DELETE_TYPE';

export const PROGRESS_LIST_REPORT='PROGRESS_LIST_REPORT';
