import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Form, Modal, Input, Row, Tooltip } from 'antd';
import { default as momentZone } from 'moment-timezone';
import { InfoCircleOutlined } from '@ant-design/icons';
import AdminModalFooter from '../../CommonComponents/Modal/AdminModalFooter';
import { formItemsLayout } from '../../../constants/AdminModalForm/FormStyleConfig';
import SelectDropDown from '../../CommonComponents/SelectDropDown';
import { formMessages } from '../../../constants/AlertMessages';

const NewTableConfig = ({ ...props }) => {
  const [form] = Form.useForm();
  const tableTypeValue = Form.useWatch('dataType', form);

  const tableTypes = [
    {
      label: 'Log',
      value: 'log'
    },
    {
      label: 'Exception',
      value: 'exception'
    }
  ];

  useEffect(() => {
    if (props.editFlag) {
      form.setFieldsValue({ ...props.data[0] });
    }
  }, []);

  const setTime = (isTimeZone, time) => {
    // console.log('timee', isTimeZone, time);
    if (isTimeZone) {
      return momentZone(time).tz(window.timeZone).format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
    } else {
      return moment(time).local().format('YYYY-MM-DDTHH:mm:ss.SSS+0000');
    }
  };

  const onTableTypeChange = (selectedVal) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      dataType: selectedVal,
      retentionDays: selectedVal === 'log' ? 28 : 35,
      topicName: '',
      tableName: ''
    });
  };

  const onSaveHandler = (close) => {
    form.validateFields().then(async (values) => {
      values = {
        ...values,
        lastUpdated: setTime(props.timeZone, values.lastUpdated)
      };

      props.saveData(values, close);
    });
  };

  const displayLastUpdatedValue = (value) => {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  };

  const getSectorDropdownOptions = (sectorList) => {
    return sectorList.map((item) => ({
      label: item.sectorName,
      value: item.sectorName
    }));
  };

  const tableType = tableTypeValue === 'log' ? 'logs' : 'exceptions';
  return (
    <Modal
      open={props.isModalOpen}
      title={(props.editFlag ? 'Edit' : 'New') + ' Table Configuration'}
      onCancel={props.onCloseModal}
      footer={
        props.editFlag ? (
          <AdminModalFooter
            close
            update
            onUpdate={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
        ) : (
          <AdminModalFooter
            save
            saveAndClose
            close
            onSave={() => onSaveHandler(false)}
            onSaveAndClose={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
        )
      }
      style={{ minWidth: 900 }}
      className="admin-modal-wrapper"
    >
      <Form
        id="exception-config"
        form={form}
        {...formItemsLayout}
        labelAlign="left"
        colon
        initialValues={{
          dataType: 'log',
          retentionDays: 28,
          recordCount: 0,
          tableSize: 0,
          solrCoreSize: 0,
          lastUpdated: displayLastUpdatedValue(moment())
        }}
      // onValuesChange={onFormValueChangeHandler}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Table Type"
              name="dataType"
              rules={[{ required: true, message: 'Table Type is mandatory!' }]}
            >
              <SelectDropDown onChange={(val) => onTableTypeChange(val)} options={tableTypes} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Table Name"
              name="tableName"
              rules={[
                { required: true, message: 'Table Name is Mandatory!' },
                {
                  pattern: new RegExp(`^${tableType}_`),
                  message: `${formMessages.INVALID_TABLE_NAME}${tableType}_`
                }
              ]}
            >
              <Input
                suffix={
                  <Tooltip
                    placement="bottom"
                    title={`${formMessages.INVALID_TABLE_NAME}${tableType}_`}
                  >
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Retention Days" name="retentionDays">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Topic Name"
              name="topicName"
              rules={[
                { required: true, message: 'Topic Name is Mandatory!' },
                {
                  pattern: new RegExp(`^pep-cgf-cle-${tableType}-`),
                  message: `${formMessages.INVALID_TABLE_TOPIC}${tableType}-`
                }
              ]}
            >
              <Input
                suffix={
                  <Tooltip
                    placement="bottom"
                    title={`${formMessages.INVALID_TABLE_TOPIC}${tableType}-`}
                  >
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Record Count" name="recordCount">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true, message: 'Sector is Mandatory!' }]}
              label="Sector"
              name="sector"
            >
              <SelectDropDown options={getSectorDropdownOptions(props.applicationSectorList)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Table Size" name="tableSize">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Solr Core Size" name="solrCoreSize">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Space Occupied" name="spaceOccupied">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Updated" name="lastUpdated">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

NewTableConfig.propTypes = {
  applicationSectorList: PropTypes.array,
  onCloseModal: PropTypes.func,
  saveData: PropTypes.func,
  data: PropTypes.any,
  editFlag: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  timeZone: PropTypes.bool
};

export default NewTableConfig;
