import * as types from '../../constants/ActionTypes/exceptionTypes';
import objectAssign from 'object-assign';
import update from 'immutability-helper';
import _ from 'lodash';
/* eslint-disable no-unused-expressions */
// import configureStore from '../../store/configureStore';
// const store = configureStore();
// import { persistStore } from 'redux-persist';
// const persistor = persistStore(store);
const initialState = {
    exceptionSearchResults: {
        pageID: null,
        data: [],
        datax: [],
        exceptionDetails: [],
        isFetching: false,
        errorMessage: null,
        recordCount: null,
        exceptionUpdateStatus: {
            status: null,
            id: null
        },
        isNewReponse: false,
        isRefresh: null,
        isProcessEnd: false
    },
    isNoRecord: false,
    replayResultData: [],
    searchCriterias: null,
    totalRecords: null,
    exceptionFilterResult: {
        filterStatus: null,
        action: null
    },
    categories: [],
    types: [],
    severities: [],
    replayReportData: [],
    isReplayLoading: false
};
// function getTotalRecordCount(totalRecords) {
//     let recordCount = null;
//     if (totalRecords < 2000) {
//         recordCount = totalRecords;
//     } else {
//         recordCount = 2000;
//     }
//     return recordCount;
// }
function filterExceptionResult(filteredExceptions, exceptionData) {
    let updatedData = JSON.parse(JSON.stringify(exceptionData));
    for (let i = 0; i < filteredExceptions.length; i++) {
        for (let j = 0; j < updatedData.length; j++) {
            if (filteredExceptions[i].exceptionID === updatedData[j].exception_id) {
                updatedData.splice(j, 1);
            }
        }
    }
    return updatedData;
}
function deleteExceptions(purgedExceptions, exceptionData) {
    let updatedData = JSON.parse(JSON.stringify(exceptionData));
    for (let i = 0; i < purgedExceptions.length; i++) {
        for (let j = 0; j < updatedData.length; j++) {
            if (purgedExceptions[i].exceptionID === updatedData[j].exception_id) {
                updatedData.splice(j, 1);
            }
        }
    }
    return updatedData;
}
function getUpdatedExceptionData(replayResult, replayedExceptions, exceptionData) {
    let newExceptionData = JSON.parse(JSON.stringify(exceptionData));
    let replayResultObj = replayResult
    if (typeof replayResult === "string") replayResultObj = JSON.parse(replayResult);
    _.each(replayResultObj, (value) => {
        let index = _.findIndex(newExceptionData, ["exception_id", value.exceptionID])
        if (index > -1) {
            newExceptionData[index].status = value.replayStatus;
            newExceptionData[index].resolution_description = value.resolution_description ? value.resolution_description : ""
        }
    })
    // for(let i = 0; i < replayedExceptions.length; i++) {
    //   for(let j=0; j < replayResultObj.length; j++) {
    //     if(replayedExceptions[i].exceptionID === replayResultObj[j].exceptionID) {
    //       newExceptionData[replayedExceptions[i].rowID].status = replayResultObj[j].replayStatus;
    //     }
    //   }
    // }
    return newExceptionData;
}

export default function exceptionReducer(state = initialState, action) {
    switch (action.type) {
        case types.SEARCH_EXCEPTIONS:
            var isFetching = state.exceptionSearchResults.data.length === 0 ? true : false;
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    isFetching: { $set: isFetching },
                    isProcessEnd: { $set: false }
                }
            }));
        case types.EXCEPTION_SEARCH_SUCCESS:
            // var totalRecords = getTotalRecordCount(action.exceptionData.totalRecords);
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    pageID: { $set: action.exceptionData.pageID },
                    data: { $push: action.exceptionData.data },
                    datax: { $set: action.exceptionData.data },
                    isFetching: { $set: false },
                    errorMessage: { $set: null },
                    recordCount: { $set: 0 },
                    isNewReponse: { $set: false },
                    isRefresh: { $set: null },
                    isProcessEnd: { $set: true }
                },
                searchCriterias: { $set: action.searchCriterias }
            }));
        case types.EXCEPTION_SEARCH_FAILURE:
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    errorMessage: { $set: action.errorMessage },
                    isFetching: { $set: false },
                    isProcessEnd: { $set: false }
                },
            }));
        case types.SORT_EXCEPTIONS:
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    isFetching: { $set: true }
                }
            }));
        case types.SORT_EXCEPTIONS_SUCCESS:
            // var records = getTotalRecordCount(action.exceptionData.totalRecords);
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    pageID: { $set: action.exceptionData.pageID },
                    data: { $set: action.exceptionData.data },
                    datax: { $set: action.exceptionData.data },
                    isFetching: { $set: false },
                    errorMessage: { $set: null },
                    recordCount: { $set: 0 },
                    isNewReponse: { $set: false },
                    isRefresh: { $set: null }
                },
                searchCriterias: { $set: action.searchCriterias }
            }));
        case types.SORT_EXCEPTIONS_ERROR:
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    errorMessage: { $set: action.message },
                    isFetching: { $set: false }
                }
            }));
        case types.EXCEPTION_DETAILS:
            return {
                ...state, exceptionSearchResults: { ...state.exceptionSearchResults, errorMessage: { $set: null }, exceptionDetails: state.exceptionSearchResults.exceptionDetails.concat(action.exceptionDetails) }
            };
        case types.CLEAR_EXCEPTION_DATA: {
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    pageID: { $set: null },
                    data: { $set: [] },
                    datax: { $set: [] },
                    recordCount: { $set: null },
                    errorMessage: { $set: null },
                    exceptionDetails: { $set: [] },
                    isRefresh: { $set: null },
                    isProcessEnd: { $set: false }
                },
                totalRecords: { $set: null },
                exceptionFilterResult: {
                    filterStatus: { $set: null },
                    action: { $set: null },
                    recordCount: { $set: null }
                },
            }));
        }
        case types.EXCEPTION_REPLAY_RESULT: {
            var updatedExceptions = getUpdatedExceptionData(action.replayResult, action.replayedExceptions, state.exceptionSearchResults.data);
            let newSetException = JSON.parse(JSON.stringify(state.replayResultData));
            let response = JSON.parse(JSON.stringify(action.replayedExceptions));
            let replayResultObj = action.replayResult
            if (typeof action.replayResult === "string") {
                replayResultObj = JSON.parse(action.replayResult);
            }
            newSetException = newSetException.concat(response)
            _.each(replayResultObj, ({ exceptionID, replayStatus, purged, filtered }) => {
                if (replayStatus !== "Scheduled" || purged === "Y" || filtered === "Y") {
                    if (newSetException && newSetException.length > 0) {
                        _.remove(newSetException, ["exceptionID", exceptionID])
                    }
                }
            })
            if (newSetException.length > 0)
                newSetException = _.uniqBy(_.compact(newSetException), "exceptionID")
            let newState = objectAssign({}, update(state, {
                exceptionSearchResults: {
                    data: { $set: updatedExceptions },
                    datax: { $set: updatedExceptions },
                    isNewReponse: { $set: true },
                    isRefresh: { $set: null }
                },
                replayResultData: { $set: newSetException }
            }))
            return newState
        }
        case types.CLEAR_EXCEPTION_DETAILS:
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    exceptionDetails: { $set: [] }
                }
            }));
        case types.EXCEPTION_SEARCH_FILTER_EXCEPTIONS_RESULT:
            var updatedExceptionData = filterExceptionResult(action.filteredExceptions, state.exceptionSearchResults.data);
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    data: { $set: updatedExceptionData },
                    datax: { $set: updatedExceptionData },
                    recordCount: { $set: state.exceptionFilterResult.totalRecords - action.filteredExceptions.length },
                    isNewReponse: { $set: true },
                    isRefresh: { $set: "filterProcess" }
                }
            }));
        case types.EXCEPTION_FILTER_STATUS:
            return objectAssign({}, update(state, {
                exceptionFilterResult: {
                    filterStatus: { $set: action.filterStatus },
                    action: { $set: action.actionType }
                },
                isNoRecord: { $set: true },
                totalRecords: { $set: null },
                exceptionSearchResults: {
                    pageID: { $set: null },
                    data: { $set: [] },
                    datax: { $set: [] },
                    recordCount: { $set: null },
                    errorMessage: { $set: null },
                    exceptionDetails: { $set: [] },
                    isRefresh: { $set: null }
                }
            }));

        case types.CLEAR_FILTER_DATA:
            return objectAssign({}, update(state, {
                exceptionFilterResult: {
                    filterStatus: { $set: null },
                    action: { $set: null },
                    recordCount: { $set: null }
                },
                totalRecords: { $set: null }
            }));
        case types.EXCEPTION_BULK_REPLAY_STATUS: {
            let newData = []
            let tableData = JSON.parse(JSON.stringify(state.exceptionSearchResults.data))
            if (state.exceptionSearchResults.data.length > 0) {
                newData = tableData.map(val => {
                    val.status = action.filterStatus.requestStatus;
                    return val
                })
            }
            let newState = objectAssign({}, update(state, {
                exceptionSearchResults: {
                    data: { $set: newData },
                    datax: { $set: newData },
                    isNewReponse: { $set: true },
                    isRefresh: { $set: "resolveProcess" }
                }
            }))
            return newState
        }

        case types.PURGE_EXCEPTIONS_RESULT:
            var newExceptionData = deleteExceptions(action.purgedExceptions, state.exceptionSearchResults.data);
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    data: { $set: newExceptionData },
                    datax: { $set: newExceptionData },
                    recordCount: { $set: state.exceptionFilterResult.totalRecords - action.purgedExceptions.length },
                    isNewReponse: { $set: true },
                    isRefresh: { $set: "purgeProcess" }
                }
            }));
        case types.REPLAY_REPORT_DATA:
            return objectAssign({}, state, {
                replayReportData: action.reportData.fileReplaysFileReplayResponses,
                isReplayLoading: false
            })
        case types.REPLAY_REPORT_LOADING:
            return objectAssign({}, state, {
                isReplayLoading: true
            })
        case types.TOTAL_EXCEPTION_RECORD_COUNT:
            return objectAssign({}, update(state, {
                totalRecords: { $set: action.exceptionData }
            }));
        case types.SAVE_EXCEPTION_DETAILS_RESULT:
            return objectAssign({}, update(state, {
                exceptionSearchResults: {
                    exceptionUpdateStatus: {
                        status: { $set: action.status },
                        id: { $set: action.exceptionId }
                    },
                    isRefresh: { $set: null }
                }
            }));
        case types.UPDATE_EXCEPTION_CST_DATA:
            return objectAssign({}, update(state, {
                [action.data.type]: { $set: action.data.data }
            }));
        default:
            return state;
    }
}
