const logDetailList = [
    { label: "Application", key: "application_nm" },
    { label: "Business ID", key: "business_id" },
    { label: "Application Domain", key: "application_domain" },
    { label: "Business ID2", key: "business_id_two" },
    { label: "Service", key: "service_nm" },
    { label: "Transaction Id", key: "trans_id" },
    { label: "Component", key: "component_nm" },
    {
        label: "Transaction Type",
        key: "trans_type",
    },
    { label: "Host Name", key: "host_nm" },
    { label: "Transaction Domain", key: "trans_domain" },
    { label: "Status", key: "log_status" },
    { label: "Level", key: "log_level" },
    { label: "Time Duration", key: "time_duration" },
    { label: "Timestamp", key: "timemark" },
];
export default logDetailList;