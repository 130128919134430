import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Checkbox, InputNumber } from "antd";
import PropTypes from "prop-types";
import { formItemsLayout, formItemsDefaultStyle } from "../../../constants/AdminModalForm/FormStyleConfig";
import AdminModalFooter from "../../CommonComponents/Modal/AdminModalFooter";
import SelectDropDown from '../../CommonComponents/SelectDropDown';
import { applicationDataInstance } from "../../../services/applicationDataService";

const NewScheduleReportConfig = ({ ...props }) => {
    useEffect(() => {
        if (props.editFlag) {
            form.setFieldsValue({ ...props.data[0] });
            if(props?.data?.[0]?.["applicationName"]){
                onFormValueChangeHandler(props.data[0]);
            }
        }
    }, []);

    const [form] = Form.useForm();
    const applicationNames = applicationDataInstance.getApplicationNames();
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const onSaveHandler = (close) => {
        form.validateFields()
            .then(async values => {
                if (props.editFlag) {
                    values = {
                        "scheduleConfigId": props.data[0]["scheduleConfigId"],
                        ...values
                    }
                }
                props.saveData(values, close);
            })
    }

    const onFormValueChangeHandler = (changedValues) => {
        if (changedValues["applicationName"]) {
            setCategoryOptions(changedValues["applicationName"] ?
                applicationDataInstance.getCategoriesForApplication(changedValues["applicationName"]).filter(cat => cat.exceptionCategoryName != "") //Temporary fix need to remove 
                : [])

            setTypeOptions(changedValues["applicationName"] ?
                applicationDataInstance.getTypesForApplication(changedValues["applicationName"]).filter(type => type.exceptionTypeName != "")
                : [])
        }
    }
    return (<Modal
            open={props.isModalOpen}
            title={(props.editFlag ? "Edit" : "New") + " Schedule Config"}
            onCancel={props.onCloseModal}
            footer={
                (props.editFlag ?
                    <AdminModalFooter
                        close update
                        onUpdate={() => onSaveHandler(true)}
                        onClose={props.onCloseModal}
                    />
                    :
                    <AdminModalFooter
                        save close saveAndClose
                        onSave={() => onSaveHandler(false)}
                        onSaveAndClose={() => onSaveHandler(true)}
                        onClose={props.onCloseModal}
                    />)
            }
            style={{ minWidth: 800 }}
            className="admin-modal-wrapper"
        >
            <Form
                id="report-config"
                form={form}
                {...formItemsLayout}
                labelAlign="left"
                colon
                onValuesChange={onFormValueChangeHandler}
            >
                <Form.Item label="Application" name="applicationName"
                    rules={[{ required: true, message: 'Application is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <SelectDropDown
                        options={applicationNames}
                        disabled={props.editFlag}
                    />
                </Form.Item>
                <Form.Item label="Service name" name="service"
                    rules={[{ required: true, message: 'Service name is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <Input disabled={props.editFlag}/>
                </Form.Item>
                <Form.Item label="Category" name="category"
                    style={formItemsDefaultStyle}>
                    <SelectDropDown
                        fieldNames={{
                            "label": "exceptionCategoryName",
                            "value": "exceptionCategoryName"
                        }}
                        options={categoryOptions}
                    />
                </Form.Item>
                <Form.Item label="Type" name="type"
                    style={formItemsDefaultStyle}>
                    <SelectDropDown
                        fieldNames={{
                            "label": "exceptionTypeName",
                            "value": "exceptionTypeName"
                        }}
                        options={typeOptions}
                    />
                </Form.Item>
                <Form.Item label="Component" name="component"
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
                <Form.Item label="Code" name="code"
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
                <Form.Item label="Dump Analysis" name="dumpAnalysis"
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
                <Form.Item label="Message" name="message"
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
                <Form.Item label="Message NVP" name="messageNVP"
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
                <Form.Item label="Replay" name="replayEnabled"
                    style={formItemsDefaultStyle}
                    valuePropName="checked">
                    <Checkbox>Replay Enabled</Checkbox>
                </Form.Item>
                <Form.Item label="Search Window" name="searchWindow"
                    rules={[{ required: true, message: 'Search Window is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <InputNumber min={0} max={23} />
                </Form.Item>
                <Form.Item label="Schedule Time" name="scheduledTime"
                    rules={[{ required: true, message: 'Schedule Time is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <InputNumber min={0} max={23} />
                </Form.Item>
            </Form>
        </Modal>)
}
NewScheduleReportConfig.propTypes = {
    saveData: PropTypes.func,
    editFlag: PropTypes.any,
    data: PropTypes.any,
    onCloseModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
};
export default NewScheduleReportConfig;