import React from "react";
import PropTypes from "prop-types";
// import Logo from "../../../assets/images/PepsiCoFull_EBS.PNG";
// import error401 from "../../../assets/images/error_401.jpg";
// import error500 from "../../../assets/images/error_500.png";
// import { onUserSignOut } from "../../../actions/UserAccess/userAccessActions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useOktaAuth } from '@okta/okta-react';
import "./ErrorModal.css";

const ErrorModal = (props) => {
  const { oktaAuth } = useOktaAuth();
  
  const onUserSignOutError = async () => {
    await oktaAuth.signOut();
    sessionStorage.clear();
    localStorage.clear();
  };

  return (
    <div>
      {/* <div className="modal-header">
        <div className="logoWidth">
          <img src={window.errorLogo} alt="Error" />
        </div>
      </div> */}
      <div className="d-flex justify-content-between">
        <div style={{ width: "100%" }} title={window.errorMessage}>
          <div className="imgErr">
            <img
              src={props.isUnauth ? window.error401 : window.error500}
              alt="Error"
            />
          </div>
          <button
            type="button"
            className="close closeIcon"
            data-dismiss="modal"
            title="Close"
          >
            ×
          </button>
          {/* <div className="closeIcon">X</div> */}
          {/* <div className="errorHeader">
            {props.isUnauth
              ? "Sorry, you are not authorized to access this page. Please contact support team."
              : "Oops! Something went wrong"}
          </div> */}
          <div className={`brnErr`}>
            {props.isUnauth ? (
              <>
                <button type="button" className="btn btn-dark mb10">
                  <a
                    className="mailToHref"
                    id="error_report_mail"
                    href={`mailto:ITCLE@pepsico.com?subject=NextGen CLE Support`}
                  >
                    <i className="fa fa-exclamation-circle" /> Raise Request
                  </a>
                </button>
                <button
                  type="button"
                  className="btn btn-info"
                  id="error_report_reload"
                  onClick={onUserSignOutError}
                >
                  <i className="fa fa-refresh" /> Logout
                </button>
              </>
            ) : (
              <>
                <button type="button" className="btn btn-dark  mb10">
                  <a
                    className="mailToHref"
                    id="error_report_mail"
                    href={`mailto:ITCLE@pepsico.com?subject=NextGen CLE Support&body=Error Message:${window.errorMessage}`}
                  >
                    <i className="fa fa-exclamation-circle" /> Report
                  </a>
                </button>
                <button
                  type="button"
                  className="btn btn-info  mb10"
                  id="error_report_reload"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <i className="fa fa-refresh" /> Reload
                </button>
                <button
                  type="button"
                  id="error_report_back"
                  className="btn btn-secondary"
                  onClick={() => {
                    props.onCloseModal();
                    props.history.goBack();
                  }}
                >
                  <i className="fa fa-angle-left" /> Back
                </button>
                {/* <button
                  type="button"
                  id="error_report_close"
                  className="btn btn-danger"
                  onClick={props.onCloseModal}
                >
                  <i className="fa fa-times"></i> Close
                </button> */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(withRouter(ErrorModal));

ErrorModal.propTypes = {
  onCloseModal: PropTypes.func,
  history: PropTypes.object,
  isUnauth: PropTypes.any,
};
