import React from "react";
import ModalView from "../CommonComponents/Modal";

import EventEmitter from "events";

const myErrorEmitter = new EventEmitter();

export const openErrorModal = (auth) => {
  myErrorEmitter.emit("openErrorModal", auth);
};
// export const closeLoader = () => {
//     myErrorEmitter.emit('closeErrorModal')
// }

const ErrorHandle = () => {
  const [show, setShow] = React.useState(false);
  const [isUnauth, setIsUnauth] = React.useState(false);
  myErrorEmitter.once("openErrorModal", (auth) => {
    setIsUnauth(auth);
    setShow(true);
    myErrorEmitter.removeListener("openErrorModal", () => {});
  });
  // myErrorEmitter.once('closeErrorModal', () => {
  //     setShow(false)
  //     myErrorEmitter.removeListener('closeErrorModal', () => { })
  // })

  return show ? (
    <ModalView
      size="lg"
      isErrModal
      view="ErrorModal"
      isUnauth={isUnauth}
      onCloseModal={() => {
        setShow(false);
      }}
    />
  ) : (
    ""
  );
};

export default ErrorHandle;
