/* eslint-disable import/default */
import promise from "es6-promise";

import "isomorphic-fetch";
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap";
import { toast } from "react-toastify";
import errorLogo from "./assets/images/PepsiCoFull_EBS.PNG";
import error401 from "./assets/images/error_401.jpg";
import error500 from "./assets/images/error_500.png";
import Main from "./Main.js";
import "antd/dist/antd.min.css";
import "hamburgers/dist/hamburgers.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'ag-grid-community/dist/styles/ag-grid.min.css';
import "ag-grid-community/dist/styles/ag-theme-material.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./assets/scss/styles.scss";
import "./assets/scss/cle-dashboard.css";
import "./index.scss";
// PHASE II

promise.polyfill();
window.iosLoader = false;
window.onlyPrime = false;
window.errorMessage = "";
window.isSearchScreen = false;
window.timeZone = "America/Chicago";
// America/Regina
// America/Chicago
window.timeLimit = 450;
window.MAX_LENGTH = 400000;
window.FULL_MAX_LENGTH = 7500000;
window.fullLoader = false;
toast.configure({
  autoClose: 3000,
  pauseOnHover: true,
  draggable: true,
  position: "top-right",
});

window.errorLogo = new Image().src = errorLogo;
window.error401 = new Image().src = error401;
window.error500 = new Image().src = error500;

ReactDOM.render(
  <Main/>,
  document.getElementById("root")
);