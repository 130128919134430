import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Row,
  Col,
} from "antd";
import CleTabs from "../../../components/CommonComponents/CleTabs/CleTabs";
import SapReport from "../../../containers/Admin/SapReport";
import LogStatusConfigResults from '../../../containers/Admin/LogStatus';
import ExceptionStatusConfigResults from "../../../containers/Admin/ExceptionStatus";

const ReportManagement = ({ isLogConfigLoading, isExceptionLoading}) => {
  const [activeTab, setActiveTab] = useState('report');
  const handleTabChange = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  const loader = (
    <div className="text-center loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
  const tabItems = [
    {
      key: 'report',
      label: 'Report Config',
      children: <SapReport />,
    },
    {
      key: 'log',
      label: 'Log Report',
      children: isLogConfigLoading ? (
        loader
        ) : (
        <LogStatusConfigResults />
      )
    },
    {
      key: 'exception',
      label: 'Exception Report',
      children: isExceptionLoading ? (
        loader
        ) : (
        <ExceptionStatusConfigResults />
      )
    }
  ];
  return (
    <div>
      <Row>
        <Col span={24}>
          <Card bordered={false}>
            <CleTabs
              defaultActiveTab={"report"}
              activeTab={activeTab}
              onTabChangeHandler={handleTabChange}
              tabItems={tabItems}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
ReportManagement.propTypes = {
  isLogConfigLoading: PropTypes.bool,
  isExceptionLoading: PropTypes.bool,
};
export default ReportManagement;
