const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: false,
  headerCheckboxSelectionFilteredOnly: false,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true,
  maxWidth: 80,
};
const COLUMNS = [
  fieldHead,
  {
    field: "applicationName",
    headerName: "Application",

    filter: false,
    suppressMenu: true,
  },
  {
    field: "applicationDomain",
    headerName: "Application Domain",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "replayHost",
    headerName: "HOST URL",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "replayMode",
    headerName: "Replay Mode",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "username",
    headerName: "Username",
    filter: false,
    suppressMenu: true,
  },
  /* {
    field: 'password',
    headerName: 'Password',
    width: 150,
    cellStyle: cellStyles
  },*/ {
    field: "description",
    headerName: "Remarks",
    filter: false,
    suppressMenu: true,
  },
];
module.exports = { COLUMNS };
