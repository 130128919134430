import { call, put, fork, all, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/Admin/blobMetricsActions";
import * as types from "../../constants/ActionTypes/Admin/blobMetrics";
import { defaultAction } from "../../services/restService";
import { logger } from "redux-logger";
export function* getAllLogMetrics(payload) {
  try {
    const response = yield call(defaultAction, payload);
    yield put(actions.allBlobLogSuccess(response));
  } catch (e) {
    logger(e);
  }
}
export function* getAllExceptionMetrics(payload) {
  try {
    const response = yield call(defaultAction, payload);
    yield put(actions.allBlobExceptionSuccess(response));
  } catch (e) {
    logger(e);
  }
}

function* getLogTotMetrics(payload, allData) {
  const response = yield call(defaultAction, payload);
  allData.push(response.data.data);
  if (response.pageID) {
    getLogTotMetrics(payload);
  }
}

export function* getLogMetrics(payload) {
  try {
    let allData = [];
    const response = yield call(defaultAction, payload);
    allData.push(response.data.data);
    
    if (response.pageID) {
      getLogTotMetrics(payload, allData);
    }

    yield put(actions.detailBlobLogSuccess(response));
  } catch (e) {
    logger(e);
  }
}
export function* getExceptionMetrics(payload) {
  try {
    const response = yield call(defaultAction, payload);
    yield put(actions.detailBlobExceptionSuccess(response));
  } catch (e) {
    logger(e);
  }
}
export function* fetchLogAllMetrics() {
  yield takeLatest(types.FETCH_BLOB_LOG_ALL_METRICS, getAllLogMetrics);
}
export function* fetchExceptionAllMetrics() {
  yield takeLatest(
    types.FETCH_BLOB_EXCEPTION_ALL_METRICS,
    getAllExceptionMetrics
  );
}
export function* fetchLogMetrics() {
  yield takeLatest(types.FETCH_BLOB_LOG_METRICS, getLogMetrics);
}
export function* fetchExceptionMetrics() {
  yield takeLatest(types.FETCH_BLOB_EXCEPTION_METRICS, getExceptionMetrics);
}

export default function* root() {
  yield all([
    fork(fetchLogAllMetrics),
    fork(fetchExceptionAllMetrics),
    fork(fetchLogMetrics),
    fork(fetchExceptionMetrics),
  ]);
}
