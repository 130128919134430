import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Drawer, Form, Button, Tag, Space } from "antd";
import _ from "lodash";
import { InputBox } from '../FormElement/InputBox/InputBox'
import "./style.scss";
const availableColumns = {
  logs: [
    "application_nm",
    "component_nm",
    "service_nm",
    "log_status",
    "host_nm",
    "business_id",
    "business_id_two",
    "trans_id",
  ],
  exceptions: [
    "application_nm",
    "component_nm",
    "service_nm",
    "host_nm",
    "exception_category",
    "exception_severity",
    "exception_type",
    "exception_status",
    "trans_id",
    "host_nm",
    "business_id",
    "business_id_two",
  ],
};

const filterColumns = {
  logs: [],
  exceptions: [],
};

const FilterDrawer = (props) => {
  let availableColumnArray =
    props.screenType === "log"
      ? availableColumns.logs
      : availableColumns.exceptions;
  let filterColumnArray =
    props.screenType === "log" ? filterColumns.logs : filterColumns.exceptions;
  const [visible, setVisible] = useState(false);
  const [selectedArray, setSelectedArray] = useState({});
  const [filterStateObject, setfilterStateObject] = useState(() => {
    return availableColumnArray.reduce((obj, value) => {
      return {
        ...obj,
        [value]: {
          selectedTags: [],
          availableTags: [],
        },
      };
    }, {});
  });
  const [initialFilterStateObject, setInitialFilterStateObject] = useState({});
  const [searchValue, setSearchValue] = useState(undefined);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      setInitialFilterStateObject(filterStateObject);
      firstUpdate.current = false;
      return;
    }
  });

  useEffect(() => {
    showDrawer();
  }, [props.visible]);

  useEffect(() => {
    let clonedFilteredObject = {};
    if (Object.keys(props.filterStateData).length !== 0) {
      clonedFilteredObject = _.cloneDeep(props.filterStateData);
    } else {
      clonedFilteredObject = _.cloneDeep(filterStateObject);
    }
    afterFilterIsClicked(clonedFilteredObject);
  }, [props.filterStateData]);

  // Effect to handle search tag Input value change
  useEffect(() => {
    let searchObj = {};
    if (searchValue !== undefined) {
      Object.entries(initialFilterStateObject).map(([key, item]) => {
        searchObj = {
          ...searchObj,
          [key]: {
            selectedTags: item.selectedTags,
            availableTags: item.availableTags.filter(val => val.toUpperCase().includes(searchValue.toUpperCase())),
          }
        }
      });
      setfilterStateObject(searchObj);
    }
  }, [searchValue]);

  // Method to highlight/un-highlight tag on click selection
  const highlightSelectedTag = (tagName) => {
    var element = document.getElementById(tagName);
    let isTagSelected = false;
    if (element.classList.contains("ant-tag-processing")) {
      element.classList.remove("ant-tag-processing");
      isTagSelected = false;
    } else {
      element.classList.add("ant-tag-processing");
      isTagSelected = true;
    }

    return isTagSelected;
  };

  // Method to move tags from available to selected array or vice-versa based on select/unselect
  const moveSelectedTags = (name, heading, obj, isTagSelected) => {
    if (isTagSelected) {
      obj[heading].availableTags = obj[heading].availableTags.filter(
        (item) => item.toString() !== name.toString()
      );
      obj[heading].selectedTags.push(name.toString());
    } else {
      obj[heading].selectedTags = obj[heading].selectedTags.filter(
        (item) => item.toString() !== name.toString()
      );
      obj[heading].availableTags.push(name.toString());
    }
  };

  const handleFilterColumnItems = (tagHeading, index, isTagDeSelected) => {
    if (isTagDeSelected) {
      if (filterColumnArray[index] && filterColumnArray[index][tagHeading] > 1) {
        filterColumnArray[index][tagHeading] = filterColumnArray[index][tagHeading] - 1;
      } else {
        filterColumnArray.splice(index, 1);
      }
    } else {
      if (index !== -1) {
        filterColumnArray[index][tagHeading] = filterColumnArray[index][tagHeading] + 1;
      } else {
        filterColumnArray.push({ [tagHeading]: 1 })
      }
    }
  }

  const onClickTag = (tagHeading, tagName) => {
    let clonedObject2 = _.cloneDeep(filterStateObject);
    const isTagSelected = highlightSelectedTag(`${tagHeading}--${tagName}`);
    const index = filterColumnArray.findIndex(obj => obj[tagHeading]);

    handleFilterColumnItems(tagHeading, index, !isTagSelected);
    if (index !== -1) {
      moveSelectedTags(tagName, tagHeading, clonedObject2, isTagSelected);
    } else {
      availableColumnArray = availableColumnArray.filter(
        (data) => data !== tagHeading
      );
      moveSelectedTags(tagName, tagHeading, clonedObject2, isTagSelected);
    }
    afterFilterIsClicked(clonedObject2);
  };

  const checkIfNoTagSelected = (clonedObject) => {
    let count = 0;
    for (const heading in clonedObject) {
      if (clonedObject[heading].selectedTags.length === 0) {
        count++;
      }
    }

    return count === Object.keys(clonedObject).length;
  }

  const afterFilterIsClicked = (clonedObject) => {
    let filteredArray = [];
    if (checkIfNoTagSelected(clonedObject)) {
      filteredArray = [...props.rowData];
      filterColumnArray.length = 0;
    } else {
      filteredArray = props.rowData.filter((item) => {
        let checkFilterColumns = filterColumnArray.map((value) =>
          clonedObject[Object.keys(value)[0]].selectedTags.includes(item[Object.keys(value)[0]].toString())
        );
        return !checkFilterColumns.includes(false);
      });
    }

    // Set FilteredRecords
    setSelectedArray(filteredArray);

    // Empty all Available Tags
    setEmptyAvailableTags(availableColumnArray, clonedObject);

    // Update available Tags based on available Columns
    availableColumnArray
      .map((keyItem) => groupByAvailableColumn(filteredArray, keyItem))
      .map((groupedItem) => filterTopColumnValues(groupedItem))
      .forEach((group) => updateAvailableTags(group, clonedObject));

    for (const heading in clonedObject) {
      clonedObject[heading].availableTags = _.uniq(clonedObject[heading].availableTags.filter((item) => !clonedObject[heading].selectedTags.includes(item)));
      clonedObject[heading].selectedTags = _.uniq(clonedObject[heading].selectedTags.filter((item) => !clonedObject[heading].availableTags.includes(item)));
    }
    setfilterStateObject(clonedObject);
    setInitialFilterStateObject(clonedObject);
  };

  const setEmptyAvailableTags = (availableArray, clonedObject) => {
    availableArray.forEach((column) => {
      if (filterColumnArray.findIndex(obj => obj[column]) === -1) {
        clonedObject[column].availableTags = [];
      }
    });
  };

  const groupByAvailableColumn = (filteredArray, keyItem) => {
    return _.chain(filteredArray)
      .groupBy(keyItem)
      .map((value, key) => {
        return {
          columnName: keyItem,
          columnValue: key,
          totalRecordsCount: value.length,
        };
      })
      .value();
  };

  const fetchTop250ColumnValues = (groupedItem) => {
    let sortObject = _.orderBy(
      groupedItem,
      (a) => a.columnValue !== "" && a.totalRecordsCount,
      "desc"
    );
    sortObject = _.take(sortObject, 250);
    return sortObject;
  };

  const filterTopColumnValues = (groupedItem) => {
    if (groupedItem.length > 250) {
      return fetchTop250ColumnValues(groupedItem);
    } else {
      let index = groupedItem.findIndex((v) => v.columnValue.length === 0);
      index !== -1 && groupedItem.splice(index, 1);
      return groupedItem;
    }
  };

  const updateAvailableTags = (group, clonedObj) => {
    group.forEach((item) => {
      clonedObj[item.columnName].availableTags.push(item.columnValue);
    });
  };

  const setEmptyFilterColumnArray = () => {
    filterColumnArray.length = 0;
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    props.onCloseFilter();
    setEmptyFilterColumnArray();
  };

  const mapHeaderName = (key) => {
    switch (key) {
      case "application_nm":
        return "Application";
      case "component_nm":
        return "Component";
      case "service_nm":
        return "Service";
      case "log_status":
      case "exception_status":
        return "Status";
      case "host_nm":
        return "HostName";
      case "business_id":
        return "Business ID";
      case "business_id_two":
        return "Business ID2";
      case "trans_id":
        return "TransactionID";
      // case "status":
      //   return "Resolution Status";
      case "exception_severity":
        return "Severity";
      case "exception_type":
        return "Type";
      case "exception_category":
        return "Category";
    }
  };

  const onClickFilterButton = () => {
    props.selectedFilterOptions(selectedArray);
    props.updateFilterState(_.cloneDeep(filterStateObject));
    onClose();
  };

  const onClickResetFilterButton = () => {
    const clonedObj = _.cloneDeep(filterStateObject);
    for (const heading in clonedObj) {
      clonedObj[heading].availableTags = _.uniq(clonedObj[heading].availableTags, clonedObj[heading].selectedTags);
      // clonedObj[heading].availableTags = [];
      clonedObj[heading].selectedTags = [];
    }
    setfilterStateObject(clonedObj);
    setSelectedArray({});
    setSearchValue(undefined);
    props.selectedFilterOptions(props.initialSearchData);
    props.updateFilterState({});
    props.resetDisplaycount();
  }

  const showContentCheckCondn = (key) => key === "application_nm" && filterStateObject["application_nm"].availableTags.length === 1 && filterStateObject["application_nm"].selectedTags.length === 0 && !searchValue ? false : true;

  return (
    <div>
      <Drawer
        title="Filter Options"
        width={420}
        onClose={onClose}
        open={visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <>
            <Space>
              <Button onClick={onClickResetFilterButton}>Reset</Button>
              <Button onClick={onClickFilterButton} type="primary">
                Apply
              </Button>
            </Space>
            <InputBox
              id="searchTags"
              type="text"
              placeholder="Search Tag"
              stclass='search-tags'
              name="searchTags"
              size="large"
              value={searchValue}
              onChange={(evt) => setSearchValue(evt.target.value)}
              allowClear
            />
          </>
        }
      >

        <Form layout="vertical" hideRequiredMark>
          <div className="ant-list ant-list-split">
            <div className="ant-spin-nested-loading">
              <div className="ant-spin-container">
                <ul className="ant-list-items">
                  {Object.entries(filterStateObject).map(([key, value]) => {
                    return (
                      showContentCheckCondn(key) && (
                        <li key={key} className="ant-list-item">
                          <div className="ant-list-item-meta">
                            <div className="ant-list-item-meta-content">
                              <h4 className="ant-list-item-meta-title">
                                <div>{mapHeaderName(key)}</div>
                              </h4>
                              <div className="ant-list-item-meta-description">
                                {Object.entries(value).map(([i, item]) => {
                                  return (
                                    item.length !== 0 &&
                                    item.filter((item, idx) => idx < 25).map((tag) => {
                                      return <Tag
                                        id={`${key}--${tag}`}
                                        key={tag}
                                        className={
                                          i === "selectedTags" && tag
                                            ? "ant-tag-processing"
                                            : ""
                                        }
                                        onClick={() => {
                                          onClickTag(key, tag);
                                        }}
                                      >
                                        {tag}
                                      </Tag>
                                    }))
                                })}
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

FilterDrawer.propTypes = {
  filterStateData: PropTypes.object,
  initialSearchData: PropTypes.array,
  onCloseFilter: PropTypes.func,
  resetDisplaycount: PropTypes.func,
  rowData: PropTypes.array,
  screenType: PropTypes.string,
  selectedFilterOptions: PropTypes.func,
  updateFilterState: PropTypes.func,
  visible: PropTypes.bool,
};

export default FilterDrawer;
