import React, { PureComponent } from "react";
import { withRouter, Route, Switch } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from '../config/oktaConfig.js';
import LogSearch from "../containers/Log/LogSearch";
import ExceptionSearch from "../containers/Exception/ExceptionSearch";
import ExceptionBulkReplay from "../containers/Exception/BulkReplay";
import ApplicationConfig from "../containers/Admin/ApplicationConfiguration/Applications";
import ConfigureExceptions from "../containers/Admin/ConfigureExceptionResults";
import ConfigureColumnAlerts from "../containers/Admin/ConfigureAlertResults";
import SolmanStaging from "../containers/Admin/SolmanStaging";
import ConfigureReports from "../containers/Admin/ConfigureReportResults";
import ScheduleReport from "../containers/Admin/ScheduleReport";
import UserList from "../containers/User/UserAccess";
import GroupAccess from "../containers/User/GroupAccess";
import ApplicationAccess from "../containers/User/ApplicationAccess";
import TableConfig from "../containers/Admin/TableConfigContainer/index.js";
import ChargeBackCostAnalysis from "../containers/Admin/ChargeBackCostAnalysis";
//import UserGroupManagement from "../containers/Admin/UserManagement/UserGroupManagement";
//import ApplicationGroupManagement from "../containers/Admin/UserManagement/ApplicationGroupManagement";
import MessageMetrics from "../containers/Admin/MessageMetrics/MessageMetrics";
import CommonTable from "../containers/CommonTable/CommonTable";
import MainLayout from "./MainLayout";
import PrimaryLayout from "./PrimaryLayout";
import Home from '../components/Okta/Home';
import Unauthorized from "./Unauthorized";

class BaseLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.oktaAuth = new OktaAuth(config);
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      props.history.replace(
        toRelativeUrl(originalUri || "/", window.location.origin)
      );
    };
  }
  UNSAFE_componentWillMount() {
    if (window.location.pathname === "/callback"
      && window.location.search.includes("error=access_denied") && window.location.pathname !== "/unauthorized") {
      window.location.replace("/unauthorized");
    }
  }
  componentDidMount() {
    document.addEventListener("click", function () {
      if (document.activeElement.toString() === "[object HTMLButtonElement]") {
        document.activeElement.blur();
      }
    });
  }

  render() {
    const hasAdminAccess = this.props.userAccessInfo && this.props.userAccessInfo.adminAccess === "Y" ? true : false;

    return (
      <Security
        oktaAuth={this.oktaAuth}
        restoreOriginalUri={this.restoreOriginalUri}
      >
        <Switch>
          <Route path={`/`} exact component={Home} />
          <Route path={`/callback`} exact component={LoginCallback} />
          <Route path="/unauthorized" component={Unauthorized} />
          <SecureRouteWrapper
            path={"/logs/search-logs/:screenName"}
            component={LogSearch}
          />
          <SecureRouteWrapper
            path={"/exceptions/search-exceptions/:screenName"}
            component={ExceptionSearch}
          />
          <SecureRouteWrapper
            path={"/exceptions/search-exception-results"}
            component={CommonTable}
          />
          <SecureRouteWrapper
            path={"/exceptions/bulk-replay"}
            component={ExceptionBulkReplay}
          />
          <SecureRouteWrapper
            path="/admin/application-config"
            component={hasAdminAccess ? ApplicationConfig : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/admin/userlist"}
            component={hasAdminAccess ? UserList : Unauthorized} />
          <SecureRouteWrapper
            path={"/admin/createuser"}
            component={hasAdminAccess ? UserList : Unauthorized} />
          <SecureRouteWrapper
            path={"/admin/usergroup"}
            component={hasAdminAccess ? GroupAccess : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/admin/applicationgroup"}
            component={hasAdminAccess ? ApplicationAccess : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/admin/configure-exceptions"}
            component={hasAdminAccess ? ConfigureExceptions : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/blob/metrics"}
            component={hasAdminAccess ? MessageMetrics : Unauthorized} />
          <SecureRouteWrapper
            path={"/admin/configure-column-alerts"}
            component={hasAdminAccess ? ConfigureColumnAlerts : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/admin/solman-staging"}
            component={hasAdminAccess ? SolmanStaging : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/admin/report-config"}
            component={hasAdminAccess ? ConfigureReports : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/admin/table-config"}
            component={hasAdminAccess ? TableConfig : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/admin/report-schedule-config"}
            component={hasAdminAccess ? ScheduleReport : Unauthorized}
          />
          <SecureRouteWrapper
            path={"/admin/cost-analysis"}
            component={hasAdminAccess ? ChargeBackCostAnalysis : Unauthorized}
          />
          <Route path="*" component={Home} />
        </Switch>
      </Security>
    );
  }
}

function SecureRouteWrapper({ path: Path, component: Component }) {
  return (
    <SecureRoute path={Path}>
      <MainLayout>
        <PrimaryLayout>
          <Component />
        </PrimaryLayout>
      </MainLayout>
    </SecureRoute >
  );
}

BaseLayout.propTypes = {
  history: PropTypes.any,
  userAccessInfo: PropTypes.object,
};

SecureRouteWrapper.propTypes = {
  component: PropTypes.any,
  path: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    userAccessInfo: state.userAccess.userAccessInfo,
  };
};

const AppWithRouterAccess = withRouter(BaseLayout);
export default connect(mapStateToProps)(AppWithRouterAccess);