import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { Radio, Select, DatePicker, Button, Form, Row, Col, } from "antd";
import { applicationDataInstance } from "../../../services/applicationDataService";
import CleTabs from "../../../components/CommonComponents/CleTabs/CleTabs";
import MultiSelect from "../../../components/CommonComponents/MultiSelect/MultiSelect";
import * as actions from "../../../actions/Admin/blobMetricsActions";
import MessageMatricsComponent from "../../../components/Admin/MessageMatricsComponent/MessageMatricsComponent";
import WrapperPage from "../../../components/WrapperPage/Page";
import AxiosUrl from "../../../services/axiosInstanceCustom";
import "./style.scss";
const summaryDaysOption = [
    { label: "1 Day", value: 'daily' },
    { label: "7 Days", value: 'weekly' },
    { label: "30 Days", value: 'monthly' },
];
const MessageLogMetrics = () => {
    const dispatch = useDispatch();
    const globalBlobMetricsState = useSelector((state) => state.blobMetrics);
    const applicationNamesList = applicationDataInstance.getApplicationNames()

    const [selectedTabName, setSelectedTabName] = useState("Summary");
    const [isDetailDataLoading, setIsDetailDataLoading] = useState(false);
    const [selectedApplicationForSummary, setSelectedApplicationForSummary] = useState(null);
    const [selectedTypeList, setSelectedTypeList] = useState(["Exception", "Log"]);
    const [blobIntRequest, setBlobIntRequest] = useState(true);
    const [isFooterLoading, setIsFooterLoading] = useState(false);
    const [disabledSearch, setDisabledSearch] = useState(true);
    const [exceptionListForDetail, setExceptionListForDetail] = useState([]);
    const [logListForDetail, setLogListForDetail] = useState([]);

    useEffect(() => {
        getformListRequest('daily');
        return (() => {
            dispatch(actions.resetValue())
        })
    }, []);

    const getformListRequest = (day) => {
        dispatch(actions.getAllLogBlobMetrics(day));
        dispatch(actions.getAllExceptionBlobMetrics(day));
    };
    const onSummaryApplicationChangeHandler = (selectedApp) => {
        if (selectedApp.constructor.name === "Object") selectedApp = [selectedApp];
        setSelectedApplicationForSummary(selectedApp);
    }
    const onDetailFormChange = (changedValue, allValue) => {
        if (allValue[0].value && allValue[1].value) {
            setDisabledSearch(false);
        } else if (!disabledSearch) {
            setDisabledSearch(true);
        }
    }
    const onDetailSearchForm = (value) => {
        let logList=[];
        let expList=[];
        setLogListForDetail([]);
        setExceptionListForDetail([]);
        setBlobIntRequest(false);
        let request = {
            application: value.application === "ALL" ? "" : value.application,
            date: moment(value.date).format("YYYY-MM-DD"),
        };
        let payloadExp = {
            method: "post",
            url: "exception/blob/details/",
            data: request,
        };
        let payloadLog = {
            method: "post",
            url: "log/blob/details/",
            data: request,
        };
        const detailLogApl = (payload) =>
            new Promise((resolve, reject) => {
                AxiosUrl.metricDataInstance(payload)
                    .then((resp) => {
                        return resolve(resp);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        const detailExpApl = (payload) =>
            new Promise((resolve, reject) => {
                AxiosUrl.metricDataInstance(payload)
                    .then((resp) => {
                        return resolve(resp);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        const addType = (data, type) => {
            if (data.length > 0) return data.map((val) => ({ ...val, "type": type }));
            return [];
        };
        const recursionExp = async (requestData) => {
            if (requestData.data.pageID === null) return expList;
            let value = await detailExpApl(requestData);
            requestData.data.pageID = value.data.pageID;
            expList=[...expList, ...addType(value.data.data, "Exception")]
            setExceptionListForDetail(expList);
            if (isDetailDataLoading) {
                setIsDetailDataLoading(false);
            }
            await recursionExp(requestData);
        };
        const recursionLog = async (requestData) => {
            if (requestData.data.pageID === null) return logList;
            let value = await detailLogApl(requestData);
            requestData.data.pageID = value.data.pageID;
            logList=[...logList, ...addType(value.data.data, "Log")]
            setLogListForDetail(logList);
            if (isDetailDataLoading) {
                setIsDetailDataLoading(false);
            }
            await recursionLog(requestData);
        }
        try {
            setIsFooterLoading(true);
            setIsDetailDataLoading(true);
            (async () => {
                const expdata = recursionExp(payloadExp);
                const logdata = recursionLog(payloadLog);
                await expdata, await logdata;
                setIsFooterLoading(false);
                setIsDetailDataLoading(false);
            })()
        }
        catch (err) {
            console.log(err);
            setIsFooterLoading(false);
            setIsDetailDataLoading(false);
        }
    }
    return (
        <div className="position-relative message-metrics-wrapper content-message">
            <CleTabs
                activeTab={selectedTabName}
                defaultActiveTab={"Summary"}
                onTabChangeHandler={(tabKey) => selectedTabName !== tabKey ? setSelectedTabName(tabKey) : ""}
                tabItems={[{
                    key: 'Summary',
                    label: 'Summary',
                    children: (
                        <WrapperPage overLoading={selectedTabName === "Summary" ? false : isDetailDataLoading}>
                            <div style={{ marginTop: 20 }}>
                                <Row justify={"center"}>
                                    <Col span={20}>
                                        <MessageMatricsComponent
                                            list
                                            footerLoading={false}
                                            data={[...globalBlobMetricsState.listBlobLog, ...globalBlobMetricsState.listBlobException]}
                                            log={globalBlobMetricsState.listBlobLog}
                                            exception={globalBlobMetricsState.listBlobException}
                                            appName={selectedApplicationForSummary}
                                            tableName={selectedTypeList}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </WrapperPage>
                    )
                }, {
                    key: 'detailView',
                    label: 'Detail View',
                    children: (
                        <WrapperPage>
                            <div style={{ marginTop: 20 }}>
                                <Row justify={"center"}>
                                    <Col span={20}>
                                        {
                                            selectedTabName !== "Summary" &&
                                            blobIntRequest &&
                                            <div className="f2em text-center">
                                                Please Select Application
                                            </div>
                                        }
                                        {
                                            selectedTabName !== "Summary" &&
                                            !blobIntRequest &&
                                            <MessageMatricsComponent
                                                list={false}
                                                appName={null}
                                                tableName={selectedTypeList}
                                                log={logListForDetail}
                                                exception={exceptionListForDetail}
                                                data={[...logListForDetail, ...exceptionListForDetail]}
                                                footerLoading={isFooterLoading}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </WrapperPage>
                    )
                }]
                }
                tabExtraControlContent={
                    <div className='d-flex'>
                        <div>
                            <Select
                                mode="multiple"
                                size={"large"}
                                placeholder="Select Type"
                                showArrow
                                defaultValue={["Exception", "Log"]}
                                onChange={val => setSelectedTypeList(val)}
                                style={{ width: 250, marginRight: 20 }}
                            >
                                <Select.Option value="Exception">Exception</Select.Option>
                                <Select.Option value="Log">Log</Select.Option>
                            </Select>
                        </div>
                        {
                            selectedTabName === "Summary" &&
                            <div className='d-flex'>
                                <div style={{ width: 300 }}>
                                    <MultiSelect
                                        placeholder="Select Application"
                                        hasSelectAll={
                                            applicationNamesList && applicationNamesList.length > 2
                                                ? true
                                                : false
                                        }
                                        disableSearch={
                                            applicationNamesList && applicationNamesList.length > 10
                                                ? false
                                                : true
                                        }
                                        selected={applicationNamesList}
                                        options={applicationNamesList}
                                        multi
                                        clearable
                                        onSelectedChanged={onSummaryApplicationChangeHandler}
                                    />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <Radio.Group
                                        onChange={(ev) => getformListRequest(ev.target.value)}
                                        optionType="button"
                                        size="large"
                                        name="radiogroup"
                                        options={summaryDaysOption}
                                        defaultValue='daily'
                                        buttonStyle="solid"
                                    />
                                </div>
                            </div>
                        }
                        {
                            selectedTabName !== "Summary" &&
                            <Form
                                layout="inline"
                                onFieldsChange={onDetailFormChange}
                                size={"large"}
                                initialValues={{
                                    date: moment().subtract(0, "days"),
                                }}
                                name="blobMetrix"
                                onFinish={onDetailSearchForm}
                            >
                                <Form.Item name="application" required>
                                    <Select
                                        showSearch
                                        style={{ width: 300 }}
                                        placeholder="Select application"
                                    >
                                        {applicationNamesList.map((val, i) => {
                                            if (i === 0) {
                                                return (
                                                    <Select.Option key={"all"} value={"ALL"}>
                                                        {"ALL"}
                                                    </Select.Option>
                                                );
                                            }
                                            return (
                                                <Select.Option key={val.value} value={val.value}>
                                                    {val.label}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="date" style={{ marginLeft: 20 }}>
                                    <DatePicker
                                        format={"MM/DD/YYYY"}
                                        allowClear={false}
                                        disabledDate={current => current && current > moment().subtract(0, "days")}
                                    />
                                </Form.Item>
                                <Form.Item style={{ marginLeft: 20 }}>
                                    <Button
                                        type="primary"
                                        name="application"
                                        disabled={disabledSearch}
                                        htmlType="submit"
                                        icon={<i className="fas fa-search mr10" aria-hidden="true" />}
                                    >
                                        Search
                                    </Button>
                                </Form.Item>
                            </Form>
                        }
                    </div>
                }
            />
        </div>
    )
}
export default MessageLogMetrics;