const cellStyles = {
  "text-align": "center",
};
const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true,
};
const COLUMNS = [
  fieldHead,
  {
    field: "applicationName",
    headerName: "Application Name",
  },
  {
    field: "interfaceID",
    headerName: "Interface",
  },
  {
    field: "division",
    headerName: "Division",
  },
  {
    field: "busErrorCode",
    headerName: "Bus Error Code",
  },
  {
    field: "busErrorGroup",
    headerName: "Bus Error Group",
  },
  {
    field: "enable",
    headerName: "Enable",
  },

  {
    field: "useTechErrCode",
    headerName: "Use Tech Error Code",
    cellStyle: cellStyles,
  },
];
module.exports = { COLUMNS };
