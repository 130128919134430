import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CommonSearch from "../../CommonSearch/CommonSearch";

const ExceptionSearch = (props) => {  
  return (
    <CommonSearch
      SCREEN_TYPE={"exception"}
      userAccessInfo={props.userAccessInfo}
      matrixReport={props.exceptionInitMatrixData}
      timeZone={props.timeZone}
    />
  );
};

const mapStateToProps = (state) => ({
  userAccessInfo: state.userAccess.userAccessInfo,
  exceptionInitMatrixData: state.reports.exceptionReports.lastHourReport,
  timeZone: state.configReducer.isCstTimeZone,
});

ExceptionSearch.propTypes = {
  exceptionInitMatrixData: PropTypes.any,
  timeZone: PropTypes.any,
  userAccessInfo: PropTypes.object,
};

export default connect(mapStateToProps)(ExceptionSearch);
