import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TableConfig from "../../../components/Admin/TableConfig";
import * as actions from "../../../actions/Admin/tableConfigActions";
import { getApplicationSectorList } from '../../../actions/Admin/applicationConfigActions';

import "../../../components/Admin/TableConfig/indexConfig.scss";

const TableConfigContainer = () => {
  const dispatch = useDispatch();

  const indexGetData = useSelector((state) => state.tableConfig.indexGetData);
  const isLoading = useSelector((state) => state.tableConfig.isLoading);
  const timeZone = useSelector((state) => state.configReducer.isCstTimeZone);
  const applicationData = useSelector((state) => state.applications);
  const saveIndexConfig = (configData) => {
    dispatch(actions.addIndexConfig(configData));
  }

  useEffect(() => {
    dispatch(actions.getIndexDetails());

    if (applicationData.applicationSectorList.length === 0) {
      dispatch(getApplicationSectorList());
    }
  }, []);

  return (
    <TableConfig
      isLoading={isLoading}
      indexConfigData={indexGetData}
      saveIndexConfig={saveIndexConfig}
      timeZone={timeZone}
      applicationSectorList={applicationData.applicationSectorList}
    />
  );
}


export default TableConfigContainer;
