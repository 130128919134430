const ExceptionColumn = [
    {
        label: "Component",
        name: "componentName",
        id: "sel_componentName",
        type: "text",
        tabIndex: "14",
    },
    {
        label: "Start Date",
        name: "startTime",
        id: "sel_startTime",
        type: "dateTime",
        tabIndex: "2",
    },
    {
        label: "Transaction ID",
        name: "transactionID",
        id: "sel_transactionID",
        type: "text",
        tabIndex: "15",
    },
    {
        label: "End Date",
        name: "endTime",
        id: "sel_endTime",
        type: "dateTime",
        tabIndex: "3",
    },
    {
        label: "Business ID",
        name: "businessID",
        id: "sel_businessID",
        type: "text",
        tabIndex: "16",
    },

    {
        label: "Service",
        name: "serviceName",
        id: "sel_serviceName",
        type: "text",
        tabIndex: "4",
    },
    {
        label: "Business ID2",
        name: "businessID2",
        id: "sel_businessID2",
        type: "text",
        tabIndex: "17",
    },
    {
        label: "Select Category",
        name: "exceptionCategory",
        id: "sel_exceptionCategory",
        type: "select",
        tabIndex: "5",
    },

    {
        label: "Name",
        name: "messageName",
        id: "sel_messageName",
        type: "text",
        tabIndex: "18",
    },
    {
        label: "Exception Type",
        name: "exceptionType",
        id: "sel_exceptionType",
        type: "select",
        tabIndex: "6",
    },
    {
        label: "Value",
        name: "messageValue",
        id: "sel_messageValue",
        type: "text",
        tabIndex: "19",
    },
    {
        label: "Select Severity",
        name: "exceptionSeverity",
        id: "sel_exceptionSeverity",
        type: "select",
        tabIndex: "7",
    },

    {
        label: "Application Domain",
        name: "applicationDomain",
        id: "sel_applicationDomain",
        type: "text",
        tabIndex: "20",
    },
    {
        label: "HostName",
        name: "hostname",
        id: "sel_hostname",
        type: "text",
        tabIndex: "8",
    },
    {
        label: "Transaction Domain",
        name: "transactionDomain",
        id: "sel_transactionDomain",
        type: "text",
        tabIndex: "21",
    },
    {
        label: "Transaction Type",
        name: "transactionType",
        id: "sel_transactionType",
        type: "text",
        tabIndex: "9",
    },
    {
        label: "Transaction Data",
        name: "transactionData",
        id: "sel_transactionData",
        type: "text",
        tabIndex: "10",
    },
    {
        label: "Dump Analysis",
        name: "dumpAnalysis",
        id: "sel_dumpAnalysis",
        type: "text",
        tabIndex: "11",
    },
    {
        label: "Resolution Status",
        name: "exceptionStatus",
        id: "sel_exceptionStatus",
        type: "checkbox",
        tabIndex: "22",
    },
    { label: "Code", name: "errorCode", id: "sel_code", type: "text", tabIndex: "12" },
    {
        label: "Resolution Description",
        name: "resolutionDescription",
        id: "sel_resolutionDescription",
        type: "text",
        tabIndex: "23",
    },
    {
        label: "Message",
        name: "message",
        id: "sel_message",
        type: "text",
        tabIndex: "13",
    },
    {
        label: "Reply Destination",
        name: "replayDestination",
        id: "sel_replayDestination",
        type: "text",
        tabIndex: "24",
    },
    
];

export default ExceptionColumn;