import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Button } from "antd";
import { toast } from "react-toastify";
import { constructServiceUrl } from '../../../services/constructUrl';
import { getTokenToReqHeader } from '../../../utils/helpers';
import WrapperPage from "../../../components/WrapperPage/Page";
import SelectDropDown from '../../../components/CommonComponents/SelectDropDown';
import { costFormItemsLayout, formItemsDefaultStyle } from "../../../constants/AdminModalForm/FormStyleConfig";
import { chargeBackYearOptions } from '../../../constants/ChargeBackCostAnalysis/yearOptions';

const ChargeBackCostAnalysis = () => {
	const [monthOptions, setMonthOptions] = useState([]);
	const [yearOptions,] = useState(chargeBackYearOptions);
	const [isDownloading, setIsDownloading] = useState(false);
	const [form] = Form.useForm();
	useEffect(() => {
		const tempMonthOptions = [];
		for (var i = 1; i <= 12; i++) {
			tempMonthOptions.push({ value: i, label: new Date(0, i - 1).toLocaleString('default', { month: 'long' }) });
		}
		setMonthOptions(tempMonthOptions);
	}, []);

	const onExportHandler = () => {
		setIsDownloading(true);
		form.validateFields()
			.then(async values => {
				let serviceParams = { apiName: "COST_ANALYSIS_EXPORT" };
				const requestParams = constructServiceUrl(serviceParams);
				fetch(requestParams.url, {
					method: "POST",
					headers: {
						Accept: "application/octet-stream",
						"Content-Type": "application/json",
						Authorization: getTokenToReqHeader(),
					},
					body: JSON.stringify(values),
				})
					.then((data) => {
						return data.blob();
					})
					.then((data) => {
						var blob = new Blob([data], {
							type:
								"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
						});
						var objectUrl = URL.createObjectURL(blob);
						window.open(objectUrl);
						setIsDownloading(false);
						toast.success("Downloaded successfully");
					})
					.catch((err) => {
						setIsDownloading(false);
						toast.error(`Oops! Something went wrong ${err}`);
					});
			});
	};
	return (
		<WrapperPage overLoading={isDownloading}>
			<div className='cost-analysis-form-header'>Charge Back Cost Analysis</div>
			<Form
				id="cost-analysis"
				form={form}
				{...costFormItemsLayout}
				initialValues={{ month: 1, year: 2024 }}
				colon
				labelAlign="left"
				layout='vertical'
				className='cost-analysis-form'
			>
				<Row gutter={[16, 16]}>
					<Col span={8}>
						<Form.Item label="Month" name="month"
							rules={[{ required: true, message: 'Month is Mandatory!' }]}
							style={formItemsDefaultStyle}>
							<SelectDropDown
								options={monthOptions}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Year" name="year"
							rules={[{ required: true, message: 'Year is Mandatory!' }]}
							style={formItemsDefaultStyle}>
							<SelectDropDown
								options={yearOptions}
							/>
						</Form.Item>
					</Col>
					<Col span={8} style={{ display: 'flex', 'alignItems': 'end' }}>
						<Button
							type="primary"
							className="mid-black"
							icon={<i
								className="fas fa-cloud-download-alt mr10"
								aria-hidden="true"
							/>}
							onClick={onExportHandler}
						>
							Export
						</Button>
					</Col>
				</Row>
			</Form >
		</WrapperPage>
	);
};
export default ChargeBackCostAnalysis;
