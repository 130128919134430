import React from "react";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";
import { saveAs } from "file-saver";
import { Button, Col, Collapse, Row, Modal } from "antd";
import {
  HEADER_COLUMNS,
  JMS_COLUMNS,
} from "../../../constants/ColumnConfig/exceptionDetails";
import CleDetail from "../../CommonComponents/CleDetail/CleDetail";
import ReadMore from "../../CommonComponents/ReadMore/ReadMore";
import exceptionDetailList from "../../../constants/MessageMatrics/exceptionDetailList";
import logDetailList from "../../../constants/MessageMatrics/logDetailList";
import "./style.scss";

const { Panel } = Collapse;

const testJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};
const MessageMetricsDetail = (props) => {
  const convertData = (data) => {
    if (data && data !== "") {
      if (data.startsWith("<?xml")) {
        return <XMLViewer xml={data} />;
      } else if (testJSON(data)) {
        return <ReactJson src={JSON.parse(data)} displayDataTypes={false} />;
      }
    }

    return data;
  };
  let panelFirstDataAvail = false;
  let panelSecondDataAvail;
  let panelFirstDataSize;
  let panelSecondDataSize;
  if (
    props.selectedRow[props.selectedType === "Log" ? "trans_before" : "trans_data"] &&
    props.selectedRow[props.selectedType === "Log" ? "trans_before" : "trans_data"] !==
      ""
  ) {
    panelFirstDataAvail = true;
    panelFirstDataSize =
      props.selectedRow[props.selectedType === "Log" ? "trans_before" : "trans_data"]
        .length;
  }
  if (
    props.selectedRow[props.selectedType === "Log" ? "trans_after" : "dump_analysis"] &&
    props.selectedRow[
      props.selectedType === "Log" ? "trans_after" : "dump_analysis"
    ] !== ""
  ) {
    panelSecondDataAvail = true;
    panelSecondDataSize =
      props.selectedRow[props.selectedType === "Log" ? "trans_after" : "dump_analysis"]
        .length;
  }
  let jsmData =
    props.selectedRow.jms_header &&
    props.selectedRow.jms_header !== "" &&
    props.selectedRow.jms_header !== "null"
      ? JSON.parse(props.selectedRow.jms_header)
      : null;
  const downloadData = (panelName) => {
    if (panelName === "panel1") {
      saveAs(
        new Blob(
          [
            props.selectedRow[
              props.selectedType === "Log" ? "trans_before" : "trans_data"
            ],
          ],
          {
            type: "text/plain;charset=utf-8",
          }
        ),
        props.selectedType === "Log"
          ? "cle_log_trans_before"
          : "cle_exception_trans_data" + ".txt"
      );
    } else {
      saveAs(
        new Blob(
          [
            props.selectedRow[
              props.selectedType === "Log" ? "trans_after" : "dump_analysis"
            ],
          ],
          {
            type: "text/plain;charset=utf-8",
          }
        ),
        props.selectedType === "Log"
          ? "cle_log_trans_after"
          : "cle_exception_dump_data" + ".txt"
      );
    }
  };
  return (
    <Modal
      open={props.isModalOpen}
      title={props.selectedType + " Metrics Detail"}
      onCancel={props.onCloseModal}
      style={{ minWidth: "90%" }}
      className={"replay-config-detail-modal"}
      footer={null}
      centered
    >
      <div>
        <CleDetail
          schema={props.selectedType === "Log" ? logDetailList : exceptionDetailList}
          data={props.selectedRow}
        />
        <Collapse defaultActiveKey={["1", "2"]} ghost expandIconPosition="end">
          <Panel
            header={props.selectedType === "Log" ? "Before Data" : "Transaction Data"}
            key="1"
            className="collapse-header"
            extra={
              panelFirstDataAvail ? (
                <Button
                  type="primary"
                  shape="round"
                  onClick={(event) => {
                    event.stopPropagation();
                    downloadData("panel1");
                  }}
                >
                  Download
                </Button>
              ) : (
                false
              )
            }
          >
            {panelFirstDataAvail ? (
              <div className="collapse-content">
                {panelFirstDataSize > window.MAX_LENGTH ? (
                  <ReadMore
                    data={
                      props.selectedRow[
                        props.selectedType === "Log" ? "trans_before" : "trans_data"
                      ]
                    }
                    onDownload={() => downloadData("panel1")}
                  />
                ) : (
                  convertData(
                    props.selectedRow[
                      props.selectedType === "Log" ? "trans_before" : "trans_data"
                    ]
                  )
                )}
              </div>
            ) : (
              <div className="no-data-msg">No Data Found</div>
            )}
          </Panel>
          <Panel
            header={props.selectedType === "Log" ? "After Data" : "Dump Analysis"}
            key="2"
            className="collapse-header"
            extra={
              panelSecondDataAvail ? (
                <Button
                  type="primary"
                  shape="round"
                  onClick={(event) => {
                    event.stopPropagation();
                    downloadData("panel2");
                  }}
                >
                  Download
                </Button>
              ) : (
                false
              )
            }
          >
            {panelSecondDataAvail ? (
              <div className="collapse-content">
                {panelSecondDataSize > window.MAX_LENGTH ? (
                  <ReadMore
                    data={
                      props.selectedRow[
                        props.selectedType === "Log" ? "trans_after" : "dump_analysis"
                      ]
                    }
                    onDownload={() => downloadData("panel2")}
                  />
                ) : (
                  convertData(
                    props.selectedRow[
                      props.selectedType === "Log" ? "trans_after" : "dump_analysis"
                    ]
                  )
                )}
              </div>
            ) : (
              <div className="no-data-msg">No Data Found</div>
            )}
          </Panel>
          {props.selectedRow?.msg_pairs && props.selectedRow?.msg_pairs !== "" && (
            <Panel
              className="collapse-header"
              header="Message Properties"
              key="3"
            >
              <div style={{ paddingLeft: 20 }}>
                <table className="tbl-blob-blue">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Name</th>
                      <th style={{ textAlign: "left", padding: "4px 25px" }}>
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.selectedRow?.msg_pairs?.map((column, i) => {
                      return (
                        <tr key={i}>
                          <th>{column.Name || column.name}</th>
                          <td>{column.Value || column.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Panel>
          )}
          {jsmData?.MessageProperties && jsmData?.MessageProperties !== "" && (
            <Panel
              className="collapse-header"
              header="Application Properties"
              key="5"
            >
              <div style={{ paddingLeft: 20 }}>
                <table className="tbl-blob-blue">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Name</th>
                      <th style={{ textAlign: "left", padding: "4px 25px" }}>
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jsmData?.MessageProperties?.map((column, i) => {
                      return (
                        <tr key={i}>
                          <th>{column.Name}</th>
                          <td>{column.Value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Panel>
          )}
          <Panel className="collapse-header" header="JMS Properties" key="4">
            <div style={{ paddingLeft: 20 }}>
              <Row gutter={[32, 8]}>
                <Col span={12}>
                  <table className="tbl-blob-blue">
                    <thead>
                      <tr>
                        <th colSpan="2">JMS Header</th>
                      </tr>
                    </thead>
                    <tbody>
                      {HEADER_COLUMNS.map((column, i) => {
                        return (
                          <tr key={i}>
                            <th>{column.headerName}</th>
                            <td>{jsmData?.JMSHeader?.[column.field]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
                <Col span={12}>
                  <table className="tbl-blob-blue">
                    <thead>
                      <tr>
                        <th colSpan="2">JMS Properties</th>
                      </tr>
                    </thead>
                    <tbody>
                      {JMS_COLUMNS.map((column, i) => {
                        return (
                          <tr key={i}>
                            <th>{column.headerName}</th>
                            <td>{jsmData?.JMSProperties?.[column.field]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
      </div>
    </Modal>
  );
};

MessageMetricsDetail.propTypes = {
  selectedRow: PropTypes.object,
  onCloseModal: PropTypes.func,
  selectedType: PropTypes.any,
  isModalOpen: PropTypes.bool,
};

export default React.memo(MessageMetricsDetail);
