// Alert types
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const SUCCESS = 'success';
export const DANGER = 'danger';
export const WARNING = 'warning';
export const INFO = 'info';
export const LIGHT = 'light';
export const DARK = 'dark';
export const formMessages = {
  INVALID_DATE : 'Invalid date/time',
  GREATER_START_TIME: 'Start date/time cannot be after End date/time.',
  INVALID_NUMBER: 'Invalid number',
  INVALID_STRING: 'Invalid character',
  INFO_MULTI_TABLE: "For this search criteria the date range allowed three days",
  INVALID_TABLE_NAME: 'Table name should starts with ',
  INVALID_TABLE_TOPIC: 'Table topic name should start with pep-cgf-cle-',
};
