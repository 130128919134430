import React, { useEffect, useState } from "react";
import { Form, Modal, Input, Radio } from "antd";
import PropTypes from "prop-types";
import { formItemsLayout, formItemsDefaultStyle } from "../../../../constants/AdminModalForm/FormStyleConfig";
import AdminModalFooter from "../../../CommonComponents/Modal/AdminModalFooter";
import { applicationDataInstance } from "../../../../services/applicationDataService";
import MultiSelect from "../../../CommonComponents/MultiSelect/MultiSelect";
const NewLogConfig = ({ ...props }) => {
  const [form] = Form.useForm();
  const [selectedApp, setSelectedApp] = useState([]);
  const applicationNames = applicationDataInstance.getApplicationNames();
  useEffect(() => {
    if (props.editFlag) {
      form.setFieldsValue({ ...props.data });
      if (props?.data?.applicationName.length > 0) {
        setSelectedApp(props.data.applicationName.map(appItem => ({
          "label": appItem,
          "value": appItem,
        })));
      }
    }
  }, []);
  const onSaveHandler = (close) => {
    form.validateFields()
      .then(async values => {
        values = {
          ...values,
          'status': values.status.constructor === Array ?  values.status : values.status.split(',').map(item => item.trim())
        }
        if (props.editFlag) {
          values = {
            "logConfigId": props.data["logConfigId"],
            ...values
          }
        }
        props.saveData(values, close);
      })
  }

  const handleMultiSelectChange = (selectedApp) => {
    setSelectedApp(selectedApp)
    form.setFieldsValue({
      ...form.getFieldsValue(),
      "applicationName": selectedApp.map(app => app.value)
    });
  };

  return (<Modal
      open={props.isModalOpen}
      title={(props.editFlag ? "Edit" : "New") + " Log Configuration"}
      onCancel={props.onCloseModal}
      footer={
        (props.editFlag ?
          <AdminModalFooter
            close update
            onUpdate={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
          :
          <AdminModalFooter
            save close saveAndClose
            onSave={() => onSaveHandler(false)}
            onSaveAndClose={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />)
      }
      style={{ minWidth: 800 }}
      className="admin-modal-wrapper"
      centered
    >
      <Form
        id="report-log-config"
        form={form}
        {...formItemsLayout}
        labelAlign="left"
        colon
        initialValues={{
          "enableNotification": false
        }}
        size={"large"}
      >

        <Form.Item label="Application Name" name="applicationName"
          rules={[{ required: true, message: 'Application is Mandatory!' }]}
          style={{ ...formItemsDefaultStyle, minHeight: "50px" }}>
          <MultiSelect
            placeholder="Select Application"
            hasSelectAll={applicationNames && applicationNames.length > 2}
            disableSearch={applicationNames && applicationNames.length > 10 ? false : true}
            options={applicationNames}
            disabled={props.editFlag}
            selected={selectedApp}
            multi
            clearable
            onSelectedChanged={(app) =>
              handleMultiSelectChange(app)
            }
          />

        </Form.Item>
        <Form.Item label="Email" name="mailID"
          rules={[{ required: true, message: 'Email is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <Input />
        </Form.Item>
        <Form.Item label="Enable Notification" name="enableNotification"
          rules={[{ required: true, message: 'Enable Notification is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <Radio.Group >
            <Radio value>Enable</Radio>
            <Radio value={false}>Disable</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Status" name="status"
          rules={[{ required: true, message: 'Status is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>)
}
NewLogConfig.propTypes = {
  onCloseModal: PropTypes.func,
  saveData: PropTypes.func,
  data: PropTypes.any,
  editFlag: PropTypes.bool,
  isModalOpen: PropTypes.bool,
};

export default NewLogConfig;