import React, { useEffect } from "react";
import { Form, Modal, Input, Radio } from "antd";
import PropTypes from "prop-types";
import { formItemsLayout, formItemsDefaultStyle } from "../../../constants/AdminModalForm/FormStyleConfig";
import AdminModalFooter from "../../CommonComponents/Modal/AdminModalFooter";
import SelectDropDown from '../../CommonComponents/SelectDropDown';
import { applicationDataInstance } from "../../../services/applicationDataService";

const NewSolmanStaging = ({ ...props }) => {
  const [form] = Form.useForm();
  const applicationNames = applicationDataInstance.getApplicationNames();
  useEffect(() => {
    if (props.editFlag) {
        form.setFieldsValue({ ...props.data[0] });
    }
  }, []);
  const onSaveHandler = (close) => {
    form.validateFields()
      .then(async values => {
        if (props.editFlag) {
          values={
            ...props.data[0],
            ...values,
            }
        }
        props.saveData(values, close);
      })
  }
  return (<Modal
      open={props.isModalOpen}
      title={(props.editFlag ? "Edit" : "New") + " SM Stage Interfaces"}
      onCancel={props.onCloseModal}
      footer={
        (props.editFlag ?
          <AdminModalFooter
            close update
            onUpdate={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
          :
          <AdminModalFooter
            save close saveAndClose
            onSave={() => onSaveHandler(false)}
            onSaveAndClose={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />)
      }
      style={{ minWidth: 800 }}
      className="admin-modal-wrapper"
    >
      <Form
        id="solman-staging"
        form={form}
        {...formItemsLayout}
        labelAlign="left"
        colon
        initialValues={{
          "enable": "N",
          "useTechErrCode": "N",
        }}
      >
        <Form.Item label="Application Name" name="applicationName"
          rules={[{ required: true, message: 'Application is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <SelectDropDown
            options={applicationNames}
            disabled={props.editFlag}
          />
        </Form.Item>
        <Form.Item label="Interface" name="interfaceID"
        rules={[{ required: true, message: 'Interface is Mandatory!' }]}
          style={formItemsDefaultStyle}>
          <Input />
        </Form.Item>
        <Form.Item label="Bus Error Code" name="busErrorCode"
          style={formItemsDefaultStyle}>
          <Input />
        </Form.Item>
        <Form.Item label="Division" name="division"
          style={formItemsDefaultStyle}>
          <Input />
        </Form.Item>
        <Form.Item label="Bus Error Group" name="busErrorGroup"
          style={formItemsDefaultStyle}>
          <Input />
        </Form.Item>
        <Form.Item label="Enable" name="enable"
          style={formItemsDefaultStyle}>
          <Radio.Group >
            <Radio value="Y">Enable</Radio>
            <Radio value="N">Disable</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="User Tech Error Code" name="useTechErrCode"
          style={formItemsDefaultStyle}>
          <Radio.Group >
            <Radio value="Y">Enable</Radio>
            <Radio value="N">Disable</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>)
}
NewSolmanStaging.propTypes = {
  saveData: PropTypes.func,
  editFlag: PropTypes.any,
  data: PropTypes.any,
  onCloseModal: PropTypes.func,
  isModalOpen: PropTypes.bool
};
export default NewSolmanStaging;