import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ExceptionStatus from "../../../components/Admin/ConfigureReports/ExceptionStatus";
import * as actions from "../../../actions/Admin/logStatusActions";

const ExceptionStatusConfigResults = () => {
  const dispatch = useDispatch();
  const globalLogConfigState = useSelector((state) => state.logConfig);

  useEffect(() => {
    if (globalLogConfigState.exceptionReportData?.length == 0) {
      dispatch(actions.getExceptionReportDetails());
    }
  }, [])

  const checkIfDuplicateEntry = (data, editId) => {
    let isDuplicate = false;
    const exceptionConfigData = _.cloneDeep(globalLogConfigState.exceptionReportData);
    for (let i = 0; i < exceptionConfigData.length; i++) {
      if (
        (editId !== exceptionConfigData[i].configID) && _.isEqual(exceptionConfigData[i].applicationName.sort(), data.applicationName.sort()) &&
        (exceptionConfigData[i].metricType.toUpperCase() ===
        data.metricType.toUpperCase()) && ((exceptionConfigData[i].reportType && exceptionConfigData[i].reportType.toUpperCase()) === data.reportType.toUpperCase())
      ) {
        isDuplicate = true;
        break;
      }
    }
    return isDuplicate;
  };

  const saveDetails = (payload) => {
    if (!checkIfDuplicateEntry(payload, payload["configID"])) {
      if (payload["configID"]) {
        dispatch(actions.updateExceptionData(payload))
      } else {
        dispatch(actions.addExceptionReport(payload));
      }
    } else {
      toast.error("Configuration already exist!")
    }
  }
  const onDelete = (payload) => {
    dispatch(actions.deleteExceptionDetail(payload))
  }
  return (
    <div>
      <ExceptionStatus
        isLoading={globalLogConfigState.isLoading}
        exceptionReportData={globalLogConfigState.exceptionReportData}
        onDelete={onDelete}
        saveDetails={saveDetails}
      />
    </div>
  );
}
export default ExceptionStatusConfigResults;