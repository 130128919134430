import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { getUsersList, getRoleUsersList, updateRoleUsersFunction, UserDataStore } from "../../../actions/Admin/userActions";
import { getUserDataByIds, getUsersNotInList } from '../../../utils/helpers';
import UserFooter from '../UserFooter';
import UserListComponent from '../UserList';
import CopyGroupModal from '../CopyGroupModal';
import { openLoader, closeLoader } from "../../../components/OverlayLoader/OverlayLoader";
import AxiosUrl from "../../../services/axiosInstanceCustom";

const ApplicationUserTab = ({ ...props }) => {
    const dispatch = useDispatch();
    const globalStateUser = useSelector((state) => state.users);

    const globalStateGroupUserList = globalStateUser?.roleUserResults?.data;
    const globalStateUserList = useSelector((state) => state.users.userResults.data);

    const [userList, setUserList] = useState([]);
    const [userListNotInGroup, setUserListNotInGroup] = useState([]);
    const [showAddUser, setShowAddUser] = useState(false);
    const [gridApi, setGridApi] = useState();
    const [userListSelectedRow, setUserListSelectedRow] = useState([]);
    const [addUserSelectedRow, setAddUserSelectedRow] = useState([]);
    const [addUserGridApi, setAddUserGridApi] = useState();
    const [selectedGroupUserIds, setSelectedGroupUserIds] = useState([]);
    const [showCopyGroup, setShowCopyGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState("All");
    const [targetGroup, setTargetGroup] = useState();

    const groupNameList = props.selectedApplicationGroupList.map(g => g["groupName"]);

    useEffect(() => {
        if (globalStateUser?.userResults?.data.length === 0) {
            dispatch(getUsersList());
        }
        if (globalStateGroupUserList.length === 0) {
            dispatch(getRoleUsersList());
        }
    }, []);
    useEffect(() => {
        loadUserList("All");
    }, [props.selectedApplication]);
    useEffect(() => {
        loadUserList("All");
    }, []);
    useEffect(() => {
        loadUserList(selectedGroup);
    }, [globalStateGroupUserList,globalStateUser]);

    const loadUserList = (selectedGroupTemp) => {
        let groupWithUserList = globalStateGroupUserList.filter(groupObj => groupNameList.includes(groupObj["groupName"]));
        let userIdList = [];
        if (selectedGroupTemp === "All") {
            groupWithUserList.forEach(groupObj => {
                userIdList = [...userIdList, ...groupObj["userList"]]
            })
        } else {
            userIdList = groupWithUserList.filter(groupObj => groupObj["groupName"] === selectedGroupTemp);
            userIdList = userIdList.length > 0 ? userIdList[0]["userList"] : [];
        }
        setSelectedGroupUserIds(userIdList);
        setSelectedGroup(selectedGroupTemp);
        setUserList(getUserDataByIds(globalStateUserList, userIdList));
        setUserListNotInGroup(getUsersNotInList(globalStateUserList, userIdList));
    }
    const loadAddUserData = (usersIdsList) => {
        setUserListNotInGroup(getUsersNotInList(globalStateUserList, usersIdsList))
        setShowAddUser(true);
    }
    const onAddUserSaveHandler=()=>{
        let newUserIds = addUserSelectedRow.map(userObj => userObj["userID"]);
        let updatedUserIds = [...selectedGroupUserIds, ...newUserIds];
        let payload = {
            "groupName":selectedGroup,
            "userList": updatedUserIds
        }
        setSelectedGroupUserIds(updatedUserIds);
        dispatch(updateRoleUsersFunction(payload));
        loadAddUserData(updatedUserIds);
        setUserList(getUserDataByIds(globalStateUserList, updatedUserIds));
    }
    const onRemoveUserHandler = () => { 
        let userIdsToBeRemoved = userListSelectedRow.map(userObj => userObj["userID"]);
        let updatedUserIds = selectedGroupUserIds.filter(userId => !userIdsToBeRemoved.includes(userId));
        let payload = {
            "groupName":selectedGroup,
            "userList": updatedUserIds
        }
        dispatch(updateRoleUsersFunction(payload));
        setUserList(getUserDataByIds(globalStateUserList, updatedUserIds));
    }
    const onSaveCopyGroupHandler=()=>{
        let axiosUrl = AxiosUrl.adminDataInstance;
        let url = `/group/duplicate/${selectedGroup}/${targetGroup}/`;
        openLoader();
        setShowCopyGroup(false);
        axiosUrl
            .put(url, {})
            .then((resp) => {
                const rep = {
                    response: resp.data,
                    action: "ADD",
                    page: "roleUserResults",
                    id: "groupName",
                };
                dispatch(UserDataStore(rep))
                closeLoader();
                toast.success("Group access copied successfully");
                gridApi.deselectAll();
                setTargetGroup(null);
            })
            .catch(() => {
                closeLoader();
                gridApi.deselectAll();
                setTargetGroup(null);
                toast.error("Group access copy Failed!");
            });
    }

    return (<>
        {
            !showAddUser &&
            <>
                <div>
                    <UserListComponent
                        groupOptionEnabled
                        selectdGroupOption={selectedGroup}
                        groupOptionsList={props.selectedApplicationGroupList ? props.selectedApplicationGroupList : []}
                        inlineStyle
                        userListData={userList}
                        setGridApi={setGridApi}
                        setSelectedRow={setUserListSelectedRow}
                        onSelectGroupHandler={(selectedVal) => loadUserList(selectedVal)} />
                </div>
                <UserFooter
                    copyGroup onCopyGroup={() => setShowCopyGroup(true)}
                    disableCopyGroup={selectedGroup==="All"}
                    adduser onAddUser={() => loadAddUserData(selectedGroupUserIds)}
                    disableAddUser={selectedGroup==="All"}
                    removeUser onRemoveUser={() => onRemoveUserHandler()}
                    disableRemoveUser={selectedGroup==="All" || userListSelectedRow.length === 0}
                    reset handleReset={() => { setUserListSelectedRow([]); gridApi.deselectAll() }} />
            </>
        }
         {
            showAddUser &&
            <>
                <div>
                    <UserListComponent
                        inlineStyle
                        userListData={userListNotInGroup}
                        setGridApi={setAddUserGridApi}
                        setSelectedRow={setAddUserSelectedRow}
                        groupOptionEnabled
                        selectdGroupOption={selectedGroup}
                        onSelectGroupHandler={(selectedVal) => loadUserList(selectedVal)}
                        groupOptionsList={props.selectedApplicationGroupList ? props.selectedApplicationGroupList : []}
                    />
                </div>
                <UserFooter
                    save saveDisabled={addUserSelectedRow.length === 0}
                    handleSave={() => onAddUserSaveHandler()}
                    reset handleReset={() => { setAddUserSelectedRow([]); addUserGridApi.deselectAll() }}
                    showCancel onCancel={() => setShowAddUser(false)} />
            </>
        }
        {
            showCopyGroup &&
            <CopyGroupModal
                isModalOpen={showCopyGroup}
                sourceGroup={selectedGroup}
                onCloseModal={() => setShowCopyGroup(false)}
                onSaveData={() => onSaveCopyGroupHandler()}
                groupOptions={globalStateGroupUserList.filter(groupObj => groupObj["groupName"] !== selectedGroup)}
                onTargetGroupSelectHandler={(val) => setTargetGroup(val)}
            />
        }
    </>);
}
ApplicationUserTab.propTypes = {
    selectedApplicationGroupList: PropTypes.array,
    selectedApplication: PropTypes.string,
};
export default ApplicationUserTab;