import * as types from '../../constants/GlobalTypes/GlobalTypes';
import objectAssign from 'object-assign';
import update from 'immutability-helper';


const initalState = {
    log: {
        filterStatus: null,
        purgeStatus: null,
        exportExcelStatus: null
    },
    exception: {
        filterStatus: null,
        purgeStatus: null,
        exportExcelStatus: null,
        resolveStatus: null
    }
}


const globalReducer = (state = initalState, action) => {
    switch (action.type) {
        case (types.LOG_FILTER_STATUS): {
            return objectAssign({}, update(state, {
                log: {
                    filterStatus: { $set: action.status }
                }
            }));
        }
        case (types.LOG_PURGE_STATUS): {
            return objectAssign({}, update(state, {
                log: {
                    purgeStatus: { $set: action.status }
                }
            }));
        }
        case (types.LOG_EXPORTEXCEL_STATUS): {
            return objectAssign({}, update(state, {
                log: {
                    exportExcelStatus: { $set: action.status }
                }
            }));
        }
        case (types.EXCEPTION_FILTER_STATUS): {
            return objectAssign({}, update(state, {
                exception: {
                    filterStatus: { $set: action.status }
                }
            }));
        }
        case (types.EXCEPTION_PURGE_STATUS): {
            return objectAssign({}, update(state, {
                exception: {
                    purgeStatus: { $set: action.status }
                }
            }));
        }
        case (types.EXCEPTION_RESOLVE_STATUS): {
            return objectAssign({}, update(state, {
                exception: {
                    resolveStatus: { $set: action.status }
                }
            }));
        }
        case (types.EXCEPTION_EXPORTEXCEL_STATUS): {
            return objectAssign({}, update(state, {
                exception: {
                    exportExcelStatus: { $set: action.status }
                }
            }));
        }
        default: {
            return state;
        }
    }
}

export default globalReducer