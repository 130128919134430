import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Loader from "../Loader/Loader";
import "./Page.css";

const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

const WrapperPage = ({
  children,
  loading = false,
  inner = false,
  overLoading = false,
  grayBackground = false,
}) => {
  const loadingStyle = {
    height: "calc(100vh - 184px)",
    overflow: "hidden",
  };
  return (
    <div
      className={`${inner ? "contentInner" : ""} pageLoader ${
        grayBackground ? "grayBackground" : ""
      }`}
      style={loading ? loadingStyle : null}
    >
      {loading ? (
        <Loader />
      ) : (
        <Spin
          indicator={antIcon}
          spinning={overLoading}
          tip="Please wait we are processing your request..."
        >
          {children}
        </Spin>
      )}
    </div>
  );
};

WrapperPage.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  inner: PropTypes.bool,
  overLoading: PropTypes.bool,
  grayBackground: PropTypes.bool,
};

export default WrapperPage;
