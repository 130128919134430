import exceptionSaga from "./Exceptions/exceptionSaga";
import logSaga from "./Logs/logSaga";
import configurationSaga from "./Admin/configurationSaga";
import applicationSaga from "./Admin/applicationConfigSaga";
import exceptionConfigSaga from "./Admin/exceptionConfigSaga";
import logStatusReport from "./Admin/logStatusReport";
import userSaga from "./Admin/userSaga";
import configureColumnAlertSaga from "./Admin/configureColumnAlertSaga";
import solmanStagingSaga from "./Admin/solmanStagingSaga";
import userAccessSaga from "./UserAccess/userAccessSaga";
import reportsSaga from "./Dashboard/reportSaga";
import analyticsSaga from "./Dashboard/analyticsSaga";
import sapReportSaga from "./Admin/sapReportSaga";
import scheduleReportSaga from "./Admin/scheduleReportSaga";
import blobMetricsSaga from "./Admin/blobMetricsSaga";
import tableConfigSaga from "./Admin/tableConfigSaga";

export default [
  exceptionSaga,
  logSaga,
  configurationSaga,
  applicationSaga,
  exceptionConfigSaga,
  userSaga,
  logStatusReport,
  configureColumnAlertSaga,
  solmanStagingSaga,
  userAccessSaga,
  reportsSaga,
  analyticsSaga,
  sapReportSaga,
  scheduleReportSaga,
  blobMetricsSaga,
  tableConfigSaga,
];
