export const GET_COLUMNCONFIG_ALERT_DETAILS = 'GET_COLUMNCONFIG_ALERT_DETAILS';
export const UPDATE_COLUMNCONFIG_ALERT_DETAILS = 'UPDATE_COLUMNCONFIG_ALERT_DETAILS';
export const DELETE_COLUMNCONFIGALERT = 'DELETE_COLUMNCONFIGALERT';
export const ADD_NEW_COLUMNCONFIGALERT = 'ADD_NEW_COLUMNCONFIGALERT';
export const UPDATE_COLUMNCONFIGALERT = "UPDATE_COLUMNCONFIGALERT";
export const UPDATE_COLUMNCONFIG_ALERT_STATUS = "UPDATE_COLUMNCONFIG_ALERT_STATUS";

export const STORE_COLUMNCONFIG_REPORT="STORE_COLUMNCONFIG_REPORT";
export const PROGRESS_COLUMNCONFIG_REPORT="PROGRESS_COLUMNCONFIG_REPORT";



