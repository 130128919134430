import React, { useState } from "react";
import PropTypes from "prop-types";
import CleTable from "../../CommonComponents/CleTable/CleTable";
import CleFooter from "../../CommonComponents/CleFooter/CleFooter";
import { COLUMNS } from "../../../constants/ColumnConfig/Admin/solmanStagingResults";
import Loader from "../../CommonComponents/Loader";
import SolmanStagingDetail from "./solmanStagingDetail";
// import NewSolmanStaging from "./newSolmanStaging";

const pageStyle = {
    height: "calc(100vh - 150px)",
    width: "100%",
};
const SolmanStaging = ({ ...props }) => {
    const [selectedRow, setSelectedRow] = useState([]);
    const [showDetailModal, setShowDetailModal] = useState(false);

    return (<>
        {
            props.isLoading &&
            <Loader />
        }
        {
            !props.isLoading &&
            <>
                <div className={"admin-cle-table-wrapper"} style={pageStyle}>
                    <CleTable
                        rowSelection="multiple"
                        getRowNodeId={(data) => data ? data.smStageConfigId + data.smStageConfigKey : ""}
                        columnDefs={COLUMNS}
                        rowData={props.solmanStaging ? props.solmanStaging : []}
                        onRowClicked={(params) => {
                            if (params.node.isSelected()) {
                                setSelectedRow(params.api.getSelectedRows());
                                params.node.setSelected(false);
                            } else {
                                params.node.setSelected(true);
                                setSelectedRow(params.api.getSelectedRows());
                            }
                        }}
                        onSelectionChanged={(gridOptions) => {
                            setSelectedRow(gridOptions.api.getSelectedRows());
                        }}
                        isColSizeAutoFit={false}
                        animateRows={false}
                        immutableData
                        rowMultiSelectWithClick
                        suppressRowClickSelection
                    />
                </div>
                <CleFooter
                    onDetail={() => setShowDetailModal(true)}
                    deleteDisabled={selectedRow.length === 0}
                    detail
                />
            </>
        }
        {showDetailModal && (
            <SolmanStagingDetail
                isModalOpen={showDetailModal}
                onCloseModal={() => setShowDetailModal(false)}
                data={selectedRow.length > 0 ? selectedRow : []}
            />
        )}
    </>);
};

SolmanStaging.propTypes = {
    saveSolmanDetails: PropTypes.any,
    onSolmanDelete: PropTypes.any,
    solmanStaging: PropTypes.any,
    isLoading: PropTypes.bool,
};
export default SolmanStaging;