import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Row, Col, Radio, InputNumber } from "antd";
import PropTypes from "prop-types";
import { formItemsLayout } from "../../../../constants/AdminModalForm/FormStyleConfig";
import AdminModalFooter from "../../../CommonComponents/Modal/AdminModalFooter";
import SelectDropDown from '../../../CommonComponents/SelectDropDown';
import { applicationDataInstance } from "../../../../services/applicationDataService";
import { SNOW_NOTIFICATION_SERVICE_NAME } from "../../../../constants/AdminModalForm/SnowNotificationServiceName";

const rowGutter = [24, 8];
const colSpan = 12;
const formItemsStyle = {
  width: "100%",
  marginBottom: "10px"
};
const procedureNameOptions = [
  { label: "Automatic", value: "Automatic" },
  { label: "Manual", value: "Manual" },
];

const replayModeOptions = [
  { label: "HTTP", value: "HTTP" },
  { label: "JMS", value: "JMS" },
  { label: "KAFKA", value: "KAFKA" },
  {
    label: "HTTP OAUTH",
    value: "HTTP_OAUTH"
  }
];

const NewConfigException = ({ ...props }) => {
  const [form] = Form.useForm();
  const applicationNames = applicationDataInstance.getApplicationNames();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [severityOptions, setSeverityOptions] = useState([]);
  const isSnowNotificationChangedValue = Form.useWatch('enableSnowNotification', form);
  useEffect(() => {
    if (props.editFlag) {
      form.setFieldsValue({ ...props.data[0] });
    }
  }, []);
  const onSaveHandler = (close) => {
    form.validateFields()
      .then(async values => {
        if (props.editFlag) {
          values = {
            "exceptionConfigId": props.data[0]["exceptionConfigId"],
            ...values
          };
        }
        props.saveData(values, close);
      });
  };
  const onFormValueChangeHandler = (changedValues) => {
    //if applicationName is changed Recalculating Category
    if (changedValues["applicationName"]) {
      setCategoryOptions(changedValues["applicationName"] ?
        applicationDataInstance.getCategoriesForApplication(changedValues["applicationName"])
        : []);
      setTypeOptions(changedValues["applicationName"] ?
        applicationDataInstance.getTypesForApplication(changedValues["applicationName"])
        : []);
      setSeverityOptions(changedValues["applicationName"] ?
        applicationDataInstance.getSeveritiesForApplication(changedValues["applicationName"])
        : []);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        "exceptionCategoryName": undefined,
        "exceptionTypeName": undefined,
        "exceptionSeverityName": undefined
      });
    }
  };
  return (
    <Modal
      open={props.isModalOpen}
      title={(props.editFlag ? "Edit" : "New") + " Exception Configuration"}
      onCancel={props.onCloseModal}
      footer={
        props.editFlag ? (
          <AdminModalFooter
            close
            update
            onUpdate={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
        ) : (
          <AdminModalFooter
            save
            close
            saveAndClose
            onSaveAndClose={() => onSaveHandler(true)}
            onSave={() => onSaveHandler(false)}
            onClose={props.onCloseModal}
          />
        )
      }
      style={{ minWidth: "75vw" }}
      className="admin-modal-wrapper"
      centered
    >
      <Form
        id="config-exception"
        form={form}
        {...formItemsLayout}
        labelAlign="left"
        colon
        onValuesChange={onFormValueChangeHandler}
        initialValues={{
          autoReplayInterval: 0,
          enableSnowNotification: 0,
          enableMailNotification: 0,
          enableGroupNotification: 0,
        }}
      >
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Application"
              name="applicationName"
              rules={[{ required: true, message: "Application is Mandatory!" }]}
              style={formItemsStyle}
            >
              <SelectDropDown
                options={applicationNames}
                disabled={props.editFlag}
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Application Domain"
              name="applicationDomain"
              rules={[
                { required: true, message: "Application Domain is Mandatory!" },
              ]}
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Category"
              name="exceptionCategoryName"
              rules={[{ required: true, message: "Category is Mandatory!" }]}
              style={formItemsStyle}
            >
              <SelectDropDown
                fieldNames={{
                  label: "exceptionCategoryName",
                  value: "exceptionCategoryName",
                }}
                options={categoryOptions}
                disabled={props.editFlag}
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Severity"
              name="exceptionSeverityName"
              rules={[{ required: true, message: "Severity is Mandatory!" }]}
              style={formItemsStyle}
            >
              <SelectDropDown
                fieldNames={{
                  label: "exceptionSeverityName",
                  value: "exceptionSeverityName",
                }}
                options={severityOptions}
                disabled={props.editFlag}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Type"
              name="exceptionTypeName"
              rules={[{ required: true, message: "Type is Mandatory!" }]}
              style={formItemsStyle}
            >
              <SelectDropDown
                fieldNames={{
                  label: "exceptionTypeName",
                  value: "exceptionTypeName",
                }}
                options={typeOptions}
                disabled={props.editFlag}
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Description"
              name="description"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Replay Type"
              name="replayType"
              rules={[{ required: true, message: "Replay Type is Mandatory!" }]}
              style={formItemsStyle}
            >
              <SelectDropDown options={procedureNameOptions} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Reply Mode"
              name="replyMode"
              rules={[{ required: true, message: "Replay Mode is Mandatory!" }]}
              style={formItemsStyle}
            >
              <SelectDropDown options={replayModeOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Reply Destination"
              name="replyDestination"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Maximum Auto Replay"
              name="maxAutoReplay"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Enable Mail Notification"
              name="enableMailNotification"
              style={formItemsStyle}
            >
              <Radio.Group>
                <Radio value={1}>Enable</Radio>
                <Radio value={0}>Disable</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Mail Notification"
              name="emailAddress"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Enable Group Notification"
              name="enableGroupNotification"
              style={formItemsStyle}
            >
              <Radio.Group>
                <Radio value={1}>Enable</Radio>
                <Radio value={0}>Disable</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Assignment Group"
              name="snowAssignmentGroup"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Enable Snow Notification"
              name="enableSnowNotification"
              style={formItemsStyle}
            >
              <Radio.Group>
                <Radio value={1}>Enable</Radio>
                <Radio value={0}>Disable</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Interval (minutes)"
              name="autoReplayInterval"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value > 10080) {
                      return Promise.reject(
                        "Replay Interval cannot exceeds more than 7 days (10080 minutes)"
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              style={formItemsStyle}
            >
              <InputNumber min={-1} />
            </Form.Item>
          </Col>
        </Row>
        {isSnowNotificationChangedValue === 1 && (
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                label="Snow Service Name"
                name="sysClassName"
                rules={[
                  {
                    required: true,
                    message: "Snow Notification Service Name is Mandatory!",
                  },
                ]}
                style={formItemsStyle}
              >
                <SelectDropDown options={SNOW_NOTIFICATION_SERVICE_NAME} />
              </Form.Item>
            </Col>
            <Col span={colSpan}>
              <Form.Item
                label="Snow Service Value"
                name="snowParam"
                style={formItemsStyle}
                rules={[
                  {
                    required: true,
                    message: "Snow Service Value is Mandatory!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};
NewConfigException.propTypes = {
  onCloseModal: PropTypes.func,
  data: PropTypes.any,
  editFlag: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  saveData: PropTypes.func,
};
export default NewConfigException;