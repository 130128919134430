const LogColumn = [
    {
        label: "Transaction ID",
        name: "transactionID",
        id: "sel_transactionID",
        type: "text",
        tabIndex: "11",
    },
    {
        label: "Start Date",
        name: "startTime",
        id: "sel_startTime",
        type: "dateTime",
        tabIndex: "2",
    },
    {
        label: "Business ID",
        name: "businessID",
        id: "sel_businessID",
        type: "text",
        tabIndex: "12",
    },
    {
        label: "End Date",
        name: "endTime",
        id: "sel_endTime",
        type: "dateTime",
        tabIndex: "3",
    },
    {
        label: "Business ID2",
        name: "businessID2",
        id: "sel_businessID2",
        type: "text",
        tabIndex: "13",
    },
    {
        label: "Service",
        name: "serviceName",
        id: "sel_serviceName",
        type: "text",
        tabIndex: "4",
    },
    {
        label: "Name",
        name: "messageName",
        id: "sel_messageName",
        type: "text",
        tabIndex: "14",
    },
    {
        label: "Component",
        name: "componentName",
        id: "sel_componentName",
        type: "text",
        tabIndex: "5",
    },
    {
        label: "Value",
        name: "messageValue",
        id: "sel_messageValue",
        type: "text",
        tabIndex: "15",
    },

    {
        label: "Before Data",
        name: "transactionBefore",
        id: "sel_transactionBefore",
        type: "text",
        tabIndex: "6",
    },

    {
        label: "Application Domain",
        name: "applicationDomain",
        id: "sel_applicationDomain",
        type: "text",
        tabIndex: "16",
    },
    {
        label: "After Data",
        name: "transactionAfter",
        id: "sel_transactionAfter",
        type: "text",
        tabIndex: "6",
    },

    {
        label: "Transaction Domain",
        name: "transactionDomain",
        id: "sel_transactionDomain",
        type: "text",
        tabIndex: "17",
    },
    {
        label: "Status",
        name: "logStatus",
        id: "sel_status",
        type: "text",
        tabIndex: "7",
    },
    {
        label: "Category",
        name: "logCategory",
        id: "sel_logCategory",
        type: "text",
        tabIndex: "18",
    },
    {
        label: "Transaction Type",
        name: "transactionType",
        id: "sel_transactionType",
        type: "text",
        tabIndex: "8",
    },
    {
        label: "Log Level",
        name: "logLevel",
        id: "sel_log_level",
        type: "radio",
        tabIndex: "19",
    },
    {
        label: "HostName",
        name: "hostname",
        id: "sel_hostname",
        type: "text",
        tabIndex: "10",
    },
];

export default LogColumn;