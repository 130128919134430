export const GET_USERS_LIST = "GET_USERS_LIST";
export const ADD_USER = "ADD_USER";
export const USERS_LIST = "USERS_LIST";
export const USER_ADD_RESULTS = "USER_ADD_RESULTS";
export const USER_EDIT_RESULTS = "USER_EDIT_RESULTS";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";

export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const ROLES_LIST = "ROLES_LIST";
export const DELETE_ROLE = "DELETE_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const ROLE_ADD_RESULTS = "ROLE_ADD_RESULTS";
export const ROLE_EDIT_RESULTS = "ROLE_EDIT_RESULTS";

export const GET_ROLE_USERS_LIST = "GET_ROLE_USERS_LIST";
export const ROLE_USERS_LIST = "ROLE_USERS_LIST";
export const ADD_ROLE_USER = "ADD_ROLE_USER";
export const UPDATE_ROLE_USER = "UPDATE_ROLE_USER";
export const ROLE_USER_ADD_RESULTS = "ROLE_USER_ADD_RESULTS";
export const DELETE_ROLE_USER = "DELETE_ROLE_USER";
export const ROLE_USER_DELETE_RESULTS = "ROLE_USER_DELETE_RESULTS";

export const GET_APPLICATION_ROLES_LIST = "GET_APPLICATION_ROLES_LIST";
export const APPLICATION_ROLES_LIST = "APPLICATION_ROLES_LIST";
export const DELETE_APPLICATION_ROLE = "DELETE_APPLICATION_ROLE";
export const EDIT_APPLICATION_ROLE = "EDIT_APPLICATION_ROLE";
export const APPLICATION_ROLE_EDIT_RESULTS = "ROLE_EDIT_RESULTS";
export const APPLICATION_ROLE_ADD_RESULTS = "APPLICATION_ROLE_ADD_RESULTS";
export const ADD_APPLICATION_ROLE = "ADD_APPLICATION_ROLE";

export const PROGRESS_USER_REPORT = "PROGRESS_USER_REPORT";
export const STORE_USER_REPORT = "STORE_USER_REPORT";

export const EMPTY_VALUE = "EMPTY_VALUE";

export const USER_API_STATUS = "USER_API_STATUS";

export const GET_ROLES_APPLICATION_LIST = "GET_ROLES_APPLICATION_LIST";

export const ADD_ROLES_APPLICATION_LIST = "ADD_ROLES_APPLICATION_LIST";
export const UPDATE_ROLES_APPLICATION_LIST = "UPDATE_ROLES_APPLICATION_LIST";
export const DELETE_ROLES_APPLICATION_LIST = "DELETE_ROLES_APPLICATION_LIST";
