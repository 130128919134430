import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Modal, Row } from "antd";

const SolmanStagingDetail = (props) => {
  const [index, setIndex] = useState(0);
  return (
    <Modal
      open={props.isModalOpen}
      onCancel={props.onCloseModal}
      width={1050}
      className="solman-staging-detail-modal modal-with-pagination"
      title={(
        <>
          <h5 className="modal-title mb-0">
            Solman Staging
          </h5>
          {props.data.length > 1 && (
            <ul className="pagination">
              <li className={`page-item ${index === 0 && "cus_notallowed"}`}>
                <button
                  id="sel_config_details_prev"
                  className={`btn btn-default ${
                    index !== 0 ? "b500" : "btndisable"
                  }`}
                  aria-label="Previous"
                  onClick={() => {
                    setIndex(index - 1);
                  }}
                >
                  <span aria-hidden="true" className="icn_pre_nxt">
                    &laquo;
                  </span>
                  <span>Previous</span>
                </button>
              </li>
              <li
                className={`page-item ${
                  index === props.data.length - 1 && "cus_notallowed"
                }`}
              >
                <button
                  id="sel_config_details_next"
                  className={`btn btn-default ${
                    index !== props.data.length - 1 ? "b500" : "btndisable"
                  }`}
                  aria-label="Next"
                  onClick={() => {
                    setIndex(index + 1);
                  }}
                >
                  <span>Next</span>
                  <span aria-hidden="true" className="icn_pre_nxt">
                    &raquo;
                  </span>
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      footer={(
        <button
          type="button"
          className="btn btn-danger"
          data-dismiss="modal"
          onClick={props.onCloseModal}
        >
          <i className="fa fa-times" /> Close
        </button>
      )}
      centered
    >
      <>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Application Name </label>
              </Col>
              <Col span={14}>
                <span className="detail_info">
                  {props.data[index].applicationName}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Interface</label>
              </Col>
              <Col span={14}>
                <span className="detail_info">
                  {props.data[index].interfaceID}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Division</label>
              </Col>
              <Col span={14}>
                <span className="detail_info">
                  {props.data[index].division}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Bus Error Code</label>
              </Col>
              <Col span={14}>
                <span
                  className="detail_info"
                >
                  {props.data[index].busErrorCode}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Bus Error Group</label>
              </Col>
              <Col span={14}>
                <span className="detail_info">
                  {props.data[index].busErrorGroup}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Enable</label>
              </Col>
              <Col span={14}>
                <span className="detail_info">
                  {props.data[index].enable === "Y" ? "Yes" : "No"}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Use Tech Error Code</label>
              </Col>
              <Col span={14}>
                <span className="detail_info">
                  {props.data[index].useTechErrCode === "Y" ? "Yes" : "No"}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </Modal>
  );
};

SolmanStagingDetail.propTypes = {
  onCloseModal: PropTypes.func,
  data: PropTypes.array,
  isModalOpen: PropTypes.bool,
};

export default SolmanStagingDetail;
