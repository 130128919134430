import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import {
  updateLogReportforLastHour, updateExceptionReportforLastHour, updateLogReportforPastHour,
  updateExceptionReportforPastHour, updateLogReportForAdmin, updateExceptionReportForAdmin
} from '../../actions/Dashboard/reportActions';
import * as types from '../../constants/ActionTypes/reportTypes';
import { defaultAction } from '../../services/restService';
import { logger } from 'redux-logger';
export function* fetchLogReportforLastHour(payload) {
  try {
    const logData = yield call(defaultAction, payload);
    yield put(updateLogReportforLastHour(logData));
  } catch (e) {
    yield put({
      type: "DASHBORAD_ERROR",
      error: e
    })
  }
}
export function* fetchExceptionReportForLastHour(payload) {
  try {
    const exceptionReport = yield call(defaultAction, payload);
    yield put(updateExceptionReportforLastHour(exceptionReport));
  } catch (e) {
    yield put({
      type: "DASHBORAD_ERROR",
      error: e
    })
  }
}
export function* fetchLogReportforPastHour(payload) {
  try {
    const report = yield call(defaultAction, payload);
    yield put(updateLogReportforPastHour(report));
  } catch (e) {
    yield put({
      type: "DASHBORAD_ERROR",
      error: e
    })
  }
}
export function* fetchExceptionReportforPastHour(payload) {
  try {
    const report = yield call(defaultAction, payload);
    yield put(updateExceptionReportforPastHour(report));
  } catch (e) {
    yield put({
      type: "DASHBORAD_ERROR",
      error: e
    });
  }
}
export function* fetchLogReportForAdmin(payload) {
  try {
    const report = yield call(defaultAction, payload);
    yield put(updateLogReportForAdmin(report));
  } catch (e) {
    logger(e)
  }
}
export function* fetchExceptionReportForAdmin(payload) {
  try {
    const report = yield call(defaultAction, payload);
    yield put(updateExceptionReportForAdmin(report));
  } catch (e) {
    logger(e)
  }
}
export function* exceptionReportForLastHour() {
  yield takeLatest(types.LAST_HOUR_EXCEPTION_REPORT, fetchExceptionReportForLastHour);
}
export function* logReportforLastHour() {
  yield takeLatest(types.LAST_HOUR_LOGS_REPORT, fetchLogReportforLastHour);
}
export function* logReportForPastHour() {
  yield takeLatest(types.PAST_HOUR_LOGS_REPORT, fetchLogReportforPastHour);
}
export function* exceptionReportForPastHour() {
  yield takeLatest(types.PAST_HOUR_EXCEPTION_REPORT, fetchExceptionReportforPastHour);
}
export function* logReportForAdmin() {
  yield takeLatest(types.ADMIN_LOG_REPORT, fetchLogReportForAdmin);
}
export function* exceptionReportForAdmin() {
  yield takeLatest(types.ADMIN_EXCEPTION_REPORT, fetchExceptionReportForAdmin);
}
export default function* root() {
  yield all([
    fork(logReportforLastHour),
    fork(exceptionReportForLastHour),
    fork(logReportForPastHour),
    fork(exceptionReportForPastHour),
    fork(logReportForAdmin),
    fork(exceptionReportForAdmin)
  ])
}
