import * as types from "../../constants/ActionTypes/userAccessTypes";
import objectAssign from "object-assign";
import storageSession from "redux-persist/lib/storage/session";
const initialState = {
  userAccessInfo: null,
  redirectURL: null,
  exceptionresponse: null,
  oktaAuthenticatedStatus: false,
};

export default function userAccessReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_ACCESS_INFO:
      return state;
    case types.USER_ACCESS_INFO:
      return objectAssign({}, state, { userAccessInfo: action.userAccessData });
    case types.USER_LOGOUT_SUCCESS:
      Object.keys(state).forEach((key) => {
        storageSession.removeItem(`persist:${key}`);
      });
      sessionStorage.clear();
      localStorage.clear();
      window.location.assign(action.redirectURL);
      return objectAssign({}, state, { userAccessInfo: null });
    case types.GET_EXCEPTION_DETAIL:
      // console.log("action", action, state.redirectURL);
      // window.location.assign(action.redirectURL);
      return state;
    case types.EXCEPTION_INFO:
      return objectAssign({}, state, {
        exceptionresponse: action.exceptionData,
      });
    case types.OKTA_AUTHENTICATED_STATUS:
      return objectAssign({}, state, {
        oktaAuthenticatedStatus: action.status,
      });
    case types.CLEAR_RESPONSE:
      return objectAssign({}, state, { exceptionresponse: null });
    default:
      return state;
  }
}
