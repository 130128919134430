import { call, fork, put, all, takeLatest, retry } from "redux-saga/effects";
import * as types from "../../constants/ActionTypes/Admin/configurationTypes";
import { defaultAction } from "../../services/restService";
import * as actions from "../../actions/Admin/configurationActions";
import { updateExceptionCSTData } from "../../actions/Exceptions/exceptionActions"

import { toast } from "react-toastify";
import { logger } from "redux-logger";
// import { onUserSignOut } from "../../actions/UserAccess/userAccessActions";

export function* fetchExceptionCategoryByAppName(payload) {
  try {
    const SECOND = 1000;
    let applicationCategoryData = yield retry(3, 10 * SECOND, defaultAction, payload);
    if (applicationCategoryData) {
      let categories = JSON.parse(sessionStorage.getItem("applicationCategoryData"));

      categories = categories ? [...categories, ...applicationCategoryData] : [...applicationCategoryData];
      
      sessionStorage.setItem(
        "applicationCategoryData",
        JSON.stringify(categories)
      );
    }
    yield put(updateExceptionCSTData({ data: applicationCategoryData, type: 'categories'}));
  } catch (e) {
    logger(e);
    toast.error(`Oops! Something went wrong ${e}`);
  }
}

export function* getApplicationExceptionCategory() {
  yield takeLatest(types.GET_APPLICATION_EXCEPTION_CATEGORY, fetchExceptionCategoryByAppName);
}

export function* fetchExceptionCategory(payload) {
  try {
    const SECOND = 1000;
    const applicationCategoryData = yield retry(3, 10 * SECOND, defaultAction, payload);
    if (applicationCategoryData) {
      sessionStorage.setItem(
        "applicationCategoryData",
        JSON.stringify(applicationCategoryData)
      );
    }
    yield put(actions.updateExceptionData({ data: applicationCategoryData, type: 'category'}));
  } catch (e) {
    logger(e);
    toast.error(`Oops! Something went wrong ${e}`);
  }
}

export function* getExceptionCategory() {
  yield takeLatest(types.GET_EXCEPTION_CATEGORY, fetchExceptionCategory);
}

export function* addExceptionCategory(payload) {
  try {
    yield call(defaultAction, payload);
    // if(categoryResults==="Exception Category Added"){
    toast.success("Category has been added successfully");
    yield put(actions.getExceptionCategory());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* exceptionCategory() {
  yield takeLatest(types.ADD_EXCEPTION_CATEGORY, addExceptionCategory);
}

export function* updateExceptionCategory(payload) {
  try {
    yield call(defaultAction, payload);
    // if(categoryResults==="Exception Categories Updated"){
    toast.success("Category has been modified successfully");
    yield put(actions.getExceptionCategory());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* exceptionCategoryResults() {
  yield takeLatest(types.UPDATE_EXCEPTION_CATEGORY, updateExceptionCategory);
}

export function* deleteExceptionCategory(payload) {
  try {
    yield call(defaultAction, payload);
    // if(categoryResults==="Exception Categories Deleted"){
    toast.success("Category has been deleted successfully");
    yield put(actions.getExceptionCategory());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* deleteCategoryResults() {
  yield takeLatest(types.DELETE_CATEGORY, deleteExceptionCategory);
}

export function* fetchExceptionSeverityByAppName(payload) {
  try {
    const SECOND = 1000;
    const applicationSeverityData = yield retry(3, 10 * SECOND, defaultAction, payload);
    if (applicationSeverityData) {
      let severities = JSON.parse(sessionStorage.getItem("applicationSeverityData"));

      severities = severities ? [...severities, ...applicationSeverityData] : [...applicationSeverityData];
      sessionStorage.setItem(
        "applicationSeverityData",
        JSON.stringify(severities)
      );
    }
    yield put(updateExceptionCSTData({data: applicationSeverityData, type: 'severities' }));
  } catch (e) {
    logger(e);
    toast.error(`Oops! Something went wrong ${e}`);
  }
}

export function* getApplicationExceptionSeverity() {
  yield takeLatest(types.GET_APPLICATION_EXCEPTION_SEVERITY, fetchExceptionSeverityByAppName);
}

export function* fetchExceptionSeverity(payload) {
  try {
    const SECOND = 1000;
    const applicationSeverityData = yield retry(3, 10 * SECOND, defaultAction, payload);
    if (applicationSeverityData) {
      sessionStorage.setItem(
        "applicationSeverityData",
        JSON.stringify(applicationSeverityData)
      );
    }
    yield put(actions.updateExceptionData({data: applicationSeverityData, type: 'severity' }));
  } catch (e) {
    logger(e);
    toast.error(`Oops! Something went wrong ${e}`);
  }
}

export function* getExceptionSeverity() {
  yield takeLatest(types.GET_EXCEPTION_SEVERITY, fetchExceptionSeverity);
}

export function* addExceptionSeverity(payload) {
  try {
    yield call(defaultAction, payload);
    // if(severityResults==="Exception Severity Added"){
    toast.success("Severity has been added successfully");
    yield put(actions.getExceptionSeverity());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* exceptionSeverity() {
  yield takeLatest(types.ADD_EXCEPTION_SEVERITY, addExceptionSeverity);
}

export function* updateExceptionSeverity(payload) {
  try {
    yield call(defaultAction, payload);
    // if(severityResults==="Exception Severities Updated"){
    toast.success("Severity has been modified successfully");
    yield put(actions.getExceptionSeverity());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* exceptionSeverityResults() {
  yield takeLatest(types.UPDATE_EXCEPTION_SEVERITY, updateExceptionSeverity);
}

export function* deleteExceptionSeverity(payload) {
  try {
    yield call(defaultAction, payload);
    // if(severityResults==="Exception Severities Deleted"){
    toast.success("Severity has been deleted successfully");
    yield put(actions.getExceptionSeverity());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* deleteSeverityResults() {
  yield takeLatest(types.DELETE_SEVERITY, deleteExceptionSeverity);
}

export function* fetchExceptionTypeByAppName(payload) {
  try {
    const SECOND = 1000;
    const applicationTypeData = yield retry(3, 10 * SECOND, defaultAction, payload);
    if (applicationTypeData) {
      let types = JSON.parse(sessionStorage.getItem("applicationTypeData"));
      types = types ? [...types, ...applicationTypeData] : [...applicationTypeData];
      sessionStorage.setItem(
        "applicationTypeData",
        JSON.stringify(types)
      );
    }
    yield put(updateExceptionCSTData({data: applicationTypeData, type: 'types' }));
  } catch (e) {
    logger(e);
    toast.error(`Oops! Something went wrong ${e}`);
  }
}

export function* getApplicationExceptionType() {
  yield takeLatest(types.GET_APPLICATION_EXCEPTION_TYPE, fetchExceptionTypeByAppName);
}

export function* fetchExceptionType(payload) {
  try {
    const SECOND = 1000;
    const applicationTypeData = yield retry(3, 10 * SECOND, defaultAction, payload);
    if (applicationTypeData) {
      sessionStorage.setItem(
        "applicationTypeData",
        JSON.stringify(applicationTypeData)
      );
    }
    yield put(actions.updateExceptionData({data: applicationTypeData, type: 'type' }));
  } catch (e) {
    logger(e);
    toast.error(`Oops! Something went wrong ${e}`);
  }
}

export function* getExceptionType() {
  yield takeLatest(types.GET_EXCEPTION_TYPE, fetchExceptionType);
}

export function* addExceptionType(payload) {
  try {
    yield call(defaultAction, payload);
    // if(typeResults==="Exception Type Added"){
    toast.success("Type has been added successfully");
    yield put(actions.getExceptionType());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* exceptiontype() {
  yield takeLatest(types.ADD_EXCEPTION_TYPE, addExceptionType);
}

export function* updateExceptionType(payload) {
  try {
    yield call(defaultAction, payload);
    // if(typeResults==="Exception Types Updated"){
    toast.success("Type has been modified successfully");
    yield put(actions.getExceptionType());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* exceptiontypeResults() {
  yield takeLatest(types.UPDATE_EXCEPTION_TYPES, updateExceptionType);
}

export function* deleteExceptionType(payload) {
  try {
    yield call(defaultAction, payload);
    // if(typeResults==="Exception Types Deleted"){
    toast.success("Type has been deleted successfully");
    yield put(actions.getExceptionType());
    // }
  } catch (e) {
    logger(e);
  }
}
export function* deleteTypeResults() {
  yield takeLatest(types.DELETE_TYPE, deleteExceptionType);
}
export default function* root() {
  yield all([
    fork(getApplicationExceptionCategory),
    fork(getExceptionCategory),
    fork(getApplicationExceptionSeverity),
    fork(getExceptionSeverity),
    fork(getApplicationExceptionType),
    fork(getExceptionType),
    fork(exceptionCategory),
    fork(exceptionSeverity),
    fork(exceptiontype),
    fork(exceptionCategoryResults),
    fork(exceptionSeverityResults),
    fork(exceptiontypeResults),
    fork(deleteTypeResults),
    fork(deleteSeverityResults),
    fork(deleteCategoryResults),
  ]);
}
