import { combineReducers } from "redux";
import exceptions from "./Exceptions/exceptionReducer";
import logs from "./Logs/logReducer";
import applications from "./Admin/applicationConfigReducer";
import lists from "./Admin/listReducer";
import configColumnAlters from "./Admin/configureColumnAlertReducer";
import configExcep from "./Admin/configExceptionReducer";
import users from "./Admin/userReducer";
import solmanStaging from "./Admin/solmanStagingReducer";
import userAccess from "./UserAccess/userAccessReducer";
import reports from "./Dashboard/reportsReducer";
import analytics from "./Dashboard/analyticsReducer";
import globalStatus from "./Dashboard/globalReducer";
import reportConfig from "./Admin/sapReportReducer";
import logConfig from "./Admin/logStatusReducer";
import scheduleReportConfig from "./Admin/scheduleReportReducer";
import configReducer from "./configReducer";
import blobMetrics from "./Admin/blobMetricsReducer";
import tableConfig from "./Admin/tableConfigReducer";

const rootReducer = () =>
  combineReducers({
    exceptions,
    logs,
    applications,
    lists,
    configColumnAlters,
    users,
    configExcep,
    solmanStaging,
    userAccess,
    reports,
    analytics,
    globalStatus,
    reportConfig,
    logConfig,
    scheduleReportConfig,
    configReducer,
    blobMetrics,
    tableConfig
  });

export default rootReducer;
