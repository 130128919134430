import URL from "../services/setEndpointURL";

const adminServices = {
  GET_APPLICATION_DATA_ID: {
    apiName: "GET_APPLICATION_DATA_ID",
    endPointUrl: "/application/config",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
    isNoError: true,
  },
  GET_APPLICATION_DETAILS: {
    apiName: "GET_APPLICATION_DETAILS",
    endPointUrl: "/application/config",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_NEW_APPLICATION: {
    apiName: "ADD_NEW_APPLICATION",
    endPointUrl: "/application/config",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_APPLICATIONCONFIG: {
    apiName: "UPDATE_APPLICATIONCONFIG",
    endPointUrl: "/application/config",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_APPLICATION: {
    apiName: "DELETE_APPLICATION",
    endPointUrl: "/application/config",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  LOG_EXCEPTION_TABLE: {
    apiName: "LOG_EXCEPTION_TABLE",
    endPointUrl: "/application/meta/config/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_INDEX_CONFIG: {
    apiName: "ADD_INDEX_CONFIG",
    endPointUrl: "/application/meta/config/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_EXCEPTION_CATEGORY: {
    apiName: "GET_EXCEPTION_CATEGORY",
    endPointUrl: "/application/category/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    server: "Pepsico",
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    isNoError: true,
  },
  ADD_EXCEPTION_CATEGORY: {
    apiName: "ADD_EXCEPTION_CATEGORY",
    endPointUrl: "/application/category/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_EXCEPTION_CATEGORY: {
    apiName: "UPDATE_EXCEPTION_CATEGORY",
    endPointUrl: "/application/category/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_CATEGORY: {
    apiName: "DELETE_CATEGORY",
    endPointUrl: "/application/category/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_EXCEPTION_SEVERITY: {
    apiName: "GET_EXCEPTION_SEVERITY",
    endPointUrl: "/application/severity/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    server: "Pepsico",
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    isNoError: true,
  },
  ADD_EXCEPTION_SEVERITY: {
    apiName: "ADD_EXCEPTION_SEVERITY",
    endPointUrl: "/application/severity/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_EXCEPTION_SEVERITY: {
    apiName: "UPDATE_EXCEPTION_SEVERITY",
    endPointUrl: "/application/severity/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_SEVERITY: {
    apiName: "DELETE_SEVERITY",
    endPointUrl: "/application/severity/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_EXCEPTION_TYPE: {
    apiName: "GET_EXCEPTION_TYPE",
    endPointUrl: "/application/type/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    server: "Pepsico",
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    isNoError: true,
  },
  ADD_EXCEPTION_TYPE: {
    apiName: "ADD_EXCEPTION_TYPE",
    endPointUrl: "/application/type/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_EXCEPTION_TYPES: {
    apiName: "UPDATE_EXCEPTION_TYPES",
    endPointUrl: "/application/type/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_TYPE: {
    apiName: "DELETE_TYPE",
    endPointUrl: "/application/type/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_EXCEPTION_CONFIG_DETAILS: {
    apiName: "GET_EXCEPTION_CONFIG_DETAILS",
    endPointUrl: "/application/config/exception/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_CONFIG_EXP: {
    apiName: "ADD_CONFIG_EXP",
    endPointUrl: "/application/config/exception/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  EDIT_CONFIG_EXP: {
    apiName: "EDIT_CONFIG_EXP",
    endPointUrl: "/application/config/exception/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_CONFIGURE_EXP: {
    apiName: "DELETE_CONFIGURE_EXP",
    endPointUrl: "/application/config/exception/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_REPLAY_CONFIG_DETAILS: {
    apiName: "GET_REPLAY_CONFIG_DETAILS",
    endPointUrl: "/application/config/replay/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_REPLAY_CONFIG_EXP: {
    apiName: "ADD_REPLAY_CONFIG_EXP",
    endPointUrl: "/application/config/replay/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  EDIT_REPLAY_CONFIG_EXP: {
    apiName: "EDIT_REPLAY_CONFIG_EXP",
    endPointUrl: "/application/config/replay/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_CONFIGURE_REPLAY: {
    apiName: "DELETE_CONFIGURE_REPLAY",
    endPointUrl: "/application/config/replay/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_COLUMNCONFIG_ALERT_DETAILS: {
    apiName: "GET_COLUMNCONFIG_ALERT_DETAILS",
    endPointUrl: "/application/colalert/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_NEW_COLUMNCONFIGALERT: {
    apiName: "ADD_NEW_COLUMNCONFIGALERT",
    endPointUrl: "/application/colalert/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_COLUMNCONFIGALERT: {
    apiName: "UPDATE_COLUMNCONFIGALERT",
    endPointUrl: "/application/colalert/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_COLUMNCONFIGALERT: {
    apiName: "DELETE_COLUMNCONFIGALERT",
    endPointUrl: "/application/colalert/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_SOLMAN_STAGING: {
    apiName: "GET_SOLMAN_STAGING",
    endPointUrl: "/application/solman/data/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_SOLMAN_STAGING: {
    apiName: "ADD_SOLMAN_STAGING",
    endPointUrl: "/application/solman/data/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_SOLMAN_STAGING: {
    apiName: "UPDATE_SOLMAN_STAGING",
    endPointUrl: "/application/solman/data/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_SOLMAN_STAGING: {
    apiName: "DELETE_SOLMAN_STAGING",
    endPointUrl: "/application/solman/data/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_REPORT: {
    apiName: "GET_REPORT",
    endPointUrl: "/reportconfig/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_REPORT: {
    apiName: "ADD_REPORT",
    endPointUrl: "/reportconfig/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_REPORT: {
    apiName: "UPDATE_REPORT",
    endPointUrl: "/reportconfig/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_REPORT: {
    apiName: "DELETE_REPORT",
    endPointUrl: "/reportconfig/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_SCHEDULE_REPORT: {
    apiName: "GET_SCHEDULE_REPORT",
    endPointUrl: "/scheduleconfig/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_SCHEDULE_REPORT: {
    apiName: "ADD_SCHEDULE_REPORT",
    endPointUrl: "/scheduleconfig/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_SCHEDULE_REPORT: {
    apiName: "UPDATE_SCHEDULE_REPORT",
    endPointUrl: "/scheduleconfig/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_SCHEDULE_REPORT: {
    apiName: "DELETE_SCHEDULE_REPORT",
    endPointUrl: "/scheduleconfig/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_LOG_CONFIG_DETAILS: {
    apiName: "GET_LOG_CONFIG_DETAILS",
    endPointUrl: "/log/report/config/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_LOG_DETAIL: {
    apiName: "ADD_LOG_DETAIL",
    endPointUrl: "/log/report/config/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  EDIT_LOG_DETAIL: {
    apiName: "EDIT_LOG_DETAIL",
    endPointUrl: "/log/report/config/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_LOG_DETAIL: {
    apiName: "DELETE_LOG_DETAIL",
    endPointUrl: "/log/report/config/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_EXCEPTION_REPORT_DETAILS: {
    apiName: "GET_EXCEPTION_REPORT_DETAILS",
    endPointUrl: "/exception/report/config/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_EXCEPTION_DETAIL: {
    apiName: "ADD_EXCEPTION_DETAIL",
    endPointUrl: "/exception/report/config/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  EDIT_EXCEPTION_DETAIL: {
    apiName: "EDIT_EXCEPTION_DETAIL",
    endPointUrl: "/exception/report/config/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_EXCEPTION_DETAIL: {
    apiName: "DELETE_EXCEPTION_DETAIL",
    endPointUrl: "/exception/report/config/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_USERS_LIST: {
    apiName: "GET_USERS_LIST",
    endPointUrl: "/users/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_USER: {
    apiName: "ADD_USER",
    endPointUrl: "/users/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  EDIT_USER: {
    apiName: "EDIT_USER",
    endPointUrl: "/users/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_USER: {
    apiName: "DELETE_USER",
    endPointUrl: "/users/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_ROLES_LIST: {
    apiName: "GET_ROLES_LIST",
    endPointUrl: "/group/access/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_ROLES_APPLICATION_LIST: {
    apiName: "GET_ROLES_APPLICATION_LIST",
    endPointUrl: "/group/access/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_ROLES_APPLICATION_LIST: {
    apiName: "ADD_ROLES_APPLICATION_LIST",
    endPointUrl: "/group/access/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_ROLES_APPLICATION_LIST: {
    apiName: "UPDATE_ROLES_APPLICATION_LIST",
    endPointUrl: "/group/access/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_ROLES_APPLICATION_LIST: {
    apiName: "DELETE_ROLES_APPLICATION_LIST",
    endPointUrl: "/group/access/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_ROLE_USERS_LIST: {
    apiName: "GET_ROLE_USERS_LIST",
    endPointUrl: "/group/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  ADD_ROLE_USER: {
    apiName: "ADD_ROLE_USER",
    endPointUrl: "/group/",
    method: "POST",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  UPDATE_ROLE_USER: {
    apiName: "UPDATE_ROLE_USER",
    endPointUrl: "/group/",
    method: "PUT",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  DELETE_ROLE_USER: {
    apiName: "DELETE_ROLE_USER",
    endPointUrl: "/group/",
    method: "DELETE",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  },
  GET_APPLICATION_SECTOR_LIST: {
    apiName: "GET_APPLICATION_SECTOR_LIST",
    endPointUrl: "/application/sector/",
    method: "GET",
    mock_json: "",
    isSecured: true,
    baseUrlPrefix: URL.ADMIN_SERVICES_API_URL,
    server: "Pepsico",
  }
};

export default adminServices;
