import React from "react";
import { store } from "./store/store";
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppWithRouterAccess from "./layout/BaseLayout";

const persistor = persistStore(store);

const Main = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <AppWithRouterAccess />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default Main;