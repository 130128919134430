import * as types from '../../constants/ActionTypes/Admin/columnConfigAlertTypes';
import { constructServiceUrl } from '../../services/constructUrl';

/* To get all column configuration alerts and the result will be displayed in the home page grid */
export function getConfigColumnAlertDetails() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_COLUMNCONFIG_ALERT_DETAILS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_COLUMNCONFIG_ALERT_DETAILS,
    request: requestParams
  };
}

/* To update the latest alert details in the home page grid */
export function updateColumnConfigurationAlertDetails(columnConfigurationAlertData) {
  return {
    type: types.UPDATE_COLUMNCONFIG_ALERT_DETAILS,
    columnConfigurationAlertData
  };
}
/* To delete a column configuration alert */
export function deleteConfigurationColumnAlert(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_COLUMNCONFIGALERT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_COLUMNCONFIGALERT,
    request: requestParams
  };
}

/* To add a new column configuration alert */
export function addconfigColumnAlertDetails(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_NEW_COLUMNCONFIGALERT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_NEW_COLUMNCONFIGALERT,
    request: requestParams
  };
}

/* To update an existing column configuration alert */
export function modifyConfigColumnAlertDetails(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_COLUMNCONFIGALERT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.UPDATE_COLUMNCONFIGALERT,
    request: requestParams
  };
}
export function updateStatus(actionType, status) {
  return {
    type: types.UPDATE_COLUMNCONFIG_ALERT_STATUS,
    actionType,
    status
  };
}


export function ColumnConfigprogress() {
  return {
    type: types.PROGRESS_COLUMNCONFIG_REPORT
  }
}

export function ColumnConfigDataStore(data) {
  return {
    type: types.STORE_COLUMNCONFIG_REPORT,
    data
  };
}