import * as  types from '../../constants/ActionTypes/Admin/configExceptionTypes';
import objectAssign from 'object-assign';
import { formatData } from '../../utils/constantReducer';

const initialState = {
  exceptionConfigData: [],
  replayConfigData: [],
  isExceptionConfigLoading: true,
  isReplayConfigLoader: true,
  excepConfigResults: {
    pageID: null,
    data: [],
    totalRecords: null
  }
};

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function exceptionConfigReducer(state = initialState, action) {
  switch (action.type) {
    case types.STORE_EXCEPTION_CONFIG_REPORT: {
      let statekey = action.data.page;
      let data = formatData(action.data, [...state[statekey]], action.data.id);
      return objectAssign({}, state, {
        [statekey]: data,
        isOverlayLoading: false,
        isExceptionConfigLoading:
          state.isExceptionConfigLoading ? (statekey === "exceptionConfigData" ? false : true) : false,
        isReplayConfigLoader: state.isReplayConfigLoader ?
          (statekey === "replayConfigData" ? false : true) : false
      });
    }
    default:
      return state;
  }
}