import * as  types from '../../constants/ActionTypes/Admin/columnConfigAlertTypes';
import objectAssign from 'object-assign';
import { formatData } from '../../utils/constantReducer';
const initialState = {
  columnConfigurationAlertData: [],
  isLoading: true,
  status: {
    action: null,
    level: null
  }
};

export default function configureColumnAlertReducer(state = initialState, action) {

  switch (action.type) {
    case types.STORE_COLUMNCONFIG_REPORT:
      return objectAssign({}, state, {
        columnConfigurationAlertData: formatData(action.data, [...state.columnConfigurationAlertData], action.data.id),
        isLoading: false
      });
    default:
      return state;
  }
}
