import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import { formItemsLayout } from '../../../../constants/AdminModalForm/FormStyleConfig';
import AdminModalFooter from '../../../CommonComponents/Modal/AdminModalFooter';
import SelectDropDown from '../../../CommonComponents/SelectDropDown';

const NewApplication = ({ ...props }) => {
  const [form] = Form.useForm();
  const [logTableList, setLogTableList] = useState([]);
  const [exceptionTableList, setExceptionTableList] = useState([]);
  useEffect(() => {
    if (props.editFlag) {
      form.setFieldsValue({ ...props.data.selectedRow[0] });
    }
  }, []);
  const onSaveHandler = (close) => {
    form.validateFields().then(async (values) => {
      if (props.editFlag) {
        values = {
          ...values
        };
      }
      props.saveData(values, close);
    });
  };

  const onSelectChange = (selectedVal, selectedOption, selectName) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [selectName]: selectedOption.value,
      [selectName === 'logTable' ? 'logRetain' : 'exceptionRetain']: selectedOption.retentionDays,
      [selectName === 'logTable' ? 'logTopic' : 'exceptionTopic']: selectedOption.topic
    });
  };

  const onSectorValueChange = (selectedVal) => {
    // console.log('data', selectedVal, props.data.logTable);
    const logTableData = props.data.logTable.filter(item => (item.table === 'log' && item.sector === selectedVal)) || {};
    const exceptionTableData = props.data.logTable.filter(item => (item.table === 'exception' && item.sector === selectedVal)) || {};

    setLogTableList(logTableData);
    setExceptionTableList(exceptionTableData);

    form.setFieldsValue({
      ...form.getFieldsValue(),
      'appSector': selectedVal,
      'logTable': logTableData.length > 0 ? logTableData[0].value : '',
      'logRetain': logTableData.length > 0 ? logTableData[0].retentionDays : 0,
      'logTopic': logTableData.length > 0 ? logTableData[0].topic : '',
      'exceptionTable': exceptionTableData.length > 0 ? exceptionTableData[0].value : '',
      'exceptionRetain': exceptionTableData.length > 0 ? exceptionTableData[0].retentionDays : 0,
      'exceptionTopic': exceptionTableData.length > 0 ? exceptionTableData[0].topic : '',
    });
  };

  // const getTable = (value, opt) => {
  //   if (value && value.length > 0) {
  //     return value.filter((val) => {
  //       return val.table === opt;
  //     });
  //   }
  //   return [];
  // };

  const getSectorDropdownOptions = (sectorList) => {
    return sectorList.map((item) => ({
      label: item.sectorName,
      value: item.sectorName
    }));
  };

  useEffect(() => {
    const logTableData = props.data.logTable.filter(item => (item.table === 'log' && item.sector === form.getFieldValue('appSector'))) || {};
    const exceptionTableData = props.data.logTable.filter(item => (item.table === 'exception' && item.sector === form.getFieldValue('appSector'))) || {};

    setLogTableList(logTableData);
    setExceptionTableList(exceptionTableData);
  }, [props.data.logTable]);

  return (
    <Modal
      open={props.isModalOpen}
      title={(props.editFlag ? 'Edit' : 'New') + ' Application'}
      onCancel={props.onCloseModal}
      footer={
        props.editFlag ? (
          <AdminModalFooter
            close
            update
            onUpdate={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
        ) : (
          <AdminModalFooter
            save
            saveAndClose
            close
            onSave={() => onSaveHandler(false)}
            onSaveAndClose={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
        )
      }
      style={{ minWidth: 900 }}
      className="admin-modal-wrapper"
    >
      <Form id="application-config" form={form} {...formItemsLayout} labelAlign="left" colon>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Application"
              name="applicationName"
              rules={[
                { required: true, message: 'Application Name is Mandatory!' },
                {
                  pattern: !props.editFlag ? new RegExp('^[A-Za-z0-9_-]+$') : '',
                  message:
                    'Invalid Application Name! Only hyphens and underscore as a special characters are allowed.'
                }
              ]}
            >
              <Input disabled={props.editFlag} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Description"
              name="appDescription"
              rules={[{ required: true, message: 'Application Description is Mandatory!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Sector"
              name="appSector"
              rules={[{ required: true, message: 'Sector is Mandatory!' }]}
            >
              <SelectDropDown
                onChange={(val) => onSectorValueChange(val)}
                options={getSectorDropdownOptions(props.applicationSectorList)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="App Owner"
              name="appOwner"
            // rules={[{ required: true, message: 'App Owner is Mandatory!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Log Retain Days"
              name="logRetain"
              rules={[{ required: true, message: 'Log Retain Day is Mandatory!' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Exception Retain Days"
              name="exceptionRetain"
              rules={[{ required: true, message: 'Exception Retain Day is Mandatory!' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Log Topic"
              name="logTopic"
              rules={[{ required: true, message: 'Log Topic is Mandatory!' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Exception Topic"
              name="exceptionTopic"
              rules={[{ required: true, message: 'Exception Topic is Mandatory!' }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Log Table Name"
              name="logTable"
              rules={[{ required: true, message: 'Log Table is Mandatory!' }]}
            >
              <SelectDropDown
                onChange={(val, option) => onSelectChange(val, option, 'logTable')}
                // options={getTable(props.data ? props.data.logTable : null, 'log')}
                options={logTableList}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Exception Table Name:"
              name="exceptionTable"
              rules={[{ required: true, message: 'Exception Table is Mandatory!' }]}
            >
              <SelectDropDown
                onChange={onSelectChange}
                // options={getTable(props.data ? props.data.logTable : null, 'exception')}
                options={exceptionTableList}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Team DL"
              name="teamDl"
            // rules={[{ required: true, message: 'Team DL is Mandatory!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Snow CI"
              name="snowCI"
            // rules={[{ required: true, message: 'Snow CI is Mandatory!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assignment Group"
              name="assignmentGroup"
            // rules={[{ required: true, message: 'Assignment Group is Mandatory!' }]}
            >
              <Input />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

NewApplication.propTypes = {
  applicationSectorList: PropTypes.array,
  onCloseModal: PropTypes.func,
  saveData: PropTypes.func,
  data: PropTypes.any,
  editFlag: PropTypes.bool,
  isModalOpen: PropTypes.bool
};

export default NewApplication;
