import * as  types from '../../constants/ActionTypes/Admin/sapReportTypes';
import objectAssign from 'object-assign';
import { formatData } from '../../utils/constantReducer';


const initialState = {
    reportData: [],
    isLoading: true
};


export default function sapReportReducer(state = initialState, action) {
    switch (action.type) {
        case types.STORE_REPORT:
            return objectAssign({}, state, { reportData: formatData(action.data, [...state.reportData], action.data.id, "service", true), isLoading: false, isOverlayLoading: false });
        default:
            return state;
    }
}
