import { React } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
const ConfirmationModalPopup = ({ title, isModalOpen, onOkModal, onCloseModal }) => {
    return (
        <Modal
            title="Confirm"
            centered
            open={isModalOpen}
            onCancel={onCloseModal}
            width={550}
            footer={
                <div className="confirm-modal-footer">
                    <Button type="primary" key="ok" danger onClick={onOkModal}>
                        <CheckOutlined /> Ok
                    </Button>
                    <Button type="primary" key="close" onClick={onCloseModal}>
                        <CloseOutlined /> Close
                    </Button>
                </div>
            }
        >
            {title}
        </Modal>
    );
}
ConfirmationModalPopup.propTypes = {
    title: PropTypes.string,
    isModalOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onOkModal: PropTypes.func,

}
export default ConfirmationModalPopup;
