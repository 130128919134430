import { call, put, fork, all, takeLatest,retry,cancel,delay } from 'redux-saga/effects';
import {
  updateLogSearchResults, updateLogDetails, updateFilterLogResult,
  updateLogFilterStatus, updateLogResultsonFilter, updateLogsonPurge, emptyLogData,
  updateTotalRecordsCount, updateLogSearchFailure, updateLogSortResults,
  updateLogSortFailure
} from '../../actions/Logs/logActions';
import * as types from '../../constants/ActionTypes/logTypes';
import { defaultAction } from '../../services/restService';
import { toast } from 'react-toastify';
import { logger } from 'redux-logger';
import { logFilterStatusGlobal, logPurgeStatus } from "../../actions/GlobalAction/GlobalAction"

export function* fetchLogSearchResults(payload) {
  try {
    const SECOND = 1000;
    const logData = yield retry(3, 10 * SECOND, defaultAction, payload)
    // const logData = yield call(defaultAction, payload);
    yield put(updateLogSearchResults(logData, payload.request.payload));
  } catch (e) {
    yield put(updateLogSearchFailure('Error in fetching Log Data'));
  }
}
export function* fetchSortedLogResults(payload) {
  try {
    const logData = yield call(defaultAction, payload);
    yield put(updateLogSortResults(logData, payload.request.payload));
  } catch (e) {
    yield put(updateLogSortFailure('Error in fetching Log Data'));
  }
}
export function* fetchLogDetails(payload) {
  try {
    const logDetails = yield call(defaultAction, payload);
    yield put(updateLogDetails(logDetails));
  } catch (e) {
    logger(e)
    // yield put(updateLogDetailFailure('Error'));
  }
}
export function* filterLogs(payload) {
  try {
    window.timeLimit=60000
    const logFilterData = yield call(defaultAction, payload);
    yield put(logFilterStatusGlobal("Filter"))
    yield delay(60000)
    window.openProcessModal("Filter")
    yield put(updateFilterLogResult(logFilterData, payload.actionType));
  } catch (e) {
    const errorData = { requestStatus: 'Error', filterRequestId: '', recordsCount: '' };
    yield put(updateLogFilterStatus(errorData, payload.actionType));
  }
}
export function* deFilterLogs(payload) {
  try {
    window.timeLimit=60000
    const logFilterData = yield call(defaultAction, payload);
    yield put(logFilterStatusGlobal("De-Filter"))
    yield delay(60000)
    window.openProcessModal("De-Filter")
    yield put(updateFilterLogResult(logFilterData, payload.actionType));
  } catch (e) {
    const errorData = { requestStatus: 'Error', filterRequestId: '', recordsCount: '' };
    yield put(updateLogFilterStatus(errorData, payload.actionType));
  }
}
export function* filterSearchResults(requestParams) {
  try {
    window.timeLimit=60000
    toast.info(`Logs filter has been started`);
    yield call(defaultAction, requestParams);
    yield delay(60000)
    toast.success(`Logs filter has been completed`);
    yield put(updateLogResultsonFilter(requestParams.request.payload));
  } catch (e) {
    logger(e)
  }
}
export function* defilterSearchResults(requestParams) {
  try {
    window.timeLimit=60000
    toast.info(`Logs De-filter has been started`);
    yield call(defaultAction, requestParams);
    yield delay(60000)
    toast.success(`Logs De-filter has been completed`);
    yield put(updateLogResultsonFilter(requestParams.request.payload));
  } catch (e) {
    logger(e)
  }
}
export function* processLogsPurge(requestParams) {
  try {
    window.timeLimit=60000
    toast.info(`Logs Purge has been started`);
    yield call(defaultAction, requestParams);
    yield delay(60000)
    toast.success(`Logs Purge has been completed`);
    yield put(updateLogsonPurge(requestParams.request.payload));
  } catch (e) {
    logger(e)
  }
}
export function* processFilterLogsPurge(payload) {
  try {
    window.timeLimit=60000
    let logPurgeData = yield call(defaultAction, payload);
    yield put(logPurgeStatus("Purge"))
    yield delay(60000)
    window.openProcessModal("Purge")
    yield put(updateFilterLogResult(logPurgeData, payload.actionType));
    // const successData = { requestStatus: 'Completed', filterRequestId: '', recordsCount: '' };
    // toast.info(`Log Purge Process has been started`);
    // yield put(updateLogFilterStatus(successData, payload.actionType));
  } catch (e) {
    const errorData = { requestStatus: 'Error', filterRequestId: '', recordsCount: '' };
    yield put(updateLogFilterStatus(errorData, payload.actionType));
  }
}
export function* fetchFilteredLogStatus(requestParams) {
  try {
    // yield delay(10000)
    const filterStatus = yield call(defaultAction, requestParams);
    if (filterStatus.requestStatus !== "Completed") {
      yield put(updateFilterLogResult(filterStatus, requestParams.actionType));
    }
    else {
      toast.success(`Log ${requestParams.actionType} Process has been completed`);
      if (requestParams.actionType === "Filter" || requestParams.actionType === "De-Filter") {
        // yield put(updateLogResultsonFilter(requestParams.request.payload));
        yield put(logFilterStatusGlobal(null))
      }
      else {
        // yield put(updateLogsonPurge(requestParams.request.payload));
        yield put(logPurgeStatus(null))
      }

      // yield put(updateLogFilterStatus(filterStatus, requestParams.actionType));
    }
  } catch (e) {
    logger(e)
  }
}
export function* stopSearchLogs() {
  try {
    const bgSyncTask = yield fork(fetchLogSearchResults)
    yield cancel(bgSyncTask);
    yield put(emptyLogData());
  } catch (e) {
    logger(e)
  }
}
export function* fetchTotalRecordSCount(payload) {
  try {
    // yield delay(50000)
    const logData = yield call(defaultAction, payload);
    yield put(updateTotalRecordsCount(logData));
  } catch (e) {
    logger(e)
  }
}
export function* logSearch() {
  yield takeLatest(types.SEARCH_LOGS, fetchLogSearchResults);
}
export function* getLogDetails() {
  yield takeLatest(types.GET_LOG_DETAILS, fetchLogDetails);
}
export function* processFilterLog() {
  yield takeLatest(types.PROCESS_FILTER_LOGS, filterLogs);
}
export function* processDeFilterLog() {
  yield takeLatest(types.PROCESS_DEFILTER_LOGS, deFilterLogs);
}
export function* logSearchResultFilter() {
  yield takeLatest(types.LOG_SEARCH_FILTER_LOGS, filterSearchResults);
}
export function* logSearchResultDeFilter() {
  yield takeLatest(types.LOG_SEARCH_DEFILTER_LOGS, defilterSearchResults);
}
export function* purgeLogs() {
  yield takeLatest(types.PURGE_LOGS, processLogsPurge);
}
export function* logFilterStatus() {
  yield takeLatest(types.GET_FILTERED_LOG_STATUS, fetchFilteredLogStatus);
}
export function* cancelSearchLogs() {
  yield takeLatest(types.CLEAR_LOG_DATA, stopSearchLogs);
}
export function* logFilterPurge() {
  yield takeLatest(types.FILTER_LOGS_PURGE, processFilterLogsPurge);
}
export function* getTotalNoOfRecords() {
  yield takeLatest(types.GET_TOTAL_RECORD_COUNT, fetchTotalRecordSCount);
}
export function* logSort() {
  yield takeLatest(types.SORT_LOGS, fetchSortedLogResults);
}
export default function* root() {
  yield all([
    fork(logSearch),
    fork(getLogDetails),
    fork(processFilterLog),
    fork(processDeFilterLog),
    fork(logSearchResultFilter),
    fork(purgeLogs),
    fork(logFilterStatus),
    fork(cancelSearchLogs),
    fork(logFilterPurge),
    fork(getTotalNoOfRecords),
    fork(logSearchResultDeFilter),
    fork(logSort)
  ])
}
