import * as types from '../../constants/ActionTypes/logTypes';
import objectAssign from 'object-assign';
import update from 'immutability-helper';

const initialState = {
    logSearchResults: {
        pageID: null,
        data: [],
        logDetails: [],
        isFetching: true,
        errorMessage: null,
        recordCount: null,
        datax: [],
        isRefresh: null,
        isProcessEnd: false
    },
    searchCriterias: null,
    totalRecords: null,
    logFilterResult: {
        filterStatus: null,
        action: null
    },
};
// function getTotalRecordCount(totalRecords) {
//   let recordCount = null;
//   if (totalRecords < 2000) {
//     recordCount = totalRecords;
//   } else {
//     recordCount = 2000;
//   }
//   return recordCount;
// }
function getUpdatedLogData(filteredLogs, logData) {
    let updatedLogData = JSON.parse(JSON.stringify(logData));
    for (let i = 0; i < filteredLogs.length; i++) {
        for (let j = 0; j < updatedLogData.length; j++) {
            if (filteredLogs[i].logID === updatedLogData[j].log_id) {
                updatedLogData.splice(j, 1);
            }
        }
    }
    return updatedLogData;
}

function removePurgedLogsfromResult(purgedLogs, logData) {
    let updatedLogData = JSON.parse(JSON.stringify(logData));
    for (let i = 0; i < purgedLogs.length; i++) {
        for (let j = 0; j < updatedLogData.length; j++) {
            if (purgedLogs[i].logID === updatedLogData[j].log_id) {
                updatedLogData.splice(j, 1);
            }
        }
    }
    return updatedLogData;
}
export default function logReducer(state = initialState, action) {
    switch (action.type) {
        case types.SEARCH_LOGS:
            var isFetching = state.logSearchResults.data.length === 0 ? true : false;
            return objectAssign({}, update(state, {
                logSearchResults: {
                    isFetching: { $set: isFetching },
                    isProcessEnd: { $set: false }
                }
            }));

        case types.PROGRESS_LOG_REDUCER:
            return objectAssign({}, update(state, {
                isOverlayLoading: { $set: true },
            }));


        case types.LOG_SEARCH_SUCCESS:
            // var totalRecords = getTotalRecordCount(action.logData.totalRecords);
            return objectAssign({}, update(state, {
                logSearchResults: {
                    pageID: { $set: action.logData.pageID },
                    data: { $push: action.logData.data },
                    isFetching: { $set: false },
                    errorMessage: { $set: null },
                    datax: { $set: action.logData.data },
                    isRefresh: { $set: null },
                    isProcessEnd: { $set: true }
                },
                searchCriterias: { $set: action.searchCriterias },
            }));
        case types.LOG_SEARCH_FAILURE:
            return objectAssign({}, update(state, {
                logSearchResults: {
                    errorMessage: { $set: action.message },
                    isFetching: { $set: false },
                    isRefresh: { $set: null },
                    isProcessEnd: { $set: false }
                },

            }));
        case types.SORT_LOGS:
            return objectAssign({}, update(state, {
                logSearchResults: {
                    isFetching: { $set: true },
                    isRefresh: { $set: null }
                },

            }));
        case types.SORT_LOGS_SUCCESS:
            // var records = getTotalRecordCount(action.logData.totalRecords);
            return objectAssign({}, update(state, {
                logSearchResults: {
                    pageID: { $set: action.logData.pageID },
                    data: { $set: action.logData.data },
                    datax: { $set: action.logData.data },
                    isFetching: { $set: false },
                    errorMessage: { $set: null },
                    recordCount: { $set: 0 },
                    isRefresh: { $set: null }
                },
                searchCriterias: { $set: action.searchCriterias },

            }));
        case types.SORT_LOGS_ERROR:
            return objectAssign({}, update(state, {
                logSearchResults: {
                    errorMessage: { $set: action.message },
                    isFetching: { $set: false },
                    isRefresh: { $set: null }
                },

            }));
        case types.LOG_DETAILS:
            return {
                ...state,
                logSearchResults: {
                    ...state.logSearchResults,
                    logDetails: state.logSearchResults.logDetails.concat(action.logDetails),
                    isRefresh: { $set: null },
                    errorMessage: { $set: null }
                },

            };
        case types.EMPTY_LOG_DATA:
            return objectAssign({}, update(state, {
                logSearchResults: {
                    pageID: { $set: null },
                    data: { $set: [] },
                    datax: { $set: [] },
                    totalRecords: { $set: null },
                    logDetails: { $set: [] },
                    errorMessage: { $set: null },
                    recordCount: { $set: null },
                    isRefresh: { $set: null },
                    isProcessEnd: { $set: false }
                }
            }));
        case types.CLEAR_LOG_DETAILS:
            return objectAssign({}, update(state, {
                logSearchResults: {
                    logDetails: { $set: [] },
                    isRefresh: { $set: null },
                    errorMessage: { $set: null },
                },

            }));
        case types.CLEAR_LOG_DATA:
            return objectAssign({}, update(state, {
                logSearchResults: {
                    pageID: { $set: null },
                    data: { $set: [] },
                    logDetails: { $set: [] },
                    isFetching: { $set: false },
                    errorMessage: { $set: null },
                    recordCount: { $set: null },
                    datax: { $set: [] },
                    isRefresh: { $set: null },
                    isProcessEnd: { $set: false }
                },
                searchCriterias: { $set: null },
                totalRecords: { $set: null },
                logFilterResult: {
                    filterStatus: { $set: null },
                    action: { $set: null }
                }
            }));
        case types.LOG_FILTER_STATUS:
            return objectAssign({}, update(state, {
                logFilterResult: {
                    filterStatus: { $set: action.filterStatus },
                    action: { $set: action.actionType }
                },
                totalRecords: { $set: null },
                logSearchResults: {
                    pageID: { $set: null },
                    data: { $set: [] },
                    datax: { $set: [] },
                    logDetails: { $set: [] },
                    errorMessage: { $set: null },
                    recordCount: { $set: null },
                }

            }));
        case types.CLEAR_FILTER_DETAILS:
            return objectAssign({}, update(state, {
                logFilterResult: {
                    action: { $set: null },
                    filterStatus: { $set: null },
                    recordCount: { $set: null },
                },
                totalRecords: { $set: null },
            }));
        case types.LOG_SEARCH_FILTER_LOGS_RESULT:
            var updatedLogData = getUpdatedLogData(action.filteredLogs, state.logSearchResults.data);
            return objectAssign({}, update(state, {
                logSearchResults: {
                    datax: { $set: updatedLogData },
                    data: { $set: updatedLogData },
                    recordCount: { $set: state.logFilterResult.totalRecords - action.filteredLogs.length },
                    isRefresh: { $set: "filterProcess" }
                }

            }));
        case types.PURGE_LOGS_RESULT:
            var updatedData = removePurgedLogsfromResult(action.purgedLogs, state.logSearchResults.data);
            return objectAssign({}, update(state, {
                logSearchResults: {
                    data: { $set: updatedData },
                    datax: { $set: updatedData },
                    recordCount: { $set: state.logFilterResult.totalRecords - action.purgedLogs.length },
                    isRefresh: { $set: "purgeProcess" }
                }
            }));
        case types.TOTAL_RECORD_COUNT:
            {
                return objectAssign({}, update(state, {
                    totalRecords: { $set: action.logData }
                }));
            }
        default:
            return state;
    }
}