import React, { useState } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import moment from "moment";

const CleDatePicker = ({ allowClear, dateFormat, value, onChange, field }) => {
  const [open, setOpen] = useState(false);

  // To handle on Date Change
  const onChangeDate = () => {
    let initDate = moment(document.getElementById(field.id).value);
    let date = moment(
      initDate[field.name === "endTime" ? "endOf" : "startOf"]("day"),
      dateFormat,
      true
    );
    onChange(date);
    setOpen(false);
  };

  setTimeout(() => {
    const input = document.getElementById(field.id);
    input &&
      input.addEventListener("keydown", (event) => {
        if (event.code === "Tab") {
          let date = moment(event.target.value, dateFormat, true);
          if (date.isValid()) {
            onChange(date);
          }
          setOpen(false);
        }
      });
  }, 400);

  return (
    <DatePicker
      allowClear={allowClear}
      open={open}
      tabIndex={field.tabIndex}
      renderExtraFooter={() => {
        return (
          <div className="pointer" onClick={onChangeDate}>
            {field.name === "endTime" ? "End" : "Start"} of the day
          </div>
        );
      }}
      onBlur={(e) => {
        let date = moment(e.target.value, dateFormat, true);
        if (date.isValid()) {
          onChange(date);
        }
      }}
      onOpenChange={(status) => {
        if (!status) {
          setOpen(false);
        }
      }}
      value={moment(value)}
      onChange={(date, dateString) => {
        onChange(dateString);
      }}
      onClick={() => setOpen(true)}
      style={{ width: "100%" }}
      id={field.id}
      format={dateFormat}
      showTime
      showNow
    />
  );
};

CleDatePicker.defaultProps = {
  allowClear: false,
  dateFormat: 'MM/DD/YYYY'
};

CleDatePicker.propTypes = {
  allowClear: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateFormat: PropTypes.string,
  field: PropTypes.object,
  onChange: PropTypes.func,
};

export default CleDatePicker;
