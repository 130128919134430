import _ from "lodash";
//import * as accessTypes from '../constants/AccessTypes/accessTypes';

export const applicationDataInstance = {
  getApplicationNames: function (applicationList) {
    let applicationNames = [];
    const applicationData = applicationList && applicationList.length > 0 ? applicationList :
      (JSON.parse(sessionStorage.getItem("applicationDetails")) || []);
    applicationData.length > 0 &&
      applicationData.forEach((app) => {
        applicationNames.push({
          label: app.applicationName,
          value: app.applicationName,
          logTable: app.logTable,
          exceptionTable: app.exceptionTable,
        });
      });
    applicationNames = _.uniqBy(
      _.orderBy(applicationNames, "label", "asc"),
      "label"
    );
    return applicationNames;
  },
  getApplicationNamesForUser: function (userAccessData, applicationList) {
    let applicationNames = [];
    const applicationData =
      applicationList && applicationList.length > 0 ? applicationList : (JSON.parse(sessionStorage.getItem("applicationDetails")) || []);
    const applicationAccess = userAccessData.roleAccess;
    for (let key in applicationAccess) {
      for (let j = 0; j < applicationData.length; j++) {
        if (key === applicationData[j].applicationName) {
          applicationNames.push({
            label: applicationData[j].applicationName,
            value: applicationData[j].applicationName,
            logTable: applicationData[j].logTable,
            exceptionTable: applicationData[j].exceptionTable,
          });
        }
      }
    }
    applicationNames = _.orderBy(applicationNames, "label", "asc");
    return applicationNames;
  },
  /*getApplicationsForFilter: function (userAccessData) {
    let applicationNames = [];
    const applicationData = JSON.parse(sessionStorage.getItem('applicationData')) || [];
    let userApplications = Object.assign({}, userAccessData.applicationAccess);
    for(let key in userApplications) {
      if (userApplications[key] === accessTypes.VIEW_ACCESS) {
        delete userApplications[key];
      }
    }
    for(let key in userApplications) {
      for(let j=0; j <applicationData.length; j++) {
        if(key === applicationData[j].applicationName) {
          applicationNames.push({label: applicationData[j].applicationName, value: applicationData[j].applicationName});
        }
      }
    }
    applicationNames =  _.orderBy(applicationNames, 'label', 'asc');
    return applicationNames;
  },*/
  getTypesForApplication: function (applicationName) {
    const applicationTypeData =
      JSON.parse(sessionStorage.getItem("applicationTypeData")) || [];
    let types = applicationTypeData.filter((item) => item.applicationName === applicationName);
    types = _.uniqBy(types, "typeID");
    return _.orderBy(types, "exceptionTypeName", "asc");
  },
  getCategoriesForApplication: function (applicationName) {
    const categoryData =
      JSON.parse(sessionStorage.getItem("applicationCategoryData")) || [];
    let categories = categoryData.filter((item) => item.applicationName === applicationName);
    categories = _.uniqBy(categories, "categoryID");
    return _.orderBy(categories, "exceptionCategoryName", "asc");
  },
  getSeveritiesForApplication: function (applicationName) {
    const severityData =
      JSON.parse(sessionStorage.getItem("applicationSeverityData")) || [];
    let severities = severityData.filter((item) => item.applicationName === applicationName);
    severities = _.uniqBy(severities, "severityID");
    return _.orderBy(severities, "exceptionSeverityName", "asc");
  },

  getTableNameByApplication: function (applicationName, tableType) {
    const applicationData =
      JSON.parse(sessionStorage.getItem("applicationDetails")) || [];
    const applicationInfo = applicationData.find(a => a["applicationName"] === applicationName);
    return applicationInfo ? (tableType.toString().toLowerCase().includes("log") ?
      applicationInfo?.["logTable"] : applicationInfo?.["exceptionTable"]) : "";
  }

};
