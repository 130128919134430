import * as types from '../../constants/ActionTypes/userAccessTypes';
import { constructServiceUrl } from '../../services/constructUrl';

export function getLoggedInUserAccessInfo() {
  let serviceParams = {}
  //serviceParams.payload = "09087740";
  serviceParams.apiName = types.GET_USER_ACCESS_INFO;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_USER_ACCESS_INFO,
    request: requestParams
  };
}

export function getExceptionInfo() {
  let serviceParams = {}
  //serviceParams.payload = "09087740";
  serviceParams.apiName = types.GET_EXCEPTION_DETAIL;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_EXCEPTION_DETAIL,
    request: requestParams
  };
}

export function exceptionInfo(exceptionData) {
  return {
    type: types.EXCEPTION_INFO,
    exceptionData
  };
}

export function oktaAuthenticated(status) {
  return {
    type: types.OKTA_AUTHENTICATED_STATUS,
    status
  };
}

export function userAccessInfo(userAccessData) {
  return {
    type: types.USER_ACCESS_INFO,
    userAccessData
  };
}
export function onUserSignOut() {
  let serviceParams = {}
  // localStorage.clear();
  // sessionStorage.clear();
  window.iosLoader = true;
  serviceParams.apiName = types.USER_LOGOUT;
  let requestParams = constructServiceUrl(serviceParams)
  return {
    type: types.USER_LOGOUT,
    request: requestParams
  };
}
export function onUserLogOutSuccess(redirectURL) {
  return {
    type: types.USER_LOGOUT_SUCCESS,
    redirectURL
  };
}
