import * as types from '../../constants/ActionTypes/Admin/logStatusTypes';
import { constructServiceUrl } from '../../services/constructUrl';

export function getLogConfigDetails() {
    let serviceParams = {};
    serviceParams.apiName = types.GET_LOG_CONFIG_DETAILS;
    let requestParams = constructServiceUrl(serviceParams);
    window.iosLoader = false;
    return {
      type: types.GET_LOG_CONFIG_DETAILS,
      request: requestParams
    };
}
export function updateData(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.EDIT_LOG_DETAIL;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.EDIT_LOG_DETAIL,
    request: requestParams
  };
}
export function reportDataStore(data) {
  return {
    type: types.STORE_LOG,
    data
  };
}
export function reportProcess() {
  return {
    type: types.PROCESS_LOG
  }
}
export function deleteDetail(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_LOG_DETAIL;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_LOG_DETAIL,
    request: requestParams
  };
}
export function addReport(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_LOG_DETAIL;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_LOG_DETAIL,
    request: requestParams
  };
}

export function getExceptionReportDetails() {
  let serviceParams = {};
  serviceParams.apiName = types.GET_EXCEPTION_REPORT_DETAILS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_EXCEPTION_REPORT_DETAILS,
    request: requestParams,
  };
}
export function updateExceptionData(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.EDIT_EXCEPTION_DETAIL;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.EDIT_EXCEPTION_DETAIL,
    request: requestParams,
  };
}
export function deleteExceptionDetail(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_EXCEPTION_DETAIL;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_EXCEPTION_DETAIL,
    request: requestParams,
  };
}
export function addExceptionReport(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_EXCEPTION_DETAIL;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_EXCEPTION_DETAIL,
    request: requestParams,
  };
}
