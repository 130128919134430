import axios from "axios";
//import { getTokenToReqHeader } from "../utils/helpers";
import URL from "./setEndpointURL";
let exceptionDataUrl = URL.EXCEPTIONDATA_API_URL;
let logDataUrl = URL.LOGDATA_API_URL;
let downloadDataUrl = URL.DOWNLOADDATA_API_URL;
let adminDataUrl = URL.ADMIN_SERVICES_API_URL;
let replayDataUrl = URL.REPLAY_DATA_API_URL;
let metricDataUrl = URL.METRIC_DATA_API_URL;

const setTokenInAxiosHeader = () => {
    const localStore = JSON.parse(localStorage.getItem('okta-token-storage'));
    return localStore.accessToken.accessToken;
}

const adminDataInstance = axios.create({
    baseURL: adminDataUrl,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    },
});

const exceptionDatainstance = axios.create({
    baseURL: exceptionDataUrl,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    },
});

const logDatainstance = axios.create({
    baseURL: logDataUrl,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    },
});

const downloadDatainstance = axios.create({
    baseURL: downloadDataUrl,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    },
});

const metricDataInstance = axios.create({
    baseURL: metricDataUrl,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    },
});

const replayDataInstance = axios.create({
    baseURL: replayDataUrl,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    },
});

const apiDownloadUrl = () => {
    return downloadDataUrl;
};

adminDataInstance.interceptors.request.use(request => {
    // get token from local storage and append it in req header.
    request.headers.Authorization = 'Bearer ' + setTokenInAxiosHeader();
    return request;
}, error => {
    return Promise.reject(error);
});

adminDataInstance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

exceptionDatainstance.interceptors.request.use(request => {
    // get token from local storage and append it in req header.
    request.headers.Authorization = 'Bearer ' + setTokenInAxiosHeader();
    return request;
}, error => {
    return Promise.reject(error);
});

exceptionDatainstance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

logDatainstance.interceptors.request.use(request => {
    // get token from local storage and append it in req header.
    request.headers.Authorization = 'Bearer ' + setTokenInAxiosHeader();
    return request;
}, error => {
    return Promise.reject(error);
});

logDatainstance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});


downloadDatainstance.interceptors.request.use(request => {
    // get token from local storage and append it in req header.
    request.headers.Authorization = 'Bearer ' + setTokenInAxiosHeader();
    return request;
}, error => {
    return Promise.reject(error);
});

downloadDatainstance.interceptors.request.use(request => {
    // get token from local storage and append it in req header.
    request.headers.Authorization = 'Bearer ' + setTokenInAxiosHeader();
    return request;
}, error => {
    return Promise.reject(error);
});

metricDataInstance.interceptors.request.use(request => {
    // get token from local storage and append it in req header.
    request.headers.Authorization = 'Bearer ' + setTokenInAxiosHeader();
    return request;
}, error => {
    return Promise.reject(error);
});

metricDataInstance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

replayDataInstance.interceptors.request.use(request => {
    // get token from local storage and append it in req header.
    request.headers.Authorization = 'Bearer ' + setTokenInAxiosHeader();
    return request;
}, error => {
    return Promise.reject(error);
});

replayDataInstance.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

export default {
    adminDataInstance,
    exceptionDatainstance,
    logDatainstance,
    downloadDatainstance,
    metricDataInstance,
    replayDataInstance,
    apiDownloadUrl,
};