import React from "react";
import PropTypes from "prop-types";
import "./UserFooter.scss";

const UserFooter = (props) => {
  return (
    <footer className="user-footer-container">
      {props.copyGroup && (
        <span>
          <button
            type="button"
            className="btn btn-secondary left15"
            onClick={props.onCopyGroup}
            disabled={props.disableCopyGroup}
          >
            <i className="far fa-copy" aria-hidden="true" /> Copy Group
          </button>
        </span>
      )}
      {props.copyUser && (
        <span disabled={props.disableCopyUser}>
          <button
            type="button"
            className="btn btn-secondary left15"
            onClick={props.onCopyUser}
            disabled={props.disableCopyUser}
          >
            <i className="far fa-copy" aria-hidden="true" /> Copy User
          </button>
        </span>
      )}
      {props.createGroup && (
        <button
          type="button"
          className="btn btn-info left15"
          onClick={props.onCreateGroup}
        >
          <i className="fas fa-plus-circle" aria-hidden="true" /> Create Group
        </button>
      )}
      {props.update && (
        <span disabled={props.updateDisabled}>
          <button
            onClick={props.onUpdateSave}
            disabled={props.updateDisabled}
            className={`btn btn-success`}
          >
            <i className={`fas fa-pencil-alt`} />
            {"Update"}
          </button>
        </span>
      )}
      {props.adduser && (
        <span disabled={props.disableAddUser}>
          <button
            type="button"
            className="btn btn-success left15"
            onClick={props.onAddUser}
            disabled={props.disableAddUser}
          >
            <i className="fas fa-user-plus" aria-hidden="true" /> Add User
          </button>
        </span>
      )}
      {props.removeUser && (
        <span disabled={props.disableRemoveUser}>
          <button
            type="button"
            className="btn btn-danger left15"
            disabled={props.disableRemoveUser}
            onClick={props.onRemoveUser}
          >
            <i className="fa fa-trash" aria-hidden="true" /> Remove User
          </button>
        </span>
      )}
      {props.saveClose && (
        <span disabled={props.saveCloseDisabled}>
          <button
            type="button"
            // type="submit"
            className={`left15 btn btn-primary`}
            onClick={props.handleUserSaveClose}
            disabled={props.saveCloseDisabled}
          >
            <i className="fas fa-save" /> Save & Close
          </button>
        </span>
      )}
      {props.createUser && (
        <span disabled={props.disabledCreateUser}>
          <button
            type="button"
            className="btn btn-success left15"
            onClick={props.onCreate}
            disabled={props.disabledCreateUser}
          >
            <i className={`fa fas fa-user-plus`} aria-hidden="true" /> Create
            user
          </button>
        </span>
      )}
      {props.modify && (
        <span disabled={props.disabledModify}>
          <button
            type="button"
            className={`btn btn-info left15`}
            onClick={props.onEdit}
            disabled={props.disabledModify}
          >
            <i className="far fa-edit" aria-hidden="true" />
            Modify
          </button>
        </span>
      )}
      {props.detail && (
        <span disabled={props.disabledDetail}>
          <button
            type="button"
            className={`btn btn-info left15`}
            onClick={props.onDetail}
            disabled={props.disabledDetail}
          >
            <i className="fa fa-info-circle" aria-hidden="true" />
            Detail
          </button>
        </span>
      )}

      {props.delete && (
        <span disabled={props.disabledDelete}>
          <button
            type="button"
            className={`btn btn-danger left15 `}
            onClick={props.onDelete}
            disabled={props.disabledDelete}
          >
            <i className="fa fa-trash" aria-hidden="true" />
            Delete
          </button>
        </span>
      )}

      {props.save && (
        <span disabled={props.saveDisabled}>
          <button
            type="button"
            disabled={props.saveDisabled}
            className="btn btn-success left15"
            onClick={props.handleSave}
          >
            <i className="fa fa-save" aria-hidden="true" /> Save
          </button>
        </span>
      )}

      {props.reset && (
        <span disabled={props.disableReset}>
          <button
            type="button"
            disabled={props.disableReset}
            onClick={props.handleReset}
            className="btn btn-info left15"
          >
            <i className="fas fa-undo-alt" aria-hidden="true" /> Reset
          </button>
        </span>
      )}

      {props.showCancel && (
        <span>
          <button
            type="button"
            className="btn btn-dark left15"
            onClick={props.onCancel}
          >
            <i className="fas far fa-times-circle" aria-hidden="true" />{" "}
            Cancel
          </button>
        </span>
      )}
    </footer>
  );
};
UserFooter.propTypes = {
  onDetail: PropTypes.func,
  onCopyUser: PropTypes.func,
  onCopyGroup: PropTypes.func,
  onCreateGroup: PropTypes.func,
  onUpdateSave: PropTypes.func,
  onAddUser: PropTypes.func,
  onRemoveUser: PropTypes.func,
  handleUserSaveClose: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  handleSave: PropTypes.func,
  handleReset: PropTypes.func,
  onCancel: PropTypes.func,
  disabledDetail: PropTypes.bool,
  detail: PropTypes.bool,
  copyGroup: PropTypes.bool,
  copyUser: PropTypes.bool,
  disableCopyUser: PropTypes.bool,
  createGroup: PropTypes.bool,
  update: PropTypes.bool,
  updateDisabled: PropTypes.bool,
  adduser: PropTypes.bool,
  disableAddUser: PropTypes.bool,
  removeUser: PropTypes.bool,
  disableRemoveUser: PropTypes.bool,
  saveClose: PropTypes.bool,
  saveCloseDisabled: PropTypes.bool,
  createUser: PropTypes.bool,
  disabledCreateUser: PropTypes.bool,
  modify: PropTypes.bool,
  disabledModify: PropTypes.bool,
  delete: PropTypes.bool,
  disabledDelete: PropTypes.bool,
  save: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  reset: PropTypes.bool,
  disableReset: PropTypes.bool,
  showCancel: PropTypes.bool,
  disableCopyGroup: PropTypes.bool,
};
export default UserFooter;
