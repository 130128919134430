import React, { useEffect } from "react";
import { Form, Modal, Input } from "antd";
import PropTypes from "prop-types";
import { formItemsLayout, formItemsDefaultStyle } from "../../../../constants/AdminModalForm/FormStyleConfig";
import AdminModalFooter from "../../../CommonComponents/Modal/AdminModalFooter";
import SelectDropDown from '../../../CommonComponents/SelectDropDown';
const NewCategory = ({ ...props }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (props.editFlag) {
            form.setFieldsValue({ ...props.data[0] });
        }
        form.setFieldsValue({ 
            ...form.getFieldsValue(),
            "applicationName":props?.applicationName?.value,
         });
    }, []);
    const onSaveHandler = () => {
        form.validateFields()
            .then(async values => {
                if (props.editFlag) {
                    values = {
                        "categoryID": props.data[0]["categoryID"],
                        ...values
                    }
                }
                props.saveData(values, "category");
            })
    }
    return (<Modal
            open={props.isModalOpen}
            title={(props.editFlag ? "Edit" : "New") + " Category"}
            onCancel={props.onCloseModal}
            footer={
                (props.editFlag ?
                    <AdminModalFooter
                        close update
                        onUpdate={() => onSaveHandler()}
                        onClose={props.onCloseModal}
                    />
                    :
                    <AdminModalFooter
                        save close
                        onSave={() => onSaveHandler()}
                        onClose={props.onCloseModal}
                    />)
            }
            style={{ minWidth: 800 }}
            className="admin-modal-wrapper"
        >
            <Form
                id="list-category-config"
                form={form}
                {...formItemsLayout}
                labelAlign="left"
                colon
            >
                <Form.Item label="Application Name" name="applicationName"
                    rules={[{ required: true, message: 'Application is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <SelectDropDown
                        options={props.applicationNamesOptions}
                        disabled={props.editFlag}
                    />
                </Form.Item>
                <Form.Item label="Category Name" name="exceptionCategoryName"
                    rules={[{ required: true, message: 'Category Name is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
                <Form.Item label="Category Description" name="exceptionCategoryDescription"
                    rules={[{ required: true, message: 'Category Description is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>)
}
NewCategory.propTypes = {
    onCloseModal: PropTypes.func,
    data: PropTypes.any,
    saveData: PropTypes.func,
    applicationName: PropTypes.any,
    editFlag: PropTypes.any,
    applicationNamesOptions: PropTypes.any,
    isModalOpen: PropTypes.bool,
};
export default NewCategory;