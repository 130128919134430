import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import COLUMNS from "../../../constants/ColumnConfig/Admin/listUsersWithoutSelectAll";
import UserHeader from "../UserHeader";
import CleTable from "../../../components/CommonComponents/CleTable/CleTable";
import UserFooter from './../UserFooter';
import { findUsersMatchingCriteria } from "../../../utils/helpers";
import "../UserList.css";

const cleTableWrapperStyle = {
  height: "calc(100vh - 190px)",
  width: "100%",
};

const SelectTargetUser = ({ ...props }) => {
  const globalConfigState = useSelector((state) => state.configReducer);

  const [gridApi, setGridApi] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [filterValues, setFilterValues] = useState(null);

  const filterData = (userListTemp) => {
    if (filterValues !== null) {
      return findUsersMatchingCriteria(
        userListTemp,
        filterValues.trim().split(",")
      );
    }
    return userListTemp;
  };

  return (
    <div className="user-list-wrapper">
      <UserHeader
        headerName={"Select Target User for " + props.sourceUser.userID}
        onSearch={(val) => {
          setFilterValues(val);
        }} />
      <div style={cleTableWrapperStyle}>
        <CleTable
          columnDefs={COLUMNS}
          rowData={filterData(props.userList.filter(user => user.userID !== props.sourceUser.userID))}
          context={{ timeZone: globalConfigState.isCstTimeZone }}
          immutableData
          rowSelection='single'
          getRowNodeId={(data) => data.userID}
          overlayLoadingTemplate={`<span>
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </span>`}
          onGridReady={(params) => {
            setGridApi(params.api);
          }}
          onRowSelected={(params) =>
            setSelectedRow(params.api.getSelectedRows())
          }
          rowDeselection
          rowMultiSelectWithClick
        />
      </div>
      <UserFooter
        save
        handleSave={() => props.onSave(selectedRow[0])}
        saveDisabled={selectedRow.length === 0}
        showCancel
        onCancel={()=>{gridApi.deselectAll() ;props.onClose();}}
      />
    </div>
  );
};

SelectTargetUser.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  userList: PropTypes.array,
  sourceUser: PropTypes.object,
};
export default SelectTargetUser;
