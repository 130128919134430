import * as types from "../../constants/ActionTypes/Admin/blobMetrics";
//import { type } from "jquery";

const initalState = {
  isListAllLogLoder: true,
  isListAllExceptionLoder: true,
  isListDetailLogLoder: true,
  isListDetailExceptionLoder: true,
  listBlobLog: [],
  listBlobException: [],
  detailBlobLog: [],
  detailBlobException: [],
  listBlobLogError: null,
  listBlobExceptionError: null,
  detailBlobLogError: null,
  detailBlobExceptionError: null,
  detailLogInitRequest: true,
  detailExceptionInitRequest: true,
};

const addType = (data, type) => {
  if (data.length > 0) return data.map((val) => ({ ...val, type }));
  return [];
};

const blobMetricsReducer = (state = initalState, action) => {
  switch (action.type) {
    case types.BLOB_RESET_DATA: {
      return {
        ...state,
        isListAllLogLoder: true,
        isListAllExceptionLoder: true,
        isListDetailLogLoder: true,
        isListDetailExceptionLoder: true,
        listBlobLog: [],
        listBlobException: [],
        detailBlobLog: [],
        detailBlobException: [],
        listBlobLogError: null,
        listBlobExceptionError: null,
        detailBlobLogError: null,
        detailBlobExceptionError: null,
        detailLogInitRequest: true,
        detailExceptionInitRequest: true,
      };
    }
    case types.FETCH_BLOB_EXCEPTION_ALL_METRICS: {
      return {
        ...state,
        isListAllExceptionLoder: true,
        listBlobExceptionError: null,
      };
    }
    case types.FETCH_BLOB_EXCEPTION_ALL_METRICS_ERROR: {
      return {
        ...state,
        isListAllExceptionLoder: false,
        listBlobExceptionError: action.error,
      };
    }
    case types.FETCH_BLOB_EXCEPTION_ALL_METRICS_SUCCESS: {
      return {
        ...state,
        isListAllExceptionLoder: false,
        listBlobException: addType(action.response, "Exception"),
        listBlobExceptionError: null,
      };
    }
    case types.FETCH_BLOB_EXCEPTION_METRICS: {
      return {
        ...state,
        isListDetailExceptionLoder: true,
        detailBlobExceptionError: null,
      };
    }
    case types.FETCH_BLOB_EXCEPTION_METRICS_ERROR: {
      return {
        ...state,
        isListDetailExceptionLoder: false,
        detailBlobExceptionError: action.error,
      };
    }
    case types.FETCH_BLOB_EXCEPTION_METRICS_SUCCESS: {
      return {
        ...state,
        isListDetailExceptionLoder: false,
        detailBlobException: addType(action.response, "Exception"),
        listBlobExceptionError: null,
        detailExceptionInitRequest: false,
      };
    }

    // Log

    case types.FETCH_BLOB_LOG_ALL_METRICS: {
      return {
        ...state,
        isListAllLogLoder: true,
        listBlobLogError: null,
      };
    }
    case types.FETCH_BLOB_LOG_ALL_METRICS_ERROR: {
      return {
        ...state,
        isListAllLogLoder: false,
        listBlobLogError: action.error,
      };
    }
    case types.FETCH_BLOB_LOG_ALL_METRICS_SUCCESS: {
      return {
        ...state,
        isListAllLogLoder: false,
        listBlobLogError: null,
        listBlobLog: addType(action.response, "Log"),
      };
    }
    case types.FETCH_BLOB_LOG_METRICS: {
      return {
        ...state,
        isListDetailLogLoder: true,
        detailBlobLogError: null,
      };
    }
    case types.FETCH_BLOB_LOG_METRICS_ERROR: {
      return {
        ...state,
        isListDetailLogLoder: false,
        detailBlobLogError: action.error,
      };
    }
    case types.FETCH_BLOB_LOG_METRICS_SUCCESS: {
      return {
        ...state,
        isListDetailLogLoder: false,
        detailBlobLogError: null,
        detailBlobLog: addType(action.response, "Log"),
        detailLogInitRequest: false,
      };
    }
    default:
      return state;
  }
};

export default blobMetricsReducer;
