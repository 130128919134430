import URL from '../services/setEndpointURL';

const metricServices = {
  FETCH_BLOB_LOG_ALL_METRICS: {
    apiName: 'FETCH_BLOB_EXCEPTION_ALL_METRICS',
    endPointUrl: '/log/blob/metrics/',
    method: 'GET',
    mock_json: '',
    isSecured: true,
    server: 'Pepsico',
    baseUrlPrefix: URL.METRIC_DATA_API_URL
  },
  FETCH_BLOB_EXCEPTION_ALL_METRICS: {
    apiName: 'FETCH_BLOB_EXCEPTION_ALL_METRICS',
    endPointUrl: '/exception/blob/metrics/',
    method: 'GET',
    mock_json: '',
    isSecured: true,
    server: 'Pepsico',
    baseUrlPrefix: URL.METRIC_DATA_API_URL
  },
  FETCH_BLOB_LOG_METRICS: {
    apiName: 'FETCH_BLOB_LOG_METRICS',
    endPointUrl: '/log/blob/details/',
    method: 'POST',
    mock_json: '',
    isSecured: true,
    server: 'Pepsico',
    baseUrlPrefix: URL.METRIC_DATA_API_URL
  },
  FETCH_BLOB_EXCEPTION_METRICS: {
    apiName: 'FETCH_BLOB_EXCEPTION_METRICS',
    endPointUrl: '/exception/blob/details/',
    method: 'POST',
    mock_json: '',
    isSecured: true,
    server: 'Pepsico',
    baseUrlPrefix: URL.METRIC_DATA_API_URL
  }
};

export default metricServices;
