import React, { useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import ConfirmationModalPopup from "../../../CommonComponents/Modal/ConfirmationModalPopup";
import CleTable from "../../../CommonComponents/CleTable/CleTable";
import { CATEGORIES, SEVERITIES, TYPES, } from "../../../../constants/ColumnConfig/Admin/listResults";
import SelectDropDown from '../../../CommonComponents/SelectDropDown';
import { applicationDataInstance } from "../../../../services/applicationDataService";
import "./../styles.scss";
import ListFooter from "./listFooter";
import NewCategory from "./newCategory";
import NewSeverity from "./newSeverity";
import NewType from "./newType";
import { checkIfExistsInArray } from "../../../../utils/helpers";

const Lists = ({ ...props }) => {
    const applicationNames = applicationDataInstance.getApplicationNames();
    const [showUpsertModal, setShowUpsertModal] = useState(false);
    const [isModalEdit, setIsModalEdit] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formType, setFormType] = useState(null);
    const [categoryGridApi, setCategoryGridApi] = useState();
    const [severityGridApi, setSeverityGridApi] = useState();
    const [typeGridApi, setTypeGridApi] = useState();
    const [categorySelectedRow, setCategorySelectedRow] = useState([]);
    const [severitySelectedRow, setSeveritySelectedRow] = useState([]);
    const [typeSelectedRow, setTypeSelectedRow] = useState([]);
    const getAllCategories = (e) => {
        return props.exceptionType?.category?.filter((category) =>
            e.value === category.applicationName
        ) || [];
    };
    const getAllSeverities = (e) => {
        return props.exceptionType?.severity?.filter((severity) =>
            e.value === severity.applicationName
        ) || [];
    };

    const getAllTypes = (e) => {
        return props.exceptionType?.type?.filter((type) =>
            e.value === type.applicationName
        ) || [];
    };

    const onSaveDataHandler = (formData, formType) => {
        if (formType === "category") {
            if (formData["categoryID"] &&
                !checkIfExistsInArray(getAllCategories(selectedApplication.applicationName), formData, "exceptionCategoryName", "categoryID", formData["categoryID"])) {
                props.saveCategoryDetails(formData);
                categoryGridApi.deselectAll();
                setCategorySelectedRow([]);
                setIsModalEdit(false);
                setShowUpsertModal(false);
            }
            else if (formData["categoryID"] === undefined &&
                !checkIfExistsInArray(getAllCategories(selectedApplication.applicationName), formData, "exceptionCategoryName")) {
                props.saveCategoryDetails(formData);
                categoryGridApi.deselectAll();
                setCategorySelectedRow([]);
                setIsModalEdit(false);
                setShowUpsertModal(false);
            }
            else {
                toast.error(`Category already exist!`);
            }
        }
        if (formType === "severity") {
            if (formData["severityID"] &&
                !checkIfExistsInArray(getAllSeverities(selectedApplication.applicationName), formData, "exceptionSeverityName", "severityID", formData["severityID"])) {
                props.saveSeverityDetails(formData)
                severityGridApi.deselectAll();
                setSeveritySelectedRow([]);
                setIsModalEdit(false);
                setShowUpsertModal(false);
            }
            else if (formData["severityID"] === undefined &&
                !checkIfExistsInArray(getAllSeverities(selectedApplication.applicationName), formData, "exceptionSeverityName")) {
                props.saveSeverityDetails(formData);
                severityGridApi.deselectAll();
                setSeveritySelectedRow([]);
                setIsModalEdit(false);
                setShowUpsertModal(false);
            }
            else {
                toast.error(`Severity already exist!`);
            }
        }
        if (formType === "type") {
            if (formData["typeID"] &&
                !checkIfExistsInArray(getAllTypes(selectedApplication.applicationName), formData, "exceptionTypeName", "typeID", formData["typeID"])) {
                props.saveTypeDetails(formData);
                typeGridApi.deselectAll();
                setTypeSelectedRow([]);
                setIsModalEdit(false);
                setShowUpsertModal(false);
            }
            else if (formData["typeID"] === undefined &&
                !checkIfExistsInArray(getAllTypes(selectedApplication.applicationName), formData, "exceptionTypeName")) {
                props.saveTypeDetails(formData);
                typeGridApi.deselectAll();
                setTypeSelectedRow([]);
                setIsModalEdit(false);
                setShowUpsertModal(false);
            }
            else {
                toast.error(`Type already exist!`);
            }
        }
    }

    const onDeleteHandler = (formType) => {
        if (formType === "category") {
            props.deleteExceptionCategory(categorySelectedRow[0]["categoryID"]);
            categoryGridApi.deselectAll();
        }
        if (formType === "severity") {
            props.deleteExceptionSeverity(severitySelectedRow[0]["severityID"]);
            severityGridApi.deselectAll();
        }
        if (formType === "type") {
            props.deleteExceptionType(typeSelectedRow[0]["typeID"]);
            typeGridApi.deselectAll();
        }
        setShowConfirmModal(false);
    }
    return (<>
        <Row>
            <Col span={6} className="admin-list-choose-application-wrapper">
                <label className="application-title">Choose an Application</label>
                <SelectDropDown
                    label={"Choose an Application"}
                    placeholder="Select..."
                    className="select select-list"
                    //value={this.state.listForm.applicationName ? this.state.listForm.applicationName : null}
                    options={applicationNames}
                    onChange={(val, opt) => setSelectedApplication({ applicationName: opt })}
                    size="large"
                />
            </Col>
        </Row>
        {
            selectedApplication && selectedApplication.applicationName &&
            <Row gutter={24} className="top25 table-scroll-y">
                <Col span={8}>
                    <div className="list-tab">
                        <div style={{ height: "250px" }}>
                            <CleTable
                                columnDefs={CATEGORIES}
                                immutableData
                                getRowNodeId={(data) => data.categoryID}
                                onRowSelected={(params) => setCategorySelectedRow(params.api.getSelectedRows())}
                                onRowClicked={(params) => { params.node.isSelected() ? params.node.setSelected(false) : params.node.setSelected(true); setCategorySelectedRow(params.api.getSelectedRows()) }}
                                onGridReady={(params) => setCategoryGridApi(params.api)}
                                rowData={getAllCategories(selectedApplication.applicationName)}
                            />
                        </div>
                    </div>
                    <ListFooter
                        add onAdd={() => { setShowUpsertModal(true); setFormType("category") }}
                        modify onModify={() => { setFormType("category"); setShowUpsertModal(true); setIsModalEdit(true) }}
                        modifyDisabled={categorySelectedRow.length === 0}
                        deleteBtn deleteDisabled={categorySelectedRow.length === 0}
                        onDelete={() => { setShowConfirmModal(true); setFormType("category") }}
                    />
                </Col>
                <Col span={8}>
                    <div className="list-tab">
                        <div style={{ height: "250px" }}>
                            <CleTable
                                columnDefs={SEVERITIES}
                                immutableData
                                getRowNodeId={(data) => data.severityID}
                                rowData={getAllSeverities(selectedApplication.applicationName)}
                                onRowSelected={(params) => setSeveritySelectedRow(params.api.getSelectedRows())}
                                onRowClicked={(params) => { params.node.isSelected() ? params.node.setSelected(false) : params.node.setSelected(true); setSeveritySelectedRow(params.api.getSelectedRows()) }}
                                onGridReady={(params) => setSeverityGridApi(params.api)}
                            />
                        </div>
                    </div>
                    <ListFooter
                        add onAdd={() => { setShowUpsertModal(true); setFormType("severity") }}
                        modify onModify={() => { setFormType("severity"); setShowUpsertModal(true); setIsModalEdit(true) }}
                        modifyDisabled={severitySelectedRow.length === 0}
                        deleteBtn deleteDisabled={severitySelectedRow.length === 0}
                        onDelete={() => { setShowConfirmModal(true); setFormType("severity") }}
                    />
                </Col>
                <Col span={8}>
                    <div className="list-tab">
                        <div style={{ height: "250px" }}>
                            <CleTable
                                columnDefs={TYPES}
                                immutableData
                                getRowNodeId={(data) => data.typeID}
                                rowData={getAllTypes(selectedApplication.applicationName)}
                                onRowSelected={(params) => setTypeSelectedRow(params.api.getSelectedRows())}
                                onRowClicked={(params) => { params.node.isSelected() ? params.node.setSelected(false) : params.node.setSelected(true); setTypeSelectedRow(params.api.getSelectedRows()) }}
                                onGridReady={(params) => setTypeGridApi(params.api)}
                            />
                        </div>
                    </div>
                    <ListFooter
                        add onAdd={() => { setShowUpsertModal(true); setFormType("type") }}
                        modify onModify={() => { setFormType("type"); setShowUpsertModal(true); setIsModalEdit(true) }}
                        modifyDisabled={typeSelectedRow.length === 0}
                        deleteBtn deleteDisabled={typeSelectedRow.length === 0}
                        onDelete={() => { setShowConfirmModal(true); setFormType("type") }}
                    />
                </Col>
            </Row>
        }
        {
            selectedApplication === null &&
            <h4 className="text-center">Please Select Application...</h4>
        }
        {
            showUpsertModal && formType === "category" &&
            <NewCategory
                saveData={(val, type) => onSaveDataHandler(val, type)}
                isModalOpen={showUpsertModal}
                editFlag={isModalEdit}
                onCloseModal={() => {
                    if (isModalEdit)
                        setIsModalEdit(false)
                    setShowUpsertModal(false)
                }}
                applicationName={selectedApplication ? selectedApplication.applicationName : null}
                applicationNamesOptions={applicationNames}
                data={categorySelectedRow}
            />
        }
        {
            showUpsertModal && formType === "severity" &&
            <NewSeverity
                saveData={(val, type) => onSaveDataHandler(val, type)}
                isModalOpen={showUpsertModal}
                editFlag={isModalEdit}
                onCloseModal={() => {
                    if (isModalEdit)
                        setIsModalEdit(false)
                    setShowUpsertModal(false)
                }}
                applicationName={selectedApplication ? selectedApplication.applicationName : null}
                applicationNamesOptions={applicationNames}
                data={severitySelectedRow}
            />
        }
        {
            showUpsertModal && formType === "type" &&
            <NewType
                saveData={(val, type) => onSaveDataHandler(val, type)}
                isModalOpen={showUpsertModal}
                editFlag={isModalEdit}
                onCloseModal={() => {
                    if (isModalEdit)
                        setIsModalEdit(false)
                    setShowUpsertModal(false)
                }}
                applicationName={selectedApplication ? selectedApplication.applicationName : null}
                applicationNamesOptions={applicationNames}
                data={typeSelectedRow}
            />
        }
        {
            showConfirmModal &&
            <ConfirmationModalPopup
                title="Are you sure you want to delete this item?"
                isModalOpen={showConfirmModal}
                onCloseModal={() => setShowConfirmModal(false)}
                onOkModal={() => onDeleteHandler(formType)} />
        }
    </>);
}

Lists.propTypes = {
    deleteExceptionCategory: PropTypes.func,
    deleteExceptionSeverity: PropTypes.func,
    deleteExceptionType: PropTypes.func,
    saveCategoryDetails: PropTypes.func,
    saveSeverityDetails: PropTypes.func,
    saveTypeDetails: PropTypes.func,
    exceptionType: PropTypes.any,
};
export default Lists;