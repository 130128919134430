import moment from "moment";
import { default as momentZone } from "moment-timezone";

const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPinned: true,
};

const COLUMNS = [
  fieldHead,
  {
    field: "tableName",
    headerName: "Table Name",
  },
  {
    field: "dataType",
    headerName: "Table Type",
  },
  {
    field: "retentionDays",
    headerName: "Retention Days",
  },
  {
    field: "topicName",
    headerName: "Topic Name",
  },
  {
    field: "recordCount",
    headerName: "Record Count",
    cellRenderer: function dateValueGettter(params) {
      let number = params.value;
      return number ? number.toLocaleString('en-US') : '';
    },
  },
  {
    field: "tableSize",
    headerName: "Table Size",
    cellRenderer: function dateValueGettter(params) {
      let number = params.value;
      return number ? number.toLocaleString('en-US') : '';
    },
  },
  {
    field: "solrCoreSize",
    headerName: "Solr Core Size",
    cellRenderer: function dateValueGettter(params) {
      let number = params.value;
      return number ? number.toLocaleString('en-US') : '';
    },
  },
  {
    field: "sector",
    headerName: "Sector",
  },
  {
    field: "lastUpdated",
    headerName: "Last Updated",
    cellRenderer: function dateValueGettter(params) {
      if (params?.context?.timeZone)
        return momentZone(params.value)
          .tz(window.timeZone)
          .format("YYYY-MM-DD HH:mm:ss");
      return moment(params.value).local().format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    field: "spaceOccupied",
    headerName: "Space Occupied",
  },
];

export default COLUMNS;
