export const GET_LOG_CONFIG_DETAILS= 'GET_LOG_CONFIG_DETAILS';
export const STORE_LOG = "STORE_LOG";
export const DELETE_LOG_DETAIL = 'DELETE_LOG_DETAIL';
export const ADD_LOG_DETAIL = 'ADD_LOG_DETAIL';
export const EDIT_LOG_DETAIL = "EDIT_LOG_DETAIL";
export const PROCESS_LOG = "PROCESS_LOG";

export const GET_EXCEPTION_REPORT_DETAILS= 'GET_EXCEPTION_REPORT_DETAILS';
export const DELETE_EXCEPTION_DETAIL = 'DELETE_EXCEPTION_DETAIL';
export const ADD_EXCEPTION_DETAIL = 'ADD_EXCEPTION_DETAIL';
export const EDIT_EXCEPTION_DETAIL = "EDIT_EXCEPTION_DETAIL";