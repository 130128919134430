import React from 'react';
import PropTypes from "prop-types";
import { Row, Button, Modal, Col } from "antd";
import { CloseOutlined, SaveFilled } from '@ant-design/icons';
import SelectDropDown from '../../components/CommonComponents/SelectDropDown';
import "./CopyGroupModal.css"
const colLabelSpan = 8;
const colSpan = 10;
const CopyGroupModal = ({ ...props }) => {
    return (
        <Modal
            open={props.isModalOpen}
            title={"Select Target Group"}
            onCancel={props.onCloseModal}
            style={{ minWidth: 700 }}
            className='copy-group-modal'
            footer={
                <Row justify={"end"}>
                    <Button
                        type="primary"
                        className="save-btn mr5 secondary"
                        icon={<SaveFilled />}
                        onClick={props.onSaveData}
                    >
                        Save
                    </Button>
                    <Button
                        type="primary"
                        className="close-btn mr5"
                        icon={<CloseOutlined />}
                        onClick={props.onCloseModal}
                        danger
                    >
                        Close
                    </Button>
                </Row>
            }
        >
            <Row>
                <Col span={colLabelSpan}>Source Group</Col>
                <Col span={colSpan}>{typeof (props.sourceGroup) === "string" ? props.sourceGroup : props.sourceGroup?.groupName}</Col>
            </Row>
            <Row>
                <Col span={colLabelSpan}>Target Group</Col>
                <Col span={colSpan}>
                    <div className="cle-select cle-multi-select">
                        <SelectDropDown className="cle-select"
                            options={props.groupOptions}
                            placeholder={"Select Group"}
                            fieldNames={{ label: "groupName", value: "groupName" }}
                            onChange={(val) => {
                                props.onTargetGroupSelectHandler(val)
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}
CopyGroupModal.propTypes = {
    isModalOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onSaveData: PropTypes.func,
    sourceGroup: PropTypes.any,
    groupOptions: PropTypes.array,
    onTargetGroupSelectHandler: PropTypes.func,
};
export default CopyGroupModal;