export const SNOW_NOTIFICATION_SERVICE_NAME = [
    {
        label: "Calculated Application Service",
        value: "cmdb_ci_service_calculated",
    },
    {
        label: "Application Service",
        value: "cmdb_ci_service_discovered",
    },
    {
        label: "Tag-Based Application Service",
        value: "cmdb_ci_service_by_tags",
    },
    {
        label: "Dynamic CI Group",
        value: "cmdb_ci_query_based_service",
    },
];
