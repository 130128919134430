import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Input,
  Form,
  Row,
  Col,
  Select,
  Radio,
  Checkbox,
  Button,
} from "antd";
import moment from "moment";
import { applicationDataInstance } from "../../services/applicationDataService";
import CleDatePicker from "../CommonComponents/CleDatePicker/CleDatePicker";
import CleFooter from "../CommonComponents/CleFooter/CleFooter";
import LogColumn from "../../constants/SearchForm/logColumn";
import MultiSelect from "../CommonComponents/MultiSelect/MultiSelect";
import ExceptionColumn from "../../constants/SearchForm/exceptionColumn";
import "./style.scss";

const { Option } = Select;
const APP_LIMIT = 10;

const FormMultiSelect = ({
  applicationNames = [],
  value = [],
  form,
  onChange,
}) => {
  return (
    <MultiSelect
      hasSelectAll={applicationNames.length > 2}
      disableSearch={applicationNames.length < APP_LIMIT}
      clearable
      multi
      placeholder={"Select application"}
      options={applicationNames}
      selected={value}
      onSelectedChanged={onChange}
      error={form.getFieldError("appName").length > 0}
      tabIndex={"1"}
    />
  );
};

FormMultiSelect.propTypes = {
  applicationNames: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  form: PropTypes.object,
};

const SearchForm = React.forwardRef((props, ref) => {
  const [, updateState] = React.useState();
  const fileds = props.log ? LogColumn : ExceptionColumn;
  const [form] = Form.useForm();
  const [optFileds,] = React.useState(fileds);
  let firstServe = props.firstServe;
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onApplicationNameChange = (applications) => {
    if (!props.log && !props.isAdminAccess && applications.length === 1) {
      const exceptionCategory = applicationDataInstance.getCategoriesForApplication(
        applications[0].value
      );

      if (exceptionCategory.length < 1) {
        props.getApplicationExceptionCategory(applications[0].value);
      }

      const exceptionType = applicationDataInstance.getTypesForApplication(applications[0].value);
      if (exceptionType.length < 1) {
        props.getApplicationExceptionType(applications[0].value);
      }

      const exceptionSeverity = applicationDataInstance.getSeveritiesForApplication(
        applications[0].value
      );
      if (exceptionSeverity.length < 1) {
        props.getApplicationExceptionSeverity(applications[0].value);
      }
    }
  }

  const hasMultiTableApplications = (searchApp) => {
    if (!searchApp || searchApp.length === 0) {
      return false;
    }
    let isSingletable = true;
    let tabshrtName = props.log ? "logTable" : "exceptionTable";
    let tabName = searchApp[0][tabshrtName];
    if (searchApp.length > 1) {
      searchApp.forEach((val) => {
        if (val[tabshrtName] !== tabName) {
          isSingletable = false;
          return;
        }
      });
    }
    if (!isSingletable) return true;
    return false;
  };

  const getExtraOption = (name, appName) => {
    if (appName.length === 1) {
      if (name === "exceptionCategory") {
        return applicationDataInstance.getCategoriesForApplication(
          appName[0].value
        );
      } else if (name === "exceptionType") {
        return applicationDataInstance.getTypesForApplication(appName[0].value);
      } else if (name === "exceptionSeverity") {
        return applicationDataInstance.getSeveritiesForApplication(
          appName[0].value
        );
      }
    }
    return [];
  };
  const getFiled = (filed) => {
    switch (filed.type) {
      case "text":
        return (
          <Input
            allowClear
            id={filed.id}
            tabIndex={filed.tabIndex}
            ref={() => {
              if (filed.name === "serviceName" && firstServe) {
                props.firstServe = false;
              }
            }}
          />
        );

      case "radio": {
        if (filed.name === "logLevel") {
          return (
            <Radio.Group id={filed.id}>
              <Radio value={"Success"} id="logLevelSuccess">
                Success
              </Radio>
              <Radio value={"Error"} id="logLevelError">
                Error
              </Radio>
              <Radio value={" "} id="logLevelAll">
                ALL
              </Radio>
            </Radio.Group>
          );
        }
        return null;
      }
      case "checkbox":
        return (
          <Checkbox.Group>
            <Checkbox
              value={"Scheduled"}
              id="Scheduled"
              style={{ lineHeight: "32px" }}
            >
              Scheduled
            </Checkbox>

            <Checkbox
              value={"Pending"}
              id="Pending"
              style={{ lineHeight: "32px" }}
            >
              Pending
            </Checkbox>

            <Checkbox
              value={"Resolved"}
              id="Resolved"
              style={{ lineHeight: "32px" }}
            >
              Resolved
            </Checkbox>

            <Checkbox
              value={"Error"}
              id="Error"
              style={{ lineHeight: "32px", marginLeft: 0 }}
            >
              Error
            </Checkbox>
          </Checkbox.Group>
        );
      default:
        return <Input allowClear id={filed.id} tabIndex={filed.tabIndex} />;
    }
  };

  let isDownload = false;

  const onFinish = (value) => {
    let values = JSON.parse(JSON.stringify(value));
    if (value && value.appName.length > 1) {
      if (props.applicationNames.length >= APP_LIMIT) {
        if (value.appName.length === props.applicationNames.length) {
          let findCommonLe = value.appName.findIndex(
            (val) => val.value === "COMMONLE"
          );
          if (findCommonLe > -1) {
            values.appName.splice(findCommonLe, 1);
          }
        }
      }
    }
    if (isDownload) props.onDownload(values);
    else props.onSearch(values);
    isDownload = false;
  };
  const [searchDisable, setSearchDisable] = React.useState(false);
  const hanldeFieldChange = (changedField, allField) => {
    setSearchDisable(form.getFieldError("appName").length > 0);

    if (
      changedField?.[0]?.name.indexOf("startTime") > -1 ||
      changedField?.[0]?.name.indexOf("endTime") > -1 ||
      changedField?.[0]?.name.indexOf("appName") > -1
    ) {
      let st = moment(form.getFieldValue("startTime"));
      let ed = moment(form.getFieldValue("endTime"));
      if (Date.parse(st) > Date.parse(ed)) {
        return form.setFields([
          {
            name: ["endTime"],
            errors: ["End date should be greater than start date!"],
          },
        ]);
      }
      if (allField?.[0]?.value.length > 1) {
        let checkMultiple = hasMultiTableApplications(allField[0].value);
        if (checkMultiple) {
          var diff = st.diff(ed, "days");
          if (diff !== 0) {
            return form.setFields([
              {
                name: ["endTime"],
                errors: ["Date field allowed 24hrs only!"],
              },
            ]);
          }
        }
      }
      form.setFields([
        {
          name: ["endTime"],
          errors: [],
        },
      ]);
    }
  };
  const forceUpdate = React.useCallback(() => updateState({}), []);
  useEffect(() => {
    forceUpdate();
  }, [props.categories, props.severities, props.types]);

  return (
    <>
      <div className="search-component-group">
        <Form
          validateMessages={validateMessages}
          onFieldsChange={hanldeFieldChange}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          labelAlign={"left"}
          name="configApplication"
          form={form}
          ref={ref}
          scrollToFirstError
          onFinish={onFinish}
          size={props.size}
        >
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                name="appName"
                label="Application Name"
                rules={[
                  { required: true },
                  () => ({
                    validator(rule, value) {
                      if (!value) return Promise.resolve();
                      let selecteApp = value;

                      if (
                        props.applicationNames.length < APP_LIMIT ||
                        props.applicationNames.length > selecteApp.length
                      ) {
                        if (selecteApp.length > 1) {
                          let findCommonLe = selecteApp.findIndex(
                            (val) => val.value === "COMMONLE"
                          );
                          if (findCommonLe > -1) {
                            return Promise.reject(
                              `Exclude COMMONLE during multiple app search!`
                            );
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <FormMultiSelect
                  applicationNames={props.applicationNames}
                  form={form}
                  onChange={onApplicationNameChange}
                />
              </Form.Item>
            </Col>
            {optFileds.map((filed, i) => {
              if (filed.type === "select") {
                return (
                  <Col span={12} key={i}>
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return prevValues.appName !== curValues.appName;
                      }}
                      label={filed.label}
                    >
                      {(val) => {
                        if (
                          val.getFieldValue(["appName"]) &&
                          val.getFieldValue(["appName"]).length !== 0 &&
                          val.getFieldValue(["appName"]).length < 2
                        ) {
                          return (
                            <Form.Item name={filed.name}>
                              <Select
                                showSearch
                                id={filed.id}
                                placeholder={filed.label}
                                tabIndex={filed.tabIndex}
                              >
                                {getExtraOption(
                                  filed.name,
                                  val.getFieldValue(["appName"])
                                ).map((val) => (
                                  <Option
                                    key={
                                      val[
                                      filed.name === "exceptionCategory"
                                        ? "categoryID"
                                        : filed.name === "exceptionType"
                                          ? "typeID"
                                          : "severityID"
                                      ]
                                    }
                                    value={
                                      val[
                                      filed.name === "exceptionCategory"
                                        ? "exceptionCategoryName"
                                        : filed.name === "exceptionType"
                                          ? "exceptionTypeName"
                                          : "exceptionSeverityName"
                                      ]
                                    }
                                  >
                                    {
                                      val[
                                      filed.name === "exceptionCategory"
                                        ? "exceptionCategoryName"
                                        : filed.name === "exceptionType"
                                          ? "exceptionTypeName"
                                          : "exceptionSeverityName"
                                      ]
                                    }
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          );
                        } else {
                          return (
                            <Form.Item name={filed.name}>
                              <Input allowClear id={filed.id} />
                            </Form.Item>
                          );
                        }
                      }}
                    </Form.Item>
                  </Col>
                );
              } else if (filed.type === "dateTime") {
                return (
                  <Col span={12} key={i}>
                    <Form.Item
                      name={filed.name}
                      label={filed.label}
                      rules={[
                        () => ({
                          validator(rule, value) {
                            if (filed.name === "endTime") {
                              if (!value) return Promise.resolve();
                              let selecteApp = form.getFieldValue(["appName"]);
                              let checkMultiple = false;

                              if (selecteApp && selecteApp.length > 1) {
                                checkMultiple = hasMultiTableApplications(
                                  selecteApp
                                );
                              }
                              let st = moment(form.getFieldValue("startTime"));
                              let ed = moment(form.getFieldValue("endTime"));

                              if (Date.parse(st) > Date.parse(ed)) {
                                return Promise.reject(
                                  `End date should be greater than start date!`
                                );
                              }
                              if (checkMultiple) {
                                var diff = st.diff(ed, "days");
                                if (diff !== 0) {
                                  return Promise.reject(
                                    `Date field allowed 24hrs only!`
                                  );
                                }
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <CleDatePicker
                        field={filed}
                        form={form}
                        dateFormat='MM/DD/YYYY HH:mm:ss'
                      />
                    </Form.Item>
                  </Col>
                );
              }
              return (
                <Col span={12} key={i}>
                  <Form.Item name={filed.name} label={filed.label}>
                    {getFiled(filed)}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" hidden>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <CleFooter
        disabledFooter={props.disabledFooter}
        downloadProgress={props.downloadProgress}
        disableSearch={searchDisable}
        search
        reset
        download
        onSearch={() => {
          isDownload = false;
          form.submit();
        }}
        onReset={()=>{form.resetFields(); props.hanldeRest()}}
        onDownload={() => {
          isDownload = true;
          form.submit();
        }}
      />
    </>
  );
});

SearchForm.defaultProps = {
  exception: false,
  log: true,
  filter: false,
  search: true,
  size: "large",
};

SearchForm.propTypes = {
  isAdminAccess: PropTypes.bool,
  log: PropTypes.bool,
  firstServe: PropTypes.bool,
  applicationNames: PropTypes.array,
  categories: PropTypes.array,
  severities: PropTypes.array,
  types: PropTypes.array,
  onSearch: PropTypes.func,
  onDownload: PropTypes.func,
  size: PropTypes.string,
  disabledFooter: PropTypes.bool,
  downloadProgress: PropTypes.bool,
  hanldeRest: PropTypes.func,
  getApplicationExceptionCategory: PropTypes.func,
  getApplicationExceptionSeverity: PropTypes.func,
  getApplicationExceptionType: PropTypes.func
}
SearchForm.displayName = 'SearchForm';

export default React.memo(SearchForm);
