import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Input, Space, Button, message, Modal } from "antd";
import "../ConfigurationExcep/styles.scss";
import "./../styles.scss";
const labelColSpan = 8;
const valueColSpan = 14;
const DetailReplayExceptionConfig = ( { isModalOpen, data, onCloseModal } ) => {
  return (
    <Modal
      open={isModalOpen}
      title={"Replay Configuration"}
      onCancel={onCloseModal}
      style={{ minWidth: 800 }}
      className={"replay-config-detail-modal"}
      centered
      footer={null}>
      <Row>
        <Col span={labelColSpan}>
          Application:
        </Col>
        <Col span={valueColSpan}>
          {data.applicationName ? data.applicationName : "N/A"}
        </Col>
      </Row>
      <Row>
        <Col span={labelColSpan}>
          Application Domain:
        </Col>
        <Col span={valueColSpan}>
          {data.applicationDomain ? data.applicationDomain : "N/A"}
        </Col>
      </Row>
      <Row>
        <Col span={labelColSpan}>
          Host URL:
        </Col>
        <Col span={valueColSpan}>
          {data.replayHost ? data.replayHost : "N/A"}
        </Col>
      </Row>
      <Row>
        <Col span={labelColSpan}>
          Replay Mode:
        </Col>
        <Col span={valueColSpan}>
          {data.replayMode ? data.replayMode : "-"}
        </Col>
      </Row>
      {data.replayMode && data.replayMode.toString().toUpperCase() === "HTTP_OAUTH" &&
        <Row>
          <Col span={labelColSpan}>
            Token URL:
          </Col>
          <Col span={valueColSpan}>
            {data.oktaIssuerUrl ? data.oktaIssuerUrl : "N/A"}
          </Col>
        </Row>
      }
      <Row>
        <Col span={labelColSpan}>
          {data.replayMode && data.replayMode.toString().toUpperCase() === "HTTP_OAUTH" ? 'Client Id:' : 'Username:'}
        </Col>
        <Col span={valueColSpan}>
          {data.username ? data.username : "N/A"}
        </Col>
      </Row>
      <Row>
        <Col span={labelColSpan}>
          {data.replayMode && data.replayMode.toString().toUpperCase() === "HTTP_OAUTH" ? 'Client Secret:' : 'Password:'}
        </Col>
        <Col span={valueColSpan - 4} className="passwordLabelWrapper">
          <Space direction="vertical">
            <Input.Password
              placeholder="Password"
              value={data.password ? data.password : ""}
            />
          </Space>
          <Button
            icon={<i className="far fa-clipboard" />}
            onClick={() => {
              navigator.clipboard.writeText( data.password ? data.password : "" );
              message.success( "Copied password" );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={labelColSpan}>
          Remarks:
        </Col>
        <Col span={valueColSpan}>
          {data.description ? data.description : "N/A"}
        </Col>
      </Row>
    </Modal>
  );
};

DetailReplayExceptionConfig.propTypes = {
  onCloseModal: PropTypes.func,
  data: PropTypes.any,
  isModalOpen: PropTypes.bool,
};

export default DetailReplayExceptionConfig;
