const columnList = [
    {
        field: "application",
        headerName: "Application",
        suppressMenu: true,
        filter: false,
    },
    {
        field: "date",
        headerName: "Date",
        filter: "agDateColumnFilter",
    },
    {
        field: "count",
        headerName: "Count",
        filter: "agNumberColumnFilter",
    },
    {
        field: "type",
        headerName: "Type",
        suppressMenu: true,
        filter: false,
    },
];
export default columnList;