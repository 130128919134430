const userRoutes = [
    {
        id: "logs",
        path: "/logs/search-logs/list",
        name: "Log Search",
        icon: "fas fa-file-code",
        isNavLink: true,
        inRoute: ["/logs/search-logs/list", "/logs/search-logs/detail"],
        state: "logs",
        searchName: "Search Log",
        searchIcon: "fas fa-file-code",
        commonPath: "/logs/search-logs/"
    },
    {
        id: "exceptions",
        path: "/exceptions/search-exceptions/list",
        name: "Exception Search",
        icon: "fas fa-bug",
        isNavLink: true,
        inRoute: [
            "/exceptions/search-exceptions/list",
            "/exceptions/search-exceptions/detail",
        ],
        state: "exceptions",
        searchName: "Search Exception",
        searchIcon: "fas fa-bug",
        commonPath: "/exceptions/search-exceptions/"
    },
];
export const adminRoutes = [{
    id: "adminmodule",
    path: "/user-page",
    name: "Admin",
    icon: "fas fa-cogs",
    subOptions: [{
        path: "/admin/application-config",
        name: "Application Config",
        icon: "fas fa-file-contract",
        id: "applicationConfig",
        inRoute: ["/admin/application-config"],
        state: "adminmodule",
        searchName: "Application Config",
        searchIcon: "fas fa-file-contract",
    },
    {
        path: "/admin/cost-analysis",
        name: "Cost Analysis",
        icon: "fas fa-file-contract",
        id: "costanalysis",
        inRoute: ["/admin/cost-analysis"],
        state: "adminmodule",
        searchName: "Cost Analysis",
        searchIcon: "fas fa-file-contract",
    },
    {
        path: "/admin/configure-exceptions",
        name: "Exception Config",
        icon: "fas fa-exclamation-triangle",
        inRoute: ["/admin/configure-exceptions"],
        id: "exceptionConfig",
        state: "adminmodule",

        searchName: "Exception Config",
        searchIcon: "fas fa-exclamation-triangle",
    },
    {
        path: "/admin/configure-column-alerts",
        name: "Config Column Alerts",
        icon: "fas fa-mail-bulk",
        inRoute: ["/admin/configure-column-alerts"],
        id: "columnAlerts",
        state: "adminmodule",

        searchName: "Config Column Alerts",
        searchIcon: "fas fa-mail-bulk",
    },

    {
        path: "/admin/solman-staging",
        name: "SolMan Staging",
        inRoute: ["/admin/solman-staging"],
        icon: "fas fa-coins",
        id: "solManStagin",
        state: "adminmodule",
        searchName: "SolMan Staging",
        searchIcon: "fas fa-coins",
    },
    {
        path: "/admin/report-schedule-config",
        name: "Schedule Config",
        icon: "fas fa-calendar-alt",
        inRoute: ["/admin/report-schedule-config"],
        id: "scheduleConfig",
        state: "adminmodule",
        searchName: "Schedule Config",
        searchIcon: "fas fa-calendar-alt",
    },
    {
        path: "/blob/metrics",
        name: "Blob Metircs",
        icon: "fas fa-cloud",
        inRoute: ["/blob/metrics"],
        id: "messageConfig",
        state: "adminmodule",
        searchName: "Blob Metrics",
        searchIcon: "fas fa-cloud",
    },
    {
        path: "/admin/report-config",
        name: "Report Config",
        icon: "fas fa-paper-plane",
        inRoute: ["/admin/report-config"],
        id: "reportConfig",
        state: "adminmodule",
        searchName: "Report Config",
        searchIcon: "fas fa-paper-plane",
    },
    {
        path: "/admin/table-config",
        name: "Table Config",
        icon: "fa fa-file",
        inRoute: ["/admin/table-config"],
        id: "tableConfig",
        state: "adminmodule",
        searchName: "Table Config",
        searchIcon: "fa fa-file",
    },
    ],
},
{
    id: "usermodule",
    path: "/admin",
    name: "User Config",
    icon: "fas fa-users-cog",
    subOptions: [{
        path: "/admin/userlist",
        name: "User Access",
        icon: "fas fa-user-shield",
        inRoute: ["/admin/userlist"],
        id: "userAccess",
        state: "usermodule",
        searchName: "User Access",
        searchIcon: "fas fa-user-shield",
    },
    {
        path: "/admin/usergroup",
        name: "Group Access",
        icon: "fas fa-users",
        inRoute: ["/admin/usergroup"],
        id: "groupAccess",
        state: "usermodule",
        searchName: "Group Access",
        searchIcon: "fas fa-users",
    },
    {
        path: "/admin/applicationgroup",
        name: "Application Access",
        inRoute: ["/admin/applicationgroup"],
        icon: "fas fa-key",
        id: "applicationAccess",
        state: "usermodule",
        searchName: "Application Access",
        searchIcon: "fas fa-key",
    },
    ],
},
];
export default userRoutes;