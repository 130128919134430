import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import UserHeader from './UserHeader';
import _ from "lodash";
import CleTable from '../../components/CommonComponents/CleTable/CleTable';
import { findUsersMatchingCriteria } from '../../utils/helpers';
import COLUMNS from '../../constants/ColumnConfig/Admin/listUsers';
import "./UserList.css";
const cleTableWrapperStyle = {
    height: "calc(100vh - 210px)",
    width: "100%",
};
const cleTableInlineWrapperStyle = {
    height: "calc(70vh - 70px)",
    width: "100%",
};
const cleTableInlineTableWrapperStyle = {
    height: "calc(55vh - 50px)",
    width: "100%",
};
const UserListComponent = ({ ...props }) => {
    const globalConfigState = useSelector((state) => state.configReducer);
    const [filterValues, setFilterValues] = useState(null);
    const filterData = (userList) => {
        if (filterValues !== null) {
            return findUsersMatchingCriteria(
                userList,
                filterValues.trim().split(",")
            );
        }
        return userList;
    };
    return (
        <div className={!props.inlineTableStyle ? 'user-list-wrapper' : ""}>
            <UserHeader
                headerName="User List"
                onSearch={(val) => {
                    setFilterValues(val);
                }}
                groupOptionEnabled={props.groupOptionEnabled}
                groupOptionsList={_.uniqBy(props.groupOptionsList, 'groupName')}
                onSelectGroupHandler={props.onSelectGroupHandler}
                selectdGroupOption={props.selectdGroupOption}
            />
            <div style={props.inlineStyle ? cleTableInlineWrapperStyle : (props.inlineTableStyle ? cleTableInlineTableWrapperStyle : cleTableWrapperStyle)}>
                <CleTable
                    columnDefs={COLUMNS}
                    rowData={filterData(props.userListData)}
                    context={{ timeZone: globalConfigState.isCstTimeZone }}
                    immutableData
                    getRowNodeId={(data) => data.userID}
                    rowMultiSelectWithClick
                    overlayLoadingTemplate={`<span>
                          <div class="spinner-border text-primary" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </span>`}
                    rowSelection={"multiple"}
                    onGridReady={(params) => {
                        if (props.setGridApi)
                            props.setGridApi(params.api);
                    }}
                    onRowSelected={(params) => {
                        if (props.setSelectedRow)
                            props.setSelectedRow(params.api.getSelectedRows());
                    }}
                />
            </div>
        </div>
    );
};
UserListComponent.propTypes = {
    userListData: PropTypes.array,
    selectedGroup: PropTypes.object,
    setGridApi: PropTypes.func,
    setSelectedRow: PropTypes.func,
    inlineStyle: PropTypes.bool,
    inlineTableStyle: PropTypes.bool,
    groupOptionEnabled: PropTypes.bool,
    groupOptionsList: PropTypes.array,
    onSelectGroupHandler: PropTypes.func,
    selectdGroupOption: PropTypes.func
};
export default UserListComponent;