import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import CleTable from "../../../CommonComponents/CleTable/CleTable";
import CleFooter from "../../../CommonComponents/CleFooter/CleFooter";
import { COLUMNS } from "../../../../constants/ColumnConfig/Admin/configExceptionResults";
import { findIfContainsInArray, findIfContainsInArrayUniversalSearch, getTokenToReqHeader } from "../../../../utils/helpers";
import NewConfigException from "./newConfigExceptions";
import ConfirmationModalPopup from "../../../CommonComponents/Modal/ConfirmationModalPopup";
import DetailExceptionConfig from "./detailConfigExceptions";
import { openLoader, closeLoader } from "../../../OverlayLoader/OverlayLoader";
import { constructServiceUrl } from "../../../../services/constructUrl";
const pageStyle = {
  height: "calc(100vh - 220px)",
  width: "100%",
};
const ConfigureExceptions = ({ ...props }) => {
  const [gridApi, setGridApi] = useState();
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [detailModalData, setDetailModalData] = useState();
  const [showDetailModal, setShowDetailModal] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const filterData = (exceptionConfigData) => {
    let searchFilter = props.filterData ? props.filterData.trim().split(",") : [];
    let filteredRes = exceptionConfigData;
    if (props.appName && props.appName.length > 0) {
      filteredRes = findIfContainsInArray(exceptionConfigData, props.appName.map(x => ({ ...x, "applicationName": x.value })), ['applicationName']);
    }
    if (searchFilter && searchFilter.length > 0) {
      filteredRes = findIfContainsInArrayUniversalSearch(filteredRes, searchFilter,
        ['applicationName', 'applicationDomain', 'exceptionCategoryName', 'exceptionTypeName',
          'exceptionSeverityName', 'procedureID', 'description', 'replyDestination', 'replayType', 'emailAddress',
          'notificationGroup', 'snowParam', 'snowAssignmentGroup']);
    }
    return filteredRes;
  };
  const onDeleteHandler = () => {
    props.onConfExceptionDelete(gridApi.getSelectedRows());
    setSelectedRow([]);
    setShowConfirmModal(false);
  };
  const onSaveDataHandler = (payload, close) => {
    props.onSaveDetails(payload);
    if (close) {
      setSelectedRow([]);
      setShowUpsertModal(false);
      gridApi.deselectAll();
      setSelectedRow([]);
    }
  };
  const onUploadExceptionHandler = (fileToUpload) => {
    let fd = new FormData();
    fd.append("fileParts", fileToUpload.file);
    let serviceParams = {};
    serviceParams.payload = "";
    serviceParams.apiName = "BULK_EXCEPTION_CONFIG_DATA_UPLOAD";
    const requestParams = constructServiceUrl(serviceParams);
    openLoader();
    fetch(requestParams.url, {
      method: "POST",
      body: fd,
      headers: {
        Authorization: getTokenToReqHeader(),
      },
    }).then(function (data) {
      if (data.status === 400) {
        throw new Error(data.text());
      }
      return data.text();
    })
      .then((data) => {
        if (data) {
          toast.success("Successfully uploaded exceptions config");
          props.getExpConfigList();
        }
      })
      .catch(() => {
        closeLoader();
        toast.error("Error in uploading exceptions config");
      });
  };
  const onExportToExcelHandler = () => {
    let payload = gridApi.getSelectedRows();
    if (payload.length === 0)
      payload = filterData(props.exceptionConfigData);
    let serviceParams = { apiName: "EXCEPTION_CONFIG_EXPORT" };
    setIsDownloading(true);
    const requestParams = constructServiceUrl(serviceParams);
    payload = payload.map((val) => {
      let newObj = { ...val };
      delete newObj.startTime;
      delete newObj.lastScheduleTime;
      return newObj;
    });
    fetch(requestParams.url, {
      method: "POST",
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/json",
        Authorization: getTokenToReqHeader(),
      },
      body: JSON.stringify(payload),
    })
      .then((data) => {
        return data.blob();
      })
      .then((data) => {
        var blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
        setIsDownloading(false);
        toast.success("Downloaded successfully");
      })
      .catch((err) => {
        setIsDownloading(false);
        toast.error(`Oops! Something went wrong ${err}`);
      });
  };
  return (<>
    <div style={pageStyle}>
      <CleTable
        columnDefs={COLUMNS}
        immutableData
        rowData={filterData(props.exceptionConfigData)}
        rowSelection={"multiple"}
        getRowNodeId={(data) => data.exceptionConfigId}
        rowMultiSelectWithClick
        rowDeselection
        onSelectionChanged={(params) => { setSelectedRow(params.api.getSelectedRows()); }}
        onRowDoubleClicked={(params) => {
          setDetailModalData(params.data);
          setShowDetailModal(true);
        }}
        onGridReady={(params) => setGridApi(params.api)}
      />
    </div>
    <CleFooter
      add onAdd={() => { setIsModalEdit(false); setShowUpsertModal(true); }}
      modify onModify={() => { setShowUpsertModal(true); setIsModalEdit(true); }}
      modifyDisabled={selectedRow.length === 0 || selectedRow.length > 1}
      deleteDisabled={selectedRow.length === 0}
      deleteOnlyDisabled={selectedRow.length === 0  || selectedRow.length > 1}
      deleteBtn onDelete={() => setShowConfirmModal(true)}
      detail onDetail={() => { setDetailModalData(gridApi.getSelectedRows()); setShowDetailModal(true); }}
      uploadException onUploadException={fileUpload => onUploadExceptionHandler(fileUpload)}
      exportToExcel onExportToExcel={() => onExportToExcelHandler()}
      isExportBulkExcel={isDownloading}
    />
    {
      showUpsertModal &&
      <NewConfigException
        editFlag={isModalEdit}
        data={isModalEdit ? selectedRow : []}
        onCloseModal={() => setShowUpsertModal(false)}
        isModalOpen={showUpsertModal}
        saveData={(data, close) => onSaveDataHandler(data, close)} />
    }
    {
      showConfirmModal &&
      <ConfirmationModalPopup
        title={"Are you sure you want to delete " + gridApi.getSelectedRows().length + " item(s)?"}
        isModalOpen={showConfirmModal}
        onCloseModal={() => setShowConfirmModal(false)}
        onOkModal={() => onDeleteHandler()} />
    }
    {
      showDetailModal &&
      <DetailExceptionConfig
        isModalOpen={showDetailModal}
        data={detailModalData}
        onCloseModal={() => setShowDetailModal(false)}
      />
    }
  </>);
};
ConfigureExceptions.propTypes = {
  exceptionConfigData: PropTypes.any,
  onConfExceptionDelete: PropTypes.func,
  filterData: PropTypes.string,
  appName: PropTypes.array,
  getExpConfigList: PropTypes.func,
  onSaveDetails: PropTypes.func,
};
export default ConfigureExceptions;