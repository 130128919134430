import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import moment from "moment";
import { default as momentZone } from "moment-timezone";

const rowStyle = {
  fontSize: "1em",
};

const CleDetail = (props) => {
  const getBoolData = (val) => {
    if (val === "Y" || val === "1" || val === 1) return "Yes";
    return "No";
  };
  const timeZoneConvert = (val, key) => {
    if (props.timeConvert && key === "timemark") {
      if (props.timeZone)
        return momentZone(val)
          .tz(window.timeZone)
          .format("YYYY-MM-DD HH:mm:ss.SSS");
      return moment(val).local().format("YYYY-MM-DD HH:mm:ss.SSS");
    }
    return val;
  };

  return (
    <Row gutter={[16, 10]} style={rowStyle}>
      {props.schema.map((tableKey, index) => {
        return props.data[tableKey.key] !== undefined ? (
          <React.Fragment key={index}>
            <Col span={5} className="b600">
              {tableKey.label}
            </Col>
            <Col span={7}>
              {tableKey.type && tableKey.type === "bool"
                ? getBoolData(props.data[tableKey.key])
                : timeZoneConvert(props.data[tableKey.key], tableKey.key) ||
                  typeof props.data[tableKey.key] === "number"
                ? timeZoneConvert(props.data[tableKey.key], tableKey.key)
                : "-"}
            </Col>
          </React.Fragment>
        ) : (
          ""
        );
      })}
    </Row>
  );
};

CleDetail.propTypes = {
  data: PropTypes.object,
  timeConvert: PropTypes.bool,
  timeZone: PropTypes.bool,
  schema: PropTypes.array,
}

export default CleDetail;
