import { call, fork, put, all, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/Admin/exceptionConfigAction";
import * as types from "../../constants/ActionTypes/Admin/configExceptionTypes";
import { defaultAction } from "../../services/restService";
import { toast } from "react-toastify";
import { logger } from "redux-logger";
export function* fetchConfigExceptionDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
      page: "exceptionConfigData",
      id: "exceptionConfigId",
    };
    yield put(actions.ExceptionConfigDataStore(rep));
  } catch (e) {
    logger(e);
  }
}

export function* getExceptionConfig() {
  yield takeLatest(
    types.GET_EXCEPTION_CONFIG_DETAILS,
    fetchConfigExceptionDetails
  );
}

export function* processExcepConfigDeletion(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "DELETE",
      page: "exceptionConfigData",
      id: "exceptionConfigId",
    };
    toast.success("Exception configuration deleted successfully");
    yield put(actions.ExceptionConfigDataStore(rep));
  } catch (e) {
    logger(e);
  }
}

export function* deleteConfException() {
  yield takeLatest(types.DELETE_CONFIGURE_EXP, processExcepConfigDeletion);
}

export function* saveConfigExcepDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "ADD",
      page: "exceptionConfigData",
      id: "exceptionConfigId",
    };

    toast.success("Exception Configuration added successfully");
    yield put(actions.ExceptionConfigDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* addConfigExcepFunction() {
  yield takeLatest(types.ADD_CONFIG_EXP, saveConfigExcepDetails);
}
export function* saveEditedConfigDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "UPDATE",
      page: "exceptionConfigData",
      id: "exceptionConfigId",
    };
    toast.success("Exception Configuration updated successfully");
    yield put(actions.ExceptionConfigDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* editConfigExcepFunction() {
  yield takeLatest(types.EDIT_CONFIG_EXP, saveEditedConfigDetails);
}

/*replay*/

export function* fetchReplayExceptionDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
      page: "replayConfigData",
      id: "replayID",
    };
    yield put(actions.ExceptionConfigDataStore(rep));
  } catch (e) {
    logger(e);
  }
}

export function* getReplayConfig() {
  yield takeLatest(
    types.GET_REPLAY_CONFIG_DETAILS,
    fetchReplayExceptionDetails
  );
}

export function* saveReplayConfigDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "ADD",
      page: "replayConfigData",
      id: "replayID",
    };
    toast.success("Replay Configuration added successfully");
    yield put(actions.ExceptionConfigDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* addReplayConfigFunction() {
  yield takeLatest(types.ADD_REPLAY_CONFIG_EXP, saveReplayConfigDetails);
}

export function* saveEditedReplayConfigDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "UPDATE",
      page: "replayConfigData",
      id: "replayID",
    };
    toast.success("Replay Configuration updated successfully");
    yield put(actions.ExceptionConfigDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* editReplayConfigFunction() {
  yield takeLatest(types.EDIT_REPLAY_CONFIG_EXP, saveEditedReplayConfigDetails);
}

export function* saveDeleteReplayConfigDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "DELETE",
      page: "replayConfigData",
      id: "replayID",
    };
    toast.success("Replay Configuration deleted successfully");
    yield put(actions.ExceptionConfigDataStore(rep));
  } catch (e) {
    logger(e);
  }
}

export function* deleteReplayConf() {
  yield takeLatest(
    types.DELETE_CONFIGURE_REPLAY,
    saveDeleteReplayConfigDetails
  );
}

export default function* root() {
  yield all([
    fork(getExceptionConfig),
    fork(deleteConfException),
    fork(addConfigExcepFunction),
    fork(editConfigExcepFunction),
    fork(getReplayConfig),
    fork(addReplayConfigFunction),
    fork(editReplayConfigFunction),
    fork(deleteReplayConf),
  ]);
}
