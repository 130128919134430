import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
const DetailLogConfig = (props) => {
  const [index, setIndex] = React.useState(0);
  let data = props.data[index];
  return (
    <div>
      <div
        className="modal-header"
        style={{ borderBottom: "1px solid #cccccc" }}
      >
        <h5 className="modal-title">
          <i className="fa fa-info-circle" /> Log Configuration
        </h5>
        {props.data.length > 1 && (
          <ul className="pagination justify-content-center pagination-sm config-pagination">
            <li className={`page-item ${index === 0 && "cus_notallowed"}`}>
              <button
                id="sel_logs_details_prev"
                className={`btn btn-default ${
                  index !== 0 ? "b500" : "btndisable"
                }`}
                aria-label="Previous"
                onClick={() => {
                  setIndex(index - 1);
                }}
              >
                <span aria-hidden="true" className="icn_pre_nxt">
                  &laquo;
                </span>
                <span>Previous</span>
              </button>
            </li>
            <li
              className={`page-item ${
                index === props.data.length - 1 && "cus_notallowed"
              }`}
            >
              <button
                id="sel_logs_details_next"
                className={`btn btn-default ${
                  index !== props.data.length - 1 ? "b500" : "btndisable"
                }`}
                aria-label="Next"
                onClick={() => {
                  setIndex(index + 1);
                }}
              >
                <span>Next</span>
                <span aria-hidden="true" className="icn_pre_nxt">
                  &raquo;
                </span>
              </button>
            </li>
          </ul>
        )}
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={props.onCloseModal}
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        <div>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <label className="form-control-label detail_label">Application</label>
                </Col>
                <Col span={14}>
                  <label className="form-control-label configExcep_detail_label">
                    {data.applicationName}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <label className="form-control-label detail_label">Email Notification</label>
                </Col>
                <Col span={14}>
                  <label className="form-control-label configExcep_detail_label">
                    {data.mailID}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <label className="form-control-label detail_label ">Enable Notification</label>
                </Col>
                <Col span={14}>
                  <label className="form-control-label configExcep_detail_label">
                    {data.enableNotification}
                  </label>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={10}>
                  <label className="form-control-label detail_label">Status</label>
                </Col>
                <Col span={14}>
                  <label className="form-control-label configExcep_detail_label">
                    {data.status}
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
DetailLogConfig.propTypes = {
  onCloseModal: PropTypes.func,
  data: PropTypes.any,
};
export default DetailLogConfig;
