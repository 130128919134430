import {call, put, fork, all, takeLatest} from 'redux-saga/effects';
import {ColumnConfigDataStore} from '../../actions/Admin/configureColumnAlertsAction';
import * as types from '../../constants/ActionTypes/Admin/columnConfigAlertTypes';
import { defaultAction } from '../../services/restService';
import { toast } from 'react-toastify';
import { logger } from 'redux-logger';
export function* processConfigurationColumnAlertDeletion(payload) {
  try {
    const response=yield call(defaultAction, payload);
    const rep={
      response,
      "action":"DELETE",
      "id":"columnAlertId"
    }
    yield put(ColumnConfigDataStore(rep));
    toast.success("Configure column based alert deleted successfully!")
  } catch(e) {
    logger(e)
    // yield put(updateStatus('delete', 'error'));
  }
}
/* To delete a column configuration alert */
export function* deleteConfigurationColumnAlert() {
  yield takeLatest(types.DELETE_COLUMNCONFIGALERT, processConfigurationColumnAlertDeletion)
}
/* Yielded method to add a new alert and to get the latest alert details from DB */
export function* saveconfigColumnAlertDetails(payload) {
  try {
    const response=yield call(defaultAction, payload);
    const rep={
      response,
      "action":"ADD",
      "id":"columnAlertId"
    }
    toast.success("Configure column based alert added successfully!")
    yield put(ColumnConfigDataStore(rep));
  } catch(e) {
    logger(e)
  }
}
/* To add a new column configuration alert */
export function* addconfigColumnAlertDetails() {
  yield takeLatest(types.ADD_NEW_COLUMNCONFIGALERT, saveconfigColumnAlertDetails)
}
/* Yielded method to modify the selected alert and to get the latest alert details from DB */
export function* modifyConfigColumnAlertDetailsDetails(payload) {
  try {
    const response=yield call(defaultAction, payload);
    const rep={
      response,
      "action":"UPDATE",
      "id":"columnAlertId"
    }
    toast.success("Configure column based alert updated successfully!")
    yield put(ColumnConfigDataStore(rep));
  
  } catch(e) {
    // yield put(updateStatus('edit', 'error'));
    logger(e)
  }
}
/* To update an existing column configuration alert */
export function* modifyConfigColumnAlertDetails() {
  yield takeLatest(types.UPDATE_COLUMNCONFIGALERT, modifyConfigColumnAlertDetailsDetails)
}
/* To get all column configuration alerts details */
export function* getAllConfigColumnAlertDetails() {
  yield takeLatest(types.GET_COLUMNCONFIG_ALERT_DETAILS, fetchConfigColumnAlertDetails)
}
/* Yielded method to get the latest alert details from DB and to update in the home page grid */
export function* fetchConfigColumnAlertDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep={
      response,
      "action":"NEW",
      "id":"columnAlertId"
    }
    yield put(ColumnConfigDataStore(rep));
  } catch(e) {
    logger(e)
  }
}

export default function* root() {
  yield all([
    fork(getAllConfigColumnAlertDetails),
    fork(deleteConfigurationColumnAlert),
    fork(addconfigColumnAlertDetails),
    fork(modifyConfigColumnAlertDetails)
  ])
}
