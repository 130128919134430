import React from "react";
import { Input } from 'antd';
import PropTypes from "prop-types";

const component = (
  name,
  type,
  size,
  stclass,
  value,
  tooltip,
  id,
  onChange,
  autoFocus,
  placeholder,
  onBlur,
  allowClear
) => (
  <Input
    name={name}
    type={type}
    id={id}
    autoFocus={autoFocus}
    size={size}
    className={stclass}
    // data-tooltip={tooltip ? "true" : "false"}
    // title={tooltip}
    // data-placement="top"
    allowClear={allowClear}
    placeholder={placeholder}
    value={value}
    onBlur={onBlur}
    onChange={onChange}
  />
);

const propsTypes = {
  allowClear: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  stclass: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  tooltip: PropTypes.func,
  placeholder: PropTypes.string,
};

const InputBox = ({
  name,
  type,
  size,
  stclass,
  value,
  tooltip,
  id,
  onChange,
  autoFocus,
  placeholder,
  onBlur,
  allowClear
}) =>
  component(name, type, size, stclass, value, tooltip, id, onChange, autoFocus, placeholder, onBlur, allowClear);
InputBox.propTypes = { ...propsTypes };

export { InputBox };
