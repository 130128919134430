const getIndex = (stateData, responseData, id) => {
  return stateData.findIndex(val => {
    if (val[id] === responseData[id]) {
      return true;
    }
    return false;
  });
};

const getIndexMultiple = (stateData, responseData, id, secId) => {
  return stateData.findIndex(val => {
    if (
      val &&
      val[id] === responseData[id] &&
      val[secId] === responseData[secId]
    ) {
      return true;
    }
    return false;
  });
};

export const formatData = (data, pervState, id, secId, isMultiple) => {
  let availData = pervState;
  if (data) {
    switch (data.action) {
      case "NEW":
        return data.response;
      case "ADD": {
        if (data.response.constructor === Array) {
          availData.push(...data.response)
        } else {
          availData.push(data.response);
        }
        return availData;
      }
      case "UPDATE": {
        let index = -1;
        if (isMultiple)
          index = getIndexMultiple(availData, data.response, id, secId);
        else index = getIndex(availData, data.response, id);
        if (index > -1) {
          availData[index] = data.response;
        }
        return availData;
      }
      case "MULTI_UPDATE": {
        if (data && data.response.length > 0)
          data.response.forEach(val => {
            let index = -1;
            index = getIndexMultiple(availData, val, id, secId);
            if (index > -1) {
              availData[index] = val;
            }
          });
        return availData;
      }
      case "DELETE": {
        if(data && data.response.length > 0)
        data.response.forEach(val => {
          let index = -1;
          if (isMultiple) index = getIndexMultiple(availData, val, id, secId);
          else index = getIndex(availData, val, id);
          if (index > -1) {
            availData.splice(index, 1);
          }
        });
        return availData;
      }
      default:
        return availData;
    }
  }
  return [];
};
