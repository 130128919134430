import React from 'react';
import { Badge } from "antd";
const exceptionDetailColumn = [
    { label: "Application", key: "application_nm" },
    { label: "Code", key: "error_code" },
    { label: "Application Domain", key: "application_domain" },
    { label: "Message", key: "msg" },
    { label: "Service", key: "service_nm" },
    {
        label: (
            <span>
                Replay Status
                <Badge status="processing" className="status-icon" />
            </span>
        ),
        key: "exception_status",
    },
    { label: "Component", key: "component_nm" },
    { label: "Resolution Description", key: "resolution_description" },
    { label: "Business ID", key: "business_id" },
    { label: "Reply Destination", key: "reply_destination" },
    { label: "Business ID2", key: "business_id_two" },
    { label: "Replay Count", key: "replay_count" },
    { label: "Transaction Id", key: "trans_id" },
    { label: "Host Name", key: "host_nm" },
    {
        label: "Transaction Type",
        key: "trans_type",
    },
    { label: "Timestamp", key: "timemark" },
    { label: "Transaction Domain", key: "trans_domain" },
    { label: "Category", key: "exception_category" },
    { label: "Issue Group", key: "issue_group" },
    { label: "Type", key: "exception_type" },
    { label: "Severity", key: "exception_severity" },
];
export default exceptionDetailColumn;