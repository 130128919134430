import { call, put, fork, all, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/Admin/solmanStagingAction";
import * as types from "../../constants/ActionTypes/Admin/solmanStagingTypes";
import { defaultAction } from "../../services/restService";
import { toast } from "react-toastify";
import { logger } from "redux-logger";
export function* fetchSolmanStagingDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
      index: 0,
    };
    yield put(actions.SolmanDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* getSolmanStaging() {
  yield takeLatest(types.GET_SOLMAN_STAGING, fetchSolmanStagingDetails);
}

export function* processSolmanStaging(payload) {
  try {
    const response = yield call(defaultAction, payload);
    toast.success("Solman Staging has been deleted successfully");
    const rep = {
      response,
      action: "DELETE",
      index: payload.index,
    };
    yield put(actions.SolmanDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* deleteSolmanStaging() {
  yield takeLatest(types.DELETE_SOLMAN_STAGING, processSolmanStaging);
}

export function* saveSolmanStaging(payload) {
  try {
    const response = yield call(defaultAction, payload);
    toast.success("Solman Staging has been added successfully");
    const rep = {
      response,
      action: "ADD",
      index: 0,
    };
    yield put(actions.SolmanDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* addSolmanStaging() {
  yield takeLatest(types.ADD_SOLMAN_STAGING, saveSolmanStaging);
}

export function* ModifySolmanStaging(payload) {
  try {
    const response = yield call(defaultAction, payload);
    toast.success("Solman Staging has been updated successfully");
    const rep = {
      response,
      action: "UPDATE",
      index: payload.index,
    };
    yield put(actions.SolmanDataStore(rep));
  } catch (e) {
    logger(e);
  }
}

export function* modifySolmanStaging() {
  yield takeLatest(types.UPDATE_SOLMAN_STAGING, ModifySolmanStaging);
}

export default function* root() {
  yield all([
    fork(getSolmanStaging),
    fork(deleteSolmanStaging),
    fork(addSolmanStaging),
    fork(modifySolmanStaging),
  ]);
}
