import React from "react";
import PropTypes from "prop-types";
import Select from "react-dropdown-select";
import { Button, Tooltip } from "antd";
import "./style.scss";

const MultiSelect = ({ ...props }) => {
    const keepSelectedInList = true;
    const contentRenderer = ({ state, methods }) => {
        if (!props.showAllColTags && props.options.length === state.values.length) {
            return (
                <div className="render-all">{props.renderAllOptionLabel}</div>
            );
        }
        // if (props.options.length === state.values.length) {
        //     return <div className="render-all">All application </div>;
        // }
        if (state.values.length > 0)
            return (
                <div className="item-overflow">
                    {dynamicRender(state.values, methods)}
                </div>
            );
        return (
            <div className="cle-multi-select-placholder">
                {props.placeholder}
            </div>
        );
    }
    const dynamicRender = (items, methods) => {
        return items.map((item) => (
            <span
                key={item.label}
                role="listitem"
                direction="ltr"
                className={`react-dropdown-select-option select-lable ${item.isDisabled ? 'select-drop-disabled' : ''}`}
                color="#0074D9"
            >
                <span className="react-dropdown-select-option-label">{item.label}</span>
                <span
                    className="react-dropdown-select-option-remove"
                    onClick={() => methods.addItem(item)}
                >
                    ×
                </span>
            </span>
        ));
    }
    // eslint-disable-next-line no-unused-vars
    const dropdownRenderer = ({ localProps, state, methods }) => {
        return (
            <div>
                {!props.disableSearch && (
                    <div className="search-conatiner">
                        {props.hasSelectAll && (
                            <div className="selectAll-conatiner">
                                <div>Search and select:</div>
                                {!props.showAllColTags ?
                                    props.options.length === state.values.length ? (
                                        <Button
                                            className="btn-selectAll"
                                            id="sel_search_clearall"
                                            onClick={methods.clearAll}
                                            type="danger"
                                            size="small"
                                            ghost
                                        >
                                            {props.clearAllLabel}
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btn-selectAll"
                                            id="sel_search_selectall"
                                            onClick={methods.selectAll}
                                            type="primary"
                                            size="small"
                                            ghost
                                        >
                                            Select all
                                        </Button>
                                    )
                                    : (
                                        <div className="d-flex">
                                            <Tooltip placement="bottom" title={props.clearAllLabel} overlayClassName='btn-tooltip'>
                                                <Button
                                                    className="btn-selectAll"
                                                    id="sel_search_clearall"
                                                    onClick={props.clearAllHandler}
                                                    icon={<i className="fas fa-undo-alt" aria-hidden="true" />}
                                                    type="danger"
                                                    size="small"
                                                    ghost
                                                />
                                            </Tooltip>
                                            <Tooltip placement="bottom" title="Select all" overlayClassName='btn-tooltip'>
                                                <Button
                                                    className="btn-selectAll"
                                                    id="sel_search_selectall"
                                                    onClick={methods.selectAll}
                                                    disabled={props.options.length === state.values.length}
                                                    icon={<i className="fa fa-check-square" aria-hidden="true" />}
                                                    type="primary"
                                                    size="small"
                                                    ghost
                                                />
                                            </Tooltip>
                                        </div>
                                    )}
                            </div>
                        )}
                        <input
                            type="text"
                            value={state.search}
                            autoFocus
                            id="sel_searchbox"
                            className="search-box"
                            onChange={methods.setSearch}
                            placeholder={props.searchInputPlaceholder}
                        />
                    </div>
                )}
                <div className="item-conatainer">
                    {props.options
                        .filter((item) => item.label.toLowerCase().includes(state.search.toLowerCase()))
                        .map((option) => {
                            if (
                                !keepSelectedInList &&
                                methods.isSelected(option)
                            ) {
                                return null;
                            }
                            return (
                                <div
                                    className={`${option.disabled || option.isDisabled ? "select-drop-disabled" : ""
                                        } Item`}
                                    id="sel_logs_appname_dropdown"
                                    disabled={option.disabled}
                                    key={option.label}
                                    onClick={
                                        option.disabled || option.isDisabled ? null : () => methods.addItem(option)
                                    }
                                ><span>
                                        <input type="checkbox"
                                            onChange={() => methods.addItem(option)}
                                            checked={
                                                state?.values?.findIndex(
                                                    (v) => v.label === option.label
                                                ) !== -1
                                            }
                                        />
                                        <label className="ItemLabel">{option.label}</label>
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
    const noDataRenderer = () => {
        return (
            <p style={{
                textAlign: "center",
                margin: "1rem 0",
            }}>
                <strong>Ooops!</strong>
                No data found
            </p>
        );
    }
    return (
        <div className={props.wrapperClassName}>
            <Select
                className="react-dropdown-select-dropdown sel_search_multi_select"
                placeholder={props.placeholder}
                color={"#0074D9"}
                disabled={props.disabled}
                clearable={props.clearable}
                separator
                clearOnSelect={false}
                dropdownHandle
                dropdownHeight={"300px"}
                style={{
                    border: `1px solid ${props.error ? "#ff4d4f" : "#ccc"}`,
                    backgroundColor: "#fff",
                }}
                multi={props.multi}
                searchable
                options={props.options}
                values={props.selected}
                dropdownGap={5}
                keepSelectedInList
                onDropdownOpen={() => undefined}
                onDropdownClose={() => undefined}
                onChange={props.onSelectedChanged}
                id="sel_search_multiselect"
                noDataLabel="No matches found"
                noDataRenderer={() => noDataRenderer()}
                contentRenderer={(innerProps, innerState, innerMethods) =>
                    contentRenderer(innerProps, innerState, innerMethods)
                }
                dropdownRenderer={(innerProps, innerState, innerMethods) =>
                    dropdownRenderer(innerProps, innerState, innerMethods)
                }
                keepOpen={props.keepOpen}
            />
        </div>
    )
}

MultiSelect.defaultProps = {
    searchInputPlaceholder: "Search Application",
    renderAllOptionLabel: "All application",
    wrapperClassName: "",
    keepOpen: false,
    clearAllLabel: "Clear all",
    showAllColTags: false,
};

MultiSelect.propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.array,
    selected: PropTypes.array,
    disableSearch: PropTypes.bool,
    hasSelectAll: PropTypes.bool,
    onSelectedChanged: PropTypes.func,
    multi: PropTypes.bool,
    placeholder: PropTypes.string,
    clearable: PropTypes.bool,
    error: PropTypes.bool,
    renderAllOptionLabel: PropTypes.string,
    searchInputPlaceholder: PropTypes.string,
    wrapperClassName: PropTypes.string,
    keepOpen: PropTypes.bool,
    clearAllLabel: PropTypes.string,
    clearAllHandler: PropTypes.func,
    showAllColTags: PropTypes.bool,
};
export default MultiSelect;