//user getLoggedInUserAccessInfo
export const GET_USER_ACCESS_INFO = 'GET_USER_ACCESS_INFO';
export const USER_ACCESS_INFO = 'USER_ACCESS_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const EXCEPTION_INFO = 'EXCEPTION_INFO';
export const GET_EXCEPTION_DETAIL = 'GET_EXCEPTION_DETAIL';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
export const OKTA_AUTHENTICATED_STATUS = 'OKTA_AUTHENTICATED_STATUS';


