import * as types from '../../constants/ActionTypes/logTypes';
import _ from 'lodash';
import { constructServiceUrl } from '../../services/constructUrl';
import { toast } from 'react-toastify';

const checkMultipleApplication = (payload, serviceParams, type) => {
    let appData = sessionStorage.getItem("applicationDetails")
    if (appData) {
        appData = JSON.parse(appData)
        let appName = payload.applicationName.split(" ")
        let table = []
        _.each(appName, (app) => {
            table.push(_.find(appData, ['applicationName', app]).logTable);
        })
        table = _.uniq(table)
        if (table.length > 1) {
            serviceParams.apiName = type
        }
    }
    return serviceParams
}
const addNewFiled = (payload) => {
    if (payload.appName.length === 1) {
        payload.table = payload.appName[0].logTable
    }
    let userInfo = JSON.parse(sessionStorage.getItem('persist:root'));
    let userAccessInfo = JSON.parse(userInfo.userAccess).userAccessInfo
    payload.userID = userAccessInfo.userID
    return payload
}
export function onLogSearch(payload) {
    let serviceParams = {}
    serviceParams.payload = addNewFiled(payload);
    serviceParams.apiName = types.SEARCH_LOGS;

    let requestParams = constructServiceUrl(checkMultipleApplication(payload, serviceParams, types.ALL_SEARCH_LOGS))
    return {
        type: types.SEARCH_LOGS,
        request: requestParams
    };
}
export function updateLogSearchResults(logData, searchCriterias) {
    return {
        type: types.LOG_SEARCH_SUCCESS,
        logData,
        searchCriterias
    };
}
export function updateLogSearchFailure(message) {
    return {
        type: types.LOG_SEARCH_FAILURE,
        message
    };
}

export function getLogDetails(payload) {
    let serviceParams = {}
    serviceParams.payload = payload;
    serviceParams.apiName = types.GET_LOG_DETAILS;
    let requestParams = constructServiceUrl(serviceParams)
    window.iosLoader = true;
    return {
        type: types.GET_LOG_DETAILS,
        request: requestParams
    };
}
export function updateLogDetails(logDetails) {
    return {
        type: types.LOG_DETAILS,
        logDetails
    };
}
export function clearLogData() {
    return {
        type: types.CLEAR_LOG_DATA
    }
}
export function emptyLogData() {
    return {
        type: types.EMPTY_LOG_DATA
    }
}
export function clearLogDetails() {
    return {
        type: types.CLEAR_LOG_DETAILS
    }
}
export function clearFilterData() {
    return {
        type: types.CLEAR_FILTER_DETAILS
    }
}
export function processLogFilter(payload, actionType) {
    let serviceParams = {};
    let type;
    serviceParams.payload = payload;
    window.iosLoader = true;
    if (actionType === 'Filter') {
        type = types.PROCESS_FILTER_LOGS;
    } else if (actionType === 'De-filter' || actionType === 'De-Filter') {
        type = types.PROCESS_DEFILTER_LOGS;
    } else if (actionType === 'Purge') {
        type = types.FILTER_LOGS_PURGE;
    }
    serviceParams.apiName = type;
    let requestParams = constructServiceUrl(serviceParams)
    toast.info(`Log ${actionType} Process has been started`);
    return {
        type: type,
        request: requestParams,
        actionType: actionType
    };
}

export function updateFilterLogResult(logFilterStatus, actionType) {
    let serviceParams = {}
    const payload = logFilterStatus.requestID;
    serviceParams.payload = payload;
    serviceParams.apiName = types.GET_FILTERED_LOG_STATUS;
    // window.iosLoader = false;
    let requestParams = constructServiceUrl(serviceParams);
    return {
        type: types.GET_FILTERED_LOG_STATUS,
        request: requestParams,
        actionType: actionType
    };
}
export function updateLogFilterStatus(filterStatus, actionType) {
    return {
        type: types.LOG_FILTER_STATUS,
        filterStatus,
        actionType
    }
}
export function logSearchResultFilter(payload, actionType) {
    let serviceParams = {}
    serviceParams.payload = payload;
    let type;
    window.iosLoader = true;
    if (actionType === 'Filter') {
        type = types.LOG_SEARCH_FILTER_LOGS;
    } else if (actionType === 'De-Filter') {
        type = types.LOG_SEARCH_DEFILTER_LOGS;
    }
    serviceParams.apiName = type;
    let requestParams = constructServiceUrl(serviceParams)
    return {
        type: type,
        request: requestParams
    };
}
export function updateLogResultsonFilter(filteredLogs) {
    return {
        type: types.LOG_SEARCH_FILTER_LOGS_RESULT,
        filteredLogs
    }
}
export function purgeLogs(payload) {
    let serviceParams = {}
    serviceParams.payload = payload;
    serviceParams.apiName = types.PURGE_LOGS;
    window.iosLoader = true;
    let requestParams = constructServiceUrl(serviceParams)
    return {
        type: types.PURGE_LOGS,
        request: requestParams
    };
}
export function updateLogsonPurge(purgedLogs) {
    return {
        type: types.PURGE_LOGS_RESULT,
        purgedLogs
    }
}
export function getTotalNoOfRecords(payload) {

    let serviceParams = {}
    serviceParams.payload = addNewFiled(payload);
    serviceParams.apiName = types.GET_LOG_COUNT;
    // let requestParams = constructServiceUrl(serviceParams)
    let requestParams = constructServiceUrl(checkMultipleApplication(payload, serviceParams, types.GET_LOG_COUNT_ALL))
    return {
        type: types.GET_TOTAL_RECORD_COUNT,
        request: requestParams
    };
}
export function updateTotalRecordsCount(logData) {
    return {
        type: types.TOTAL_RECORD_COUNT,
        logData
    }
}
export function onLogSort(payload) {
    let serviceParams = {}
    serviceParams.payload = addNewFiled(payload);
    serviceParams.apiName = types.SEARCH_LOGS;
    window.iosLoader = true;
    let appData = sessionStorage.getItem("applicationDetails")
    if (appData) {
        appData = JSON.parse(appData)
        let appName = payload.applicationName.split(" ")
        let table = []
        _.each(appName, (app) => {
            table.push(_.find(appData, ['applicationName', app]).logTable);
        })
        table = _.uniq(table)
        if (table.length > 1) {
            serviceParams.apiName = types.ALL_SEARCH_LOGS;
        }
    }
    let requestParams = constructServiceUrl(serviceParams)
    return {
        type: types.SORT_LOGS,
        request: requestParams
    };
}
export function updateLogSortResults(logData, searchCriterias) {
    return {
        type: types.SORT_LOGS_SUCCESS,
        logData,
        searchCriterias
    };
}
export function updateLogSortFailure(message) {
    return {
        type: types.SORT_LOGS_ERROR,
        message
    };
}
export function updateLogDetailFailure(message) {
    return {
        type: types.LOGS_ERROR,
        message
    };
}
export function logReducerProgress() {
    return {
        type: types.PROGRESS_LOG_REDUCER
    }
}