const cellStyles = {
  "text-align": "center",
};
const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: false,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPinned: true,
  lockPosition: 'left',
  maxWidth: 60,
};
const COLUMNS = [
  fieldHead,
  {
    field: "applicationName",
    headerName: "Application",
  },
  {
    field: "mailID",
    headerName: "Email",
  },
  {
    field: "metricType",
    headerName: "Metric type",
  },
  {
    field: "reportType",
    headerName: "Report type",
    cellRenderer: function (params) {
      let val = params.value || "Daily";
      return '<span class="text-center">' + val + "</span>";
    },
  },
  {
    field: "enableNotification",
    headerName: "Enable Notification",
    cellStyle: cellStyles,
    cellRenderer: function (params) {
      let val = params.value === false ? "N" : "Y";
      return '<span class="text-center">' + val + "</span>";
    },
  }
];
module.exports = { COLUMNS };
