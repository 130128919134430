import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Checkbox, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import logColumn from "../../../../constants/ExportDataInExcelModal/logColumn";
import exceptionColumn from "../../../../constants/ExportDataInExcelModal/exceptionColumn";
import "./style.scss";

const modalWidth = "65%";
const defaultSelectedFields = ["application_nm", "timemark"];

const SelectFieldForExportModal = ({ ...props }) => {
  const localColumns =
    props.colName === "EXCEPTION_COLUMNS"
      ? [...exceptionColumn]
      : [...logColumn];
  const [replay, setReplay] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [selectedFields, setSelectedFields] = useState(defaultSelectedFields);
  const onSelectReplayCheckboxHandler = () => {
    if (replay) {
      setReplay(false);
      setSelectedFields(defaultSelectedFields);
    } else {
      setReplay(true);
      setIsAll(false);
      setSelectedFields(["application_nm", "timemark", "exception_id"]);
    }
  };
  const onSelectAllCheckboxHandler = () => {
    if (isAll) {
      setIsAll(false);
      setSelectedFields(defaultSelectedFields);
    } else {
      setIsAll(true);
      setSelectedFields(localColumns.map((x) => x.field));
    }
  };
  const onSelectCheckboxHandler = (isChecked, fieldName) => {
    if (isChecked) {
      setSelectedFields([...selectedFields, fieldName]);
    } else {
      let updateSelectedFields = selectedFields.filter(
        (item) => item !== fieldName
      );
      setSelectedFields(updateSelectedFields);
    }
  };

  useEffect(() => {
    if (localColumns.length === selectedFields.length) {
      setIsAll(true);
    } else {
      setIsAll(false);
    }
  }, [selectedFields]);

  return (
    <Modal
      title={
        <div className="select-field-modal-header">
          <h5 className="modal-title">Select Field</h5>
          <div className="d-flex">
            {props.colName === "EXCEPTION_COLUMNS" && (
              <div>
                <Checkbox
                  className="select-all-checkbox"
                  checked={replay}
                  onChange={onSelectReplayCheckboxHandler}
                >
                  Replay Template
                </Checkbox>
              </div>
            )}
            <div className={replay ? "cus_notallowed cus_disabled" : ""}>
              <Checkbox
                disabled={replay}
                className="select-all-checkbox"
                checked={isAll}
                onChange={onSelectAllCheckboxHandler}
              >
                Select All
              </Checkbox>
            </div>
          </div>
        </div>
      }
      centered
      open={props.isModalOpen}
      closable={false}
      width={modalWidth}
      footer={
        <div className="d-flex select-field-modal-footer">
          {props.count && props.count > 10000 && (
            <div className="wrn_dwnd">
              Max download limit is 10000 records
            </div>
          )}
          <Button
            type="primary"
            size={"default"}
            className="secondary"
            onClick={() => {
              setTimeout(() => {
                props.processExcelDownload(selectedFields);
              }, 300);
            }}
          >
            <CheckOutlined /> Ok
          </Button>
          <Button
            type="primary"
            key="close"
            danger
            onClick={props.closeExcelModal}
          >
            <CloseOutlined /> Close
          </Button>
        </div>
      }
    >
      <div className="d-flex flex-wrap">
        {localColumns.map(({ headerName, field }) => {
          return (
            <div
              className={`${
                ["application_nm", "timemark"].includes(field) || replay
                  ? "cus_notallowed"
                  : ""
              } pr-3 mt-3`}
              key={field}
            >
              <div
                className={`${
                  ["application_nm", "timemark"].includes(field) || replay
                    ? "cus_disabled"
                    : ""
                }`}
              >
                <Checkbox
                  id={field}
                  value={field}
                  checked={
                    selectedFields ? selectedFields.includes(field) : false
                  }
                  onChange={(e) =>
                    onSelectCheckboxHandler(e.target.checked, field)
                  }
                >
                  {headerName}
                </Checkbox>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

SelectFieldForExportModal.propTypes = {
  isModalOpen: PropTypes.bool,
  colName: PropTypes.string,
  processExcelDownload: PropTypes.func,
  closeExcelModal: PropTypes.func,
  count: PropTypes.number,
};

export default SelectFieldForExportModal;
