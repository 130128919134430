import { getSnowServiceName } from "../../../utils/helpers";

const cellStyles = {
  "text-align": "center",
};

const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: false,
  headerCheckboxSelectionFilteredOnly: false,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true,
};
export const COLUMNS = [
  fieldHead,
  {
    field: "applicationName",
    headerName: "Application",
  },
  {
    field: "exceptionCategoryName",
    headerName: "Category",
  },
  {
    field: "exceptionTypeName",
    headerName: "Type",
  },
  {
    field: "exceptionSeverityName",
    headerName: "Severity",
  },
  {
    field: "columnName",
    headerName: "Column Name",
  },
  {
    field: "columnValue",
    headerName: "Column Value",
  },
  {
    field: "description",
    headerName: "Description",
  },
  {
    field: "emailAddress",
    headerName: "Email",
  },
  {
    field: "notificationGroup",
    headerName: "Notification Group",
    cellStyle: cellStyles,
  },
  {
    field: "enableMailNotification",
    headerName: "Email Notification",
    cellStyle: cellStyles,
  },
  {
    field: "enableGroupNotification",
    headerName: "Group Notification",
    cellStyle: cellStyles,
  },
  {
    field: "enableSnowNotification",
    headerName: "Snow Notification",
    cellStyle: cellStyles,
  },
  {
    field: "sysClassName",
    headerName: "Snow Service Name",
    filter: false,
    suppressMenu: true,
    cellRenderer: function (params) {
      return '<span class="text-center">' + getSnowServiceName(params.value) + "</span>";
    },
  },
  {
    field: "snowParam1",
    headerName: "Snow Service Value",
  },
];
