import React from "react";
import PropTypes from "prop-types";

const component = (id, label, value, checked, onChange, type, cls, disable) => (
  <div className={`custom-control custom-${cls}`} style={{ paddingLeft: 20 }}>
    <input
      type={type}
      id={id}
      name={id}
      className="custom-control-input"
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disable}
      readOnly={disable}
      tabIndex={-1}
    />
    <label className="custom-control-label label-class" htmlFor={id}>
      {label}
    </label>
  </div>
);

const propsTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

const Radio = ({ id, label, value, checked, onChange }) =>
  component(id, label, value, checked, onChange, "radio", "radio");
const CheckBox = ({ id, label, value, checked, onChange }) =>
  component(id, label, value, checked, onChange, "checkbox", "checkbox");
const Toggle = ({ id, label, value, checked, disable, onChange }) =>
  component(id, label, value, checked, onChange, "checkbox", "switch", disable);

Radio.propTypes = { ...propsTypes };
CheckBox.propTypes = { ...propsTypes };
Toggle.propTypes = { ...propsTypes };

export { Radio, CheckBox, Toggle };
