import { call, put, fork, all, takeLatest} from 'redux-saga/effects';
import { updateExceptionReport, updateLogReport} from '../../actions/Dashboard/analyticsActions';
import * as types from '../../constants/ActionTypes/analyticsTypes';
import { defaultAction} from '../../services/restService';
import { logger } from 'redux-logger';
export function* fetchExceptionReport(payload) {
  try {
    const exceptionReport = yield call(defaultAction, payload);
    yield put(updateExceptionReport(exceptionReport));
  } catch(e) {
    logger(e)
  }
}
export function* fetchLogReport(payload) {
  try {
    const logReport = yield call(defaultAction, payload);
    yield put(updateLogReport(logReport));
  } catch(e) {
    logger(e)
  }
}
export function* getExceptionReport() {
  yield takeLatest(types.GET_EXCEPTION_ANALYTICS, fetchExceptionReport);
}
export function* getLogReport() {
  yield takeLatest(types.GET_LOG_ANALYTICS, fetchLogReport);
}
export default function* root() {
  yield all([
    fork(getExceptionReport),
    fork(getLogReport)
  ])
}
