import * as types from '../../constants/ActionTypes/Admin/sapReportTypes';
import { constructServiceUrl } from '../../services/constructUrl';

export function fetchReportData() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_REPORT,
    request: requestParams
  };
}
export function updateData(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.UPDATE_REPORT,
    request: requestParams
  };
}
export function reportDataStore(data) {
  return {
    type: types.STORE_REPORT,
    data
  };
}
export function reportProcess() {
  return {
    type: types.PROCESS_REPORT
  }
}
export function deleteDetail(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_REPORT,
    request: requestParams
  };
}
export function addReport(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_REPORT,
    request: requestParams
  };
}
