import React, { useState } from "react";
import PropTypes from "prop-types";
import NewReplayConfig from "./newReplayConfig";
import CleTable from "../../../CommonComponents/CleTable/CleTable";
import CleFooter from "../../../CommonComponents/CleFooter/CleFooter";
import { COLUMNS } from "../../../../constants/ColumnConfig/Admin/replayConfigResults";
import DetailReplayExceptionConfig from "./detailReplayConfigModal";
import ConfirmationModalPopup from "../../../CommonComponents/Modal/ConfirmationModalPopup";
const pageStyle = {
    height: "calc(100vh - 220px)",
    width: "100%",
};
const ReplayConfig = ({ ...props }) => {
    const [gridApi, setGridApi] = useState();
    const [detailModalData, setDetailModalData] = useState();
    const [showDetailModal, setShowDetailModal] = useState();
    const [selectedRow, setSelectedRow] = useState([]);
    const [showUpsertModal, setShowUpsertModal] = useState(false);
    const [isModalEdit, setIsModalEdit] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const onSaveDataHandler = (payload, close) => {
        props.onSaveDetails(payload);
        if (close) {
            setShowUpsertModal(false);
            gridApi.deselectAll();
            setSelectedRow([]);
        }
    };
    const onDeleteHandler = () => {
        setShowConfirmModal(false);
        props.onConfReplayDelete({ replayID: selectedRow[0]["replayID"] });
        gridApi.deselectAll();
        setSelectedRow([]);
    };
    return (<>
        <div style={pageStyle} >
            <CleTable
                columnDefs={COLUMNS}
                rowData={props.replayConfigData ? props.replayConfigData : []}
                rowSelection='single'
                immutableData
                getRowNodeId={(data) => data.replayID}
                onRowDoubleClicked={(params) => {
                    setDetailModalData(params.data);
                    setShowDetailModal(true);
                }}
                onRowSelected={(params) => { setSelectedRow(params.api.getSelectedRows()); }}
                rowMultiSelectWithClick
                onGridReady={(params) => setGridApi(params.api)}
            />
        </div>
        <CleFooter
            add onAdd={() => setShowUpsertModal(true)}
            modify onModify={() => { setShowUpsertModal(true); setIsModalEdit(true); }}
            modifyDisabled={selectedRow.length === 0}
            detail onDetail={() => { setDetailModalData(gridApi.getSelectedRows()[0]); setShowDetailModal(true); }}
            deleteDisabled={selectedRow.length === 0}
            onDelete={() => setShowConfirmModal(true)}
            deleteBtn />
        {
            showUpsertModal &&
            <NewReplayConfig
                editFlag={isModalEdit}
                data={selectedRow[0]}
                onCloseModal={() => { setShowUpsertModal(false); setIsModalEdit(false); }}
                isModalOpen={showUpsertModal}
                saveData={(payload, close) => onSaveDataHandler(payload, close)}
            />
        }
        {
            showDetailModal &&
            <DetailReplayExceptionConfig
                isModalOpen={showDetailModal}
                data={detailModalData}
                onCloseModal={() => setShowDetailModal(false)}
            />
        }
        {
            showConfirmModal &&
            <ConfirmationModalPopup
                title="Are you sure you want to delete this item?"
                isModalOpen={showConfirmModal}
                onCloseModal={() => setShowConfirmModal(false)}
                onOkModal={() => onDeleteHandler()} />
        }
    </>);
};
ReplayConfig.propTypes = {
    replayConfigData: PropTypes.any,
    onConfReplayDelete: PropTypes.func,
    onSaveDetails: PropTypes.func,
};
export default ReplayConfig;