import React from "react";
import PropTypes from "prop-types";

const WarningModal = (props) => {
  return (
    <div>
      <div className="modal-header">
        <h5 className="modal-title">
          <i className="fa fa-warning modal_header_icon" />
          Attention
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={props.onCloseModal}
        >
          &times;
        </button>
      </div>
      <div className="modal-body">
        <div className="container-fluid">{props.data.msg}</div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger"
          id="sel_log_exception_record_close"
          data-dismiss="modal"
          onClick={props.onCloseModal}
        >
          <i className="fa fa-times" /> Close
        </button>
      </div>
    </div>
  );
};
WarningModal.propTypes = {
  onCloseModal: PropTypes.func,
  data: PropTypes.object,
};

export default WarningModal;
