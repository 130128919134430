import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { getUsersList, updateRoleUsersFunction, UserDataStore } from "../../../actions/Admin/userActions";
import UserFooter from '../UserFooter';
import { getUserDataByIds, getUsersNotInList } from '../../../utils/helpers';
import UserListComponent from '../UserList';
import CopyGroupModal from '../CopyGroupModal';
import { openLoader, closeLoader } from "../../../components/OverlayLoader/OverlayLoader";
import AxiosUrl from "../../../services/axiosInstanceCustom";
const GroupUserTab = ({ ...props }) => {
    const dispatch = useDispatch();
    const globalStateUserList = useSelector((state) => state.users.userResults.data);
    const [userList, setUserList] = useState([]);
    const [userListNotInGroup, setUserListNotInGroup] = useState([]);
    const [showAddUser, setShowAddUser] = useState(false);
    const [gridApi, setGridApi] = useState();
    const [addUserGridApi, setAddUserGridApi] = useState();
    const [userListSelectedRow, setUserListSelectedRow] = useState([]);
    const [addUserSelectedRow, setAddUserSelectedRow] = useState([]);
    const [selectedGroupUserIds, setSelectedGroupUserIds] = useState([]);
    const [showCopyGroup, setShowCopyGroup] = useState(false);
    const [targetGroup, setTargetGroup] = useState();

    useEffect(() => {
        if (globalStateUserList.length === 0) {
            dispatch(getUsersList());
        }
    }, []);

    useEffect(() => {
        if (props.userIdList.length === 0) {
            setTimeout(() => {
                if (gridApi) {
                    gridApi.showLoadingOverlay();
                }
            });
        }
    }, []);
    useEffect(() => {
        setUserList(getUserDataByIds(globalStateUserList, props.userIdList))
    }, [globalStateUserList]);
    useEffect(() => {
        setSelectedGroupUserIds(props.userIdList ? props.userIdList : [])
        setUserList(getUserDataByIds(globalStateUserList, props.userIdList))
        if (showAddUser) {
            loadAddUserData(props.userIdList ? props.userIdList : []);
        }
    }, [props.selectedGroup]);

    const loadAddUserData = (usersIdsList) => {
        setUserListNotInGroup(getUsersNotInList(globalStateUserList, usersIdsList))
        setShowAddUser(true);
    }

    const onAddUserSaveHandler = () => {
        let newUserIds = addUserSelectedRow.map(userObj => userObj["userID"]);
        let updatedUserIds = [...selectedGroupUserIds, ...newUserIds];
        let payload = {
            ...props.selectedGroup,
            "userList": updatedUserIds
        }
        setSelectedGroupUserIds(updatedUserIds);
        dispatch(updateRoleUsersFunction(payload));
        loadAddUserData(updatedUserIds);
        setUserList(getUserDataByIds(globalStateUserList, updatedUserIds));
    }
    const onRemoveUserHandler = () => {
        let userIdsToBeRemoved = userListSelectedRow.map(userObj => userObj["userID"]);
        let updatedUserIds = selectedGroupUserIds.filter(userId => !userIdsToBeRemoved.includes(userId));
        let payload = {
            ...props.selectedGroup,
            "userList": updatedUserIds
        }

        dispatch(updateRoleUsersFunction(payload));
        setUserList(getUserDataByIds(globalStateUserList, updatedUserIds));
    }
    const onSaveCopyGroupHandler = () => {
        let axiosUrl = AxiosUrl.adminDataInstance;
        let url = `/group/duplicate/${props.selectedGroup.groupName}/${targetGroup}/`;
        openLoader();
        setShowCopyGroup(false);
        axiosUrl
            .put(url, {})
            .then((resp) => {
                const rep = {
                    response: resp.data,
                    action: "UPDATE",
                    page: "roleUserResults",
                    id: "groupName",
                };
                dispatch(UserDataStore(rep))
                closeLoader();
                toast.success("Group access copied successfully");
                gridApi.deselectAll();
                setTargetGroup(null);
            })
            .catch(() => {
                closeLoader();
                gridApi.deselectAll();
                setTargetGroup(null);
                toast.error("Group access copy Failed!");
            });
    }

    return (<>
        {
            !showAddUser &&
            <>
                <div>
                    <UserListComponent
                        inlineStyle
                        userListData={userList}
                        setGridApi={setGridApi}
                        setSelectedRow={setUserListSelectedRow} />
                </div>
                <UserFooter
                    copyGroup onCopyGroup={() => setShowCopyGroup(true)}
                    createGroup onCreateGroup={()=>props.onCreateGroupHandler()}
                    adduser onAddUser={() => loadAddUserData(selectedGroupUserIds)}
                    removeUser onRemoveUser={() => onRemoveUserHandler()}
                    disableRemoveUser={userListSelectedRow.length === 0}
                    reset handleReset={() => { setUserListSelectedRow([]); gridApi.deselectAll() }} />
            </>
        }
        {
            showAddUser &&
            <>
                <div>
                    <UserListComponent
                        inlineStyle
                        userListData={userListNotInGroup}
                        setGridApi={setAddUserGridApi}
                        setSelectedRow={setAddUserSelectedRow}
                    />
                </div>
                <UserFooter
                    save saveDisabled={addUserSelectedRow.length === 0}
                    handleSave={() => onAddUserSaveHandler()}
                    reset handleReset={() => { setAddUserSelectedRow([]); addUserGridApi.deselectAll() }}
                    showCancel onCancel={() => setShowAddUser(false)} />
            </>
        }
        {
            showCopyGroup &&
            <CopyGroupModal
                isModalOpen={showCopyGroup}
                sourceGroup={props.selectedGroup}
                onCloseModal={() => setShowCopyGroup(false)}
                onSaveData={() => onSaveCopyGroupHandler()}
                groupOptions={props.groupList.filter(groupObj => groupObj["groupName"] !== props.selectedGroup["groupName"])}
                onTargetGroupSelectHandler={(val) => setTargetGroup(val)}
            />
        }
    </>);
}
GroupUserTab.propTypes = {
    userIdList: PropTypes.array,
    selectedGroup: PropTypes.object,
    groupList: PropTypes.array,
    onCreateGroupHandler: PropTypes.any
};
export default GroupUserTab;