import { call, fork, put, all, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/Admin/userActions";
import * as types from "../../constants/ActionTypes/Admin/userTypes";
import { defaultAction } from "../../services/restService";
import { toast } from "react-toastify";
import { logger } from "redux-logger";

export function* fetchUsersList(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
      page: "userResults",
      id: "userID",
    };

    yield put(actions.UserDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* getUsersList() {
  yield takeLatest(types.GET_USERS_LIST, fetchUsersList);
}

export function* saveUserDetails(payload) {
  try {
    yield put(actions.userApi(null));
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "ADD",
      page: "userResults",
      id: "userID",
    };
    toast.success("User is added successfully!");
    yield put(actions.userApi("completed"));
    yield put(actions.UserDataStore(rep));
    yield put(actions.getRolesApplicationList());
    yield put(actions.getRoleUsersList());
  } catch (e) {
    logger(e);
  }
}
export function* addUserFunction() {
  yield takeLatest(types.ADD_USER, saveUserDetails);
}

export function* saveEditedUserDetails(payload) {
  try {
    yield put(actions.userApi(null));
    const response = yield call(defaultAction, payload);
    const rep = {
      response: response,
      action: "UPDATE",
      page: "userResults",
      id: "userID",
    };
    yield put(actions.userApi("completed"));
    toast.success("User is successfully Updated!");
    yield put(actions.UserDataStore(rep));
    yield put(actions.getRolesApplicationList());
    yield put(actions.getRoleUsersList());
  } catch (e) {
    logger(e);
  }
}
export function* editUserFunction() {
  yield takeLatest(types.EDIT_USER, saveEditedUserDetails);
}

export function* deleteUserDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "DELETE",
      page: "userResults",
      id: "userID",
    };
    yield put(actions.UserDataStore(rep));
    toast.success("User is deleted successfully!");
  } catch (e) {
    logger(e);
  }
}
export function* deleteUser() {
  yield takeLatest(types.DELETE_USER, deleteUserDetails);
}

//roles//

export function* fetchRolesList(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
      page: "roleResults",
      id: "roleID",
    };
    yield put(actions.UserDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* getRolesList() {
  yield takeLatest(types.GET_ROLES_LIST, fetchRolesList);
}
export function* deleteRolesDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "DELETE",
      page: "roleResults",
      id: "roleID",
    };
    yield put(actions.UserDataStore(rep));
    toast.success("Role is deleted successfully!");
  } catch (e) {
    logger(e);
  }
}
export function* deleteRoles() {
  yield takeLatest(types.DELETE_ROLE, deleteRolesDetails);
}

export function* saveRoleDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "ADD",
      page: "roleResults",
      id: "roleID",
    };
    yield put(actions.UserDataStore(rep));
    toast.success("Role is added successfully!");
  } catch (e) {
    logger(e);
  }
}
export function* addRoleFunction() {
  yield takeLatest(types.ADD_ROLE, saveRoleDetails);
}

export function* saveEditedRoleDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "UPDATE",
      page: "roleResults",
      id: "roleID",
    };
    toast.success("Role is successfully Updated!");
    yield put(actions.UserDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* editRoleFunction() {
  yield takeLatest(types.EDIT_ROLE, saveEditedRoleDetails);
}
export function* fetchRoleUsersList(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
      page: "roleUserResults",
      id: "roleID",
    };
    yield put(actions.UserDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* getRoleUsersList() {
  yield takeLatest(types.GET_ROLE_USERS_LIST, fetchRoleUsersList);
}

//APPLicationuser//

export function* fetchRolesApplicationList(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
      page: "roleApplicationResults",
      id: "roleID",
    };

    yield put(actions.UserDataStore(rep));
  } catch (e) {
    logger(e);
  }
}
export function* saveRolesApplicationList(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "ADD",
      page: "roleApplicationResults",
      id: "groupAccessId",
    };
    yield put(actions.UserDataStore(rep));
    yield put(actions.getUsersList());
    toast.success("Role application added successfully");
  } catch (e) {
    logger(e);
  }
}

export function* updateRolesApplicationList(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "MULTI_UPDATE",
      page: "roleApplicationResults",
      id: "groupAccessId",
    };
    yield put(actions.UserDataStore(rep));
    yield put(actions.getUsersList());
    toast.success("Role application updated successfully");
  } catch (e) {
    logger(e);
  }
}

export function* deleteRolesApplicationList(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "DELETE",
      page: "roleApplicationResults",
      id: "groupAccessId",
    };
    yield put(actions.UserDataStore(rep));
    yield put(actions.getUsersList());
    toast.success("Role application deleted successfully");
  } catch (e) {
    logger(e);
  }
}

export function* getRoleApplicationList() {
  yield takeLatest(types.GET_ROLES_APPLICATION_LIST, fetchRolesApplicationList);
}
export function* saveRolesApplicationData() {
  yield takeLatest(types.ADD_ROLES_APPLICATION_LIST, saveRolesApplicationList);
}
export function* updateRolesApplicationData() {
  yield takeLatest(types.UPDATE_ROLES_APPLICATION_LIST, updateRolesApplicationList);
}
export function* deleteRolesApplicationData() {
  yield takeLatest(types.DELETE_ROLES_APPLICATION_LIST, deleteRolesApplicationList);
}

// export function* getApplicationRolesList() {}

//roleUser//
export function* saveRoleUserDetails(payload) {
  try {
    const { methodName } = payload.request;
    let response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: methodName === 'POST' ? "ADD" : "UPDATE",
      page: "roleUserResults",
      id: "roleID",
    };

    yield put(actions.UserDataStore(rep));
    // yield put(actions.getRoleUsersList());
    // yield put(actions.getUsersList());
    toast.success(`User group ${methodName === 'POST' ? 'added' : 'updated'} successfully`);
  } catch (e) {
    logger(e);
  }
}
export function* addRoleUsersFunction() {
  yield takeLatest(types.ADD_ROLE_USER, saveRoleUserDetails);
}
export function* deleteRoleUserDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "DELETE",
      page: "roleUserResults",
      id: "roleID",
    };
    yield put(actions.UserDataStore(rep));
    yield put(actions.getRoleUsersList());
    yield put(actions.getUsersList());
    toast.success("User role deleted successfully");
  } catch (e) {
    logger(e);
  }
}

export function* deleteRoleUsersFunction() {
  yield takeLatest(types.DELETE_ROLE_USER, deleteRoleUserDetails);
}

export default function* root() {
  yield all([
    fork(getUsersList),
    fork(addUserFunction),
    fork(editUserFunction),
    fork(deleteUser),
    fork(getRolesList),
    fork(deleteRoles),
    fork(addRoleFunction),
    fork(editRoleFunction),
    fork(getRoleUsersList),
    fork(addRoleUsersFunction),
    fork(deleteRoleUsersFunction),
    fork(getRoleApplicationList),
    fork(saveRolesApplicationData),
    fork(updateRolesApplicationData),
    fork(deleteRolesApplicationData)
  ]);
}
