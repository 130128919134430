import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import * as actions from '../../actions/Admin/logStatusActions';
import * as types from '../../constants/ActionTypes/Admin/logStatusTypes';
import { defaultAction } from '../../services/restService';
import { toast } from 'react-toastify';
import { createLogger } from 'redux-logger';
export function* getReport(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      "action": "NEW",
      "page": "logConfigData",
      "id": "logConfigId"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e)
  }
}
export function* fetchReport() {
  yield takeLatest(types.GET_LOG_CONFIG_DETAILS, getReport);
}

export function* deleteReport(payload) {
  try {
    yield put(actions.reportProcess())
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been deleted successfully")
    const rep = {
      response,
      "action": "DELETE",
      "page": "logConfigData",
      "id": "logConfigId"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e)
  }
}
export function* deleteReportData() {
  yield takeLatest(types.DELETE_LOG_DETAIL, deleteReport)
}

export function* saveReportData(payload) {
  try {
    yield put(actions.reportProcess());
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been saved successfully")
    const rep = {
      response,
      "action": "ADD",
      "page": "logConfigData",
      "id": "logConfigId"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e)
  }
}
export function* addReportData() {
  yield takeLatest(types.ADD_LOG_DETAIL, saveReportData)
}

export function* ModifyReportData(payload) {
  try {
    yield put(actions.reportProcess())
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been updated successfully")
    const rep = {
      response,
      "action": "UPDATE",
      "page": "logConfigData",
      "id": "logConfigId"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e)
  }
}
export function* modifyReport() {
  yield takeLatest(types.EDIT_LOG_DETAIL, ModifyReportData)
}

/* Exception */

export function* getExceptionReport(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      "action": "NEW",
      "page": "exceptionReportData",
      "id": "configID"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e)
  }
}
export function* fetchExceptionReport() {
  yield takeLatest(types.GET_EXCEPTION_REPORT_DETAILS, getExceptionReport);
}

export function* deleteExceptionReport(payload) {
  try {
    yield put(actions.reportProcess())
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been deleted successfully")
    const rep = {
      response,
      "action": "DELETE",
      "page": "exceptionReportData",
      "id": "configID"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e)
  }
}
export function* deleteExceptionReportData() {
  yield takeLatest(types.DELETE_EXCEPTION_DETAIL, deleteExceptionReport)
}

export function* saveExceptionReportData(payload) {
  try {
    yield put(actions.reportProcess());
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been saved successfully")
    const rep = {
      response,
      "action": "ADD",
      "page": "exceptionReportData",
      "id": "configID"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e)
  }
}
export function* addExceptionReportData() {
  yield takeLatest(types.ADD_EXCEPTION_DETAIL, saveExceptionReportData)
}

export function* ModifyExceptionReportData(payload) {
  try {
    yield put(actions.reportProcess())
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been updated successfully")
    const rep = {
      response,
      "action": "UPDATE",
      "page": "exceptionReportData",
      "id": "configID"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e)
  }
}
export function* modifyExceptionReport() {
  yield takeLatest(types.EDIT_EXCEPTION_DETAIL, ModifyExceptionReportData)
}

export default function* root() {
  yield all([
    fork(fetchReport),
    fork(deleteReportData),
    fork(addReportData),
    fork(modifyReport),
    fork(fetchExceptionReport),
    fork(deleteExceptionReportData),
    fork(addExceptionReportData),
    fork(modifyExceptionReport)])
}
