export const formatData = (data, pervState, index) => {
  let availData = pervState;
  if (data) {
    switch (data.action) {
      case "NEW":
        return data.response;
      case "ADD": {
        availData.push(data.response);
        return availData;
      }
      case "UPDATE": {
        availData[index] = data.response;
        return availData;
      }
      case "DELETE": {
        //   if(data && data.response.length > 0)
        //   data.response.forEach(val => {
        //     let index = -1;
        //     if (isMultiple) index = getIndexMultiple(availData, val, id, secId);
        //     else index = getIndex(availData, val, id);
        //     if (index > -1) {
        //       availData.splice(index, 1);
        //     }
        //   });
        availData.splice(index, 1);
        return availData;
      }
      default:
        return availData;
    }
  }
  return [];
};
