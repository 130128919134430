import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CleTabs from "../../../components/CommonComponents/CleTabs/CleTabs";
import SelectDropDown from '../../../components/CommonComponents/SelectDropDown';
import { applicationDataInstance } from '../../../services/applicationDataService';
import { getRoleUsersList, getRolesApplicationList } from "../../../actions/Admin/userActions";
import * as applicationActions from '../../../actions/Admin/applicationConfigActions';
import ApplicationUserTab from './ApplicationUserTab';
import ApplicationGroupTab from './ApplicationGroupTab';
import "../User.scss";

const ApplicationAccess = () => {
    
    const dispatch = useDispatch();
    const globalUserState = useSelector((state) => state.users);
    const applicationList = useSelector((state) => state.applications.applicationData);
    const applicationGroupList = globalUserState?.roleApplicationResults?.data;
    const groupNameOptions = globalUserState?.roleUserResults?.data;
    const applicationNames = applicationDataInstance.getApplicationNames(applicationList);
    
    const [activeTab, setActiveTab] = useState();
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [selectedApplicationGroupList, setSelectedApplicationGroupList] = useState([]);

    useEffect(() => {
        if(applicationList.length===0){
            dispatch(applicationActions.getApplicationDetails());
        }
        if (globalUserState?.roleApplicationResults?.data?.length === 0) {
            dispatch(getRolesApplicationList())
        }
        if (globalUserState?.roleUserResults?.data?.length === 0) {
            dispatch(getRoleUsersList());
        }
    }, [])
    useEffect(() => {
        if (selectedApplication) {
            let GroupAccessList = applicationGroupList.filter(accessObj => accessObj["applicationName"] === selectedApplication)
            setSelectedApplicationGroupList(GroupAccessList);
        }
        onApplicationChangeHandler(selectedApplication);
    }, [applicationGroupList]);

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }
    const onApplicationChangeHandler = (selectedValue) => {
        setSelectedApplication(selectedValue);
        let GroupAccessList = applicationGroupList.filter(accessObj => accessObj["applicationName"] === selectedValue)
        setSelectedApplicationGroupList(GroupAccessList);
    }
    return (
        <CleTabs
            defaultActiveTab="applicationGroup"
            activeTab={activeTab}
            onTabChangeHandler={(tabName) => handleTabChange(tabName)}
            tabClassName="group-access-tab-with-controls"
            tabItems={[
                {
                    key: 'applicationGroup',
                    label: 'Application Group',
                    children: <>
                        {
                            selectedApplication &&
                            <ApplicationGroupTab
                                selectedApplication={selectedApplication}
                                initialGroupAccessList={selectedApplicationGroupList}
                                groupNameOptionsList={groupNameOptions ? groupNameOptions : []}
                            />
                        }
                        {
                            !selectedApplication &&
                            <div className="no_role_select">
                                Please select application..
                            </div>
                        }
                    </>
                },
                {
                    key: 'UserGroup',
                    label: 'User Group',
                    children: <>
                        {
                            selectedApplication &&
                            <ApplicationUserTab
                                selectedApplication={selectedApplication}
                                selectedApplicationGroupList={selectedApplicationGroupList} />
                        }
                        {
                            !selectedApplication &&
                            <div className="no_role_select">
                                Please select application..
                            </div>
                        }
                    </>
                },
            ]}
            tabExtraControlContent={(
                <div>
                    <SelectDropDown
                        className="cle-select"
                        options={applicationNames ? applicationNames : []}
                        placeholder={"Select Application"}
                        autoFocus
                        value={selectedApplication ? selectedApplication : null}
                        onChange={(val) => onApplicationChangeHandler(val)}
                    />
                </div>)}
        />
    );
}

export default ApplicationAccess;