import moment from "moment";
import { default as momentZone } from "moment-timezone";

const fieldHead = {
    field: "",
    headerName: "",
    width: 60,
    maxWidth: 60,
    headerCheckboxSelection: false,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    sortable: false,
    pinned: "left",
    filter: false,
    suppressMenu: true,
    lockPosition: 'left',
    lockPinned: true,
};
const COLUMNS = [
    fieldHead,
    {
        field: "userID",
        headerName: "User ID",
        filter: false,
        suppressMenu: true,
    },
    {
        field: "firstName",
        headerName: "Firstname",
        filter: false,
        suppressMenu: true,
    },
    {
        field: "lastName",
        headerName: "Lastname",
        filter: false,
        suppressMenu: true,
    },
    {
        field: "email",
        headerName: "Email",
        filter: false,
        suppressMenu: true,
    },
    {
        field: "lastLogin",
        headerName: "Last Login",
        filter: "agDateColumnFilter",
        cellRenderer: function dateValueGettter(params) {
            if (params.value === null) return "";
            if (params?.context?.timeZone)
                return momentZone(params.value)
                    .tz(window.timeZone)
                    .format("YYYY-MM-DD HH:mm:ss.SSS");
            return moment(params.value).local().format("YYYY-MM-DD HH:mm:ss.SSS");
        },
    },
];

export default COLUMNS;