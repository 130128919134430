import React from "react";
import PropTypes from "prop-types";
import { Row, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
const overLayLoaderStyle = {
  opacity: "0.5",
  pointerEvents: "none",
};

const ModalFooter = ({
  onUpdate,
  onSave,
  onSaveClose,
  onClose,
  update = false,
  save = false,
  saveClose = false,
  close = false,
  reset = false,
  onReset,
  loading = false,
  resolve = false,
  filter = false,
  defilter = false,
  purge = false,
  onFilter,
  onDefilter,
  onResolve,
  onPurge,
}) => {
  return (
    <Row justify={"end"} style={loading && overLayLoaderStyle}>
      {resolve && (
        <Button
          type="primary"
          size={"default"}
          id="sel_detail_exception_modal_resolve"
          className="secondary mr10"
          icon={<i className="fas fa-check-double mr10" aria-hidden="true" />}
          onClick={onResolve}
        >
          Resolve
        </Button>
      )}
      {filter && (
        <Button
          type="primary"
          size={"default"}
          className={"mr10"}
          icon={<i className="fas fa-filter mr10" aria-hidden="true" />}
          onClick={onFilter}
        >
          Filter
        </Button>
      )}
      {defilter && (
        <Button
          type="primary"
          size={"default"}
          className={"mid-black mr10"}
          icon={<i className="fas fa-undo-alt mr10" aria-hidden="true" />}
          onClick={onDefilter}
        >
          De-Filter
        </Button>
      )}
      {purge && (
        <Button
          type="primary"
          size={"default"}
          danger
          className={"mr10"}
          onClick={onPurge}
          icon={<i className="fa fa-trash mr10" aria-hidden="true" />}
        >
          Purge
        </Button>
      )}
      {reset && (
        <Button
          type="primary"
          size={"default"}
          className="blackBtn"
          icon={<i className="fas fa-undo-alt mr10" aria-hidden="true" />}
          onClick={onReset}
        >
          Reset
        </Button>
      )}
      {update && (
        <Button
          type="primary"
          size={"default"}
          className="secondary"
          icon={<i className="fas fa-pencil-alt mr10" />}
          onClick={onUpdate}
        >
          Update
        </Button>
      )}
      {save && (
        <Button
          type="primary"
          size={"default"}
          className="secondary"
          onClick={onSave}
          icon={<i className="fas fa-save mr10" />}
        >
          Save
        </Button>
      )}
      {saveClose && (
        <Button
          type="primary"
          size={"default"}
          onClick={onSaveClose}
          icon={<i className="fas fa-save mr10" />}
        >
          Save & Close
        </Button>
      )}
      {close && (
        <Button
          type="primary"
          danger
          onClick={onClose}
          id="sel_details_close_btn"
          className="d-flex align-items-center"
          icon={<CloseOutlined />}
          size={"default"}
        >
          Close
        </Button>
      )}
    </Row>
  );
};

ModalFooter.propTypes = {
  reset: PropTypes.bool,
  save: PropTypes.bool,
  saveClose: PropTypes.bool,
  update: PropTypes.bool,
  close: PropTypes.bool,
  filter: PropTypes.bool,
  defilter: PropTypes.bool,
  loading: PropTypes.bool,
  resolve: PropTypes.bool,
  onClose: PropTypes.func,
  onPurge: PropTypes.func,
  onReset: PropTypes.func,
  onResolve: PropTypes.func,
  onFilter: PropTypes.func,
  onDefilter: PropTypes.func,
  purge: PropTypes.bool,
  onSave: PropTypes.func,
  onSaveClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ModalFooter;
