import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import CleTable from "../../../CommonComponents/CleTable/CleTable";
import CleFooter from "../../../CommonComponents/CleFooter/CleFooter";
import NewApplication from "./newApplication";
import { COLUMNS } from "../../../../constants/ColumnConfig/Admin/applicationResults";
import { checkIfExistsInArray } from "../../../../utils/helpers";
import ConfirmationModalPopup from "../../../CommonComponents/Modal/ConfirmationModalPopup";
const pageStyle = {
  height: "calc(100vh - 200px)",
  width: "100%",
};
const Applications = ({ ...props }) => {
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalData, setModalData] = useState({ selectedRow: [], logTable: {} });
  const [selectedRow, setSelectedRow] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [isModalEdit, setIsModalEdit] = useState(false);

  const formTable = () => {
    return props.getLogAndExceptionTable.map((item) => ({
      sector: item.sector,
      table: item.dataType,
      value: item.tableName,
      label: item.tableName,
      retentionDays: item.retentionDays,
      topic: item.topicName,
    }));
  };

  const showUpsertModalHandler = (editFlag) => {
    if (!editFlag)
      setModalData({ ...modalData, logTable: formTable(), selectedRow: [] });

    if (editFlag)
      setModalData({
        ...modalData,
        logTable: formTable(),
        selectedRow: selectedRow,
      });
    setShowUpsertModal(true);
  };

  const onCloseModalHandler = () => {
    setShowUpsertModal(false);
    setIsModalEdit(false);
    gridApi.deselectAll();
    setSelectedRow([]);
  };

  const saveApplicationDataHandler = (applicationDetails, close) => {
    // console.log('applicationDetails', applicationDetails, modalData);
    if (close) {
      setShowUpsertModal(false);
      gridApi.deselectAll();
      setSelectedRow([]);
    }
    if (isModalEdit) {
      props.saveApplicationDetails(applicationDetails, true);
      setIsModalEdit(false);
    } else if (
      !checkIfExistsInArray(
        props.applicationData,
        applicationDetails,
        "applicationName"
      )
    ) {
      props.saveApplicationDetails(applicationDetails);
    } else {
      toast.error(`Application already exist!`);
    }
  };

  const handleApplicationDelete = () => {
    props.onApplicationDelete({
      applicationName: selectedRow[0]["applicationName"],
    });
    gridApi.deselectAll();
    setShowConfirmModal(false);
    setSelectedRow([]);
  };

  return (
    <>
      <div style={pageStyle}>
        <CleTable
          immutableData
          getRowNodeId={(data) => data.applicationName}
          columnDefs={COLUMNS}
          rowData={props.applicationData ? props.applicationData : []}
          onRowClicked={(params) => {
            params.node.isSelected()
              ? params.node.setSelected(false)
              : params.node.setSelected(true);
            setSelectedRow(params.api.getSelectedRows());
          }}
          onRowSelected={(params) => {
            setSelectedRow(params.api.getSelectedRows());
          }}
          onGridReady={(params) => setGridApi(params.api)}
        />
        <CleFooter
          add
          onAdd={() => {
            showUpsertModalHandler(false);
            setIsModalEdit(false);
          }}
          modify
          onModify={() => {
            showUpsertModalHandler(true);
            setIsModalEdit(true);
          }}
          modifyDisabled={selectedRow.length === 0}
          deleteBtn
          onDelete={() => setShowConfirmModal(true)}
          deleteDisabled={selectedRow.length === 0}
        />
      </div>
      {showUpsertModal && (
        <NewApplication
          editFlag={
            modalData &&
            modalData.selectedRow &&
            modalData.selectedRow.length > 0
          }
          applicationSectorList={props.applicationSectorList}
          data={modalData}
          isModalOpen={showUpsertModal}
          onCloseModal={onCloseModalHandler}
          saveData={(appData, close) =>
            saveApplicationDataHandler(appData, close)
          }
        />
      )}
      {showConfirmModal && (
        <ConfirmationModalPopup
          title="Are you sure you want to delete this item?"
          isModalOpen={showConfirmModal}
          onCloseModal={() => setShowConfirmModal(false)}
          onOkModal={handleApplicationDelete}
        />
      )}
    </>
  );
};

Applications.propTypes = {
  applicationData: PropTypes.any,
  applicationSectorList: PropTypes.array,
  saveApplicationDetails: PropTypes.func,
  onApplicationDelete: PropTypes.func,
  getLogAndExceptionTable: PropTypes.any,
};

export default Applications;