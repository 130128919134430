export const GET_EXCEPTION_CONFIG_DETAILS= 'GET_EXCEPTION_CONFIG_DETAILS';
export const GET_EXCEPTION_CONFIG= 'GET_EXCEPTION_CONFIG';
export const DELETE_CONFIGURE_EXP='DELETE_CONFIGURE_EXP';
export const DELETE_EXP_CONFIG='DELETE_EXP_CONFIG';

export const ADD_CONFIG_EXP='ADD_CONFIG_EXP';
export const EDIT_CONFIG_EXP = 'EDIT_CONFIG_EXP';
export const CONFIG_EXCEP_ADD_RESULTS = 'CONFIG_EXCEP_ADD_RESULTS';
export const CONFIG_EXCEP_EDIT_RESULTS = 'CONFIG_EXCEP_EDIT_RESULTS';

export const GET_REPLAY_CONFIG_DETAILS= 'GET_REPLAY_CONFIG_DETAILS';
export const GET_REPLAY_CONFIG= 'GET_REPLAY_CONFIG';
export const ADD_REPLAY_CONFIG_EXP='ADD_REPLAY_CONFIG_EXP';
export const EDIT_REPLAY_CONFIG_EXP = 'EDIT_REPLAY_CONFIG_EXP';
export const CONFIG_REPLAY_ADD_RESULTS = 'CONFIG_REPLAY_ADD_RESULTS';
export const CONFIG_REPLAY_EDIT_RESULTS = 'CONFIG_REPLAY_EDIT_RESULTS';
export const DELETE_CONFIGURE_REPLAY = 'DELETE_CONFIGURE_REPLAY';
export const DELETE_REPLAY_CONFIG='DELETE_REPLAY_CONFIG';


export const STORE_EXCEPTION_CONFIG_REPORT="STORE_EXCEPTION_CONFIG_REPORT";
export const PROGRESS_EXCEPTION_CONFIG_REPORT="PROGRESS_EXCEPTION_CONFIG_REPORT";