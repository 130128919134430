import * as types from '../../constants/ActionTypes/Admin/applicationConfigTypes';
import objectAssign from 'object-assign';
import { formatData } from '../../utils/constantReducer';

const initialState = {
  applicationData: [],
  applicationSectorList: [],
  logExceptionTable: [],
  detectAction: null,
  isApplicationLoading: true,
  isListloading: true
};


export default function applicationsConfigReducer(state = initialState, action) {

  switch (action.type) {
    case types.STORE_APPLICATION_REPORT:
      return objectAssign({}, state, {
        applicationData: formatData(action.data, [...state.applicationData], action.data.id),
        isApplicationLoading: false
      });
    case types.LOG_EXCEPTION_TABLE_RESULTS:
      return objectAssign({}, state, {
        logExceptionTable: action.tableData,
        isListloading: false
      });
    case types.SET_APPLICATION_SECTOR_LIST:
      return objectAssign({}, state, {
        applicationSectorList: action.payload,
        isListloading: false
      });
    default:
      return state;
  }
}