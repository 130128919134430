import * as types from "../../constants/ActionTypes/Admin/blobMetrics";
import { constructServiceUrl } from "../../services/constructUrl";

export const getAllLogBlobMetrics = (dateRange) => {
  let serviceParams = {};
  serviceParams.apiName = types.FETCH_BLOB_LOG_ALL_METRICS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.FETCH_BLOB_LOG_ALL_METRICS,
    request: {
      ...requestParams,
      url: `${requestParams.url}${dateRange}`
    }
  };
};
export const getAllExceptionBlobMetrics = (dateRange) => {
  let serviceParams = {};
  serviceParams.apiName = types.FETCH_BLOB_EXCEPTION_ALL_METRICS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.FETCH_BLOB_EXCEPTION_ALL_METRICS,
    request: {
      ...requestParams,
      url: `${requestParams.url}${dateRange}`
    }
  };
};

export const getDetailLogBlobMetrics = (payload) => {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.FETCH_BLOB_LOG_METRICS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.FETCH_BLOB_LOG_METRICS,
    request: requestParams,
  };
};
export const getDetailExceptionBlobMetrics = (payload) => {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.FETCH_BLOB_EXCEPTION_METRICS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.FETCH_BLOB_EXCEPTION_METRICS,
    request: requestParams,
  };
};

export const resetValue = () => {
  return {
    type: types.BLOB_RESET_DATA,
  };
};

export const allBlobLogSuccess = (response) => {
  return {
    type: types.FETCH_BLOB_LOG_ALL_METRICS_SUCCESS,
    response,
  };
};
export const allBlobExceptionSuccess = (response) => {
  return {
    type: types.FETCH_BLOB_EXCEPTION_ALL_METRICS_SUCCESS,
    response,
  };
};

export const detailBlobLogSuccess = (response) => {
  return {
    type: types.FETCH_BLOB_LOG_METRICS_SUCCESS,
    response,
  };
};
export const detailBlobExceptionSuccess = (response) => {
  return {
    type: types.FETCH_BLOB_EXCEPTION_METRICS_SUCCESS,
    response,
  };
};

export const allBlobLogError = (error) => {
  return {
    type: types.FETCH_BLOB_LOG_ALL_METRICS_ERROR,
    error,
  };
};
export const allBlobExceptionError = (error) => {
  return {
    type: types.FETCH_BLOB_EXCEPTION_ALL_METRICS_ERROR,
    error,
  };
};

export const detailBlobLogError = (error) => {
  return {
    type: types.FETCH_BLOB_LOG_METRICS_ERROR,
    error,
  };
};
export const detailBlobExceptionError = (error) => {
  return {
    type: types.FETCH_BLOB_EXCEPTION_METRICS_ERROR,
    error,
  };
};
