/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { default as momentZone } from "moment-timezone";
import _ from "lodash";
import { isCancel, CancelToken } from "axios";
import { Modal } from "antd";
import LoadingBar from "react-top-loading-bar";
import axiosRetry from "axios-retry";

import axiosApi, { outApiCheck } from "../../services/axiosInstance";
import AxiosUrl from "../../services/axiosInstanceCustom";
import SearchForm from "../../components/SearchForm/SearchForm";
import WrapperPage from "../../components/WrapperPage/Page";
import { applicationDataInstance } from "../../services/applicationDataService";
import * as applicationActions from '../../actions/Admin/applicationConfigActions';
import * as configurationActions from '../../actions/Admin/configurationActions';
import { findMaxValueApplication, getTokenToReqHeader } from "../../utils/helpers";
import CommonTable from "../CommonTable/CommonTable";
import SelectFieldForExportModal from "../../components/CommonComponents/Modal/SelectFieldForExportModal";
import { errorNotification, successNotification } from "../../components/CommonComponents/Notification";
import { getLogExceptionCount } from "../../utils/logExceptionUtils";
import SectorDetailWarningModal from './SectorDetailWarningModal';

moment.suppressDeprecationWarnings = true;
let cancelToken = CancelToken.source();
let cancelCountToken;
const API_RECALL_TIME = 5000;

const CommonSearch = ({ ...props }) => {
  const dispatch = useDispatch();
  const progressBarRef = useRef(null);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showNoRecordModal, setShowNoRecordModal] = useState(false);
  const [showSelectColModal, setShowSelectColModal] = useState(false);
  const [isDownloadInProgress, setDownloadInProgress] = useState(false);
  const [disabledFooter, setDisableFooter] = useState(false);
  const [applicationOptions, setApplicationOptions] = useState([]);
  const [formRef, setFormRef] = useState();
  const [initialFormData, setInitialFormData] = useState();
  const [apiPayload, setApiPayload] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [appNameWithoutSectorData, setAppNameWithoutSectorData] = useState([]);

  useEffect(() => {
    //need to remove from inside the component
    let retries = 0;
    axiosRetry(axiosApi, {
      retries: 10,
      retryDelay: (retryCount) => retryCount * 1000,
      retryCondition: (error) => {
        retries = retries + 1;
        if (retries >= 10) {
          retries = 0;
          return false;
        }
        return (
          axiosRetry.isNetworkOrIdempotentRequestError(error) ||
          error.code === "ECONNABORTED" ||
          error.code === "ENOTFOUND" ||
          error.response.status === 400
        );
      },
    });
  }, []);


  useEffect(() => {
    if (props.applications.length === 0) {
      props.getApplicationDetails();
    }
    //loading application options 
    let tempApplicationNames = [];
    if (props.userAccessInfo.adminAccess === "Y") {
      tempApplicationNames = applicationDataInstance.getApplicationNames(props.applications);
    } else {
      tempApplicationNames =
        applicationDataInstance.getApplicationNamesForUser(
          props.userAccessInfo, props.applications
        );
    }
    setApplicationOptions(tempApplicationNames);
    loadInitialFormData();
  }, [props.applications]);

  useEffect(() => {
    loadInitialFormData();
  }, [applicationOptions]);

  useEffect(() => {
    if (formRef && formRef.getFieldValue("startTime") === undefined && formRef.getFieldValue("endTime") === undefined) {
      formRef.setFieldsValue({
        startTime: moment(formatAccordingToTimeZone(moment().subtract(4, "hours"))).format("MM/DD/YYYY HH:mm:ss").toString(),
        endTime: moment(formatAccordingToTimeZone(moment())).format("MM/DD/YYYY HH:mm:ss").toString(),
      });
    }
    loadInitialFormData();
  }, [formRef]);

  useEffect(() => {
    if (initialFormData && formRef) {
      formRef.setFieldsValue({ ...initialFormData });
    }
  }, [props.match.params.screenName]);

  const formatAccordingToTimeZone = (orgtime) => {
    if (props.timeZone) {
      return momentZone.tz(orgtime, window.timeZone);
    } else {
      return moment(orgtime).local();
    }
  };

  const loadInitialFormData = () => {
    let localData = JSON.parse(sessionStorage.getItem(props.SCREEN_TYPE === "log" ? "logSearchCriterias" : "exceptionSearchCriterias"));
    //console.log(localData, "------local Data");
    if (localData) {
      let formValues = {
        ...localData,
        startTime: moment(moment.parseZone(localData.startTime).format("MM/DD/YYYY HH:mm:ss")).format("MM/DD/YYYY HH:mm:ss").toString(),
        endTime: moment(moment.parseZone(localData.endTime).format("MM/DD/YYYY HH:mm:ss")).format("MM/DD/YYYY HH:mm:ss").toString(),
      };
      if (formRef) {
        formRef.setFieldsValue(formValues);
      }
      setInitialFormData(formValues);
    }
    else {
      //console.log(appName, '---appName');
      let tempData = {
        appName: getInitialApplication(),
        startTime: moment(formatAccordingToTimeZone(moment().subtract(4, "hours"))).format("MM/DD/YYYY HH:mm:ss").toString(),
        endTime: moment(formatAccordingToTimeZone(moment())).format("MM/DD/YYYY HH:mm:ss").toString(),
        logLevel: " ",
        exceptionStatus: ["Pending", "Resolved"],
      };
      //console.log(tempData, '---appName');
      formRef && formRef.setFieldsValue({ ...tempData });
      setInitialFormData(tempData);
    }
  };

  const getInitialApplication = () => {
    let matrixReportList = null;
    if (props.matrixReport) {
      if (typeof props.matrixReport === "string") {
        matrixReportList = JSON.parse(props.matrixReport);
      } else {
        //value = this.props.matrixReport;
        matrixReportList = _.cloneDeep(props.matrixReport);
      }
      matrixReportList = Object.values(matrixReportList);
    }

    let maxValApplication = findMaxValueApplication(matrixReportList);
    if (maxValApplication !== null) {
      return maxValApplication;
    }
    //console.log(props.applications, '::::::::props.applications');
    //console.log(applicationOptions, '::::::::applicationOptions');

    if (applicationOptions && applicationOptions.length > 0) {
      return [applicationOptions[0]];
    } else {
      return [];
    }
  };


  const formatTimeForPayload = (orgtime) => {
    let time = moment(orgtime).format("YYYY-MM-DD HH:mm:ss");
    if (props.timeZone) {
      return momentZone.tz(time, window.timeZone).format();
    } else {
      return moment(time).format();
    }
  };

  const onSearchFormHandler = (formValues, isProceed = false) => {
    let payload = buildPayload(formValues);
    const appWithoutSectorData = payload.filter(item => item.applicationName !== 'TEST_CLE' && item.table.includes('undefined')).map(item => item.applicationName);
    if (!isProceed && appWithoutSectorData.length > 0) {
      setAppNameWithoutSectorData({
        appNames: appWithoutSectorData,
        formValues
      });
      setWarningModalOpen(true);
    } else {
      setWarningModalOpen(false);
      setIsPageLoading(true);
      setApiPayload(payload);
      GET_COUNT(payload);
    }
  };

  const buildPayload = (formValues) => {
    let payload = {
      ...formValues,
      applicationName: formValues?.appName.map((val) => val.value).join(" "),
      startTime: formatTimeForPayload(formValues.startTime),
      endTime: formatTimeForPayload(formValues.endTime),
      appName: applicationOptions.filter((app) =>
        formValues.appName.find((val) => app.value === val.value)
      ),
    };

    let request = {
      sortColumns: "timestamp",
      sortOrder: "desc",
      ..._.pickBy(payload, _.identity),
    };

    if (props.SCREEN_TYPE === "log") {
      sessionStorage.setItem("logSearchCriterias", JSON.stringify(request));
    } else {
      sessionStorage.setItem("exceptionSearchCriterias", JSON.stringify(request));
    }

    //building payload for multiple applications 
    let table = props.SCREEN_TYPE === "log" ? "logTable" : "exceptionTable";
    let application = request.appName;

    let appGroup = application.reduce((r, a) => {
      r[a[table]] = [...(r[a[table]] || []), a];
      return r;
    }, {});
    let payloadTemp = [];
    Object.keys(appGroup).forEach((val) => {
      let applicationName = appGroup[val].map((app) => app.value).join(" ");
      let newQuery = { ...request, table: val, applicationName };
      payloadTemp.push(newQuery);
    });

    return payloadTemp;
  };


  const GET_COUNT = (payloadArray) => {
    let url = props.SCREEN_TYPE === "log" ? "/log/count/" : "/exception/count/";
    let axiosUrl = props.SCREEN_TYPE === "log" ? AxiosUrl.logDatainstance : AxiosUrl.exceptionDatainstance;
    setShowNoRecordModal(false);
    setTotalCount(0);
    cancelCountToken = CancelToken.source();
    const create_count_api_promise = (request) => {
      return new Promise((resolve, reject) => {
        axiosUrl.post(url, request, {
          cancelToken: cancelCountToken.token,
        })
          .then((resp) => {
            let respStatus = outApiCheck(resp);
            if (respStatus === "block") {
              return;
            }
            if (!respStatus) {
              setTimeout(() => {
                create_count_api_promise(request);
              }, API_RECALL_TIME);
              return;
            }
            setTotalCount(prevCount => prevCount + resp.data);
            if (
              props.match.params.screenName === "list" &&
              resp.data > 0
            ) {
              if (props.SCREEN_TYPE === "log") {
                setIsPageLoading(false);
                let path = {
                  pathname: "/logs/search-logs/detail",
                  state: "preventWillMount",
                };
                props.history.push(path);
              } else {
                setIsPageLoading(false);
                let path = {
                  pathname: "/exceptions/search-exceptions/detail",
                  state: "preventWillMount",
                };
                props.history.push(path);
              }
            }
            resolve(resp);
          })
          .catch((error) => {
            //console.log(error);
            setShowNoRecordModal(true);
            SHOW_NO_RECORD_MODAL();
            setIsPageLoading(false);
            if (isCancel(error)) {
              console.log("post Request canceled", error);
              return reject(error);
            }
          });
      });
    };
    let count_promise = [];
    payloadArray.forEach((val) => {
      count_promise.push(create_count_api_promise(val));
    });
    Promise.allSettled(count_promise)
      .then((val) => {
        let checkNoRecord = val.every((val) => val.value?.data === 0);
        if (checkNoRecord && !showNoRecordModal) {
          setIsPageLoading(false);
          setShowNoRecordModal(true);
          SHOW_NO_RECORD_MODAL();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsPageLoading(false);
      });
  };

  const SHOW_NO_RECORD_MODAL = () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to logout request.");
    }
    if (typeof cancelCountToken != typeof undefined) {
      cancelCountToken.cancel("Operation canceled due to logout request.");
    }
    Modal.info({
      title: "No Records found!",
      onOk: () => { setShowNoRecordModal(false); },
    });
  };

  const onDownloadHandler = async (selectedCols) => {
    const exportAllDataService = (request) => {
      return new Promise((resolve, reject) => {
        fetch(AxiosUrl.apiDownloadUrl() + url, {
          method: "POST",
          headers: {
            Accept: "application/octet-stream",
            "Content-Type": "application/json",
            Authorization: getTokenToReqHeader(),
          },
          body: JSON.stringify(request),
        })
          .then((data) => {
            return data.blob();
          })
          .then((data) => {
            var blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = props.SCREEN_TYPE + ".xlsx";
            link.click();
            return resolve("Completed");
          })
          .catch((error) => {
            return reject(error);
          });
      });
    };

    let url = props.SCREEN_TYPE === "log" ? "/log/download/" : "/exception/download/";
    let payload = buildPayload(formRef.getFieldsValue());
    let count_promise = [];

    let totalRecordCount = await getLogExceptionCount(props.SCREEN_TYPE, payload);
    if (totalRecordCount > 0) {
      payload.forEach((val, iter) => {
        val.columns = selectedCols;
        val.totalRecordCount = totalRecordCount[iter];
        count_promise.push(exportAllDataService(val));
      });

      setDisableFooter(true);
      setDownloadInProgress(true);
      setShowSelectColModal(false);

      progressBarRef.current.continuousStart();
      Promise.allSettled(count_promise)
        .then((v) => {
          console.log(v);
          setDisableFooter(false);
          setDownloadInProgress(false);
          progressBarRef.current.complete();
          successNotification(`Downloaded successfully`);
        })
        .catch((err) => {
          setDisableFooter(false);
          setDownloadInProgress(false);
          progressBarRef.current.complete();
          errorNotification("Oops something went wrong!", err);
        });
    }
    else {
      setShowSelectColModal(false);
      errorNotification("No Records found!", "Please try different Date Range.");
    }
  };

  return (
    <WrapperPage overLoading={isPageLoading}>
      <LoadingBar
        ref={progressBarRef}
        containerClassName="fixed-progress-bar"
        height={3}
        color="#1890ff"
      />
      {
        isWarningModalOpen &&
        <SectorDetailWarningModal
          appNames={appNameWithoutSectorData.appNames}
          isModalOpen={isWarningModalOpen}
          isAdminAccess={props.userAccessInfo.adminAccess.toUpperCase() === 'Y'}
          onCloseModal={() => { setWarningModalOpen(false); }}
          onProceed={() => onSearchFormHandler(appNameWithoutSectorData.formValues, true)}
        />
      }
      {
        props.match.params.screenName === "list" &&
        <SearchForm
          applicationNames={applicationOptions}
          ref={(ref) => setFormRef(ref)}
          //hanldeRest={() => formRef.setFieldsValue({ ...initialFormData })}
          hanldeRest={() => formRef.setFieldsValue({
            appName: [],
            startTime: moment(formatAccordingToTimeZone(moment().subtract(4, "hours"))).format("MM/DD/YYYY HH:mm:ss").toString(),
            endTime: moment(formatAccordingToTimeZone(moment())).format("MM/DD/YYYY HH:mm:ss").toString(),
          })}
          onSearch={onSearchFormHandler}
          onDownload={() => { setShowSelectColModal(true); }}
          firstServe
          exception={props.SCREEN_TYPE === "exception"}
          log={props.SCREEN_TYPE === "log"}
          disabledFooter={disabledFooter}
          downloadProgress={isDownloadInProgress}
          getApplicationExceptionCategory={props.getApplicationExceptionCategory}
          getApplicationExceptionSeverity={props.getApplicationExceptionSeverity}
          getApplicationExceptionType={props.getApplicationExceptionType}
          categories={props.categories}
          severities={props.severities}
          types={props.types}
          isAdminAccess={props.userAccessInfo && props.userAccessInfo.adminAccess === "Y"}
        />
      }
      {
        props.match.params.screenName !== "list" &&
        (<CommonTable
          cancelToken={cancelToken}
          cancelCountToken={cancelCountToken}
          totalCount={totalCount}
          timeZone={props.timeZone}
          onReduceCount={(count) => {
            setTotalCount(totalCount - count);
          }}
        />
        )
      }
      {
        showSelectColModal &&
        <SelectFieldForExportModal
          processExcelDownload={onDownloadHandler}
          colName={
            props.SCREEN_TYPE === "log"
              ? "LOG_COLUMNS"
              : "EXCEPTION_COLUMNS"
          }
          isModalOpen={showSelectColModal}
          count={props.SCREEN_TYPE === "log" ? 20001 : 10001}
          closeExcelModal={() => { setShowSelectColModal(false); }} />
      }
    </WrapperPage>
  );
};

const mapStateToProps = (state) => {
  return {
    applications: state.applications.applicationData,
    categories: state.exceptions.categories,
    types: state.exceptions.types,
    severities: state.exceptions.severities,
    isApplicationLoading: state.applications.isApplicationLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getApplicationDetails: () => dispatch(applicationActions.getApplicationDetails()),
    getApplicationExceptionCategory: (appName) => dispatch(configurationActions.getApplicationExceptionCategory(appName)),
    getApplicationExceptionSeverity: (appName) => dispatch(configurationActions.getApplicationExceptionSeverity(appName)),
    getApplicationExceptionType: (appName) => dispatch(configurationActions.getApplicationExceptionType(appName))
  };
};

CommonSearch.propTypes = {
  applications: PropTypes.array,
  categories: PropTypes.array,
  severities: PropTypes.array,
  types: PropTypes.array,
  history: PropTypes.object,
  SCREEN_TYPE: PropTypes.string,
  matrixReport: PropTypes.any,
  totalCount: PropTypes.number,
  timeZone: PropTypes.any,
  match: PropTypes.object,
  userAccessInfo: PropTypes.object,
  visible: PropTypes.bool,
  getApplicationDetails: PropTypes.func,
  getApplicationExceptionCategory: PropTypes.func,
  getApplicationExceptionSeverity: PropTypes.func,
  getApplicationExceptionType: PropTypes.func,
  isApplicationLoading: PropTypes.bool

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CommonSearch));