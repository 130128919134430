import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as actions from "../../../actions/Admin/userActions";
import UserFooter from '../UserFooter';
import UpsertUser from "./UpsertUser";
import UserDetail from "./UserDetail";
import ConfirmationModalPopup from "../../../components/CommonComponents/Modal/ConfirmationModalPopup";
import SelectTargetUser from "./SelectTargetUser";
import AxiosUrl from "../../../services/axiosInstanceCustom";
import {
  closeLoader,
  openLoader,
} from "../../../components/OverlayLoader/OverlayLoader";
import UserListComponent from "../UserList";

const UserAccess = () => {
  const dispatch = useDispatch();
  const globalUserState = useSelector((state) => state.users.userResults);

  const [gridApi, setGridApi] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSelectTargetUserScreen, setShowSelectTargetUserScreen] = useState(false);
  useEffect(() => {
    if (globalUserState.data.length === 0)
      dispatch(actions.getUsersList());

    if(gridApi && globalUserState.data.length === 0){
      gridApi.showLoadingOverlay();
    }

  }, []);

  useEffect(() => {
    if(gridApi && globalUserState.data.length === 0){
      gridApi.showLoadingOverlay();
    }
  }, [gridApi]);

  useEffect(() => {
    if(gridApi && globalUserState.data.length !== 0){
      gridApi.hideOverlay();
    }
  }, [globalUserState.data]);

  const onEditUserHandler = () => {
    if (selectedRow.length > 0) {
      setShowUpsertModal(true);
      setEditFlag(true);
      setEditData(selectedRow[0]);
    }
  };
  const onDeleteHandler = () => {
    dispatch(actions.progress());
    dispatch(actions.deleteUser(selectedRow));
    gridApi.deselectAll();
    setShowConfirmModal(false);
    setSelectedRow([]);
  };
  const onSaveCopyUserHandler = (targetUser) => {
    //console.log("onSaveCopyUserHandler")
    let axiosUrl = AxiosUrl.adminDataInstance;
    let url = `/users/duplicate/${selectedRow[0].userID}/${targetUser.userID}/`;
    openLoader();
    axiosUrl
      .put(url, {})
      .then((resp) => {
        const rep = {
          response: resp.data,
          action: "UPDATE",
          page: "userResults",
          id: "userID",
        };
        setShowSelectTargetUserScreen(false);
        dispatch(actions.UserDataStore(rep))
        dispatch(actions.getRolesApplicationList())
        dispatch(actions.getRoleUsersList())
        closeLoader();
        toast.success("User access copied successfully");
        setSelectedRow([]);
        gridApi.deselectAll();
      })
      .catch(() => {
        closeLoader();
        toast.error("User access copy failed!");
      });
  }
  return (
    <>
      {!showUpsertModal && !showSelectTargetUserScreen && (
        <div className="userListContainer">
          <UserListComponent
            userListData={globalUserState.data}
            setSelectedRow={setSelectedRow}
            setGridApi={setGridApi}
          />
          <UserFooter
            copyUser
            disableCopyUser={selectedRow.length !== 1}
            createUser
            modify
            disabledModify={selectedRow.length !== 1}
            detail
            disabledDetail={selectedRow.length === 0}
            delete
            disabledDelete={selectedRow.length === 0}
            onCreate={() => {
              setShowUpsertModal(true);
              setEditData(null);
              setEditFlag(false);
            }}
            onEdit={() => onEditUserHandler()}
            onDetail={() => setShowDetailModal(true)}
            onDelete={() => setShowConfirmModal(true)}
            onCopyUser={() => setShowSelectTargetUserScreen(true)}
          />
        </div>
      )}
      {showUpsertModal && (
        <UpsertUser
          editFlag={editFlag}
          editData={editData}
          onClose={() => {
            setSelectedRow([]);
            setShowUpsertModal(false);
            setEditData(null);
            setEditFlag(false);
          }}
        />
      )}
      {showDetailModal && (
        <UserDetail
          isModalOpen={showDetailModal}
          onCloseModal={() => setShowDetailModal(false)}
          data={selectedRow.length > 0 ? selectedRow : []}
        />
      )}
      {showConfirmModal && (
        <ConfirmationModalPopup
          title={
            "Are you sure you want to delete " + selectedRow.length + " item?"
          }
          isModalOpen={showConfirmModal}
          onCloseModal={() => setShowConfirmModal(false)}
          onOkModal={onDeleteHandler}
        />
      )}
      {
        showSelectTargetUserScreen &&
        <SelectTargetUser
          sourceUser={selectedRow.length > 0 ? selectedRow[0] : null}
          userList={globalUserState.data}
          onSave={(targetUser) => onSaveCopyUserHandler(targetUser)}
          onClose={() => { setSelectedRow([]); setShowSelectTargetUserScreen(false) }}
        />
      }

    </>
  );
};
export default UserAccess;