import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScheduleReport from '../../../components/Admin/ScheduleReport';
import * as actions from '../../../actions/Admin/scheduleReportAction';
const ScheduleReportContainer = () => {
	const dispatch = useDispatch();
	const globalReportConfigState = useSelector((state) => state.scheduleReportConfig);
	useEffect(() => {
		if (globalReportConfigState.reportData?.length === 0) {
			dispatch(actions.fetchScheduleReportData());
		}
	}, [])
	const saveDetails = (payload) => {
		dispatch(actions.addScheduleReport(payload))
	}
	const updateDetails = (payload) => {
		dispatch(actions.updateScheduleData(payload))
	}
	const onDelete = (payload) => {
		dispatch(actions.deleteScheduleDetail(payload))
	}
	return (
		<div>
			<ScheduleReport
				isLoading={globalReportConfigState.isLoading}
				reponse={globalReportConfigState.reportData}
				onDelete={onDelete}
				saveDetails={saveDetails}
				updateDetails={updateDetails} />
		</div>);
}
export default ScheduleReportContainer;
