import React from "react";
import PropTypes from "prop-types";
import { Row, Button, Col, Tooltip } from "antd";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  EditOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import "./ModalTitle.scss";

const ModalTitle = ({
  modalTitle,
  modalTitleIcon = "detail",
  nextPrevBtn = false,
  editBtn = false,
  handleMove,
  onClose,
  onEdit,
  dataIndex,
  totalLength,
}) => {
  return (
    <Row className={"modalTitle"} align={"middle"} justify={"space-between"}>
      <Col>
        <Row>
          <Col>
            {modalTitleIcon === "detail" && (
              <InfoCircleOutlined className="btnHeaderLeftIcon" />
            )}
            {modalTitleIcon === "add" && (
              <PlusCircleOutlined className="btnHeaderLeftIcon" />
            )}
            {modalTitleIcon === "edit" && (
              <FormOutlined className="btnHeaderLeftIcon" />
            )}
          </Col>
          <Col className="headerTitle">{modalTitle}</Col>
        </Row>
      </Col>

      <Col>
        <Row align={"middle"}>
          {nextPrevBtn && (
            <Col className="mr50">
              <Row>
                <Button
                  className={"mr20 btnWidth"}
                  type="primary"
                  shape="round"
                  id="sel_logs_details_prev"
                  icon={<DoubleLeftOutlined className={"btnIcon"} />}
                  onClick={() => handleMove("prev")}
                  size={"default"}
                  disabled={dataIndex === 0}
                >
                  Previous
                </Button>
                <Button
                  className={"btnWidth"}
                  type="primary"
                  shape="round"
                  id="sel_logs_details_next"
                  size={"default"}
                  disabled={dataIndex === totalLength - 1}
                  onClick={() => handleMove("next")}
                >
                  Next <DoubleRightOutlined className={"btnIcon"} />
                </Button>
              </Row>
            </Col>
          )}
          <Col>
            {editBtn && (
              <Tooltip title="Edit">
                <EditOutlined
                  className="headerRightEdIcon pointer"
                  onClick={onEdit}
                />
              </Tooltip>
            )}
            <Tooltip title="Close">
              <CloseOutlined
                className="headerCloseIcon pointer"
                onClick={onClose}
              />
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ModalTitle.propTypes = {
  dataIndex: PropTypes.number,
  editBtn: PropTypes.bool,
  handleMove: PropTypes.func,
  modalTitle: PropTypes.string,
  modalTitleIcon: PropTypes.string,
  nextPrevBtn: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  totalLength: PropTypes.number,
};

export default ModalTitle;
