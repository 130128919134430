import * as types from "../../constants/ActionTypes/reportTypes";
import objectAssign from "object-assign";
import update from "immutability-helper";
const initialState = {
  logReports: {
    lastHourReport: null,
    pastHrReport: null,
    adminReport: null,
  },
  exceptionReports: {
    lastHourReport: null,
    pastHrReport: null,
    adminReport: null,
  },
  error: null,
  isTimeZoneDisable: false,
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LAST_HOUR_LOGS_REPORT:
      return state;

    case "TIMEZONE_TOGGLE_DISABLE":
      return objectAssign(
        {},
        update(state, {
          isTimeZoneDisable: { $set: true },
        })
      );

    case types.LAST_HOUR_LOGS_REPORT_RESULT:
      return objectAssign(
        {},
        update(state, {
          logReports: {
            lastHourReport: { $set: action.logReport },
            error: { $set: null },
          },
        })
      );
    case types.LAST_HOUR_EXCEPTION_REPORT_RESULT:
      return objectAssign(
        {},
        update(state, {
          exceptionReports: {
            lastHourReport: { $set: action.exceptionReport },
            error: { $set: null },
          },
        })
      );
    case types.PAST_HOUR_LOGS_REPORT_RESULT:
      return objectAssign(
        {},
        update(state, {
          logReports: {
            pastHrReport: { $set: action.pastHrLogReport },
          },
        })
      );
    case types.PAST_HOUR_EXCEPTION_REPORT_RESULT:
      return objectAssign(
        {},
        update(state, {
          exceptionReports: {
            pastHrReport: { $set: action.pastHrExceptionRpt },
          },
        })
      );
    case "DASHBORAD_ERROR": {
      return objectAssign(
        {},
        update(state, {
          error: { $set: action.error },
        })
      );
    }
    case types.ADMIN_LOG_REPORT_RESULT:
      return objectAssign(
        {},
        update(state, {
          logReports: {
            adminReport: { $set: action.logReport },
          },
        })
      );
    case types.ADMIN_EXCEPTION_REPORT_RESULT:
      return objectAssign(
        {},
        update(state, {
          exceptionReports: {
            adminReport: { $set: action.exceptionReport },
          },
        })
      );
    default:
      return state;
  }
}
