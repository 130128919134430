import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
const ListFooter = ({ add, onAdd, modify, onModify, modifyDisabled, deleteBtn, deleteDisabled, onDelete }) => {
    return (
        <div className="list-footer-wrapper">
            {add && (
                <Button
                    type="primary"
                    size={"default"}
                    className="secondary mr10"
                    onClick={onAdd}
                    icon={
                        <i className="fas fa-plus-circle mr10" aria-hidden="true" />
                    }
                >
                    Add
                </Button>
            )}
            {modify && (
                <Button
                    type="primary"
                    size={"default"}
                    className="mr10 modify-btn"
                    disabled={modifyDisabled}
                    icon={<i className="far fa-edit mr10" aria-hidden="true" />}
                    onClick={onModify}
                >
                    Modify
                </Button>
            )}
            {deleteBtn && (
                <Button
                    type="primary"
                    size={"default"}
                    danger
                    className="delete-btn"
                    disabled={deleteDisabled}
                    onClick={onDelete}
                    icon={<i className="fa fa-trash mr10" aria-hidden="true" />}
                >
                    Delete
                </Button>
            )}
        </div>);
}
ListFooter.propTypes = {
    add: PropTypes.bool,
    onAdd: PropTypes.func,
    modify: PropTypes.bool,
    onModify: PropTypes.func,
    modifyDisabled: PropTypes.bool,
    deleteBtn: PropTypes.bool,
    deleteDisabled: PropTypes.bool,
    onDelete: PropTypes.func,
};
export default ListFooter;