import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import Applications from "./Applications";
import AdminLists from "../../../containers/Admin/ApplicationConfiguration/AdminLists";
import CleTabs from "../../../components/CommonComponents/CleTabs/CleTabs";
import "./styles.scss";
import Loader from "../../Loader/Loader";

const ApplicationConfig = ({
  alert,
  applicationData,
  applicationSectorList,
  deleteExceptionCategory,
  deleteExceptionSeverity,
  deleteExceptionType,
  exceptionType,
  isListloading,
  isApplicationLoading,
  onApplicationDelete,
  saveApplicationDetails,
  saveCategoryDetails,
  saveSeverityDetails,
  saveTypeDetails,
  getLogAndExceptionTable,
}) => {
  const [activeTab, setActiveTab] = useState('applications');
 
  const handleTabChange = (tab) =>  {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const tabItems = [
    {
    key: 'applications',
    label: 'Applications',
    children: isApplicationLoading ? (
      <Loader />
    ) : (
      <Applications
        alert={alert}
        applicationData={applicationData}
        onApplicationDelete={onApplicationDelete}
        applicationSectorList={applicationSectorList}
        saveApplicationDetails={
          saveApplicationDetails
        }
        getLogAndExceptionTable={
          getLogAndExceptionTable
        }
      />
    ),
  },
  {
    key: 'lists',
    label: 'Lists',
    children: isListloading ? (
      <Loader />
    ) : (
      <AdminLists
        exceptionType={exceptionType}
        saveCategoryDetails={saveCategoryDetails}
        saveSeverityDetails={saveSeverityDetails}
        saveTypeDetails={saveTypeDetails}
        deleteExceptionCategory={deleteExceptionCategory}
        deleteExceptionSeverity={deleteExceptionSeverity}
        deleteExceptionType={deleteExceptionType}
      />
    )
  }
];

    return (
        <div className="content-wrapper">
            <Row>
                <Col span={24}>
                    <CleTabs
                      activeTab={activeTab}
                      defaultActiveTab={"applications"}
                      onTabChangeHandler={handleTabChange}
                      tabItems={tabItems}
                    />
                </Col>
            </Row>
        </div>
    );
}

ApplicationConfig.propTypes = {
  deleteExceptionCategory: PropTypes.func,
  deleteExceptionSeverity: PropTypes.func,
  deleteExceptionType: PropTypes.func,
  saveCategoryDetails: PropTypes.func,
  saveSeverityDetails: PropTypes.func,
  saveTypeDetails: PropTypes.func,
  applicationData: PropTypes.any,
  applicationSectorList: PropTypes.array,
  exceptionType: PropTypes.any,
  onApplicationDelete: PropTypes.func,
  saveApplicationDetails: PropTypes.func,
  getLogAndExceptionTable: PropTypes.any,
  alert: PropTypes.any,
  isListloading: PropTypes.bool,
  isApplicationLoading: PropTypes.bool,
};

export default ApplicationConfig;
