export const GET_APPLICATION_DETAILS = 'GET_APPLICATION_DETAILS';
export const APPLICATION_DETAILS = 'APPLICATION_DETAILS';
export const DELETE_APPLICATION='DELETE_APPLICATION';
export const ADD_NEW_APPLICATION='ADD_NEW_APPLICATION';
export const UPDATE_APPLICATIONCONFIG="UPDATE_APPLICATIONCONFIG";
export const LOG_EXCEPTION_TABLE="LOG_EXCEPTION_TABLE";
export const LOG_EXCEPTION_TABLE_RESULTS="LOG_EXCEPTION_TABLE_RESULTS";

export const GET_APPLICATION_SECTOR_LIST = 'GET_APPLICATION_SECTOR_LIST';
export const SET_APPLICATION_SECTOR_LIST = 'SET_APPLICATION_SECTOR_LIST';

export const STORE_APPLICATION_REPORT="STORE_APPLICATION_REPORT"
export const PROGRESS_APPLICATION_REPORT="PROGRESS_APPLICATION_REPORT"

