import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { notification } from "antd";
import WrapperPage from "../../WrapperPage/Page";
import MessageMatricsFooter from "./MessageMatricsFooter";
import CleTable from "../../CommonComponents/CleTable/CleTable";
import AxiosUrl from "../../../services/axiosInstanceCustom";
import columnList from "../../../constants/MessageMatrics/columnList";
import columnDetailList from "../../../constants/MessageMatrics/columnDetailList";
import { applicationDataInstance } from "../../../services/applicationDataService";
import MessageMetricsDetail from "./MessageMetricsDetail";

const pageStyle = {
  height: "calc(100vh - 180px)",
  width: "100%",
}
const MessageMatricsComponent = (props) => {
  const [gridApi, setGridApi] = useState();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [selectedAppName, setSelectedAppName] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState([]);
  const [detailModalData, setDetailModalData] = useState();
  const [showDetailModal, setShowDetailModal] = useState();

  useEffect(() => {
    if (props && props.appName && props.appName.length !== selectedAppName.length && gridApi) {
      setSelectedAppName(props.appName);
      gridApi.onFilterChanged();
    }
  }, [props?.appName]);

  useEffect(() => {
    if (props && props.tableName && props.tableName.length !== selectedTableName.length && gridApi) {
      setSelectedTableName(props.tableName);
      gridApi.onFilterChanged();
    }
  }, [props?.tableName]);

  const doesExternalFilterPass = (node) => {
    let valid = true;
    if (node) {
      if (props.list) {
        if (props.appName && props.appName.length > 0) {
          let index = props.appName.findIndex(
            (val) => node.data?.application === val.value
          );
          valid = index > -1;
        }
      }
      if (valid) {
        valid = props.tableName.includes(node.data?.type);
      }
    }
    return valid;
  };

  const onDetailHandler = (selectedRow) => {
    let request = {
      application: selectedRow[0].application_nm,
      date: selectedRow[0].date_text,
    };
    if (selectedRow[0].log_id) {
      request.logID = selectedRow[0].log_id;
      request.table = applicationDataInstance.getTableNameByApplication(request.application, selectedRow[0].type);
    } else {
      request.exceptionID = selectedRow[0].exception_id;
      request.table = applicationDataInstance.getTableNameByApplication(request.application, selectedRow[0].type);
    }
    let payload = {
      method: "post",
      url: selectedRow[0].type === "Log" ? "/log/blob/item/" : "/exception/blob/item/",
      data: request,
    };
    // let axiosUrl = selectedRow[0].type === "Log" ? AxiosUrl.logDatainstance : AxiosUrl.exceptionDatainstance;
    setIsPageLoading(true);
    AxiosUrl.metricDataInstance(payload).then((res) => {
      let response = res.data;
      if (response === "" || response === "No records Found!" || response === null || !response) {
        setIsPageLoading(false);
        notification.info({
          message: "No Record found",
          description:
            "The content of the record is not available in database!",
        });
        return;
      }
      else {
        setDetailModalData({ selectedRow: response, selectedType: selectedRow[0].type })
        setShowDetailModal(true);
        setIsPageLoading(false);
      }
    })
  }

  const isExternalFilterPresent = () => {
    return true;
  };
  return (<>
    <WrapperPage overLoading={isPageLoading}>
      <div className="ag-theme-height" style={pageStyle}>
        <CleTable
          key={JSON.stringify([...selectedTableName, ...selectedAppName])}
          getRowNodeId={(data) => data.log_id || data.exception_id || data.application + data.date + data.type}
          suppressRowClickSelection
          columnDefs={props.list ? columnList : columnDetailList}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          onGridReady={(params) => { setGridApi(params.api) }}
          overlayNoRowsTemplate={"No record found.."}
          onRowDoubleClicked={(params) => {
            if (!props.list) {
              onDetailHandler([params.data])
            }
          }}
          rowData={props.data}
        />
        <MessageMatricsFooter
          gridApi={gridApi}
          log={props.log}
          exception={props.exception}
          tableName={props.tableName}
          detail={!props.list}
          loading={props.footerLoading}
        />
      </div>
    </WrapperPage>
    {
      showDetailModal &&
      <MessageMetricsDetail
        isModalOpen={showDetailModal}
        selectedRow={detailModalData.selectedRow}
        selectedType={detailModalData.selectedType}
        onCloseModal={() => setShowDetailModal(false)}
      />
    }
  </>);
}

MessageMatricsComponent.propTypes = {
  appName: PropTypes.array,
  footerLoading: PropTypes.bool,
  tableName: PropTypes.array,
  exception: PropTypes.array,
  list: PropTypes.bool,
  data: PropTypes.array,
  log: PropTypes.array,
};

export default MessageMatricsComponent;
