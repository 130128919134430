import * as  types from '../../constants/ActionTypes/Admin/configurationTypes';
import objectAssign from 'object-assign';

const initialState = {
  exceptionType: [],
  detectAction: null,
  isListloading: true,
  isOverlayLoading: false,
};

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function applicationsConfigReducer(state = initialState, action) {
  switch (action.type) {
    case types.APPLICATION_DATA:
      return objectAssign({}, state, {
        exceptionType: {
          ...state.exceptionType,
          [action.exceptionType.type]: action.exceptionType.data
        },
        detectAction: state.detectAction,
        isListloading: false,
      })
    default:
      return state;
  }
}
