import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { Form, Input, Col, Row } from "antd";
import { getUsersList, addRoleUsersFunction } from "../../../actions/Admin/userActions";
import UserFooter from '../UserFooter';
import UserListComponent from '../UserList';
import "./CreateGroup.css"

const CreateGroup = ({ ...props }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const globalStateUserList = useSelector((state) => state.users.userResults.data);
    const [gridApi, setGridApi] = useState();

    useEffect(() => {
        if (globalStateUserList.length === 0) {
            dispatch(getUsersList());
        }
    }, []);
    const onSaveGroupHandler = () => {
        form.validateFields()
            .then(async (values) => {
                let payload={
                    ...values,
                    "userList":values["userList"]?values["userList"].map(userObj=>userObj["userID"]) :[]
                }
                dispatch(addRoleUsersFunction(payload));
                props.updateSelectedGroup(payload);
                props.onCancel();
            })
    }
    const onSelectRowHandler = (rows) => {
        form.setFieldValue("userList", rows);
    }

    return (
        <>
            <div className="create-user-container">
                <div className="create-user-header">
                    Create Group
                </div>
                <Form
                    id="create-user-form"
                    form={form}
                    labelAlign="left"
                    colon
                >
                    <Row>
                        <Col span={"12"}>
                            <Form.Item
                                label="Group Name"
                                name="groupName"
                                rules={[
                                    { required: true, message: 'Group Name is Mandatory!' },
                                    () => ({
                                        validator(_, value) {
                                            

                                            if (props.availableGroups.findIndex(item => item.groupName === value) > -1) {
                                                return Promise.reject('Group Name already exist');
                                            }

                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                                labelCol={{ span: 8 }} wrapperCol={{ span: 15 }}
                            >
                                <Input autoFocus/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={"12"}>
                            <Form.Item label="Group Description" name="groupDescription"
                                labelCol={{ span: 8 }} wrapperCol={{ span: 15 }}
                            >
                                <Input autoFocus/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={"24"} className='inline-user-list'>
                            <Form.Item name="userList"
                                rules={[{ required: true, message: 'Atleast one user is Mandatory!' }]}>
                                <UserListComponent
                                    inlineTableStyle
                                    userListData={globalStateUserList}
                                    setGridApi={setGridApi}
                                    setSelectedRow={(rows) => onSelectRowHandler(rows)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </div>
            <UserFooter
                save
                handleSave={() => onSaveGroupHandler()}
                reset
                showCancel
                handleReset={() => { form.resetFields(); gridApi.deselectAll() }}
                onCancel={() => props.onCancel()}
            />
        </>
    )
}
CreateGroup.propTypes = {
    onCancel: PropTypes.func,
    updateSelectedGroup: PropTypes.func,
    availableGroups: PropTypes.array
};
export default CreateGroup;