import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigColumnAlertDetails, addconfigColumnAlertDetails, deleteConfigurationColumnAlert, modifyConfigColumnAlertDetails } from '../../../actions/Admin/configureColumnAlertsAction';
import ConfigureColumnAlerts from '../../../components/Admin/ConfigureColumnAlerts';
const ConfigureAlertResults = () => {
	const dispatch = useDispatch();
	const globalConfigAltState = useSelector((state) => state.configColumnAlters);
	
	useEffect(() => {
		if (globalConfigAltState.columnConfigurationAlertData?.length === 0) {
			dispatch(getConfigColumnAlertDetails());
		}
	}, [])
	const onConfigColumnAlertDelete = (id) => {
		dispatch(deleteConfigurationColumnAlert([id]));
		dispatch(getConfigColumnAlertDetails());
	}
	const saveConfigColumnAlertDetails = (configColumnAlertDetails) => {
		if (configColumnAlertDetails.columnAlertId) {
			dispatch(modifyConfigColumnAlertDetails(configColumnAlertDetails));
		} else {
			dispatch(addconfigColumnAlertDetails(configColumnAlertDetails));
		}
	}
	return (
		<div>
			<ConfigureColumnAlerts
				isLoading={globalConfigAltState.isLoading}
				updateStatus={globalConfigAltState.updateStatus}
				columnConfigurationAlertData={globalConfigAltState.columnConfigurationAlertData}
				applicationData={globalConfigAltState.applicationData}
				onConfigColumnAlertDelete={onConfigColumnAlertDelete}
				saveConfigColumnAlertDetails={saveConfigColumnAlertDetails} />
		</div>
	);
}
export default ConfigureAlertResults;
