import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfigureReports from "../../../components/Admin/ConfigureReports";
import * as actions from "../../../actions/Admin/logStatusActions";
const ConfigureExceptionResults = () => {
  const dispatch = useDispatch();
  const globalLogConfigState = useSelector((state) => state.logConfig);
  useEffect(() => {
    if (globalLogConfigState.logConfigData?.length === 0) {
      dispatch(actions.getLogConfigDetails());
    }
    if (globalLogConfigState.exceptionReportData?.length === 0) {
      dispatch(actions.getExceptionReportDetails());
    }
  }, [])
  const getLogConfigList = () => {
    dispatch(actions.getLogConfigDetails());
  };
  const onLogDelete = (payload) => {
    dispatch(actions.deleteDetail(payload));
    dispatch(actions.getLogConfigDetails());
  };
  return (
    <div>
      <ConfigureReports
        getLogConfigList={getLogConfigList}
        isLogConfigLoading={globalLogConfigState.isLogConfigLoading}
        isExceptionLoading={globalLogConfigState.isExceptionLoading}
        logConfigData={globalLogConfigState.logConfigData}
        onLogDelete={onLogDelete}
      />
    </div>
  );
}
export default ConfigureExceptionResults;