import * as accessTypes from "../constants/AccessTypes/accessTypes";
// Only for role access git
export const userAccessValidator = {
  validateReadAccess: function (applicationName, userAccessInfo) {
    let isValid = false;
    if (
      (userAccessInfo.roleAccess[applicationName] &&
        userAccessInfo.roleAccess[applicationName].includes(
          accessTypes.VIEW_ACCESS
        )) ||
      userAccessInfo.adminAccess === "Y"
    ) {
      isValid = true;
    }
    return isValid;
  },
  validatePurgeAccess: function (applicationName, userAccessInfo) {
    let isValid = true;
    if (applicationName !== null && applicationName !== "") {
      if (
        userAccessInfo.roleAccess[applicationName] &&
        !userAccessInfo.roleAccess[applicationName].includes(
          accessTypes.PURGE_ACCESS
        ) &&
        userAccessInfo.adminAccess !== "Y"
      ) {
        isValid = false;
      }
    }
    return isValid;
  },
  validateReplayAccess: function (applicationName, userAccessInfo) {
    let isValid = true;
    if (applicationName !== null && applicationName !== "") {
      if (
        userAccessInfo.roleAccess[applicationName] &&
        !userAccessInfo.roleAccess[applicationName].includes(
          accessTypes.VIEW_ACCESS
        ) &&
        userAccessInfo.adminAccess !== "Y"
      ) {
        isValid = false;
      }
    }
    return isValid;
  },
  checkUserAccess: function (applicationName, userAccessInfo, access) {
    let isValid = false;
    if (userAccessInfo.adminAccess === "Y") {
      isValid = true;
      return isValid;
    }
    if (applicationName && applicationName.length > 0) {
      isValid = applicationName.every((val) =>
        userAccessInfo?.roleAccess[val.value]?.includes(access)
      );
    }
    return isValid;
  },
  validJsmEditAccess: function (applicationName, userAccessInfo) {
    let isValid = false;
    if (userAccessInfo.adminAccess === "Y") {
      isValid = true;
      return isValid;
    }
    if (applicationName !== null && applicationName !== "") {
      if (
        userAccessInfo.roleAccess[applicationName] &&
        (userAccessInfo.roleAccess[applicationName].includes("edit_JMS_Header") ||
          userAccessInfo.roleAccess[applicationName].includes("edit")
        )
      ) {
        isValid = true;
        return isValid;
      }
    }
    return isValid;
  },
  validateAccessUserRole: function (applicationName, userAccessInfo, action) {
    let isValid = false;
    if (userAccessInfo.adminAccess === "Y") {
      isValid = true;
      return isValid;
    }
    if (applicationName !== null && applicationName !== "") {
      // Check Purge Access
      if (
        userAccessInfo.roleAccess[applicationName] &&
        userAccessInfo.roleAccess[applicationName].includes("purge")
      ) {
        isValid = true;
        return isValid;
      }
      if (action === "filter") {
        if (
          userAccessInfo.roleAccess[applicationName] &&
          userAccessInfo.roleAccess[applicationName].includes("filter")
        ) {
          isValid = true;
          return isValid;
        }
      }
      if (action === "replay") {
        if (
          userAccessInfo.roleAccess[applicationName] &&
          userAccessInfo.roleAccess[applicationName].includes("replay")
        ) {
          isValid = true;
          return isValid;
        }
      }
    }
    return isValid;
  },
};
