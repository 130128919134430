
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../actions/Admin/applicationConfigActions';
import ApplicationConfig from '../../../../components/Admin/ApplicationConfig';
const ApplicationConfiguration = () => {
  const dispatch = useDispatch();
  const globalApplicationState = useSelector((state) => state.applications);
  useEffect(() => {
    if (globalApplicationState.applicationData.length === 0) {
      dispatch(actions.getApplicationDetails());
    }
    if (globalApplicationState.logExceptionTable.length === 0) {
      dispatch(actions.getLogAndExceptionTable());
    }
    if (globalApplicationState.applicationSectorList.length === 0) {
      dispatch(actions.getApplicationSectorList());
    }
  }, [])
  const onApplicationDelete = (id) => {
    dispatch(actions.deleteApplication([id]));
  }
  const saveApplicationDetails = (applicationDetails, isUpdate = false) => {
    if (isUpdate) {
      dispatch(actions.modifyApplicationconfig(applicationDetails));
    } else {
      dispatch(actions.addApplicationDetails(applicationDetails));
    }
  }
  return (
    <div>
      <ApplicationConfig
        isListloading={globalApplicationState.isListloading}
        isApplicationLoading={globalApplicationState.isApplicationLoading}
        applicationData={globalApplicationState.applicationData}
        applicationSectorList={globalApplicationState.applicationSectorList}
        onApplicationDelete={onApplicationDelete}
        saveApplicationDetails={saveApplicationDetails}
        getLogAndExceptionTable={globalApplicationState.logExceptionTable}
      />
    </div>
  );
}
export default ApplicationConfiguration;
