const cellStyles = {
  "text-align": "center",
};
const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: false,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true,
};
const CATEGORIES = [
  fieldHead,
  {
    field: "exceptionCategoryName",
    headerName: "Category",
  },
  {
    field: "exceptionCategoryDescription",
    headerName: "Description",
  },
];
const SEVERITIES = [
  fieldHead,
  {
    field: "exceptionSeverityName",
    headerName: "Severity",
    cellStyle: cellStyles,
  },
  {
    field: "exceptionSeverityDescription",
    headerName: "Description",
  },
];
const TYPES = [
  fieldHead,
  {
    field: "exceptionTypeName",
    headerName: "Types",
  },
  {
    field: "exceptionTypeDescription",
    headerName: "Description",
  },
];
module.exports = { CATEGORIES, SEVERITIES, TYPES };
