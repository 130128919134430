const exceptionColumn = [
    {
        field: 'application_nm',
        headerName: 'Application',
    },
    {
        field: 'component_nm',
        headerName: 'Component Name',
    },
    {
        field: 'service_nm',
        headerName: 'ServiceName',
    },

    {
        field: 'exception_status',
        headerName: 'Status',
    },
    {
        field: 'trans_domain',
        headerName: 'Transaction Domain'
    },
    {
        field: 'host_nm',
        headerName: 'HostName',
    },
    {
        field: 'business_id',
        headerName: 'Business ID',
    },
    {
        field: 'business_id_two',
        headerName: 'Business ID2',
    },
    {
        field: 'trans_id',
        headerName: 'TransactionID',
    },
    {
        field: 'exception_category',
        headerName: 'Category'
    }, {
        field: 'exception_type',
        headerName: 'Type'
    }, {
        field: 'exception_severity',
        headerName: 'Severity'
    },
    {
        field: 'trans_data',
        headerName: 'Transaction Data'
    },
    {
        field: 'timemark',
        headerName: 'Time Occured',
    },
    {
        field: 'resolved',
        headerName: 'Resolved'
    },
    {
        field: 'jms_header',
        headerName: 'JMS Header'
    },
    {
        field: 'trans_type',
        headerName: 'Transaction Type'
    },
    {
        field: 'dump_analysis',
        headerName: 'Dump Analysis'
    },
    {
        field: 'msg',
        headerName: 'Message'
    },
    {
        field: 'code',
        headerName: 'Code'
    }
];    
export default exceptionColumn;