import React from "react";
import PropTypes from "prop-types";
import { withOktaAuth } from "@okta/okta-react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { connect } from "react-redux";
import { OverlayLoader } from "../components/OverlayLoader/OverlayLoader";
import { getExceptionCategory, getExceptionSeverity, getExceptionType } from "../actions/Admin/configurationActions";
import { getApplicationDetails } from "../actions/Admin/applicationConfigActions";
import {
  getLoggedInUserAccessInfo,
  oktaAuthenticated,
} from "../actions/UserAccess/userAccessActions";
import ErrorHandle from "../components/ErrorHandle/ErrorHandle";
import ProcessModal from "../components/ProcessModal/ProcessModal";
import "../App.css";
class MainLayout extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.authState && this.props.authState.isAuthenticated) {
      this.props.dispatch(
        oktaAuthenticated(this.props.authState.isAuthenticated)
      );
      if (
        this.props.userAccessInfo === null ||
        (this.props.userAccessInfo && this.props.userAccessInfo.email === null)
      ) {
        this.props.dispatch(getLoggedInUserAccessInfo());
      }
    } else {
      this.props.oktaAuthenticatedStatus &&
        this.props.userAccessInfo === null &&
        this.props.dispatch(getLoggedInUserAccessInfo());
      if (
        this.props.userAccessInfo !== null ||
        (this.props.userAccessInfo && this.props.userAccessInfo.email !== null)
      ) {
        if (!sessionStorage.getItem("applicationDetails")) {
          this.props.dispatch(getApplicationDetails());
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userAccessInfo !== this.props.userAccessInfo) {
      if (this.props.userAccessInfo && this.props.userAccessInfo.adminAccess === "Y" && !sessionStorage.getItem("applicationTypeData")) {
        this.props.dispatch(getExceptionType());
      }
      if (this.props.userAccessInfo && this.props.userAccessInfo.adminAccess === "Y" && !sessionStorage.getItem("applicationCategoryData")) {
        this.props.dispatch(getExceptionCategory());
      }
      if (this.props.userAccessInfo && this.props.userAccessInfo.adminAccess === "Y" && !sessionStorage.getItem("applicationSeverityData")) {
        this.props.dispatch(getExceptionSeverity());
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.oktaAuthenticatedStatus && this.props.userAccessInfo === null &&
      nextProps.userAccessInfo &&
      nextProps.userAccessInfo.userID !== null
    ) {
      // this.props.dispatch(getApplicationData());
      this.props.dispatch(getApplicationDetails());
      // this.props.dispatch(getExceptionInfo());
    }
  }

  render() {
    let reponseData = null;
    let showChildProps = false;
    if (this.props.oktaAuthenticatedStatus) {
      if (
        !this.props.userAccessInfo ||
        this.props.userAccessInfo.userID === null
      ) {
        reponseData = (
          <Spin
            className="initLoader"
            size="large"
            indicator={<LoadingOutlined spin/>}
          />
        );
      } else if (
        this.props.userAccessInfo &&
        this.props.userAccessInfo.adminAccess === "N"
      ) {
        if (
          !this.props.userAccessInfo ||
          this.props.userAccessInfo.roleAccess === null ||
          this.props.userAccessInfo.roleAccess.length === 0
        ) {
          reponseData = (
            <h1>
              Since You dont have access for application please contact pepsico
              team
            </h1>
          );
        } else {
          showChildProps = true;
        }
      } else {
        showChildProps = true;
      }
    }
    return (
      <div className="wrapper">
        <ErrorHandle />
        <OverlayLoader />
        <ProcessModal />
        <div
          className="modal fade"
          role="dialog"
          id="force-logout-modal"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Warning</h5>
              </div>
              <div id="modal-logout-content" />
            </div>
          </div>
        </div>
        <div>
          {showChildProps ? this.props.children : reponseData}
        </div>
      </div>
    );
  }
}
MainLayout.propTypes = {
  authState: PropTypes.object,
  children: PropTypes.node,
  dispatch: PropTypes.func,
  oktaAuthenticatedStatus: PropTypes.bool,
  userAccessInfo: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    userAccessInfo: state.userAccess.userAccessInfo,
    isApplicationLoading: state.applications.isApplicationLoading,
    exceptionresponse: state.userAccess.exceptionresponse,
    redirectURL: state.userAccess.redirectURL,
    isListloading: state.lists.isListloading,
    oktaAuthenticatedStatus: state.userAccess.oktaAuthenticatedStatus,
  };
}
export default connect(mapStateToProps)(withOktaAuth(MainLayout));
