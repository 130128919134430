import { call, put, fork, all, takeLatest, retry } from "redux-saga/effects";
import {
  logAndExceptionTable,
  applicatinDataStore,
  getApplicationDetails,
  setApplicationSectorList
} from "../../actions/Admin/applicationConfigActions";
// import { getApplicationConfig } from "../../actions/Admin/configurationActions";
import * as types from "../../constants/ActionTypes/Admin/applicationConfigTypes";
import { defaultAction } from "../../services/restService";
import { toast } from "react-toastify";
import { logger } from "redux-logger";
// import { onUserSignOut } from "../../actions/UserAccess/userAccessActions";

export function* fetchApplicationDetails(payload) {
  try {
    const SECOND = 1000;
    const response = yield retry(3, 10 * SECOND, defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
    };
    if (response) {
      sessionStorage.setItem("applicationDetails", JSON.stringify(response));
    }
    yield all([put(applicatinDataStore(rep))]);
  } catch (e) {
    toast.error(`Oops! Something went wrong ${e}`);
    logger(e);
  }
}
export function* getApplicationConfig() {
  yield takeLatest(types.GET_APPLICATION_DETAILS, fetchApplicationDetails);
}

export function* fetchApplicationSectorList(payload) {
  try {
    const SECOND = 1000;
    const response = yield retry(3, 10 * SECOND, defaultAction, payload);
    
    if (response) {
      yield put(setApplicationSectorList(response));
    }
  } catch (e) {
    toast.error(`Oops! Something went wrong ${e}`);
    logger(e);
  }
}
export function* getApplicationSectorList() {
  yield takeLatest(types.GET_APPLICATION_SECTOR_LIST, fetchApplicationSectorList);
}

export function* processApplicationDeletion(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "DELETE",
      id: "applicationName",
    };
    toast.success("Application has been deleted successfully");
    yield all([put(applicatinDataStore(rep)), put(getApplicationDetails())]);
  } catch (e) {
    logger(e);
  }
}
export function* deleteApplication() {
  yield takeLatest(types.DELETE_APPLICATION, processApplicationDeletion);
}

export function* saveApplicationDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "ADD",
      id: "applicationName",
    };

    let applicationList = JSON.parse(sessionStorage.getItem("applicationDetails"));

    applicationList = applicationList ? [...applicationList, response] : [response];
    
    sessionStorage.setItem(
      "applicationDetails",
      JSON.stringify(applicationList)
    );

    toast.success("Application has been added successfully");
    yield all([put(applicatinDataStore(rep))]);
  } catch (e) {
    logger(e);
  }
}
export function* addApplicationDetails() {
  yield takeLatest(types.ADD_NEW_APPLICATION, saveApplicationDetails);
}

export function* ModifyApplicationConfigDetails(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "UPDATE",
      id: "applicationName",
    };
    toast.success("Application has been modified successfully");
    yield all([put(applicatinDataStore(rep))]);
  } catch (e) {
    logger(e);
  }
}
export function* modifyApplicationConfig() {
  yield takeLatest(
    types.UPDATE_APPLICATIONCONFIG,
    ModifyApplicationConfigDetails
  );
}

export function* getLogAndExceptionTable(payload) {
  try {
    const tableData = yield call(defaultAction, payload);
    yield put(logAndExceptionTable(tableData));
  } catch (e) {
    logger(e);
  }
}
export function* logsAndExceptionTable() {
  yield takeLatest(types.LOG_EXCEPTION_TABLE, getLogAndExceptionTable);
}

export default function* root() {
  yield all([
    fork(getApplicationConfig),
    fork(deleteApplication),
    fork(addApplicationDetails),
    fork(modifyApplicationConfig),
    fork(logsAndExceptionTable),
    fork(getApplicationSectorList)
  ]);
}
