export const FETCH_BLOB_LOG_ALL_METRICS = "FETCH_BLOB_LOG_ALL_METRICS";
export const FETCH_BLOB_EXCEPTION_ALL_METRICS =
  "FETCH_BLOB_EXCEPTION_ALL_METRICS";

export const FETCH_BLOB_LOG_METRICS = "FETCH_BLOB_LOG_METRICS";
export const FETCH_BLOB_EXCEPTION_METRICS = "FETCH_BLOB_EXCEPTION_METRICS";

export const FETCH_BLOB_LOG_ALL_METRICS_SUCCESS =
  "FETCH_BLOB_LOG_ALL_METRICS_SUCCESS";
export const FETCH_BLOB_EXCEPTION_ALL_METRICS_SUCCESS =
  "FETCH_BLOB_EXCEPTION_ALL_METRICS_SUCCESS";

export const FETCH_BLOB_LOG_METRICS_SUCCESS = "FETCH_BLOB_LOG_METRICS_SUCCESS";
export const FETCH_BLOB_EXCEPTION_METRICS_SUCCESS =
  "FETCH_BLOB_EXCEPTION_METRICS_SUCCESS";

export const FETCH_BLOB_LOG_ALL_METRICS_ERROR =
  "FETCH_BLOB_LOG_ALL_METRICS_ERROR";
export const FETCH_BLOB_EXCEPTION_ALL_METRICS_ERROR =
  "FETCH_BLOB_EXCEPTION_ALL_METRICS_ERROR";

export const FETCH_BLOB_LOG_METRICS_ERROR = "FETCH_BLOB_LOG_METRICS_ERROR";
export const FETCH_BLOB_EXCEPTION_METRICS_ERROR =
  "FETCH_BLOB_EXCEPTION_METRICS_ERROR";
export const BLOB_RESET_DATA = "BLOB_RESET_DATA";
