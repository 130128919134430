export const SEARCH_EXCEPTIONS = 'SEARCH_EXCEPTIONS';
export const EXCEPTION_SEARCH_SUCCESS = 'EXCEPTION_SEARCH_SUCCESS';
export const EXCEPTION_SEARCH_FAILURE = 'EXCEPTION_SEARCH_FAILURE';
export const GET_EXCEPTION_DETAILS = 'GET_EXCEPTION_DETAILS';
export const EXCEPTION_DETAILS = 'EXCEPTION_DETAILS';
export const CLEAR_EXCEPTION_DATA = 'CLEAR_EXCEPTION_DATA';
export const REPLAY_EXCEPTIONS = 'REPLAY_EXCEPTIONS';
export const EXCEPTION_REPLAY_RESULT = 'EXCEPTION_REPLAY_RESULT';
export const CLEAR_EXCEPTION_DETAILS = 'CLEAR_EXCEPTION_DETAILS';
export const EXCEPTION_SEARCH_FILTER_EXCEPTIONS = 'EXCEPTION_SEARCH_FILTER_EXCEPTIONS';
export const EXCEPTION_SEARCH_FILTER_EXCEPTIONS_RESULT = 'EXCEPTION_SEARCH_FILTER_EXCEPTIONS_RESULT';
export const FILTER_EXCEPTIONS = 'FILTER_EXCEPTIONS';
export const DEFILTER_EXCEPTIONS = 'DEFILTER_EXCEPTIONS';
export const FILTER_EXCEPTIONS_RESULT = 'FILTER_EXCEPTIONS_RESULT';
export const DEFILTER_EXCEPTIONS_RESULT = 'DEFILTER_EXCEPTIONS_RESULT';
export const GET_FILTERED_EXCEPTION_STATUS = 'GET_FILTERED_EXCEPTION_STATUS';
export const EXCEPTION_FILTER_STATUS = 'EXCEPTION_FILTER_STATUS';
export const CLEAR_FILTER_DATA = 'CLEAR_FILTER_DATA';
export const PURGE_EXCEPTIONS = 'PURGE_EXCEPTIONS';
export const PURGE_EXCEPTIONS_RESULT = 'PURGE_EXCEPTIONS_RESULT';
export const FILTER_EXCEPTIONS_RESOLVE = 'FILTER_EXCEPTIONS_RESOLVE';
export const FILTER_EXCEPTIONS_RESOLVE_RESULT = 'FILTER_EXCEPTIONS_RESOLVE_RESULT';
export const GET_REPLAY_REPORT_DATA = 'GET_REPLAY_REPORT_DATA';
export const REPLAY_REPORT_DATA = 'REPLAY_REPORT_DATA';
export const FILTER_EXCEPTIONS_PURGE = 'FILTER_EXCEPTIONS_PURGE';
export const GET_TOTAL_EXCEPTION_RECORD_COUNT = 'GET_TOTAL_EXCEPTION_RECORD_COUNT';
export const TOTAL_EXCEPTION_RECORD_COUNT = 'TOTAL_EXCEPTION_RECORD_COUNT';
export const EXCEPTION_SEARCH_DEFILTER_EXCEPTIONS = 'EXCEPTION_SEARCH_DEFILTER_EXCEPTIONS';
export const SORT_EXCEPTIONS = 'SORT_EXCEPTIONS';
export const SORT_EXCEPTIONS_SUCCESS = 'SORT_EXCEPTIONS_SUCCESS';
export const SORT_EXCEPTIONS_ERROR = 'SORT_EXCEPTIONS_ERROR';
export const SAVE_EXCEPTION_DETAILS = 'SAVE_EXCEPTION_DETAILS';
export const SAVE_EXCEPTION_DETAILS_RESULT = 'SAVE_EXCEPTION_DETAILS_RESULT';
export const GET_SOLMAN_EXCEPTION_DETAILS = 'GET_SOLMAN_EXCEPTION_DETAILS';
export const ALL_SEARCH_EXCEPTIONS = "ALL_SEARCH_EXCEPTIONS"
export const REPLAY_EXCEPTIONS_STATUS = "REPLAY_EXCEPTIONS_STATUS"
export const REPLAY_EXCEPTIONS_STATUS_UPDATE = "REPLAY_EXCEPTIONS_STATUS_UPDATE"
export const EXCEPTION_BULK_REPLAY_STATUS = "EXCEPTION_BULK_REPLAY_STATUS"
export const GET_EXCEPTION_COUNT = "GET_EXCEPTION_COUNT"
export const GET_EXCEPTION_COUNT_ALL = "GET_EXCEPTION_COUNT_ALL"
export const REPLAY_REPORT_LOADING = "REPLAY_REPORT_LOADING";
export const PROGRESS_EXCEPTION_REDUCER = "PROGRESS_EXCEPTION_REDUCER";
export const EXCEPTIONS_ERROR = 'EXCEPTIONS_ERROR';
export const UPDATE_EXCEPTION_CST_DATA = 'UPDATE_EXCEPTION_CST_DATA';
