import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CleTabs from "../../../components/CommonComponents/CleTabs/CleTabs";
import GroupApplicationTab from './GroupApplicationTab';
import { getRoleUsersList, getUsersList, getRolesApplicationList } from "../../../actions/Admin/userActions";
import SelectDropDown from '../../../components/CommonComponents/SelectDropDown';
import UserFooter from '../UserFooter';
import GroupUserTab from './GroupUserTab';
import "../User.scss";
import CreateGroup from './CreateGroup';
const GroupAccess = () => {
    const dispatch = useDispatch();
    const globalUserState = useSelector((state) => state.users);
    const groupNameUserListOptions = globalUserState?.roleUserResults?.data;
    const [activeTab, setActiveTab] = useState();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroupName, setSelectedGroupName] = useState('');
    const [selectedGroupAppAccessList, setSelectedGroupAppAccessList] = useState();
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    useEffect(() => {
        if (globalUserState?.roleUserResults?.data?.length === 0) {
            dispatch(getRoleUsersList());
        }
        if (globalUserState?.roleApplicationResults?.data?.length === 0) {
            dispatch(getRolesApplicationList())
        }
        if (globalUserState?.userResults?.data?.length === 0)
            dispatch(getUsersList());
    }, [])
    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    useEffect(() => {
        if (selectedGroupName) {
            let selectedGroupData = groupNameUserListOptions.find(
                (item) => item.groupName === selectedGroupName
            );
            setSelectedGroup(selectedGroupData);
        }
    }, [globalUserState?.roleUserResults]);
    
    const onGroupChangeHandler = (selectedValue, selectedOption) => {
        setSelectedGroupName(selectedValue);
        setSelectedGroup(selectedOption);
        let selectedGroupApplicationList = globalUserState.roleApplicationResults.data.filter(
            (role) => role.groupName === selectedValue
        );
        setSelectedGroupAppAccessList(selectedGroupApplicationList);
    }

    useEffect(() => {
        if (selectedGroupName) {
            let selectedGroupApplicationList = globalUserState.roleApplicationResults.data.filter(
                (role) => role.groupName === selectedGroupName
            );
            setSelectedGroupAppAccessList(selectedGroupApplicationList);
        }
    }, [globalUserState.roleApplicationResults.data]);

    return (<>
        {
            !showCreateGroup &&
            <CleTabs
                defaultActiveTab="applicationAccess"
                activeTab={activeTab}
                onTabChangeHandler={(tabName) => handleTabChange(tabName)}
                tabClassName="group-access-tab-with-controls"
                tabItems={[
                    {
                        key: 'applicationAccess',
                        label: 'Application access',
                        children: <>
                            {
                                selectedGroup &&
                                <GroupApplicationTab
                                    selectedGroup={selectedGroup}
                                    initialAppAccessList={selectedGroupAppAccessList}
                                    applicationAccessList={globalUserState?.roleApplicationResults?.data}
                                />
                            }
                            {
                                !selectedGroup &&
                                <div className="no_role_select">
                                    Please select group..
                                </div>
                            }
                        </>
                    },
                    {
                        key: 'UserAccess',
                        label: 'User access',
                        children: <>
                            {
                                selectedGroup &&
                                <GroupUserTab
                                    selectedGroup={selectedGroup}
                                    userIdList={selectedGroup.userList ? selectedGroup.userList : []}
                                    groupList={groupNameUserListOptions}
                                    onCreateGroupHandler={() => setShowCreateGroup(true)} />
                            }
                            {
                                !selectedGroup &&
                                <>
                                    <div className="no_role_select">
                                        Please select group..
                                    </div>
                                    <UserFooter createGroup
                                        onCreateGroup={() => setShowCreateGroup(true)} />
                                </>
                            }
                        </>
                    },
                ]}
                tabExtraControlContent={(
                    <div>
                        <SelectDropDown
                            className="cle-select"
                            options={groupNameUserListOptions}
                            placeholder={"Select group"}
                            autoFocus
                            fieldNames={{ label: "groupName", value: "groupName" }}
                            value={selectedGroup ? selectedGroup.groupName : null}
                            onChange={(val, Option) => {
                                onGroupChangeHandler(val, Option);
                            }}
                        />
                    </div>)}
            />
        }
        {
            showCreateGroup &&
            <CreateGroup
                onCancel={() => setShowCreateGroup(false)}
                availableGroups={groupNameUserListOptions}
                updateSelectedGroup={(newGroup)=>onGroupChangeHandler(newGroup["groupName"],newGroup)}
            />
        }
    </>);
}

export default GroupAccess;