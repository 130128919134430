import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CleTable from '../../CommonComponents/CleTable/CleTable';
import CleFooter from '../../CommonComponents/CleFooter/CleFooter';
import COLUMNS from '../../../constants/ColumnConfig/Admin/tableConfigColumns';
import NewTableConfig from './newTableConfig';
import { checkIfExistsInArray } from '../../../utils/helpers';
import Loader from '../../CommonComponents/Loader';
import TableConfigDetail from './detailTableConfig';

const pageStyle = {
  height: "calc(100vh - 160px)",
  width: "100%",
};

const TableConfig = ({
  applicationSectorList,
  isLoading,
  indexConfigData,
  saveIndexConfig,
  timeZone
}) => {
  const globalConfigState = useSelector((state) => state.configReducer);

  const [selectedRow, setSelectedRow] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const onSaveDataHandler = (payload, close) => {
    if (close) {
      setShowAddEditModal(false);
      setSelectedRow([]);
    }
    if (!checkIfExistsInArray(indexConfigData, payload, 'tableName')) {
      saveIndexConfig(payload);
    } else {
      toast.error('Configuration already exist!');
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <div className={'admin-cle-table-wrapper'} style={pageStyle}>
            <CleTable
              rowSelection="multiple"
              columnDefs={COLUMNS}
              context={{ timeZone: globalConfigState.isCstTimeZone }}
              rowData={indexConfigData}
              getRowNodeId={(data) => data.tableName}
              onRowDoubleClicked={(params) => {
                // console.log('params', params.data);
                setSelectedRow([params.data]);
                setShowDetailModal(true);
              }}
              onSelectionChanged={(gridOptions) => {
                setSelectedRow(gridOptions.api.getSelectedRows());
              }}
              immutableData
              rowMultiSelectWithClick
            />
          </div>
          <CleFooter
            onAdd={() => {
              setShowAddEditModal(true);
            }}
            onDetail={() => setShowDetailModal(true)}
            deleteDisabled={selectedRow.length === 0}
            add
            detail
          />
        </>
      )}
      {showDetailModal && (
        <TableConfigDetail
          isModalOpen={showDetailModal}
          onCloseModal={() => setShowDetailModal(false)}
          data={selectedRow.length > 0 ? selectedRow : []}
        />
      )}
      {showAddEditModal && (
        <NewTableConfig
          editFlag={false}
          data={[]}
          onCloseModal={() => {
            setShowAddEditModal(false);
          }}
          isModalOpen={showAddEditModal}
          applicationSectorList={applicationSectorList}
          saveData={(payload, close) => onSaveDataHandler(payload, close)}
          timeZone={timeZone}
        />
      )}
    </>
  );
};

TableConfig.propTypes = {
  applicationSectorList: PropTypes.array,
  saveIndexConfig: PropTypes.func,
  indexConfigData: PropTypes.any,
  isLoading: PropTypes.bool,
  timeZone: PropTypes.bool
};

export default TableConfig;
