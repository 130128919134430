import { getAppConfig, getServiceConfig } from "../config/appConfig";
import URL from "./setEndpointURL";
/*Fetch Service Url
 Url can be configured in App Config(app/config/appConfig) for diffrent
 envrionments like development, SIT and UAT
*/
export function fetchServerEntry(serverName) {
  let appConfig = getAppConfig();
  var serverMapping =
    appConfig.serverConfig[appConfig.config.environment][serverName];
  return serverMapping;
}

/*Fetch  Url Prefix
 Fetching Prefix Service Url from Appconfig to make Service Url as configurable
*/
export function fetchServerUrlPrefix(serverName, isSecured) {
  let serverEntry = fetchServerEntry(serverName);
  if (serverEntry != null) {
    if (isSecured) {
      return serverEntry.secureUrlPrefix;
    } else {
      return serverEntry.urlPrefix;
    }
  }
}

/*Constructing Service Url to handle server and local envrionments
 constructServiceUrl Service Url.
*/
export function constructServiceUrl(params) {
  let apiParams = {};
  let serviceConfig = getServiceConfig();
  let serviceEntry = serviceConfig[params.apiName];
  if (serviceEntry.endPointUrl != null) {
      let url = "";
      let UrlPrefix = URL.API_URL;
      let endPointUrl = serviceEntry.endPointUrl;

      let serverUrlPrefix = fetchServerUrlPrefix(
          serviceEntry.server,
          serviceEntry.isSecured
      );
      if (!window.location.origin) {
          window.location.origin =
              window.location.protocol +
              "//" +
              window.location.hostname +
              (window.location.port ? ":" + window.location.port : "");
      }
      if (/localhost/.test(window.location.origin)) {
          url = serverUrlPrefix + endPointUrl;
      } else if (/192.168.225.24/.test(window.location.origin)) {
          url = serverUrlPrefix + endPointUrl;
      } else {
          url = UrlPrefix + endPointUrl;
          //    url = serverUrlPrefix + endPointUrl;
      }
      if (
          window.location.hostname ===
          "cle-react-qa.apps.pcf.dev.azure.intra.pepsico.com"
      ) {
          url = serverUrlPrefix + endPointUrl;
      }
      if(serviceEntry.baseUrlPrefix != null){
          url = serviceEntry.baseUrlPrefix + endPointUrl;
      }
      let serviceUrl = url.trim();
      let appConfig = getAppConfig();
      if (
          serviceEntry.endPointUrl != null &&
          appConfig.config.mockEnabled === false
      ) {
          apiParams.url = serviceUrl;
          apiParams.methodName = serviceEntry.method;
      } else {
          let serverEntry = fetchServerEntry(serviceEntry.server);
          apiParams.url = serverEntry.mock + serviceEntry.mock_json;
          apiParams.methodName = "GET";
      }
      apiParams.payload = params.payload;
      apiParams.mockEnabled = appConfig.config.mockEnabled;
      apiParams.isBgProcess = serviceEntry.isStatus;
      apiParams.isNoError = serviceEntry.isNoError;
  }
  return apiParams;
}