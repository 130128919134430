import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SolmanStaging from "../../../components/Admin/SolmanStaging";
import * as actions from "../../../actions/Admin/solmanStagingAction";
const SolmanStagingContainer = () => {
  const dispatch = useDispatch();
  const globalSolmanStagingState = useSelector((state) => state.solmanStaging);
  useEffect(() => {
    if (globalSolmanStagingState.solmanStagingData?.length === 0) {
      dispatch(actions.getSolmanStagingDetails());
    }
  }, [])
  const saveSolmanDetails = (solmanDetails, isEdit, i) => {
    if (isEdit) {
      dispatch(actions.modifySolmanStaging(solmanDetails, i));
    } else {
      dispatch(actions.addSolmanStaging(solmanDetails));
    }
  };
  const onSolmanDelete = (id, i) => {
    dispatch(actions.deleteSolmanStaging([id], i));
  };
  return (
    <div>
      <SolmanStaging
        isLoading={globalSolmanStagingState.isLoading}
        solmanStaging={globalSolmanStagingState.solmanStagingData}
        onSolmanDelete={onSolmanDelete}
        saveSolmanDetails={saveSolmanDetails}
      />
    </div>
  );
}
export default SolmanStagingContainer;
