
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getReplayReportData, replayReportLoading } from '../../../actions/Exceptions/exceptionActions';
import BulkReplay from '../../../components/BulkReplay';
class ExceptionBulkReplay extends React.Component {
  componentDidMount() {
    window.iosLoader = true
    this.getReplayDetailInit()
  }
  timeOut;
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.replayReportData && typeof(nextProps.replayReportData)!=="string" && nextProps.replayReportData.length > 0) {
      if (nextProps.replayReportData.find(val => val.status === "Scheduled")) {
        this.timeOut = setTimeout(() => {
          this.initatedTimer()
        }, 10000)
      }
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeOut)
  }

  initatedTimer() {
    this.getReplayDetail()
  }
  getReplayDetailInit = () => {
    //const requestParams = this.props.userAccessInfo.userID
    this.props.dispatch(getReplayReportData())
  }

  getReplayDetail = () => {
    window.iosLoader = false
    //const requestParams = this.props.userAccessInfo.userID
    this.props.dispatch(getReplayReportData())
  }
  getReplayRefresh = () => {
    this.props.dispatch(replayReportLoading())
    this.getReplayDetail()
  }
  render() {
    return (
      <BulkReplay
        timeZone={this.props.timeZone}
        getReplayRefresh={this.getReplayRefresh}
        replayReportLoading={this.props.replayReportLoading}
        getReplayDetail={this.getReplayDetail}
        userAccessInfo={this.props.userAccessInfo}
        replayReportData={this.props.replayReportData} />
    );
  }
}
ExceptionBulkReplay.propTypes = {
  dispatch: PropTypes.func,
  replayReportData: PropTypes.array,
  userAccessInfo: PropTypes.object,
  replayReportLoading: PropTypes.bool,
  timeZone: PropTypes.bool
};
function mapStateToProps(state) {
  return {
    replayReportData: state.exceptions.replayReportData,
    userAccessInfo: state.userAccess.userAccessInfo,
    replayReportLoading: state.exceptions.isReplayLoading,
    timeZone: state.configReducer.isCstTimeZone
  };
}
export default connect(mapStateToProps)(ExceptionBulkReplay);
