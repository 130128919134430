const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true,
};
const COLUMNS = [
  fieldHead,
  {
    field: "applicationName",
    headerName: "Application Name",
  },
  {
    field: "service",
    headerName: "Service",
  },
  {
    field: "component",
    headerName: "Component",
  },
  {
    field: "category",
    headerName: "Category",
  },
  {
    field: "type",
    headerName: "Type",
  },
  // {
  //   field: "severity",
  //   headerName: "Severity",
  // },
  {
    field: "code",
    headerName: "Code",
  },
  {
    field: "message",
    headerName: "Message",
  },
  {
    field: "messageNVP",
    headerName: "Message NVP",
  },
  {
    field: "dumpAnalysis",
    headerName: "Dump Analysis",
  },
  {
    field: "replayEnabled",
    headerName: "Replay Enabled",
  },
  // {
  //   field: "specialreplay",
  //   headerName: "Special Replay",
  // },
  // {
  //   field: "configid",
  //   headerName: "Config ID",
  // },
  // {
  //   field: "replayInterval",
  //   headerName: "Replay Interval",
  // },
  {
    field: "scheduledTime",
    headerName: "Scheduled Time",
  },
  {
    field: "searchWindow",
    headerName: "Search Window",
  }
  
];

export default COLUMNS;
