import React, { useState, useEffect } from "react";
import { Form, Modal, Radio, Input, Row, Col } from "antd";
import PropTypes from "prop-types";
import { applicationDataInstance } from "../../../services/applicationDataService";
import SelectDropDown from '../../CommonComponents/SelectDropDown';
import AdminModalFooter from "../../CommonComponents/Modal/AdminModalFooter";
import { formItemsLayout } from "../../../constants/AdminModalForm/FormStyleConfig";
import { COLUMN_NAME_OPTIONS } from "../../../constants/AdminModalForm/ConfigColumnAlerts";
import { SNOW_NOTIFICATION_SERVICE_NAME } from "../../../constants/AdminModalForm/SnowNotificationServiceName";

export const formItemsDefaultStyle = {
    width: "90%",
    marginBottom: "5px"
};

const NewConfigColumnAlert = ({ ...props }) => {
    const [form] = Form.useForm();
    const applicationNames = applicationDataInstance.getApplicationNames();
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [severityOptions, setSeverityOptions] = useState([]);
    const isSnowNotificationChangedValue = Form.useWatch('enableSnowNotification', form);
    useEffect(() => {
        if (props.editFlag) {
            form.setFieldsValue({ ...props.data[0] });
        }
    }, []);

    const onSaveHandler = (close) => {
        form.validateFields()
            .then(async values => {
                console.log(values, '-----values');
                if (props.editFlag) {
                    values = {
                        "columnAlertId": props.data[0]["columnAlertId"],
                        "columnAlertKey": props.data[0]["columnAlertKey"],
                        ...values
                    };
                }
                props.saveData(values, close);
            });
    };
    const onFormValueChangeHandler = (changedValues) => {
        if (changedValues["applicationName"]) {
            setCategoryOptions(changedValues["applicationName"] ?
                applicationDataInstance.getCategoriesForApplication(changedValues["applicationName"])
                : []);
            setTypeOptions(changedValues["applicationName"] ?
                applicationDataInstance.getTypesForApplication(changedValues["applicationName"])
                : []);
            setSeverityOptions(changedValues["applicationName"] ?
                applicationDataInstance.getSeveritiesForApplication(changedValues["applicationName"])
                : []);
            form.setFieldsValue({
                ...form.getFieldsValue(),
                "exceptionCategoryName": undefined,
                "exceptionTypeName": undefined,
                "exceptionSeverityName": undefined
            });
        }
    };
    return (<Modal
        open={props.isModalOpen}
        title={(props.editFlag ? "Edit" : "New") + " Column Alert Configuration"}
        onCancel={props.onCloseModal}
        footer={
            (props.editFlag ?
                <AdminModalFooter
                    close update
                    onUpdate={() => onSaveHandler(true)}
                    onClose={props.onCloseModal}
                />
                :
                <AdminModalFooter
                    save saveAndClose close
                    onSave={() => onSaveHandler(false)}
                    onSaveAndClose={() => onSaveHandler(true)}
                    onClose={props.onCloseModal}
                />)
        }
        style={{ minWidth: '80%' }}
        className="admin-modal-wrapper"
        centered
    >
        <Form
            id="exception-config"
            form={form}
            {...formItemsLayout}
            labelAlign="left"
            colon
            initialValues={{
                "enableMailNotification": 0,
                "enableGroupNotification": 0,
                "enableSnowNotification": 0
            }}
            onValuesChange={onFormValueChangeHandler}
        >
            <Row>
                <Col span={12}>
                    <Form.Item label="Application" name="applicationName"
                        rules={[{ required: true, message: 'Application is Mandatory!' }]}
                        style={formItemsDefaultStyle}>
                        <SelectDropDown
                            options={applicationNames}
                            disabled={props.editFlag}
                        />
                    </Form.Item>
                    <Form.Item label="Category" name="exceptionCategoryName"
                        rules={[{ required: true, message: 'Category is Mandatory!' }]}
                        style={formItemsDefaultStyle}>
                        <SelectDropDown
                            fieldNames={{
                                "label": "exceptionCategoryName",
                                "value": "exceptionCategoryName"
                            }}
                            options={categoryOptions}
                            disabled={props.editFlag}
                        />
                    </Form.Item>
                    <Form.Item label="Type" name="exceptionTypeName"
                        rules={[{ required: true, message: 'Type is Mandatory!' }]}
                        style={formItemsDefaultStyle}>
                        <SelectDropDown
                            fieldNames={{
                                "label": "exceptionTypeName",
                                "value": "exceptionTypeName"
                            }}
                            options={typeOptions}
                            disabled={props.editFlag}
                        />
                    </Form.Item>
                    <Form.Item label="Severity" name="exceptionSeverityName"
                        rules={[{ required: true, message: 'Severity is Mandatory!' }]}
                        style={formItemsDefaultStyle}>
                        <SelectDropDown
                            fieldNames={{
                                "label": "exceptionSeverityName",
                                "value": "exceptionSeverityName"
                            }}
                            options={severityOptions}
                            disabled={props.editFlag}
                        />
                    </Form.Item>

                    <Form.Item label="Column Name" name="columnName"
                        rules={[{ required: true, message: 'Column Name is Mandatory!' }]}
                        style={formItemsDefaultStyle}>
                        <SelectDropDown
                            options={COLUMN_NAME_OPTIONS}
                            disabled={props.editFlag}
                        />
                    </Form.Item>
                    <Form.Item label="Column Value" name="columnValue"
                        rules={[{ required: true, message: 'Column Value is Mandatory!' }]}
                        style={formItemsDefaultStyle}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Description" name="description"
                        style={formItemsDefaultStyle}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Enable Email Notification" name="enableMailNotification"
                        style={formItemsDefaultStyle}>
                        <Radio.Group >
                            <Radio value={1}>Enable</Radio>
                            <Radio value={0}>Disable</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Email Notification" name="emailAddress"
                        rules={[{ required: true, message: 'Email Notification is Mandatory!' }]}
                        style={formItemsDefaultStyle}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Enable Group Notification" name="enableGroupNotification"
                        style={formItemsDefaultStyle}>
                        <Radio.Group >
                            <Radio value={1}>Enable</Radio>
                            <Radio value={0}>Disable</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Assignment Group" name="notificationGroup"
                        style={formItemsDefaultStyle}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Enable Snow Notification" name="enableSnowNotification"
                        style={formItemsDefaultStyle}>
                        <Radio.Group >
                            <Radio value={1}>Enable</Radio>
                            <Radio value={0}>Disable</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        isSnowNotificationChangedValue === 1 &&
                        <>
                            <Form.Item
                                label="Snow Service Name"
                                name="sysClassName"
                                rules={[{ required: true, message: 'Snow Notification Service Name is Mandatory!' }]}
                                style={formItemsDefaultStyle}
                            >
                                <SelectDropDown
                                    options={SNOW_NOTIFICATION_SERVICE_NAME}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Snow Service Value"
                                name="snowParam1"
                                rules={[{ required: true, message: 'Snow Service Value is Mandatory!' }]}
                                style={formItemsDefaultStyle}
                            >
                                <Input />
                            </Form.Item>
                            {/* <Popover style={{ padding: '5px 0px 0px 10px' }}
                                content={snowCIInfoContent}
                                title="Title"
                                trigger="hover">
                                <InfoCircleOutlined />
                            </Popover> */}
                        </>
                    }
                </Col>
            </Row>
        </Form>
    </Modal>);
};
NewConfigColumnAlert.propTypes = {
    onCloseModal: PropTypes.func,
    saveData: PropTypes.func,
    data: PropTypes.any,
    editFlag: PropTypes.bool,
    isModalOpen: PropTypes.bool,
};
export default NewConfigColumnAlert;