import * as types from '../../constants/ActionTypes/Admin/tableConfigTypes';
import { LOG_EXCEPTION_TABLE } from "../../constants/ActionTypes/Admin/applicationConfigTypes";
import { constructServiceUrl } from '../../services/constructUrl';

export function getIndexDetails() {
    let serviceParams = {};
    serviceParams.apiName = LOG_EXCEPTION_TABLE;
    let requestParams = constructServiceUrl(serviceParams);
    window.iosLoader = false;
    return {
      type: LOG_EXCEPTION_TABLE,
      request: requestParams
    };
}

export function reportDataStore(data) {
  return {
    type: types.STORE_DATA,
    data
  };
}
export function reportProcess() {
  return {
    type: types.PROCESS_DATA
  }
}
export function addIndexConfig(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_INDEX_CONFIG;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_INDEX_CONFIG,
    request: requestParams
  };
}
