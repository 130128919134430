import { call, put, fork, all, takeLatest, delay, retry } from 'redux-saga/effects';
import {
  updateExceptionSearchResults, updateExceptionDetails, updateReplayExceptionResult,
  updateExceptionResultsonFilter, getFilterExceptionResult, updateExceptionFilterStatus,
  updateExceptionBulkReplayStatus,
  updateExceptionPurgeResult, updateReplayReport, updateTotalNoOfExceptions,
  updateExceptionSearchFailure, updateExceptionSortResults,
  updateExceptionSortFailure, updateExceptionDetailsSave
} from '../../actions/Exceptions/exceptionActions';
import * as types from '../../constants/ActionTypes/exceptionTypes';
import { defaultAction } from '../../services/restService';
import { toast } from 'react-toastify';
import { logger } from 'redux-logger';
import { exceptionFilterStatus, exceptionPurgeStatus, exceptionResolveStatus } from "../../actions/GlobalAction/GlobalAction"

export function* fetchExceptionSearchResults(payload) {
  try {
    const SECOND = 1000
    const exceptionData = yield retry(3, 10 * SECOND, defaultAction, payload)
    // const exceptionData = yield call(defaultAction, payload);
    yield put(updateExceptionSearchResults(exceptionData, payload.request.payload));
  } catch (e) {
    yield put(updateExceptionSearchFailure('Error in Fetching Exception Data'));
  }
}
export function* fetchSortedExceptionResults(payload) {
  try {
    const exceptionData = yield call(defaultAction, payload);
    yield put(updateExceptionSortResults(exceptionData, payload.request.payload));
  } catch (e) {
    yield put(updateExceptionSortFailure('Error in fetching Log Data'));
  }
}
export function* fetchExceptionDetails(payload) {
  try {
    const exceptionDetails = yield call(defaultAction, payload);
    yield put(updateExceptionDetails(exceptionDetails));
  } catch (e) {
    logger(e)
    // yield put(updateExceptionFailure("Error"));

  }
}
export function* replayExceptionsResult(requestParams) {
  try {
    const replayResult = yield call(defaultAction, requestParams)
    toast.info(`Exception resolve has been started`);
    // let result=JSON.parse(replayResult)
    // result=result.filter(({replayStatus})=> replayStatus==="Scheduled")
    // if(result.length > 0){
    //   fetchReplayStatus(requestParams)
    // }
    yield put(updateReplayExceptionResult(replayResult, requestParams.request.payload));
  } catch (e) {
    logger(e)
  }
}
export function* filterExceptionSearchResults(requestParams) {
  try {
    window.timeLimit = 60000
    toast.info(`Exception filter has been started`);
    yield call(defaultAction, requestParams);
    yield delay(60000)
    toast.success(`Exception filter has been completed`);
    yield put(updateExceptionResultsonFilter(requestParams.request.payload));
  } catch (e) {
    logger(e)
  }
}
export function* defilterExceptionSearchResults(requestParams) {
  try {
    window.timeLimit = 60000
    toast.info(`Exception de-filter has been started`);
    yield call(defaultAction, requestParams);
    yield delay(60000)
    toast.success(`Exception de-filter has been completed`);
    yield put(updateExceptionResultsonFilter(requestParams.request.payload));
  } catch (e) {
    logger(e)
  }
}
export function* processExceptionFilter(requestParams) {
  try {
    window.timeLimit = 60000
    const filterResult = yield call(defaultAction, requestParams);
    yield put(exceptionFilterStatus("Filter"))
    toast.info(`Exceptions filter Process has been started`);
    yield delay(60000)
    window.openProcessModal("Filter")
    yield put(getFilterExceptionResult(filterResult, requestParams.action));
  } catch (e) {
    const errorStatus = {
      "filterRequestID": null,
      "requestStatus": "Error",
      "recordsCount": null
    };
    yield put(updateExceptionFilterStatus(errorStatus, requestParams.action));
  }
}
export function* processExceptionDefilter(requestParams) {
  try {
    window.timeLimit = 60000
    const filterResult = yield call(defaultAction, requestParams);
    yield put(exceptionFilterStatus("De-Filter"))
    toast.info(`Exceptions De-filter Process has been started`);
    yield delay(60000)
    window.openProcessModal("De-Filter")
    yield put(getFilterExceptionResult(filterResult, requestParams.action));
  } catch (e) {
    const errorStatus = {
      "filterRequestID": null,
      "requestStatus": "Error",
      "recordsCount": null
    };
    yield put(updateExceptionFilterStatus(errorStatus, requestParams.action));
  }
}
export function* fetchFilteredExceptionStatus(requestParams) {
  try {
    yield delay(5000)
    const filterStatus = yield call(defaultAction, requestParams);
    if (filterStatus.requestStatus !== "Completed") {
      yield put(getFilterExceptionResult(filterStatus, requestParams.action));
    }
    else {
      toast.success(`Exception ${requestParams.actionType ? requestParams.actionType : requestParams.action} Process has been completed`);
      if (requestParams.action === "Filter" || requestParams.action === "De-Filter") {
        // yield put(updateExceptionResultsonFilter(requestParams.request.payload));
        yield put(exceptionFilterStatus(null))
        return;
      }
      else if (requestParams.action === "Resolve") {
        yield put(updateExceptionBulkReplayStatus(filterStatus, requestParams.action));
        yield put(exceptionResolveStatus(null))
        return;
      }
      else {
        // yield put(updateExceptionPurgeResult(requestParams.request.payload));
        yield put(exceptionPurgeStatus(null))
      }
      // yield put(updateExceptionFilterStatus(filterStatus, requestParams.action));
    }
  } catch (e) {
    logger(e)
  }
}
export function* processPurgeExceptions(requestParams) {
  try {
    window.timeLimit = 60000
    toast.info(`Exception Purge has been started`);
    yield call(defaultAction, requestParams);
    yield delay(60000)
    toast.success(`Exception Purge has been completed`);
    yield put(updateExceptionPurgeResult(requestParams.request.payload));
  } catch (e) {
    logger(e)
  }
}
export function* processExceptionResolve(requestParams) {
  try {
    const status = yield call(defaultAction, requestParams);
    yield put(exceptionResolveStatus("Resolve"))
    toast.info(`Exceptions Resolve Process has been started`);
    yield put(getFilterExceptionResult(status, requestParams.action));
  } catch (e) {
    const errorStatus = {
      "filterRequestID": null,
      "requestStatus": "Error",
      "recordsCount": null
    };
    yield put(updateExceptionFilterStatus(errorStatus, requestParams.action));
  }
}
export function* fetchReplayReportData(requestParams) {
  try {
    // yield put(replayReportLoading())
    const reportData = yield call(defaultAction, requestParams);
    yield put(updateReplayReport(reportData));
  } catch (e) {
    logger(e)
  }
}
export function* processFilterExceptionPurge(requestParams) {
  try {
    window.timeLimit = 60000
    toast.info(`Exceptions Purge Process has been started`);
    let filterResult = yield call(defaultAction, requestParams);
    yield delay(60000)
    window.openProcessModal("Purge")
    yield put(getFilterExceptionResult(filterResult, requestParams.action));
    // toast.success(`Exceptions Purge Process has been completed`);
    // const status = {
    //   "filterRequestID": null,
    //   "requestStatus": "Completed",
    //   "recordsCount": null
    // };
    // yield put(updateExceptionFilterStatus(status, requestParams.action));
  } catch (e) {
    const status = {
      "filterRequestID": null,
      "requestStatus": "Error",
      "recordsCount": null
    };
    yield put(updateExceptionFilterStatus(status, requestParams.action));
  }
}
export function* fetchTotalNoOfExceptions(payload) {
  try {
    // yield delay(5000)
    const exceptionData = yield call(defaultAction, payload);
    yield put(updateTotalNoOfExceptions(exceptionData));

  } catch (e) {
    logger(e)
  }
}
export function* processExceptionDetailsSave(payload) {
  try {
    yield call(defaultAction, payload);
    yield put(updateExceptionDetailsSave('success', payload.request.payload.exceptionID));
  } catch (e) {
    yield put(updateExceptionDetailsSave('error', payload.request.payload.exceptionID));
  }
}
export function* fetchSolmanExceptionDetails(payload) {
  try {
    const exceptionDetails = yield call(defaultAction, payload);
    yield put(updateExceptionDetails(exceptionDetails));
  } catch (e) {
    logger(e)
  }
}
export function* fetchReplayStatus(requestParams) {
  try {
    yield delay(10000)
    const exceptionDetails = yield call(defaultAction, requestParams);
    let result = exceptionDetails
    result = result.filter(({ replayStatus }) => replayStatus === "Scheduled")
    if (result.length === 0) {
      toast.success(`Exception resolve has been completed`);
    }
    yield put(updateReplayExceptionResult(exceptionDetails, requestParams.request.payload));

  } catch (e) {
    logger(e)
  }
}

export function* exceptionSearch() {
  yield takeLatest(types.SEARCH_EXCEPTIONS, fetchExceptionSearchResults);
}
export function* getExceptionDetails() {
  yield takeLatest(types.GET_EXCEPTION_DETAILS, fetchExceptionDetails);
}
export function* replayExceptions() {
  yield takeLatest(types.REPLAY_EXCEPTIONS, replayExceptionsResult);
}
export function* filterExceptionSearchResult() {
  yield takeLatest(types.EXCEPTION_SEARCH_FILTER_EXCEPTIONS, filterExceptionSearchResults);
}
export function* defilterExceptionSearchResult() {
  yield takeLatest(types.EXCEPTION_SEARCH_DEFILTER_EXCEPTIONS, defilterExceptionSearchResults);
}
export function* filterExceptions() {
  yield takeLatest(types.FILTER_EXCEPTIONS, processExceptionFilter);
}
export function* filterExceptionStatus() {
  yield takeLatest(types.GET_FILTERED_EXCEPTION_STATUS, fetchFilteredExceptionStatus);
}
export function* defilterExceptions() {
  yield takeLatest(types.DEFILTER_EXCEPTIONS, processExceptionDefilter);
}
export function* purgeExceptions() {
  yield takeLatest(types.PURGE_EXCEPTIONS, processPurgeExceptions);
}
export function* resolveExceptions() {
  yield takeLatest(types.FILTER_EXCEPTIONS_RESOLVE, processExceptionResolve);
}
export function* filterExceptionPurge() {
  yield takeLatest(types.GET_REPLAY_REPORT_DATA, fetchReplayReportData);
}
export function* getReplayReportData() {
  yield takeLatest(types.FILTER_EXCEPTIONS_PURGE, processFilterExceptionPurge);
}
export function* getTotalNoOfExceptions() {
  yield takeLatest(types.GET_TOTAL_EXCEPTION_RECORD_COUNT, fetchTotalNoOfExceptions);
}
export function* exceptionSort() {
  yield takeLatest(types.SORT_EXCEPTIONS, fetchSortedExceptionResults);
}
export function* saveExceptionDetails() {
  yield takeLatest(types.SAVE_EXCEPTION_DETAILS, processExceptionDetailsSave);
}
export function* getSolmanExceptionDetails() {
  yield takeLatest(types.GET_SOLMAN_EXCEPTION_DETAILS, fetchSolmanExceptionDetails);
}
export function* getReplayStatus() {
  yield takeLatest(types.REPLAY_EXCEPTIONS_STATUS, fetchReplayStatus);
}
export default function* root() {
  yield all([
    fork(getReplayStatus),
    fork(exceptionSearch),
    fork(getExceptionDetails),
    fork(replayExceptions),
    fork(filterExceptions),
    fork(filterExceptionSearchResult),
    fork(defilterExceptionSearchResult),
    fork(filterExceptionStatus),
    fork(defilterExceptions),
    fork(purgeExceptions),
    fork(resolveExceptions),
    fork(getReplayReportData),
    fork(filterExceptionPurge),
    fork(getTotalNoOfExceptions),
    fork(exceptionSort),
    fork(saveExceptionDetails),
    fork(getSolmanExceptionDetails)
  ])
}
