import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

const ModalView = ({
  children,
  visible,
  footer = null,
  title = null,
  size = "50%",
  onClose,
}) => {
  return (
    <Modal
      title={title}
      centered
      closable={false}
      open={visible}
      footer={footer}
      width={size}
      onCancel={onClose}
      destroyOnClose
    >
      {children}
    </Modal>
  );
};

ModalView.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.node,
  visible: PropTypes.bool,
};

export default ModalView;
