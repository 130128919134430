import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import SelectDropDown from "../../../components/CommonComponents/SelectDropDown";
import "./SelectGroupForCreateUser.css";

const _ = require("lodash");

const SelectGroupForCreateUser = ({ ...props }) => {
  const [errorIndex, setErrorIndex] = useState([]);
  const [applicationGroupList, setApplicationGroupList] = useState(
    _.cloneDeep(props.applicationGroupOptions)
  );
  const onSave = () => {
    let tempErrorIndex = [];
    applicationGroupList.forEach((applicationElement, index) => {
      if (applicationElement.selectedGroup === undefined) {
        tempErrorIndex.push(index);
      }
    });
    setErrorIndex(tempErrorIndex);
    if (tempErrorIndex.length == 0) {
      props.onSaveData(applicationGroupList);
    }
  };

  const onChangeSelectGroupHandler = (selectedOption, selectedIndex) => {
    applicationGroupList[selectedIndex]["selectedGroup"] = selectedOption;
    setApplicationGroupList(applicationGroupList);
  };
  return (
    <Modal
      className="select-group-for-user-create"
      open={props.isModalOpen}
      onCancel={props.onCloseModal}
      title={
        <h5 className="modal-title">
          Application access requires your attention
        </h5>
      }
      style={{ minWidth: "55%" }}
      footer={
        <Row justify={"end"}>
          <Button
            type="primary"
            size={"default"}
            className="secondary"
            onClick={onSave}
            icon={<i className="fas fa-save mr10" />}>
            Save
          </Button>
          <Button
            type="primary"
            danger
            onClick={props.onCloseModal}
            id="sel_details_close_btn"
            className="d-flex align-items-center"
            icon={<CloseOutlined />}
            size={"default"}>
            Close
          </Button>
        </Row>
      }>
      {applicationGroupList &&
        applicationGroupList.map((applicationGroupElement, index) => (
          <>
            <Row
              className={
                "line-item-row " +
                (errorIndex.includes(index) ? " line-item-error" : "")
              }
              key={index}
              gutter={16}>
              <Col span={7} className="p10">
                {applicationGroupElement.applicationName}
              </Col>
              <Col span={7} className="p10">
                {applicationGroupElement.accessName}
              </Col>
              <Col span={10}>
                <SelectDropDown
                  className="cle-select"
                  size="large"
                  fieldNames={{ label: "groupName", value: "groupName" }}
                  options={applicationGroupElement.groupName}
                  placeholder={"Select Group"}
                  onChange={(val, selectedOption) =>
                    onChangeSelectGroupHandler(selectedOption, index)
                  }
                />
              </Col>
            </Row>
            {errorIndex.includes(index) && (
              <Row justify={"end"} key={"error-"+index}>
                <div className="error-item-msg">
                  Invalid application access found !
                </div>
              </Row>
            )}
          </>
        ))}
    </Modal>
  );
};

SelectGroupForCreateUser.propTypes = {
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSaveData: PropTypes.func,
  applicationGroupOptions: PropTypes.array,
};
export default SelectGroupForCreateUser;
