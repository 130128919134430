import React, { useEffect, useState } from "react";
import { Form, Row, Col, Radio, Tooltip } from "antd";
import PropTypes from "prop-types";
import SelectDropDown from "../../../components/CommonComponents/SelectDropDown";
import { applicationDataInstance } from "../../../services/applicationDataService";
import "../lineItem.css"

const userAccessRolesList = [
    { label: "View", value: "view" },
    { label: "Replay", value: "replay" },
    { label: "Edit Header", value: "edit_JMS_Header" },
    { label: "Edit Payload", value: "edit_JMS_Payload" },
];

const ApplicationAccessItem = ({ ...props }) => {
    const applicationNames = applicationDataInstance.getApplicationNames();
    const [editIndex, setEditIndex] = useState(-1);
    useEffect(() => {
        setEditIndex(-1);
    }, [props.selectedGroup]);
    const onApplicationNameChangeHandler = (selectedApplication, index) => {
        let appAccessList = props.formRef.getFieldValue("appAccessList");
        appAccessList[index] = { groupName: props?.selectedGroup?.groupName, applicationName: selectedApplication };
        props.formRef.setFieldValue("appAccessList", appAccessList);
    };

    return (
        <Form.List name="appAccessList">
            {(appAccessList, { add, remove }) => {
                return (
                    <>
                        <div className="line-item-header">
                            <div className="line-item-header-text">Application access</div>
                            <div
                                className="pointer add-item-container"
                                onClick={() => {
                                    add();
                                    setEditIndex(appAccessList.length);
                                }}
                            >
                                <i
                                    className="fa fa-plus-circle add-item-button"
                                    aria-hidden="true"
                                />
                                <span className="add-item-txt">Add Item</span>
                            </div>
                        </div>
                        <div className="line-item-list-wrapper">
                            {appAccessList.length == 0 && (
                                <div className="no_record_role">No Data Found.</div>
                            )}
                            {appAccessList.map((accessElement, index) => (
                                <Row
                                    justify="space-between"
                                    key={"accessElement-" + index}
                                    className={
                                        "line-item-row " +
                                        (props.formRef.getFieldsError().filter(
                                            (fieldErr) =>
                                                fieldErr.errors.length > 0 &&
                                                fieldErr.name.includes("appAccessList") &&
                                                fieldErr.name.includes(index)
                                        ).length > 0
                                            ? "line-item-error "
                                            : "")
                                    }
                                >
                                    <Col span={5}>
                                        <Form.Item
                                            name={[index, "applicationName"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Application Name is Mandatory!",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (props.formAppNameList.findIndex((item, id) => id !== index && item.applicationName === value) > -1) {
                                                            return Promise.reject('Application cannot have multiple access');
                                                        }
            
                                                        return Promise.resolve();
                                                    }
                                                })
                                            ]}
                                            style={{ margin: "0px" }}
                                            className="user-application-select"
                                        >
                                            {editIndex === index && (
                                                <SelectDropDown
                                                    placeholder={"Select Application"}
                                                    className="cle-select"
                                                    value={props.formRef &&
                                                        props.formRef.getFieldValue("appAccessList")[index]
                                                        ? props.formRef.getFieldValue("appAccessList")[index]["applicationName"]
                                                        : null}
                                                    options={applicationNames}
                                                    onChange={(e) =>
                                                        onApplicationNameChangeHandler(e, index)
                                                    }
                                                />
                                            )}
                                            {editIndex !== index && (
                                                <div>
                                                    {
                                                        props.formRef &&
                                                            props.formRef.getFieldValue("appAccessList")[index]
                                                            ? props.formRef.getFieldValue("appAccessList")[index]["applicationName"]
                                                            : "Select Application"
                                                    }
                                                </div>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={1} >{ }</Col>
                                    <Col span={14} className="access-col-wrapper">
                                        <Form.Item
                                            name={[index, "accessName"]}
                                            rules={[
                                                { required: true, message: "Access is Mandatory!" },
                                            ]}
                                            style={{ minWidth: "25vw", margin: "0px" }}
                                        >
                                            <Radio.Group
                                                options={userAccessRolesList}
                                                className="access-wrapper"
                                                disabled={editIndex !== index}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1} >{ }</Col>
                                    <Col span={1} className="d-flex icon-wrapper">
                                        {editIndex !== index && (
                                            <Tooltip title="Edit">
                                                <span
                                                    className="action-icon-edit pointer"
                                                    onClick={() => setEditIndex(index)}
                                                >
                                                    <i
                                                        className="fas fa-pencil-alt"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Col>
                                    <Col span={1} className="d-flex icon-wrapper">
                                        <Tooltip title="Delete">
                                            <span
                                                className="action-icon-delete pointer"
                                                onClick={() => {
                                                    remove(accessElement.name);
                                                    setEditIndex(-1);
                                                }}
                                            >
                                                <i className="far fa-trash-alt" aria-hidden="true" />
                                            </span>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </>
                );
            }}
        </Form.List>
    );
};

ApplicationAccessItem.propTypes = {
    updateUserAccessHandler: PropTypes.func,
    formAppNameList: PropTypes.array,
    formRef: PropTypes.any,
    selectedGroup: PropTypes.any
};
export default ApplicationAccessItem;
