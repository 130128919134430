import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Popover, Modal, Row, Col, Tooltip, notification } from "antd";
import LoadingBar from "react-top-loading-bar";
import { isCancel, CancelToken } from "axios";
import { TableOutlined } from '@ant-design/icons';
import _ from 'lodash';
import CleFooter from "../../components/CommonComponents/CleFooter/CleFooter";
import LOG_COLUMNS from "../../constants/ColumnConfig/logResults";
import WrapperPage from "../../components/WrapperPage/Page";
import CleTable from "../../components/CommonComponents/CleTable/CleTable";
import { outApiCheck } from "../../services/axiosInstance";
import EXCEPTION_COLUMNS from "../../constants/ColumnConfig/exceptionResults";
import ModalView from "../../components/CommonComponents/Modal/ModalView";
import ModalTitle from "../../components/CommonComponents/Modal/ModalTitle";
import ModalFooter from "../../components/CommonComponents/Modal/ModalFooter";
import modalWarning from "../../components/CommonComponents/Modal/ModalWarning";
import FilterDrawer from "../../components/CommonComponents/FilterDrawer/index";
import BlobDetail from "../../components/BlobDetail/BlobDetail";
import MultiSelect from "../../components/CommonComponents/MultiSelect/MultiSelect";
import SelectFieldForExportModal from "../../components/CommonComponents/Modal/SelectFieldForExportModal";
import { getTokenToReqHeader } from "../../utils/helpers";
import { userAccessValidator } from "../../utils/userAccessValidator";
import AxiosUrl from "../../services/axiosInstanceCustom";
import "./CommonTable.scss";

let cancelTableToken;
let cancelTableCountToken;
let cancelReplay;
let cancelReplayAll;
let cancelReplayStatus;
let cancelFilter;
let cancelFilterAll;

const MAX_DATA = 10000;
const RECALL_TIME = 5000;
const RESOLVE_STATUS_CHECK = 10000;
const ERROR_DISP = "Oops something went wrong!";
class CommonTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      selectedCount: 0,
      sortDirection: "desc",
      showModal: false,
      showDrawer: false,
      dataIndex: 0,
      displayedRowCount: undefined,
      selectedRow: null,
      overLoading: false,
      modalOverLoading: false,
      exportExcelModal: false,
      downloadProgress: false,
      disabledFooter: false,
      searchComplete: false,
      refreshload: false,
      rowData: [],
      selectedTag: "",
      selectedHeader: "",
      COLUMN_CONFIG: this.props.history.location.pathname.includes("logs")
        ? LOG_COLUMNS
        : EXCEPTION_COLUMNS,
      filterStateObject: {},
      columnList: this.props.history.location.pathname.includes("logs")
        ? LOG_COLUMNS
        : EXCEPTION_COLUMNS,
      visibleColumnList: [],
      auditUserGroups: [],
    };
  }
  gridApi = null;
  //tableData = null;
  progressLoader = null;
  //API_INITIATED = false;
  // sortSelectedColumn = "timestamp";
  API_DETAIL = [];
  TOTAL_COUNT = 0;
  modalData = {
    modalTitle: `${"Log"} Detail`,
    modalTitleIcon: "detail",
    nextPrevBtn: false,
    editBtn: false,
    size: "75%",
  };
  modalFooter = {
    update: false,
    close: true,
    resolve: false,
    filter: false,
    defilter: false,
  };

  dataIndex = 0;
  //API_DETAIL_REQUEST = [];
  API_DETAIL_RESPONSE = [];
  API_AUDIT_RESPONSE = [];
  RESOLVE_RECORDS = [];
  tableSearchData = [];
  statusObj = {};
  ALL_RESOLVE_INITAITE = false;
  NORECORD_FLAG = false;
  SCREEN_TYPE = this.props.history.location.pathname.includes("logs")
    ? "log"
    : "exception";
  cleFooter = {
    resolve: false,
    // filter: false,
    // defilter: false,
    // purge: false,
  };
  // sortColumns =
  //   this.SCREEN_TYPE === "log" ? SORT_LOG_COLUMNS : SORT_EXCEPTION_COLUMNS;
  componentDidMount() {
    let visibleColumnList = [];
    if (sessionStorage.getItem('exceptionapi')?.length > 1) {
      let exceptionInfo = JSON.parse(sessionStorage.getItem('exceptionapi'));
      this.getExceptionDetail(exceptionInfo);
      this.NORECORD_FLAG = true;
    }
    if (this.props.totalCount === undefined || this.props.totalCount === 0) {
      this.GET_COUNT();
    }
    this.GET_DATA();
    if (sessionStorage.getItem(`${this.SCREEN_TYPE}VisibleColumnList`)?.length > 1) {
      visibleColumnList = JSON.parse(sessionStorage.getItem(`${this.SCREEN_TYPE}VisibleColumnList`));
    } else {
      visibleColumnList = this.getColumnList();
    }
    this.onColumnsVisibilityChange(visibleColumnList);

    if (this.state.searchComplete === true)
      this.setState({ searchComplete: false });
  }


  UNSAFE_componentWillMount() {
    let keyName =
      this.SCREEN_TYPE === "log"
        ? "logSearchCriterias"
        : "exceptionSearchCriterias";
    let query = JSON.parse(sessionStorage.getItem(keyName));
    if (query && query.appName.length > 0) {
      let table = this.SCREEN_TYPE === "log" ? "logTable" : "exceptionTable";
      let application = query.appName;
      let appGroup = application.reduce((r, a) => {
        r[a[table]] = [...(r[a[table]] || []), a];
        return r;
      }, {});
      let payload = [];
      Object.keys(appGroup).forEach((val) => {
        let applicationName = appGroup[val].map((app) => app.value).join(" ");
        let newQuery = { ...query, table: val, applicationName };
        payload.push(newQuery);
      });
      this.API_DETAIL = payload;
      //let checkAccessBtn;
      let checkResolveBtn = true;
      // if (query.filtered === "N") {
      //   checkAccessBtn = "filter";
      // }
      // if (query.filtered === "Y") {
      //   checkAccessBtn = "defilter";
      // }
      if (
        query.exceptionStatus &&
        query.exceptionStatus.length === 1 &&
        query.exceptionStatus.includes("Resolved")
      ) {
        checkResolveBtn = false;
      }
      if (
        this.props.userAccessInfo?.adminAccess === "Y" &&
        this.API_DETAIL.length === 1
      ) {
        this.cleFooter = {
          resolve: this.SCREEN_TYPE === "exception" && checkResolveBtn,
          // filter: checkAccessBtn === "filter",
          // defilter: checkAccessBtn === "defilter",
          // purge: true,
        };
      } else if (this.API_DETAIL.length === 1) {
        let purgeAccess = userAccessValidator.validateAccessUserRole(
          this.API_DETAIL[0].appName[0].label,
          this.props.userAccessInfo,
          "purge"
        );
        if (purgeAccess) {
          this.cleFooter = {
            resolve: this.SCREEN_TYPE === "exception" && checkResolveBtn,
            // filter: checkAccessBtn === "filter",
            // defilter: checkAccessBtn === "defilter",
            // purge: true,
          };
        } else {
          // let filterAccess = userAccessValidator.validateAccessUserRole(
          //   this.API_DETAIL[0].appName[0].label,
          //   this.props.userAccessInfo,
          //   "filter"
          // );

          this.cleFooter = {
            resolve:
              this.SCREEN_TYPE === "exception" &&
              checkResolveBtn &&
              userAccessValidator.validateAccessUserRole(
                this.API_DETAIL[0].appName[0].label,
                this.props.userAccessInfo,
                "replay"
              ),
            // filter: checkAccessBtn === "filter" && filterAccess,
            // defilter: checkAccessBtn === "defilter" && filterAccess,
            // purge: false,
          };
        }
      }
      if (this.props.history.location.state === undefined) {
        this.GET_DATA();
        this.GET_COUNT();
      }
    } else {
      if (this.NORECORD_FLAG === true)
        this.props.history.goBack();
    }
  }

  GET_COUNT = () => {
    let url = this.SCREEN_TYPE === "log" ? "/log/count/" : "/exception/count/";

    let axiosUrl =
      this.SCREEN_TYPE === "log"
        ? AxiosUrl.logDatainstance
        : AxiosUrl.exceptionDatainstance;

    if (typeof cancelTableCountToken != typeof undefined) {
      cancelTableCountToken.cancel("Operation canceled due to logout request.");
    }
    cancelTableCountToken = CancelToken.source();
    this.setState(() => ({
      totalCount: 0,
    }));
    const totCountApi = (request) => {
      axiosUrl
        .post(url, request, {
          cancelToken: cancelTableCountToken.token,
        })
        .then((resp) => {
          let respStatus = outApiCheck(resp);
          if (respStatus === "block") {
            return;
          }
          if (!respStatus) {
            setTimeout(() => {
              totCountApi(request);
            }, RECALL_TIME);
            return;
          }

          this.setState(({ totalCount }) => ({
            totalCount: totalCount + resp.data,
          }));
        })
        .catch((error) => {
          if (isCancel(error)) {
            console.log("post Request canceled");
            return;
          }
          // setTimeout(() => {
          //   totCountApi(request);
          // }, RECALL_TIME);
        });
    };
    this.API_DETAIL.forEach((val) => {
      totCountApi(val);
    });
  };

  // Method to return all the columns for Configure Columns Select Input
  getColumnList = () => {
    return this.state.columnList.filter(item => item.headerName !== '').map((item) => ({
      ...item,
      label: item.headerName,
      value: item.field
    }));
  };

  // Method to return default columns for Configure Columns Select Input
  resetToDefaultCols = () => {
    const defaultCols = this.state.columnList.filter(item => item.headerName !== '' && item.default === true).map((item) => ({
      ...item,
      label: item.headerName,
      value: item.field
    }));

    this.setState({
      visibleColumnList: defaultCols
    });
  };

  // Method to update selected columns for Select Input & Search Result Table
  onColumnsVisibilityChange = (values) => {
    if (values && values.length > 0) {
      let updatedCols = this.state.columnList.filter((el) => {
        return values.some((f) => {
          return el.field === '' || f.field === el.field;
        });
      }).map(column => ({
        ...column,
        ...(values.length <= 5 && { flex: 1 }),
      }));
      const columnData = _.uniqBy([...values], 'field');

      this.setState({
        visibleColumnList: columnData,
        COLUMN_CONFIG: updatedCols
      });

      // To store visible column list in session storage
      sessionStorage.setItem(`${this.SCREEN_TYPE}VisibleColumnList`, JSON.stringify(columnData));
    }
  };

  componentWillUnmount() {
    if (typeof cancelTableToken != typeof undefined) {
      cancelTableToken.cancel("Operation canceled due to logout request.");
    }
    if (typeof cancelTableCountToken != typeof undefined) {
      cancelTableCountToken.cancel("Operation canceled due to logout request.");
    }
    if (typeof cancelReplay != typeof undefined) {
      cancelReplay.cancel("Operation canceled due to logout request.");
    }
    if (typeof cancelReplayAll != typeof undefined) {
      cancelReplayAll.cancel("Operation canceled due to logout request.");
    }
    if (typeof cancelReplayStatus != typeof undefined) {
      cancelReplayStatus.cancel("Operation canceled due to logout request.");
    }
    if (typeof cancelFilter != typeof undefined) {
      cancelFilter.cancel("Operation canceled due to logout request.");
    }
    if (typeof cancelFilterAll != typeof undefined) {
      cancelFilterAll.cancel("Operation canceled due to logout request.");
    }
    this.gridApi && this.gridApi.api.setRowData([]);
    this.API_DETAIL_RESPONSE = [];
    this.API_AUDIT_RESPONSE = [];
    this.RESOLVE_RECORDS = [];
    this.tableSearchData = [];
  }

  fetchData = () => {
    const tempArr = [];
    this.gridApi &&
      this.gridApi.api.forEachNode((node) => tempArr.push(node.data));
    this.setState({
      rowData: [...tempArr]
    });
  };

  GET_DATA = () => {
    // this.progressLoader.continuousStart();
    if (this.state.searchComplete === true) {
      this.setState({ searchComplete: false });
    }
    let url =
      this.SCREEN_TYPE === "log" ? "/log/search/" : "/exception/search/";
    this.setState({ refreshload: true });
    let axiosUrl =
      this.SCREEN_TYPE === "log"
        ? AxiosUrl.logDatainstance
        : AxiosUrl.exceptionDatainstance;
    if (typeof cancelTableToken != typeof undefined) {
      cancelTableToken.cancel("Operation canceled due to new request.");
    }
    if (typeof this.props.cancelToken != typeof undefined) {
      this.props.cancelToken.cancel(
        "Operation canceled due to logout request."
      );
    }
    cancelTableToken = CancelToken.source();
    const recursiveApi = (request) => {
      return new Promise((resolve, reject) => {
        axiosUrl
          .post(url, request, {
            cancelToken: cancelTableToken.token,
          })
          .then((resp) => {
            let respStatus = outApiCheck(resp);
            if (respStatus === "block") {
              return;
            }
            if (!respStatus) {
              setTimeout(() => {
                if (this.tableSearchData.length < MAX_DATA) {
                  recursiveApi(request);
                } else {
                  this.setState({ searchComplete: true });
                }
              }, RECALL_TIME);
              return;
            }
            request.pageID = resp.data.pageID;
            this.setState({ refreshload: false });
            let selectAllEnabled = false;
            if (
              this.gridApi.api.getSelectedRows().length !== 0 &&
              this.gridApi.api.getSelectedRows().length ===
              this.gridApi.api.getDisplayedRowCount()
            ) {
              selectAllEnabled = true;
            }
            if (resp.data?.data?.length > 0) {
              this.gridApi.api.applyTransaction({
                add: resp.data.data,
              });
              this.tableSearchData = [...this.tableSearchData, ...resp.data.data];
            }
            //this.API_INITIATED = false;
            if (selectAllEnabled) {
              this.gridApi.api.selectAllFiltered();
            }
            if (
              resp.data.pageID !== null &&
              this.tableSearchData.length < MAX_DATA
            ) {
              recursiveApi(request);
            } else {
              this.setState({ searchComplete: true });
            }

            resolve(resp.data);
          })
          .catch((error) => {
            if (isCancel(error)) {
              console.log("post Request canceled");
              return;
            }
            return reject(error);
          });
      });
    };
    let count_promise = [];
    this.API_DETAIL.forEach((val) => {
      count_promise.push(recursiveApi(val));
    });
    Promise.allSettled(count_promise)
      .then((val) => {
        let checkStatus = val.every((val) => val.status === "rejected");
        if (this.NORECORD_FLAG === false) {
          if (checkStatus) {
            return this.countCloseModal();
          }
          if (val && val.length > 0) {
            let checkNoRecord = val.every(
              (valx) => valx.value?.data?.length === 0
            );
            if (checkNoRecord) {
              this.countCloseModal();
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleRefreshBtn = () => {
    this.gridApi.api.setRowData([]);
    this.gridApi.api.showLoadingOverlay();
    this.tableSearchData = [];
    // this.rowData = [];
    this.GET_DATA();
    this.GET_COUNT();
    this.setState({
      filterStateObject: {},
      searchComplete: false,
      displayedRowCount: undefined,
      rowData: []
    });
  };

  hanldeSelectAll = () => {
    this.gridApi.api.selectAllFiltered();
  };
  hanldeDeSelectAll = () => {
    this.gridApi.api.deselectAll();
  };

  hanldeDetail = (data) => {
    let name = this.SCREEN_TYPE === "log" ? "Log" : "Exception";
    let checkData = data && Array.isArray(data);
    this.modalData = {
      modalTitle: `${name} Detail`,
      modalTitleIcon: "detail",
      nextPrevBtn: !checkData && this.gridApi.api.getSelectedRows().length > 1,
      editBtn: false,
      size: "75%",
    };
    this.modalFooter = {
      update: false,
      close: true,
      resolve: false,
      filter: false,
      defilter: false,
    };

    this.dataIndex = 0;

    let API_REQUEST = checkData ? data[0] : this.getSortIndex()[0];

    let tableName;
    if (this.API_DETAIL.length === 1) {
      tableName = this.API_DETAIL[0];
    } else {
      tableName = this.API_DETAIL.find(({ applicationName }) =>
        applicationName.split(" ").includes(API_REQUEST.application_nm)
      );
    }
    let request = {
      application: API_REQUEST.application_nm,
      table: tableName?.table,
      timemark: API_REQUEST.timemark,
      [this.SCREEN_TYPE === "log" ? "log_status" : "exception_status"]: this.SCREEN_TYPE === "log" ? API_REQUEST.log_status : API_REQUEST.exception_status
    };
    // console.log(request, ':::::::::::request');
    if (this.SCREEN_TYPE === "log") {
      request.logID = API_REQUEST.log_id;
    } else {
      request.exceptionID = API_REQUEST.exception_id;
    }
    this.setState({ overLoading: true });
    this.getSortIndex();
    this.getApiDetail(request);
  };
  getApiDetail = (request) => {
    let url = this.SCREEN_TYPE === "log" ? "/log/item/" : "/exception/item/";
    let axiosUrl = this.SCREEN_TYPE === "log"
      ? AxiosUrl.logDatainstance
      : AxiosUrl.exceptionDatainstance;

    if (
      userAccessValidator.validateAccessUserRole(
        this.API_DETAIL[0].appName[0].value,
        this.props.userAccessInfo,
        "replay"
      ) &&
      request.exception_status !== "Resolved" &&
      this.SCREEN_TYPE === "exception"
    ) {
      this.modalFooter.resolve = true;
    } else {
      this.modalFooter.resolve = false;
    }
    axiosUrl
      .post(url, request)
      .then((resp) => {
        let respStatus = outApiCheck(resp);
        if (respStatus === "block") {
          return;
        }
        if (!respStatus) {
          this.setState({ overLoading: false, modalOverLoading: false });
          this.errorNotify(ERROR_DISP, "Server busy");
          return;
        }

        //Audit API call if detail type is exception 
        if (this.SCREEN_TYPE === "exception" && resp.data.exception_status !== "Pending") {
          this.setState({ auditstatus: request.exception_status });
          let auditRequestPayload = {
            ...request,
            status: request.exception_status,
            user: this.props.userAccessInfo?.userID,
          };
          let auditurl = "/replay/audit/";
          let axiosAuditUrl = AxiosUrl.replayDataInstance;
          //console.log(auditurl, ':::::::', axiosAuditUrl);

          axiosAuditUrl.post(auditurl, auditRequestPayload).then((respData) => {
            //console.log(respData, ':::::::respData');
            const auditgroups = _.groupBy(respData.data, "request_id");
            let groupByUser = Object.entries(auditgroups).map(([, value]) =>
              _.groupBy(value, "user_id")
            );

            //console.log(groupByUser, '::: groupByUser');
            this.API_AUDIT_RESPONSE.push(groupByUser);
            this.API_DETAIL_RESPONSE.push(resp.data);
            this.setState({
              selectedRow: resp.data,
              auditUserGroups: groupByUser,
              showModal: true,
              overLoading: false,
              modalOverLoading: false,
            });
          });

          // console.log(auditRequestPayload, '------auditRequest');
        }
        else {
          this.API_AUDIT_RESPONSE.push([]);
          this.API_DETAIL_RESPONSE.push(resp.data);
          this.setState({
            selectedRow: resp.data,
            showModal: true,
            overLoading: false,
            modalOverLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ overLoading: false, modalOverLoading: false });
        this.errorNotify(ERROR_DISP, error);
      });
  };
  getExceptionDetail = (resp) => {
    let name = this.SCREEN_TYPE === "log" ? "Log" : "Exception";
    // let checkData = data && Array.isArray(data);
    this.API_DETAIL_RESPONSE.push(resp);
    this.setState({
      showModal: true,
      selectedRow: resp,
      overLoading: false,
      modalOverLoading: false,
    });
    // this.hanldeDetail();
    this.modalData = {
      modalTitle: `${name} Detail`,
      modalTitleIcon: "detail",
      // nextPrevBtn: !checkData && this.gridApi.api.getSelectedRows().length > 1,
      editBtn: false,
      size: "75%",
    };
    this.modalFooter = {
      update: false,
      close: true,
      resolve: false,
      filter: false,
      defilter: false,
    };
  };
  getSortIndex = () => {
    let node = this.gridApi.api.getSelectedNodes();
    if (node.length > 0) {
      let orderNode = node.sort((a, b) => a.rowIndex - b.rowIndex);

      return orderNode.map((val) => val.data);
    }
    return this.gridApi.api.getSelectedNodes();
  };
  hanldeMoveIndex = (opt) => {
    if (opt === "prev" && this.dataIndex > 0) {
      this.dataIndex--;
    }
    if (
      opt === "next" &&
      this.gridApi.api.getSelectedRows().length - 1 > this.dataIndex
    ) {
      this.dataIndex++;
    }
    let API_REQUEST = this.getSortIndex()[this.dataIndex];

    if (this.SCREEN_TYPE === "exception" && API_REQUEST &&
      API_REQUEST["exception_status"] && (API_REQUEST["exception_status"] === "Pending" || API_REQUEST["exception_status"] === "Error")) {
      this.modalFooter.resolve = true;
    }
    else {
      this.modalFooter.resolve = false;
    }
    if (API_REQUEST) {
      let id = this.SCREEN_TYPE === "log" ? "log_id" : "exception_id";
      let index = this.API_DETAIL_RESPONSE.findIndex(
        (val) => val[id] === API_REQUEST[id]
      );

      // console.log(index, ':::index', this.dataIndex, ':::::this.dataIndex');
      // console.log(this.API_AUDIT_RESPONSE[this.dataIndex], ':::::this.API_AUDIT_RESPONSE[this.dataIndex]');
      if (index > -1) {
        this.setState({
          auditstatus: API_REQUEST["exception_status"],
          auditUserGroups: this.API_AUDIT_RESPONSE[this.dataIndex],
          selectedRow: this.API_DETAIL_RESPONSE[this.dataIndex],
        });
      } else {
        let tableName = this.API_DETAIL.find(({ applicationName }) =>
          applicationName.split(" ").includes(API_REQUEST.application_nm)
        );

        let request = {
          application: API_REQUEST.application_nm,
          table: tableName.table,
          timemark: API_REQUEST.timemark,
          status: API_REQUEST.status,
        };
        if (this.SCREEN_TYPE === "log") {
          request.logID = API_REQUEST.log_id;
        } else {
          request.exceptionID = API_REQUEST.exception_id;
          request.exception_status = API_REQUEST.exception_status;
        }
        this.setState({
          modalOverLoading: true,
          auditstatus: null,
          auditUserGroups: []
        });
        this.getApiDetail(request);
      }
    }
  };
  onCloseModal = () => {
    this.API_DETAIL_RESPONSE = [];
    this.API_AUDIT_RESPONSE = [];

    this.setState({
      showModal: false,
      selectedRow: null,
      auditUserGroups: [],
    });
    if (sessionStorage.getItem('exceptionapi')?.length > 1) {
      sessionStorage.setItem("exceptionapi", " ");
      this.NORECORD_FLAG = false;
      this.props.clearApiResponse();
      this.props.history.push("/exceptions/search-exceptions/list");
    }
  };

  countCloseModal = () => {
    let secondsToGo = 10;
    let timer;

    const modal = Modal.info({
      title: "Since table have no record to show.",
      content: `Page will redirect after ${secondsToGo} seconds.`,
      keyboard: false,
      onOk: () => {
        clearInterval(timer);
        this.hanldeBack();
      },
    });
    timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: `Page will redirect after ${secondsToGo} seconds.`,
      });
      if (secondsToGo === 0) {
        clearInterval(timer);
        modal.destroy();
        this.hanldeBack();
      }
    }, 1000);
  };

  handleResolve = (key, data) => {
    this.modalFooter.resolve = false;
    // let selectedRecord = this.gridApi.api.getSelectedRows();
    let title;
    let appConfig;
    let request = [];
    if (key === "modal") {
      title = `Do you want to resolve this items?`;
      appConfig = [data];
      request = this.formPayLoad(appConfig);
    } else {
      appConfig = this.gridApi.api.getSelectedRows();
      if (appConfig.length > 0) {
        title = `Do you want to resolve ${appConfig.length} items?`;
        request = this.formPayLoad(appConfig);
      } else {
        title = `Do you want to resolve all items? (${this.state.totalCount || this.props.totalCount
          })`;
      }
    }

    let url = "/replay/";

    let checkAllStatus = request.some((val) => val.status === "Scheduled");

    if (checkAllStatus || this.ALL_RESOLVE_INITAITE) {
      Modal.warning({
        title: "Replay has been initiated ",
        content: `Selected records  has been already scheduled. Please check replay status`,
      });
      return;
    }

    modalWarning(title, "", () => {
      if (request.length > 0) {
        this.setState({ disabledFooter: true });
        this.progressLoader.continuousStart();
        if (key === "modal") {
          this.setState({ modalOverLoading: true });
        }
        cancelReplay = CancelToken.source();
        AxiosUrl.replayDataInstance
          .post(url, request, {
            cancelToken: cancelReplay.token,
          })
          .then((resp) => {
            let respStatus = outApiCheck(resp);
            if (respStatus === "block") {
              return;
            }
            if (!respStatus) {
              this.setState({ disabledFooter: false, modalOverLoading: false });
              this.progressLoader.complete();
              this.errorNotify(ERROR_DISP, "Server busy");
              return;
            }
            this.successNotify(`Replay Process has been initiated`);
            if (resp.data) {
              if (resp.data.request_status === "Running" || resp.data.request_status === "Scheduled") {
                //Updating Status of Rows AG grid
                try {
                  if (this.gridApi?.api?.getSelectedRows()?.length > 0) {
                    this.gridApi?.api?.getSelectedRows()?.forEach((rowInArray) => {
                      let rowNode = null;
                      if (this.API_DETAIL_RESPONSE.length > 0) {
                        rowNode = this.gridApi.api.getRowNode(data['exception_id']);
                      } else {
                        rowNode = this.gridApi.api.getRowNode(rowInArray['exception_id']);
                      }
                      rowNode.setDataValue("exception_status", "Running");
                    });
                  }
                }
                catch (e) {
                  console.log("failed to update replay status of selected rows");
                }
                this.RESOLVE_RECORDS.push(...request);
                //Updating status in stored detail data array
                if (this.API_DETAIL_RESPONSE.length > 0) {
                  this.API_DETAIL_RESPONSE = this.API_DETAIL_RESPONSE.map(detailElement => {
                    const objectExistsInReplayList = (detailElement['exception_id'] === data['exception_id']);
                    if (objectExistsInReplayList) {
                      detailElement['exception_status'] = "Running";
                    }
                    return detailElement;
                  });
                  // console.log(this.state.selectedRow, ':::::::::::this.state.selectedRow');
                  if (Array.isArray(this.state.selectedRow)) {
                    let newStateArray = this.state.selectedRow?.map(elementInStateArray => {
                      const objectExistsInStateList = this.gridApi?.api?.getSelectedRows().some(objectInReplayList => objectInReplayList['exception_id'] === elementInStateArray['exception_id']);
                      if (objectExistsInStateList) {
                        elementInStateArray['exception_status'] = "Running";
                      }
                      return elementInStateArray;
                    });
                    this.setState({ selectedRow: newStateArray });
                  }
                  else if (typeof this.state.selectedRow === 'object') {
                    this.setState({
                      selectedRow: { ...this.state.selectedRow, 'exception_status': "Running" }
                    });
                  }
                }
              }
            }
            this.progressLoader.complete();
            this.setState({ disabledFooter: false, modalOverLoading: false });
            setTimeout(() => {
              this.resolveStatusHandler(true);
            }, RESOLVE_STATUS_CHECK);
          })
          .catch((error) => {
            if (isCancel(error)) {
              console.log("post Request canceled");
              return;
            }
            this.progressLoader.complete();
            this.setState({ disabledFooter: false, modalOverLoading: false });
            this.errorNotify(ERROR_DISP, error);
          });
      } else {
        this.handleAllResolve();
      }
    });
  };
  handleAllResolve = () => {
    this.setState({ disabledFooter: true });
    this.progressLoader.continuousStart();
    let url = "replay/bulk";

    let query = JSON.parse(sessionStorage.getItem("exceptionSearchCriterias"));

    let format = this.checkTable(query);
    if (format.length === 1) {
      query.table = format[0].table;
    }
    query.userID = this.props.userAccessInfo?.userID;
    // let request = {
    //   searchRequest: query,
    //   user: this.props.userAccessInfo?.userID,
    // };
    cancelReplayAll = CancelToken.source();
    AxiosUrl.replayDataInstance
      .post(url, query, {
        cancelToken: cancelReplayAll.token,
      })
      .then((resp) => {
        let respStatus = outApiCheck(resp);
        if (respStatus === "block") {
          return;
        }
        if (!respStatus) {
          this.setState({ disabledFooter: false });
          this.progressLoader.complete();
          this.errorNotify(ERROR_DISP, "Server busy");
          return;
        }
        this.successNotify(`Resolve Process has been initiated`);
        let records = [];
        if (
          resp.data.request_status === "Started" ||
          resp.data.request_status === "Scheduled"
        ) {
          this.gridApi.api.forEachNode((rowNode) => {
            if (rowNode) {
              rowNode.setDataValue("exception_status", "Scheduled");
              records.push(rowNode.data);
            }
          });
        }
        if (records.length > 0) {
          this.RESOLVE_RECORDS = this.formPayLoad(records);

          setTimeout(() => {
            this.resolveStatusHandler(false);
          }, RESOLVE_STATUS_CHECK);
        }
        this.progressLoader.complete();
        this.setState({ disabledFooter: false });
      })
      .catch((error) => {
        if (isCancel(error)) {
          console.log("post Request canceled");
          return;
        }
        this.setState({ disabledFooter: false });
        this.progressLoader.complete();
        this.errorNotify(ERROR_DISP, error);
      });
  };

  resolveStatusHandler = () => {
    this.modalFooter.resolve = false;
    cancelReplayStatus = CancelToken.source();
    const resolveApi = () => {
      AxiosUrl.replayDataInstance
        .post("/replay/status/", this.RESOLVE_RECORDS, {
          cancelToken: cancelReplayStatus.token,
        })
        .then((resp) => {
          let respStatus = outApiCheck(resp);
          if (respStatus === "block") {
            return;
          }
          if (!respStatus) {
            if (this.RESOLVE_RECORDS.length > 0) {
              setTimeout(() => {
                resolveApi();
              }, RESOLVE_STATUS_CHECK);
            }
            return;
          }
          if (resp.data && resp.data.length > 0) {
            resp.data.forEach((val) => {
              if (this.API_DETAIL_RESPONSE.length > 0) {
                this.API_DETAIL_RESPONSE = this.API_DETAIL_RESPONSE.map(
                  (req) => {
                    if (req.exception_id === val.exceptionID) {
                      req.exception_status = val.replayStatus;
                      req.resolution_description = val.resolution_description;
                    }
                    return req;
                  }
                );
                //modal footer resolve button 
                if (val.replayStatus !== "Resolved" && this.SCREEN_TYPE === "exception") {
                  this.modalFooter.resolve = true;
                } else {
                  this.modalFooter.resolve = false;
                }

                if (
                  this.state.selectedRow &&
                  this.state.selectedRow.exception_id === val.exceptionID
                ) {
                  let selectedRow = Object.assign({}, this.state.selectedRow);
                  selectedRow.exception_status = val.replayStatus;
                  selectedRow.resolution_description =
                    val.resolution_description;
                  this.setState({ selectedRow });
                }
              }
              let rowNode = this.gridApi.api.getRowNode(val.exceptionID);
              try {
                if (rowNode) {
                  rowNode.setDataValue("exception_status", val.replayStatus);
                }
              }
              catch (e) {
                console.log(e, "error");
              }
              if (val.replayStatus !== "Scheduled") {
                let nonScheduleRecords = this.RESOLVE_RECORDS.findIndex(
                  (req) => req.exceptionID === val.exceptionID
                );
                if (nonScheduleRecords > -1) {
                  this.RESOLVE_RECORDS.splice(nonScheduleRecords, 1);
                }
              }

            });
            if (this.RESOLVE_RECORDS.length > 0) {
              setTimeout(() => {
                resolveApi();
              }, RESOLVE_STATUS_CHECK);
            }
          }
        })
        .catch((error) => {
          if (isCancel(error)) {
            console.log("post Request canceled");
            return;
          }
          if (this.RESOLVE_RECORDS.length > 0) {
            setTimeout(() => {
              resolveApi();
            }, RESOLVE_STATUS_CHECK);
          }
        });
    };
    resolveApi();
  };

  hanldeBack = () => {
    if (typeof this.props.cancelToken != typeof undefined) {
      this.props.cancelToken.cancel(
        "Operation canceled due to logout request."
      );
    }
    if (typeof this.props.cancelCountToken != typeof undefined) {
      this.props.cancelCountToken.cancel(
        "Operation canceled due to logout request."
      );
    }

    this.props.history.goBack();
  };
  formPayLoad = (request) => {
    return request.map((val) => {
      let obj = {
        application: val.application_nm,
        timemark: val.timemark,
        user: this.props.userAccessInfo?.userID,
        status: val.status,
      };
      if (this.SCREEN_TYPE === "log") {
        obj.logID = val.log_id;
      } else {
        obj.exceptionID = val.exception_id;
      }

      if (this.API_DETAIL.length === 1) {
        obj.table = this.API_DETAIL[0].table;
      } else {
        let tableName = this.API_DETAIL.find(({ applicationName }) =>
          applicationName.split(" ").includes(val.application_nm)
        );
        if (tableName) {
          obj.table = tableName.table;
        }
      }
      return obj;
    });
  };
  checkTable = (request) => {
    let table = this.SCREEN_TYPE === "log" ? "logTable" : "exceptionTable";
    let application = request.appName;
    let appGroup = application.reduce((r, a) => {
      r[a[table]] = [...(r[a[table]] || []), a];
      return r;
    }, {});
    let payload = [];
    Object.keys(appGroup).forEach((val) => {
      let applicationName = appGroup[val].map((app) => app.value).join(" ");
      let newQuery = { ...request, table: val, applicationName };
      payload.push(newQuery);
    });
    return payload;
  };

  handleExportToExcel = (value) => {
    // e.preventDefault();
    let url =
      this.SCREEN_TYPE === "log"
        ? "/log/download/selected/"
        : "/exception/download/selected/";
    let API_REQUEST = this.gridApi.api.getSelectedRows();
    if (API_REQUEST.length > 0) {
      let payload = {
        columns: value,
        request: this.formPayLoad(API_REQUEST),
      };
      this.setState({
        downloadProgress: true,
        exportExcelModal: false,
      });
      this.progressLoader.continuousStart();
      fetch(AxiosUrl.apiDownloadUrl() + url, {
        method: "POST",
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/json",
          Authorization: getTokenToReqHeader(),
        },
        body: JSON.stringify(payload),
      })
        .then((data) => {
          return data.blob();
        })
        .then((data) => {
          var blob = new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = this.SCREEN_TYPE + ".xlsx";
          link.click();
          this.setState({
            downloadProgress: false,
          });
          this.progressLoader.complete();
          this.successNotify(`Downloaded successfully`);
        })
        .catch((error) => {
          this.errorNotify(ERROR_DISP, error);
          this.progressLoader.complete();
          this.setState({
            downloadProgress: false,
          });
        });
    } else {
      this.handleAllExportToExcel(value);
    }
  };
  handleAllExportToExcel = (columns) => {
    // let keyName =
    //   this.SCREEN_TYPE === "log"
    //     ? "logSearchCriterias"
    //     : "exceptionSearchCriterias";
    let url =
      this.SCREEN_TYPE === "log" ? "/log/download/" : "/exception/download/";
    const exportAllData = (request) => {
      return new Promise((resolve, reject) => {
        fetch(AxiosUrl.apiDownloadUrl() + url, {
          method: "POST",
          headers: {
            Accept: "application/octet-stream",
            "Content-Type": "application/json",
            Authorization: getTokenToReqHeader(),
          },
          body: JSON.stringify(request),
        })
          .then((data) => {
            return data.blob();
          })
          .then((data) => {
            var blob = new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = this.SCREEN_TYPE + ".xlsx";
            link.click();
            return resolve("Completed");
          })
          .catch((error) => {
            return reject(error);
          });
      });
    };
    this.setState({
      downloadProgress: true,
      exportExcelModal: false,
    });
    this.progressLoader.continuousStart();
    let count_promise = [];
    this.API_DETAIL.forEach((val) => {
      val.columns = columns;
      val.totalRecordCount = this.state.totalCount || this.props.totalCount;
      count_promise.push(exportAllData(val));
    });
    Promise.allSettled(count_promise)
      .then(() => {
        this.setState({
          downloadProgress: false,
        });
        this.successNotify(`Downloaded successfully`);
        this.progressLoader.complete();
      })
      .catch((err) => {
        console.log(err);
        this.progressLoader.complete();
        this.setState({
          downloadProgress: false,
        });
        this.errorNotify(ERROR_DISP, err);
      });
  };
  successNotify = (msg) => {
    notification.success({
      message: msg,
    });
  };
  errorNotify = (msg, desc) => {
    let error = JSON.stringify(desc);
    if (desc.message) {
      error = desc.message;
    }
    notification.error({
      message: msg,
      description: error,
    });
  };

  showDisplayCount = () => {
    if (
      (this.state.displayedRowCount === undefined && (this.props.totalCount && this.props.totalCount <= MAX_DATA)) ||
      (this.state.displayedRowCount === undefined && (this.state.totalCount && this.state.totalCount <= MAX_DATA))
    ) {
      return false;
    }

    return true;
  };

  onFilterClick = () => {
    this.fetchData();
    this.setState({
      showDrawer: true,
    });
  };

  onCloseFilter = () => {
    this.setState({
      showDrawer: false,
    });
  };

  getFilterOptions = (data) => {
    this.setState({
      displayedRowCount: data.length,
      rowData: [...data]
    });
    this.gridApi.api.setRowData(data);
  };

  updateFilterState = (filterObj) => {
    this.setState({
      filterStateObject: filterObj,
    });
  };

  handleSaveContent = (content) => {
    let tableName;
    if (this.API_DETAIL.length === 1) {
      tableName = this.API_DETAIL[0];
    } else {
      tableName = this.API_DETAIL.find(({ applicationName }) =>
        applicationName.split(" ").includes(this.state.selectedRow.application_nm)
      );
    }
    let request = {
      application: this.state.selectedRow.application_nm,
      data_encoding: this.state.selectedRow.data_encoding,
      exceptionID: this.state.selectedRow.exception_id,
      jmsHeader: this.state.selectedRow.jms_header,
      timemark: this.state.selectedRow.timemark,
      transactionData: content,
      table: tableName?.table,
      user: this.props.userAccessInfo?.userID
    };
    this.setState({ modalOverLoading: true });
    AxiosUrl.exceptionDatainstance
      .put("/exception/", request)
      .then((resp) => {
        let respStatus = outApiCheck(resp);
        if (respStatus === "block") {
          return;
        }
        if (!respStatus) {
          this.setState({ modalOverLoading: false });
          this.errorNotify(ERROR_DISP, "Server busy");
          return;
        }
        let req = JSON.parse(resp.config.data);
        if (this.API_DETAIL_RESPONSE.length > 0) {
          let index = this.API_DETAIL_RESPONSE.findIndex(
            (val) => val.exception_id === req.exceptionID
          );
          if (index > -1) {
            this.API_DETAIL_RESPONSE[index].trans_data = req.transactionData;
          }
          if (
            this.state.selectedRow &&
            this.state.selectedRow.exception_id === req.exceptionID
          ) {
            let selectedRow = Object.assign({}, this.state.selectedRow);
            selectedRow.trans_data = req.transactionData;
            this.setState({ selectedRow });
          }
        }

        this.successNotify(`Transaction Data has been updated`);
        this.setState({ modalOverLoading: false });
      })
      .catch((error) => {
        this.errorNotify(ERROR_DISP, error);
        this.setState({ modalOverLoading: false });
      });
  };

  render() {
    // console.log(this.state, '::::::::::: this.state');
    this.TOTAL_COUNT = this.state.totalCount || this.props.totalCount;
    return (
      <WrapperPage overLoading={this.state.overLoading}>
        <LoadingBar
          ref={(ref) => (this.progressLoader = ref)}
          containerClassName="fixed-progress-bar"
          height={3}
          color="#1890ff"
        />
        {this.state.showDrawer && (
          <FilterDrawer
            screenType={this.SCREEN_TYPE}
            rowData={this.state.rowData}
            visibleFilter={this.state.showDrawer}
            onCloseFilter={this.onCloseFilter}
            selectedFilterOptions={(options) => this.getFilterOptions(options)}
            filterStateData={this.state.filterStateObject}
            updateFilterState={this.updateFilterState}
            columnHeaderData={
              this.props.history.location.pathname.includes("logs")
                ? LOG_COLUMNS
                : EXCEPTION_COLUMNS
            }
            statusObj={this.statusObj}
            selectedTagName={(value, header) => {
              this.setState({ selectedTag: value, selectedHeader: header });
            }}
            initialSearchData={this.tableSearchData}
            resetDisplaycount={() => this.setState({ displayedRowCount: undefined })}
          />
        )}
        {
          this.state.showModal && this.state.selectedRow &&
          (Array.isArray(this.state.selectedRow) ? (this.state.selectedRow.length > 0) : (Object.keys(this.state.selectedRow).length > 0)) &&
          <ModalView
            visible={this.state.showModal}
            size={"90%"}
            centered
            onClose={this.onCloseModal}
            title={
              <ModalTitle
                {...this.modalData}
                onClose={this.onCloseModal}
                handleMove={this.hanldeMoveIndex}
                dataIndex={this.dataIndex}
                totalLength={
                  (this.gridApi && this.gridApi.api.getSelectedRows().length) || 0
                }
              />
            }
            footer={
              <ModalFooter
                {...this.modalFooter}
                onClose={this.onCloseModal}
                onResolve={() => {
                  this.handleResolve("modal", this.state.selectedRow);
                }}
              />
            }
          >
            <WrapperPage overLoading={this.state.modalOverLoading}>
              <BlobDetail
                auditUserGroups={this.state.auditUserGroups}
                auditstatus={this.state.auditstatus}
                usersList={[...this.props.usersList]}
                type={this.SCREEN_TYPE}
                timeZone={this.props.timeZone}
                selectedRow={this.state.selectedRow}
                saveContent={this.handleSaveContent}
                userAccessInfo={this.props.userAccessInfo}
                handleMove={this.hanldeMoveIndex}
              />
            </WrapperPage>
          </ModalView>
        }

        <Row
          className="table_header_container"
          align={"middle"}
          justify={"space-between"}
        >
          <Col span={9}>
            <div className="d-flex align-items-center">
              <Tooltip
                placement="bottom"
                overlayInnerStyle={!this.state.searchComplete ? { maxWidth: "150px" } : {}}
                title={this.state.searchComplete ? "Filter Search Results" : "Filter will be accessible once the data is loaded"}
                overlayClassName='btn-tooltip'>
                <Button
                  className="mr-2"
                  type="primary"
                  disabled={this.state.searchComplete ? false : true}
                  onClick={() => this.onFilterClick()}
                  icon={(
                    <i
                      className={"fas fa-solid fa-filter"}
                      aria-hidden="true"
                    />
                  )}
                  ghost
                />
              </Tooltip>
              <Popover
                content={(
                  <MultiSelect
                    placeholder="Hide/Show Columns"
                    options={this.getColumnList()}
                    selected={this.state.visibleColumnList}
                    onSelectedChanged={(values) => this.onColumnsVisibilityChange(values)}
                    renderAllOptionLabel="All Columns"
                    searchInputPlaceholder="Search Column Name"
                    wrapperClassName="multi-select-wrapper"
                    clearAllHandler={() => this.resetToDefaultCols()}
                    clearAllLabel="Reset to default columns"
                    showAllColTags
                    hasSelectAll
                    keepOpen
                    multi
                  />
                )}
                className="columns-select-popover"
                placement="bottomLeft"
                trigger="click"
              >
                <Tooltip placement="bottom" title="Configure Columns" overlayClassName='btn-tooltip'>
                  <Button
                    icon={<TableOutlined />}
                    type="primary"
                    ghost
                  />
                </Tooltip>
              </Popover>

              <Button
                className="ml-2"
                type="primary"
                ghost
                onClick={() => this.handleRefreshBtn()}
                style={{ display: 'none' }}
              >
                <i
                  className={`fas fa-sync-alt ${this.state.refreshload && "fa-spin"
                    } fa-fw mr-2`}
                  aria-hidden="true"
                />
                Refresh
              </Button>

            </div>
          </Col>
          <div className="d-flex justify-content-flex-end">
            <div className="total_record_container">
              {this.state.selectedCount !== 0 && (
                <span>
                  Selected Records :{" "}
                  {new Intl.NumberFormat("en-US").format(
                    this.state.selectedCount
                  )}
                </span>
              )}
              {this.showDisplayCount() && (
                <span className={"ml-1"}>
                  Displayed Records :{" "}
                  {this.gridApi
                    ? new Intl.NumberFormat("en-US").format(
                      this.state.displayedRowCount || this.gridApi.api.getDisplayedRowCount()
                    )
                    : 0}{" "}
                </span>
              )}

              <span id="sel_total_result" className={"ml-1"}>
                Total Records :{" "}
                {this.TOTAL_COUNT
                  ? new Intl.NumberFormat("en-US").format(this.TOTAL_COUNT)
                  : "..."}
              </span>
            </div>
          </div>
        </Row>

        <div
          className="commontable_container"
          id="sel_log_exception_result_table"
        >
          <CleTable
            rowSelection="multiple"
            onGridReady={(params) => {
              this.gridApi = params;
              // this.props.onGridReady(params);
            }}
            animateRows={false}
            immutableData
            rowMultiSelectWithClick
            onRowDoubleClicked={(params) => {
              // console.log('---------onRowDoubleClicked----------');
              this.hanldeDetail([params.data]);
            }}
            overlayLoadingTemplate={`<span>           
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="table_over_loader">fetching data from server...</div>
            </span>`}
            pagination={false}
            context={{ timeZone: this.props.timeZone || false }}
            getRowStyle={(params) => {
              return params.data.log_level === "Error"
                ? { "background-color": "#ff00005c" }
                : null;
            }}
            getRowNodeId={(data) => data.log_id || data.exception_id}
            onSelectionChanged={(gridOptions) => {
              // console.log('---------onSelectionChanged-----------');
              setTimeout(() => {
                // console.log('---------onSelectionChanged------TimeOut-----', gridOptions.api.getSelectedRows());
                this.setState({
                  selectedCount: gridOptions.api.getSelectedRows().length,
                  // selectedRow: gridOptions.api.getSelectedRows(),
                });
              }, 250);
            }}
            columnDefs={this.state.COLUMN_CONFIG}
            isColSizeAutoFit={false}
          />
        </div>

        <CleFooter
          disabledFooter={this.state.disabledFooter}
          deSelectAll={this.state.selectedCount !== 0}
          selectAll={this.state.selectedCount === 0}
          onBack={this.hanldeBack}
          {...this.cleFooter}
          onResolve={this.handleResolve}
          downloadProgress={this.state.downloadProgress}
          purgeDisabled={this.state.selectedCount === 0}
          back
          download
          detail
          deleteDisabled={this.state.selectedCount === 0}
          onDetail={this.hanldeDetail}
          onSelectAll={this.hanldeSelectAll}
          onDeSelectAll={this.hanldeDeSelectAll}
          onDownload={() => {
            this.setState({ exportExcelModal: true });
          }}
        />
        {this.state.exportExcelModal && (
          <SelectFieldForExportModal
            processExcelDownload={this.handleExportToExcel}
            colName={
              this.SCREEN_TYPE === "log"
                ? "LOG_COLUMNS"
                : "EXCEPTION_COLUMNS"
            }
            isModalOpen={this.state.exportExcelModal}
            count={this.state.selectedCount > 0
              ? this.state.selectedCount
              : this.props.totalCount || this.state.totalCount}
            closeExcelModal={() => {
              this.setState({
                exportExcelModal: false,
              });
            }} />
        )}
      </WrapperPage>
    );
  }
}
CommonTable.propTypes = {
  exceptionresponse: PropTypes.object,
  cancelToken: PropTypes.object,
  cancelCountToken: PropTypes.object,
  totalCount: PropTypes.number,
  onReduceCount: PropTypes.func,
  history: PropTypes.object,
  userAccessInfo: PropTypes.object,
  timeZone: PropTypes.bool,
  clearApiResponse: PropTypes.func,
  usersList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  userAccessInfo: state.userAccess.userAccessInfo,
  exceptionresponse: state.userAccess.exceptionresponse,
  usersList: state.users.userResults.data,
});
const mapDispatchToProps = (dispatch) => {
  return {
    clearApiResponse: () =>
      dispatch({ type: "CLEAR_RESPONSE" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CommonTable));
