import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import "./styles.scss";
import COLUMNS from "../../constants/ColumnConfig/bulkReplay";
import CleTable from "../CommonComponents/CleTable/CleTable";
import {
  getTokenToReqHeader
} from "../../utils/helpers";
import { toast } from "react-toastify";
import { constructServiceUrl } from "../../services/constructUrl";
import { openLoader, closeLoader } from "../OverlayLoader/OverlayLoader";

const BulkReplay = ({ ...props }) => {
  const [selectedFile, setSelectedFile] = useState(false);
  let file = React.createRef();
  const onFileSelect = () => {
    if (file.current.files[0]) {
      setSelectedFile(file.current.files[0].name);
    }
  };
  const uploadFile = () => {
    let fd = new FormData();
    fd.append("file", file.current.files[0]);
    //fd.append("user", this.props.userAccessInfo.userID);
    let serviceParams = {};
    serviceParams.payload = "";
    serviceParams.apiName = "BULK_REPLAY_DATA_UPLOAD";
    const requestParams = constructServiceUrl(serviceParams);
    openLoader();
    fetch(requestParams.url, {
      method: "POST",
      body: fd,
      headers: {
        Authorization: getTokenToReqHeader(),
      },
    })
      .then(function (data) {
        closeLoader();
        return data.json();
      })
      .then((data) => {
        closeLoader();
        if (data) {
          setSelectedFile(null);
          toast.success("Successfully uploaded exceptions");
          props.getReplayDetail();
        }
      })
      .catch(() => {
        closeLoader();
        setSelectedFile(null);
        toast.error("Error in uploading exceptions");
      });
  };
  return (
    <div className="bulkreplay">
      <Row gutter={24}>
        <Col span={4} className="replay-title">
          <label>Bulk Replay Request:</label>
        </Col>
        <Col span={11} className="replay-subtitle">
          <label className="custom-file">
            <input
              ref={file}
              type="file"
              id="file"
              className="custom-file-input"
              onChange={onFileSelect}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            <span className="custom-file-control">
              {selectedFile
                ? selectedFile
                : "Select an Exception export file with Exceptions to be replayed..."}
            </span>
          </label>
          <div className="limit_pos">
            <ul className="limit_excel p20">
              <li>
                Use exception file downloaded using Replay Template option
              </li>
              <li>The file should contain maximum of 10000 records</li>
              <li>Maximum file size 1 MB</li>
            </ul>
          </div>
        </Col>
        <Col span={9}>
          <button
            id="sel_bulkupload_submit"
            className={`btn btn-success btn-lg f16 right10 ${
              !selectedFile && "disabled"
            }`}
            onClick={uploadFile}
            type="button"
          >
            <i className="fa fa-check" aria-hidden="true" /> Submit
          </button>
          <button
            type="button"
            id="sel_bulkupload_reload"
            className={`btn btn-info btn-lg f16 ${
              props.replayReportLoading && "disabled"
            }`}
            onClick={() => props.getReplayRefresh()}
          >
            <i
              className={`fas fa-sync-alt ${
                props.replayReportLoading && "fa-spin"
              }  fa-fw`}
              aria-hidden="true"
            />
            Reload
          </button>
        </Col>
      </Row>
      <Row className="top20">
        <Col span={4} className="replay-title">
          <label>Bulk Replay Logs:</label>
        </Col>
        <Col span={18}>
          <div style={{ height: "400px" }}>
            <CleTable
              id="sel_bulkupload_table"
              pagination
              context={{ timeZone: props.timeZone || false }}
              columnDefs={COLUMNS}
              rowData={props.replayReportData}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
BulkReplay.propTypes = {
  replayReportData: PropTypes.array,
  userAccessInfo: PropTypes.object,
  getReplayDetail: PropTypes.func,
  replayReportLoading: PropTypes.bool,
  getReplayRefresh: PropTypes.func,
  timeZone: PropTypes.bool,
};
export default BulkReplay;
