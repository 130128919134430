import React, { useEffect } from "react";
import {
  Form,
  Modal,
  Input,
  Row,
  Col,
  InputNumber,
  Radio,
  Checkbox,
} from "antd";
import PropTypes from "prop-types";
import { formItemsLayout } from "../../../constants/AdminModalForm/FormStyleConfig";
import AdminModalFooter from "../../CommonComponents/Modal/AdminModalFooter";
import SelectDropDown from "../../CommonComponents/SelectDropDown";
import { applicationDataInstance } from "../../../services/applicationDataService";

const formItemsStyle = {
  width: "100%",
  marginBottom: "10px",
};
const rowGutter = [16, 8];
const colSpan = 12;
const thresholdDaysOption = [
  { label: "SUNDAY", value: "SUNDAY" },
  { label: "MONDAY", value: "MONDAY" },
  { label: "TUESDAY", value: "TUESDAY" },
  { label: "WEDNESDAY", value: "WEDNESDAY" },
  { label: "THURSDAY", value: "THURSDAY" },
  { label: "FRIDAY", value: "FRIDAY" },
  { label: "SATURDAY", value: "SATURDAY" },
];
const alertTypeNamesOptions = [
  { label: "Both", value: "Both" },
  { label: "Email", value: "Email" },
  { label: "None", value: "None" },
  { label: "ServiceNow", value: "ServiceNow" },
];
const NewReportConfig = ({ ...props }) => {
  const [form] = Form.useForm();
  const applicationNames = applicationDataInstance.getApplicationNames();
  useEffect(() => {
    if (props.editFlag) {
      let tempThresholdDays = [];
      props?.data?.[0]?.thresholdDays && Object.entries(props?.data?.[0]?.thresholdDays).forEach(
        ([key, value]) => {
          if (value) {
            tempThresholdDays.push(thresholdDaysOption[key - 1]["value"]);
          }
        }
      );
      // console.log('props.data', props.data[0])
      form.setFieldsValue({
        ...props.data[0],
        "thresholdDays": tempThresholdDays,
      });
    }
  }, []);
  const onSaveHandler = (close) => {
    form.validateFields().then(async (values) => {
      let thresholdDaysConvertedValue = {};
      thresholdDaysOption
        .map((object) => object.label)
        .forEach((day, dayIter) => {
          thresholdDaysConvertedValue[dayIter + 1] = values.thresholdDays
            ? values.thresholdDays.includes(day)
            : false;
        });

      if (props.editFlag) {
        values = {
          "reportConfigId": props.data[0]["reportConfigId"],
          thresholdDays: thresholdDaysConvertedValue,
          ...values
        };
      }

      values = {
        ...values,
        thresholdDays: thresholdDaysConvertedValue,
      };
      props.saveData(values, close);
    });
  };
  return (
    <Modal
      open={props.isModalOpen}
      title={(props.editFlag ? "Edit" : "New") + " Report Config"}
      onCancel={props.onCloseModal}
      footer={
        props.editFlag ? (
          <AdminModalFooter
            close
            update
            onUpdate={() => onSaveHandler(true)}
            onClose={props.onCloseModal}
          />
        ) : (
          <AdminModalFooter
            save
            close
            saveAndClose
            onSaveAndClose={() => onSaveHandler(true)}
            onSave={() => onSaveHandler(false)}
            onClose={props.onCloseModal}
          />
        )
      }
      style={{ minWidth: "65vw" }}
      className="admin-modal-wrapper"
      centered
    >
      <Form
        id="solman-staging"
        form={form}
        {...formItemsLayout}
        labelAlign="left"
        colon
        initialValues={{
          allowBreach: false,
        }}
      >
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Application"
              name="applicationName"
              rules={[{ required: true, message: "Application is Mandatory!" }]}
              style={formItemsStyle}
            >
              <SelectDropDown
                options={applicationNames}
                disabled={props.editFlag}
              />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Service Name"
              name="serviceName"
              rules={[
                { required: true, message: "Service Name is Mandatory!" },
              ]}
              style={formItemsStyle}
            >
              <Input disabled={props.editFlag} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Email is Mandatory!" }]}
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Email Message"
              name="emailMsg"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Message Code"
              name="messageCode"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Message Fct"
              name="messsageFct"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Message Type"
              name="messageType"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="High threshold"
              name="thresholdHighLimit"
              rules={[
                { required: true, message: "High threshold is Mandatory!" },
              ]}
              style={formItemsStyle}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Low threshold"
              name="thresholdLowLimit"
              rules={[
                { required: true, message: "Low threshold is Mandatory!" },
              ]}
              style={formItemsStyle}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Allow Breach"
              name="allowBreach"
              style={formItemsStyle}
            >
              <Radio.Group>
                <Radio value>Enable</Radio>
                <Radio value={false}>Disable</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              label="Alert Type"
              name="alertType"
              style={formItemsStyle}
            >
              <SelectDropDown options={alertTypeNamesOptions} />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              label="Assignment Group"
              name="assignmentGroup"
              style={formItemsStyle}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item label="Threshold Day" name="thresholdDays"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              style={formItemsStyle}>
              <Checkbox.Group
                options={thresholdDaysOption}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

NewReportConfig.propTypes = {
  saveData: PropTypes.func,
  editFlag: PropTypes.any,
  data: PropTypes.any,
  onCloseModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
};

export default NewReportConfig;
