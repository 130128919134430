import * as types from '../../constants/ActionTypes/exceptionTypes';
import _ from 'lodash';
import { constructServiceUrl } from '../../services/constructUrl';
const checkMultipleApplication = (payload, serviceParams, type) => {
  let appData = sessionStorage.getItem("applicationDetails")
  if (appData) {
    appData = JSON.parse(appData)
    let appName = payload.applicationName.split(" ")
    let table = []
    _.each(appName, (app) => {
      table.push(_.find(appData, ['applicationName', app]).exceptionTable);
    })
    table = _.uniq(table)
    if (table.length > 1) {
      serviceParams.apiName = type;
    }
  }
  return serviceParams
}
const addNewFiled = (payload) => {
  if (payload.appName.length === 1) {
    payload.table = payload.appName[0].exceptionTable
  }
  let userInfo=JSON.parse(sessionStorage.getItem('persist:root'));
  let userAccessInfo=JSON.parse(userInfo.userAccess).userAccessInfo
  payload.userID = userAccessInfo.userID
  return payload
}
export function onExceptionSearch(payload) {
  let serviceParams = {}
  serviceParams.payload = addNewFiled(payload);
  serviceParams.apiName = types.SEARCH_EXCEPTIONS;
  let requestParams = constructServiceUrl(checkMultipleApplication(payload, serviceParams, types.ALL_SEARCH_EXCEPTIONS))
  return {
    type: types.SEARCH_EXCEPTIONS,
    request: requestParams
  };
}
export function updateExceptionSearchResults(exceptionData, searchCriterias) {
  return {
    type: types.EXCEPTION_SEARCH_SUCCESS,
    exceptionData,
    searchCriterias
  };
}
export function updateExceptionSearchFailure(errorMessage) {
  return {
    type: types.EXCEPTION_SEARCH_FAILURE,
    errorMessage
  };
}
export function getExceptionDetails(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.GET_EXCEPTION_DETAILS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.GET_EXCEPTION_DETAILS,
    request: requestParams
  };
}
export function updateExceptionDetails(exceptionDetails) {
  return {
    type: types.EXCEPTION_DETAILS,
    exceptionDetails
  };
}
export function clearExceptionData() {
  return {
    type: types.CLEAR_EXCEPTION_DATA
  }
}
export function replayException(exceptionDetails) {
  let serviceParams = {}
  serviceParams.payload = exceptionDetails;
  serviceParams.apiName = types.REPLAY_EXCEPTIONS;
  let requestParams = constructServiceUrl(serviceParams);
  return {
    type: types.REPLAY_EXCEPTIONS,
    request: requestParams
  };
}
export function updateReplayExceptionResult(replayResult, replayedExceptions) {
  return {
    type: types.EXCEPTION_REPLAY_RESULT,
    replayResult,
    replayedExceptions
  }
}
export function clearExceptionDetails() {
  return {
    type: types.CLEAR_EXCEPTION_DETAILS
  }
}
export function filterExceptionsFromSearchResult(exceptionDetails, actionType) {
  let serviceParams = {}
  serviceParams.payload = exceptionDetails;
  let type = null;
  window.iosLoader = true;
  if (actionType === 'Filter') {
    type = types.EXCEPTION_SEARCH_FILTER_EXCEPTIONS;
  } else if (actionType === 'De-Filter') {
    type = types.EXCEPTION_SEARCH_DEFILTER_EXCEPTIONS;
  }
  serviceParams.apiName = type;
  let requestParams = constructServiceUrl(serviceParams);
  return {
    type: type,
    request: requestParams
  };
}
export function updateExceptionResultsonFilter(filteredExceptions) {
  return {
    type: types.EXCEPTION_SEARCH_FILTER_EXCEPTIONS_RESULT,
    filteredExceptions
  }
}
export function onExceptionFilter(exceptionDetails, action) {
  let type;
    // if(action === 'Resolve'){
    //   window.iosLoader = false;
    // }
  if (action === 'Filter') {
    type = types.FILTER_EXCEPTIONS;
  } else if (action === 'De-filter' || action === 'De-Filter') {
    type = types.DEFILTER_EXCEPTIONS;
  } else if (action === 'Resolve') {
    type = types.FILTER_EXCEPTIONS_RESOLVE;
  } else if (action === 'Purge') {
    type = types.FILTER_EXCEPTIONS_PURGE;
  }
  let serviceParams = {}
  serviceParams.payload = exceptionDetails;
  serviceParams.apiName = type;
  let requestParams = constructServiceUrl(serviceParams);
  return {
    type: type,
    request: requestParams,
    action: action
  };
}
export function getFilterExceptionResult(filterResult, action) {
  let serviceParams = {}
  const payload = filterResult.requestID;
  serviceParams.payload = payload;
  serviceParams.apiName = types.GET_FILTERED_EXCEPTION_STATUS;
  let requestParams = constructServiceUrl(serviceParams);
  // window.iosLoader = false;
  return {
    type: types.GET_FILTERED_EXCEPTION_STATUS,
    request: requestParams,
    action: action
  };
}
export function updateExceptionFilterStatus(filterStatus, actionType) {
  return {
    type: types.EXCEPTION_FILTER_STATUS,
    filterStatus,
    actionType
  }
}
export function updateExceptionBulkReplayStatus(filterStatus, actionType) {
  return {
    type: types.EXCEPTION_BULK_REPLAY_STATUS,
    filterStatus,
    actionType
  }
}
export function clearFilterData() {
  return {
    type: types.CLEAR_FILTER_DATA
  }
}
export function purgeExceptions(exceptions) {
  let serviceParams = {}
  serviceParams.payload = exceptions;
  serviceParams.apiName = types.PURGE_EXCEPTIONS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.PURGE_EXCEPTIONS,
    request: requestParams
  };
}
export function updateExceptionPurgeResult(purgedExceptions) {
  return {
    type: types.PURGE_EXCEPTIONS_RESULT,
    purgedExceptions
  }
}
export function getReplayReportData(data) {
  let serviceParams = {}
  serviceParams.payload = data;
  serviceParams.apiName = types.GET_REPLAY_REPORT_DATA;
  let requestParams = constructServiceUrl(serviceParams);
  // window.iosLoader = false;
  return {
    type: types.GET_REPLAY_REPORT_DATA,
    request: requestParams
  };
}
export function updateReplayReport(reportData) {
  return {
    type: types.REPLAY_REPORT_DATA,
    reportData
  }
}
export function replayReportLoading() {
  return {
    type: types.REPLAY_REPORT_LOADING
  }
}

export function getTotalNoOfExceptions(data) {
  let serviceParams = {}
  serviceParams.payload = addNewFiled(data);
  serviceParams.apiName = types.GET_EXCEPTION_COUNT;
  // let requestParams = constructServiceUrl(serviceParams);
  let requestParams = constructServiceUrl(checkMultipleApplication(data, serviceParams, types.GET_EXCEPTION_COUNT_ALL))
  return {
    type: types.GET_TOTAL_EXCEPTION_RECORD_COUNT,
    request: requestParams
  };
}
export function updateTotalNoOfExceptions(exceptionData) {
  return {
    type: types.TOTAL_EXCEPTION_RECORD_COUNT,
    exceptionData
  }
}
export function onExceptionSort(payload) {
  let serviceParams = {}
  serviceParams.payload = addNewFiled(payload);
  serviceParams.apiName = types.SEARCH_EXCEPTIONS;
  let appData = sessionStorage.getItem("applicationDetails")
  window.iosLoader = true;
  if (appData) {
    appData = JSON.parse(appData)
    let appName = payload.applicationName.split(" ")
    let table = []
    _.each(appName, (app) => {
      table.push(_.find(appData, ['applicationName', app]).exceptionTable);
    })
    table = _.uniq(table)
    if (table.length > 1) {
      serviceParams.apiName = types.ALL_SEARCH_EXCEPTIONS;
    }
  }
  let requestParams = constructServiceUrl(serviceParams)
  return {
    type: types.SORT_EXCEPTIONS,
    request: requestParams
  };
}
export function updateExceptionSortResults(exceptionData, searchCriterias) {
  return {
    type: types.SORT_EXCEPTIONS_SUCCESS,
    exceptionData,
    searchCriterias
  };
}
export function updateExceptionSortFailure(message) {
  return {
    type: types.SORT_EXCEPTIONS_ERROR,
    message
  };
}
export function updateExceptionFailure(message) {
  return {
    type: types.EXCEPTIONS_ERROR,
    message
  };
}
export function onSaveExceptionDetails(params) {
  let serviceParams = {};
  serviceParams.payload = params;
  serviceParams.apiName = types.SAVE_EXCEPTION_DETAILS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.SAVE_EXCEPTION_DETAILS,
    request: requestParams
  };
}
export function updateExceptionDetailsSave(status, exceptionId) {
  return {
    type: types.SAVE_EXCEPTION_DETAILS_RESULT,
    status,
    exceptionId
  };
}
export function getSolmanExceptionDetails(params) {
  let serviceParams = {};
  serviceParams.payload = params;
  serviceParams.apiName = types.GET_SOLMAN_EXCEPTION_DETAILS;
  let requestParams = constructServiceUrl(serviceParams)
  return {
    type: types.GET_SOLMAN_EXCEPTION_DETAILS,
    request: requestParams
  };
}

export function getExceptionReplayStatus(params) {
  let serviceParams = {};
  serviceParams.payload = params;
  serviceParams.apiName = types.REPLAY_EXCEPTIONS_STATUS;
  let requestParams = constructServiceUrl(serviceParams)
  return {
    type: types.REPLAY_EXCEPTIONS_STATUS,
    request: requestParams
  };

}

export function updateExceptionReplayStatus(result) {
  return {
    type: types.REPLAY_EXCEPTIONS_STATUS_UPDATE,
    request: result
  };


}
export function exceptionReducerProgress() {
  return {
    type: types.PROGRESS_EXCEPTION_REDUCER
  }
}

export function updateExceptionCSTData(data) {
  return {
    type: types.UPDATE_EXCEPTION_CST_DATA,
    data
  };
}