import React from "react";
import PropTypes from "prop-types";
import { Col, Modal, Row } from "antd";
import { CheckBox } from "../../../components/CommonComponents/FormElement/Buttons/Buttons";
import "./userDetail.scss";

const UserDetail = (props) => {
  const [index, setIndex] = React.useState(0);
  return (
    <Modal
      open={props.isModalOpen}
      onCancel={props.onCloseModal}
      width={1140}
      className="user-detail-modal"
      title={(
        <>
          <h5 className="modal-title mb-0">
            User Detail
          </h5>
          {props.data.length > 1 && (
            <ul
              className="pagination justify-content-center pagination-sm config-pagination"
              style={{ left: "28%" }}
            >
              <li className={`page-item ${index === 0 && "cus_notallowed"}`}>
                <button
                  id="sel_logs_details_prev"
                  className={`btn btn-default ${
                    index !== 0 ? "b500" : "btndisable"
                  }`}
                  aria-label="Previous"
                  onClick={() => {
                    setIndex(index - 1);
                  }}
                >
                  <span aria-hidden="true" className="icn_pre_nxt">
                    &laquo;
                  </span>
                  <span>Previous</span>
                </button>
              </li>
              <li
                className={`page-item ${
                  index === props.data.length - 1 && "cus_notallowed"
                }`}
              >
                <button
                  id="sel_logs_details_next"
                  className={`btn btn-default ${
                    index !== props.data.length - 1 ? "b500" : "btndisable"
                  }`}
                  aria-label="Next"
                  onClick={() => {
                    setIndex(index + 1);
                  }}
                >
                  <span>Next</span>
                  <span aria-hidden="true" className="icn_pre_nxt">
                    &raquo;
                  </span>
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      footer={(
      <button
        type="button"
        className="btn btn-danger"
        data-dismiss="modal"
        onClick={props.onCloseModal}
      >
        <i className="fa fa-times" /> Close
      </button>
      )}
      centered
    >
      <>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">First Name </label>
              </Col>
              <Col span={15}>
                <label className="form-control-label configExcep_detail_label">
                  {props.data[index].firstName}
                </label>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Last Name </label>
              </Col>
              <Col span={15}>
                <label className="form-control-label configExcep_detail_label">
                  {props.data[index].lastName}
                </label>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">GPID</label>
              </Col>
              <Col span={15}>
                <label className="form-control-label configExcep_detail_label">
                  {props.data[index].userID}
                </label>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Email </label>
              </Col>
              <Col span={15}>
                <label
                  className="form-control-label configExcep_detail_label ellipsis"
                  title={
                    props.data[index].email ? props.data[index].email : "NA"
                  }
                >
                  {props.data[index].email ? props.data[index].email : "NA"}
                </label>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Admin Access</label>
              </Col>
              <Col span={15}>
                <label className="form-control-label configExcep_detail_label">
                  {props.data[index].adminAccess === "Y" ? "Yes" : "No"}
                </label>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={9}>
                <label className="form-control-label detail_label">Bulk Replay Access </label>
              </Col>
              <Col span={15}>
                <label className="form-control-label configExcep_detail_label">
                  {props.data[index].bulkReply === "Y" ? "Yes" : "No"}
                </label>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="b600 mb-2">Application Access</Row>
        <Row gutter={16}>
          <Col span={24}>
            {props.data[index].roleAccess &&
            Object.keys(props.data[index].roleAccess).length > 0 ? (
              <div className="access-list-box">
                {Object.keys(props.data[index].roleAccess).map((app, i) => {
                  return (
                    <Row key={i} className="user-detail-row">
                      <Col span={6}>
                        {app}
                      </Col>
                      <Col
                        span={18}
                        className="col2"
                        style={{ marginTop: -4 }}
                      >
                        <Row>
                          <Col
                            className="timezone-disable"
                            span={6}
                          >
                            <CheckBox
                              onChange={() => {}}
                              label={"View"}
                              disable
                              checked={props.data[index].roleAccess[
                                app
                              ].includes("view")}
                            />
                          </Col>
                          <Col
                            className="timezone-disable"
                            span={6}
                          >
                            <CheckBox
                              onChange={() => {}}
                              label={"Replay"}
                              disable
                              checked={props.data[index].roleAccess[
                                app
                              ].includes("replay")}
                            />
                          </Col>
                          <Col
                            className="timezone-disable"
                            span={6}
                            style={{ paddingRight: 7, paddingLeft: 0 }}
                          >
                            <div
                              style={{
                                width: 100,
                              }}
                            >
                              <CheckBox
                                onChange={() => {}}
                                label={"Edit Header"}
                                disable
                                checked={props.data[index].roleAccess[
                                  app
                                ].includes("edit_JMS_Header")}
                              />
                            </div>
                          </Col>
                          <Col
                            className="timezone-disable"
                            span={6}
                            style={{ paddingRight: 0, paddingLeft: 7 }}
                          >
                            <div
                              style={{
                                width: 105,
                              }}
                            >
                              <CheckBox
                                onChange={() => {}}
                                label={"Edit Payload"}
                                disable
                                checked={props.data[index].roleAccess[
                                  app
                                ].includes("edit_JMS_Payload")}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            ) : (
              <div className="f16 b500 text-center">
                No Application access found!
              </div>
            )}
          </Col>
        </Row>
      </>
    </Modal>
  );
};
UserDetail.propTypes = {
  onCloseModal: PropTypes.func,
  data: PropTypes.array,
  isModalOpen: PropTypes.bool,
};

export default UserDetail;
