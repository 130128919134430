import React, { useEffect } from "react";
import { Form, Modal, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import PropTypes from "prop-types";
import { applicationDataInstance } from "../../../../services/applicationDataService";
import SelectDropDown from '../../../CommonComponents/SelectDropDown';
import AdminModalFooter from "../../../CommonComponents/Modal/AdminModalFooter";
import { formItemsLayout, formItemsDefaultStyle } from "../../../../constants/AdminModalForm/FormStyleConfig";

const replayModes = [
  {
    label: "HTTP",
    value: "HTTP",
  },
  {
    label: "JMS",
    value: "JMS",
  },
  {
    label: "KAFKA",
    value: "KAFKA"
  },
  {
    label: "HTTP OAUTH",
    value: "HTTP_OAUTH"
  },
];

const NewReplayConfig = ( { ...props } ) => {
  useEffect( () => {
    if ( props.editFlag ) {
      form.setFieldsValue( { ...props.data } );
    }
  }, [] );
  const [ form ] = Form.useForm();
  const applicationNames = applicationDataInstance.getApplicationNames();
  const isReplayModeChanged = Form.useWatch( 'replayMode', form );

  const onSaveHandler = ( close ) => {
    form.validateFields()
      .then( async values => {
        if ( props.editFlag ) {
          values = {
            "replayID": props.data[ "replayID" ],
            ...values
          };
        }
        props.saveData( values, close );
      } );
  };
  const onFormValueChangeHandler = () => { };
  return ( <Modal
    open={props.isModalOpen}
    title={( props.editFlag ? "Edit" : "New" ) + " Replay Configuration"}
    onCancel={props.onCloseModal}
    footer={
      ( props.editFlag ?
        <AdminModalFooter
          close update
          onUpdate={() => onSaveHandler( true )}
          onClose={props.onCloseModal}
        />
        :
        <AdminModalFooter
          save close saveAndClose
          onSaveAndClose={() => onSaveHandler( true )}
          onSave={() => onSaveHandler( false )}
          onClose={props.onCloseModal}
        /> )
    }
    style={{ minWidth: 800 }}
    className="admin-modal-wrapper"
    centered
  >
    <Form
      id="exception-config"
      form={form}
      {...formItemsLayout}
      labelAlign="left"
      colon
      onValuesChange={onFormValueChangeHandler}
    >
      <Form.Item label="Application Name" name="applicationName"
        rules={[ { required: true, message: 'Application is Mandatory!' } ]}
        style={formItemsDefaultStyle}>
        <SelectDropDown
          disabled={props.editFlag}
          options={applicationNames}
        />
      </Form.Item>
      <Form.Item label="Application Domain" name="applicationDomain"
        rules={[ { required: true, message: 'Application Domain is Mandatory!' } ]}
        style={formItemsDefaultStyle}>
        <Input />
      </Form.Item>
      <Form.Item label="Host URL" name="replayHost"
        rules={[ { required: true, message: 'Host URL is Mandatory!' } ]}
        style={formItemsDefaultStyle}>
        <Input />
      </Form.Item>
      <Form.Item label="Replay Mode" name="replayMode" style={formItemsDefaultStyle}
        rules={[ { required: true, message: 'Replay Mode is Mandatory!' } ]}
      >
        <SelectDropDown
          disabled={props.editFlag}
          options={replayModes}
        />
      </Form.Item>
      <Form.Item label={form.getFieldValue('replayMode') !== 'HTTP_OAUTH' ? 'Username' : 'Client Id'} name="username"
        rules={[ { required: true, message: 'User ID is Mandatory!' } ]}
        style={formItemsDefaultStyle}>
        <Input />
      </Form.Item>
      <Form.Item label={form.getFieldValue('replayMode') !== 'HTTP_OAUTH' ? 'Password' : 'Client Secret'} name="password"
        rules={[ { required: true, message: 'Password is Mandatory!' } ]}
        style={formItemsDefaultStyle}>
        <Input.Password iconRender={( visible ) => ( visible ? <EyeTwoTone /> : <EyeInvisibleOutlined /> )} />
      </Form.Item>
      
      {isReplayModeChanged === 'HTTP_OAUTH' &&
        <Form.Item label="Token URL" name="oktaIssuerUrl" style={formItemsDefaultStyle}
          rules={[ { required: true, message: 'Token URL is Mandatory!' } ]}
        >
          <Input disabled={form.getFieldValue( 'replayMode' ) !== 'HTTP_OAUTH'} />
        </Form.Item>
      }
      <Form.Item label="Remarks" name="description"
        rules={[ { required: true, message: 'Remarks is Mandatory!' } ]}
        style={formItemsDefaultStyle}>
        <Input />
      </Form.Item>

    </Form>
  </Modal> );

};
NewReplayConfig.propTypes = {
  onCloseModal: PropTypes.func,
  data: PropTypes.any,
  editFlag: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  saveData: PropTypes.func,
};
export default NewReplayConfig;