import _ from 'lodash';
import { store } from "../store/store";
import { SNOW_NOTIFICATION_SERVICE_NAME } from "../constants/AdminModalForm/SnowNotificationServiceName";


export const getTokenToReqHeader = () => {
    const localStore = JSON.parse(localStorage.getItem('okta-token-storage'));
    let token = localStore ? getToken(localStore) : 'nolocalstore';
    token = 'Bearer ' + token;
    return token;
};

const getToken = (store) => store.accessToken ? store.accessToken.accessToken : 'nogettoken';


export const checkIfExistsInArray = (originalArray, itemToFind, key, ignoreKey, ignoreId) => {
    if (typeof key === "string") {
        return originalArray.some(
            objectInArray => objectInArray[key].toString().toLowerCase() === itemToFind[key].toString().toLowerCase()
                && (ignoreId ? objectInArray[ignoreKey] !== ignoreId : true));
    }
    else if (typeof key === "object") {
        return originalArray.some(objectInArray => {
            return key.every(listItem => objectInArray[listItem] === itemToFind[listItem]) && (ignoreId ? objectInArray[ignoreKey] !== ignoreId : true);
        });
    }
};

export const findIfContainsInArray = (originalArray, arrayToCheck, key) => {
    return originalArray.filter(objectInArray => {
        return arrayToCheck.some(ObjectToCheck => {
            return key.every(listItem => objectInArray[listItem] === ObjectToCheck[listItem]);
        });
    });
};

export const findIfContainsInArrayUniversalSearch = (arrayToFindIn, searchField, searchKeys) => {
    if (typeof searchField === "object") {
        return arrayToFindIn.filter(objectInArray => searchKeys.some((searchKeyItem) => searchField.some(searchFieldItem => objectInArray[searchKeyItem]?.toString().toLowerCase().includes(searchFieldItem.toString().toLowerCase()))));
    }
    else if (typeof searchField === "string") {
        return arrayToFindIn.filter(objectInArray => JSON.stringify(objectInArray).toLowerCase().includes(searchField.toLowerCase()));
    }
};

export const findUsersMatchingCriteria = (arrayList, searchField) => {
    if (typeof searchField === "object") {
        return arrayList.filter(objectInArray => {
            return searchField.every(searchFieldItem => {
                if ((objectInArray["userID"] && objectInArray["userID"].toString().toLowerCase().includes(searchFieldItem.toString().toLowerCase())) ||
                    (objectInArray["email"] && objectInArray["email"].toString().toLowerCase().includes(searchFieldItem.toString().toLowerCase())) ||
                    (objectInArray["firstName"] && objectInArray["firstName"].toString().toLowerCase().includes(searchFieldItem.toString().toLowerCase())) ||
                    (objectInArray["lastName"] && objectInArray["lastName"].toString().toLowerCase().includes(searchFieldItem.toString().toLowerCase()))) {
                    return true;
                }
                return false;
            });
        });
    }
    return arrayList;
};

export const findDifferenceFromTwoList = (firstArray, secondArray, key, key2) => {
    let added = [];
    let deleted = [];
    let updated = [];
    firstArray.forEach((firstArrayObject) => {
        let newObjectFound = secondArray.find((secondArrayObject) => secondArrayObject[key] === firstArrayObject[key]);
        if (newObjectFound) {
            if (!_.isEqual(firstArrayObject, newObjectFound)) {
                updated.push(newObjectFound);
            }
        }
        else {
            deleted.push(firstArrayObject);
        }
    });
    secondArray.forEach((secondArrayObject) => {
        let ifNotExists = !firstArray.some((firstArrayObject) => {
            return secondArrayObject[key] === firstArrayObject[key] && secondArrayObject[key2] === firstArrayObject[key2];
        });
        if (ifNotExists && !checkIfExistsInArray(updated, secondArrayObject, [key, key2])) {
            added.push(secondArrayObject);
        }
    });
    return { added, updated, deleted };
};

export const getUserDataByIds = (allUserList, idsToFind) => {
    return allUserList.filter(userObj => idsToFind.includes(userObj["userID"]));
};
export const getUsersNotInList = (allUserList, idsToIgnore) => {
    return allUserList.filter(userObj => !idsToIgnore.includes(userObj["userID"]));
};

export const findMaxValueApplication = (reportList) => {
    if (reportList && reportList.length > 0) {
        let maxApp = [];
        reportList.forEach((val) => {
            delete val.all;
            let obj = val,
                keys = Object.keys(obj),
                largest = Math.max.apply(
                    null,
                    keys.map((x) => obj[x])
                ),
                result = keys.reduce((result, key) => {
                    if (obj[key] === largest) {
                        result = { [key]: obj[key] };
                    }
                    return result;
                }, {});
            maxApp.push(result);
        });
        let maxValApp = _.maxBy(maxApp, (o) => o[Object.keys(o)[0]]);
        if (maxValApp && Object.keys(maxValApp)[0]) {
            return [
                {
                    value: Object.keys(maxValApp)[0],
                    label: Object.keys(maxValApp)[0],
                },
            ];
        }
    }
    return null;
};

export const checkIfCurrentLoggedInUserIsAdmin = () => {
    const globalStore = store?.getState();
    return globalStore?.userAccess?.userAccessInfo?.adminAccess === "Y" ? true : false;
};

export const getSnowServiceName = (selectedValue) => {
    const selectedObj = SNOW_NOTIFICATION_SERVICE_NAME.find(o => o.value === selectedValue)
    return selectedObj ? selectedObj.label : '';
}