

const initState={
 isCstTimeZone:setInitValue(),
}
function setInitValue(){
    if(localStorage.getItem("isTimeZoneDefined")==='' || localStorage.getItem("isTimeZoneDefined") === null){
        localStorage.setItem("isTimeZoneDefined", true);
        return true
    }else if(localStorage.getItem("isTimeZoneDefined")==="false"){
        return false
    }
    else{
        return true
    }
}
const configReducer=(state=initState,action)=>{
    switch(action.type)
    {
        case "UPDATE_TIMEZONE":{
            return Object.assign({},state,
            {isCstTimeZone:action.timeZone}
        );}
        default: return state
    }

}

export default configReducer