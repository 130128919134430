import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Form } from "antd";
import { useDispatch } from 'react-redux';
import UserFooter from '../UserFooter';
import GroupAccessItem from './GroupAccessItem';
import { findDifferenceFromTwoList } from '../../../utils/helpers';
import { saveRolesApplicationList, deleteRolesApplicationList, updateRolesApplicationList } from "../../../actions/Admin/userActions";

const ApplicationGroupTab = ({ ...props }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [initialFormData, setInitialFormData] = useState(null);
    const formGroupNameList = Form.useWatch('groupAccessList', form);
    const [, setErrorList] = useState(null);

    useEffect(() => {
        form.setFieldsValue({
            groupAccessList: props.initialGroupAccessList
        });
        
        setInitialFormData({ groupAccessList: props.initialGroupAccessList });
    }, [props.initialGroupAccessList]);

    const onSaveAccessHandler = () => {
        form.validateFields()
            .then(async (values) => {
                setErrorList([]);
                let differenceObj = findDifferenceFromTwoList(props.initialGroupAccessList, values["groupAccessList"], "groupName", "accessName")
                
                if (differenceObj.added && differenceObj.added.length > 0) {
                    dispatch(saveRolesApplicationList(differenceObj.added));
                }
                
                if (differenceObj.deleted && differenceObj.deleted.length > 0) {
                    dispatch(deleteRolesApplicationList(differenceObj.deleted));
                }

                if (differenceObj.updated && differenceObj.updated.length > 0) {
                    dispatch(updateRolesApplicationList(differenceObj.updated));
                }
                form.setFieldsValue({
                    groupAccessList: values["groupAccessList"]
                })
                setInitialFormData({ groupAccessList:values["groupAccessList"]})
            }).catch((err) => {
                console.log(err);
                setErrorList(err);
            });
    }

    return (<>
        <Form
            id="application-group-form"
            form={form}
            initialValues={initialFormData}>
            <GroupAccessItem
                formRef={form}
                selectedApplication={props.selectedApplication}
                groupNameOptionsList={props.groupNameOptionsList}
                formGroupNameList={formGroupNameList}
            />
        </Form>
        <UserFooter
            update
            reset
            onUpdateSave={onSaveAccessHandler}
            handleReset={() => { form.resetFields() }}
        />
    </>);
}
ApplicationGroupTab.propTypes = {
    selectedApplication: PropTypes.string,
    initialGroupAccessList: PropTypes.array,
    groupAccessList: PropTypes.array,
    groupNameOptionsList: PropTypes.array,
};
export default ApplicationGroupTab;