import * as types from '../../constants/ActionTypes/analyticsTypes';
import objectAssign from 'object-assign';
import update from 'immutability-helper';
const initialState = {
  logReport: {
  },
  exceptionReport: {
  }
};

export default function analyticsReducer(state = initialState, action) {
  switch (action.type) {
    case types.EXCEPTION_ANALYTICS:
      return objectAssign({}, update(state, {
        exceptionReport: { $set: action.exceptionReport }
      }));
    case types.LOG_ANALYTICS:
      return objectAssign({}, update(state, {
        logReport: { $set: action.logReport }
      }));
    default:
      return state;
  }
}
