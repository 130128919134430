import React, { useState } from "react";
import PropTypes from "prop-types";
import CleTable from "../../../CommonComponents/CleTable/CleTable";
import CleFooter from "../../../CommonComponents/CleFooter/CleFooter";
import { COLUMNS } from "../../../../constants/ColumnConfig/Admin/exceptionStatusResults";
import Loader from "../../../CommonComponents/Loader";
import ConfirmationModalPopup from "../../../CommonComponents/Modal/ConfirmationModalPopup";
import NewExceptionConfig from "./newExceptionConfig";
const pageStyle = {
    height: "calc(100vh - 210px)",
    width: "100%",
};
const ExceptionStatus = ({ ...props }) => {
    const [showUpsertModal, setShowUpsertModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [isModalEdit, setIsModalEdit] = useState(false);
    const onDeleteHandler = () => {
        props.onDelete([selectedRow[0]]);
        gridApi.deselectAll();
        setSelectedRow([]);
        setShowConfirmModal(false);
    };
    const onSaveDataHandler = (payload, close) => {
        props.saveDetails(payload);
        if (close) {
            setShowUpsertModal(false);
            gridApi.deselectAll();
            setSelectedRow([]);
        }
    };
    return (<>
        {
            props.isLoading &&
            <Loader />
        }
        {
            !props.isLoading &&
            <>
                <div className={"admin-cle-table-wrapper"} style={pageStyle}>
                    <CleTable
                        rowSelection="single"
                        getRowNodeId={(data) => data.configID}
                        suppressRowClickSelection
                        columnDefs={COLUMNS}
                        immutableData
                        rowData={props.exceptionReportData ? props.exceptionReportData : []}
                        onRowClicked={(params) => { params.node.isSelected() ? params.node.setSelected(false) : params.node.setSelected(true); setSelectedRow(params.api.getSelectedRows()); }}
                        onRowSelected={(params) => { setSelectedRow(params.api.getSelectedRows()); }}
                        onGridReady={(params) => setGridApi(params.api)}
                    />
                </div>
                <CleFooter
                    add onAdd={() => { setIsModalEdit(false); setShowUpsertModal(true); }}
                    modify onModify={() => { setShowUpsertModal(true); setIsModalEdit(true); }}
                    modifyDisabled={selectedRow.length === 0}
                    deleteDisabled={selectedRow.length === 0}
                    onDelete={() => setShowConfirmModal(true)}
                    deleteBtn />
            </>
        }
        {
            showConfirmModal &&
            <ConfirmationModalPopup
                title="Are you sure you want to delete this item?"
                isModalOpen={showConfirmModal}
                onCloseModal={() => setShowConfirmModal(false)}
                onOkModal={() => onDeleteHandler()} />
        }
        {
            showUpsertModal &&
            <NewExceptionConfig
                editFlag={isModalEdit}
                data={isModalEdit ? selectedRow : []}
                onCloseModal={() => { setShowUpsertModal(false); setIsModalEdit(false); }}
                isModalOpen={showUpsertModal}
                saveData={(payload, close) => onSaveDataHandler(payload, close)}
            />
        }
    </>);
};

ExceptionStatus.propTypes = {
    exceptionReportData: PropTypes.any,
    onDelete: PropTypes.func,
    saveDetails: PropTypes.func,
    isLoading: PropTypes.any,
};
export default ExceptionStatus;