import { call, put, fork, all, takeLatest } from "redux-saga/effects";
import * as actions from "../../actions/Admin/tableConfigActions";
import * as types from "../../constants/ActionTypes/Admin/tableConfigTypes";
import { LOG_EXCEPTION_TABLE } from "../../constants/ActionTypes/Admin/applicationConfigTypes";
import { defaultAction } from "../../services/restService";
import { toast } from "react-toastify";
import { createLogger } from "redux-logger";

export function* getIndexReport(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      action: "NEW",
      id: "tableName",
    };
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    createLogger(e);
  }
}
export function* fetchIndexReport() {
  yield takeLatest(LOG_EXCEPTION_TABLE, getIndexReport);
}

export function* saveIndexReportData(payload) {
  try {
    yield put(actions.reportProcess());
    const response = yield call(defaultAction, payload);
    toast.success("Table Config added successfully");
    const rep = {
      response,
      action: "ADD",
      id: "tableName",
    };
    yield put(actions.reportDataStore(rep));
    yield put(actions.getIndexDetails());
  } catch (e) {
    createLogger(e);
  }
}

export function* addIndexReportData() {
  yield takeLatest(types.ADD_INDEX_CONFIG, saveIndexReportData);
}

export default function* root() {
  yield all([
    fork(fetchIndexReport),
    fork(addIndexReportData),
  ]);
}
