import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import * as actions from '../../actions/Admin/scheduleReportAction';
import * as types from '../../constants/ActionTypes/Admin/scheduleReportTypes';
import { defaultAction } from '../../services/restService';
import { toast } from 'react-toastify';
import { logger } from 'redux-logger';
export function* getScheduleReport(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      "action": "NEW",
      "id": "application"
    }
    yield put(actions.reportScheduleDataStore(rep));
  } catch (e) {
    logger(e)
  }
}
export function* fetchScheduleReport() {
  yield takeLatest(types.GET_SCHEDULE_REPORT, getScheduleReport)
}

export function* deleteScheduleReport(payload) {
  try {
    const response = yield call(defaultAction, payload);
    toast.success("Schedule report Config has been deleted successfully")
    const rep = {
      response,
      "action": "DELETE",
      "id": "application"
    }
    yield put(actions.reportScheduleDataStore(rep));

  } catch (e) {
    logger(e)
  }
}
export function* deleteScheduleReportData() {
  yield takeLatest(types.DELETE_SCHEDULE_REPORT, deleteScheduleReport)
}

export function* saveScheduleReportData(payload) {
  try {
    const response = yield call(defaultAction, payload);
    toast.success("Schedule report Config has been saved successfully")
    const rep = {
      response,
      "action": "ADD",
      "id": "application"
    }
    yield put(actions.reportScheduleDataStore(rep));
  } catch (e) {
    logger(e)
  }
}
export function* addScheduleReportData() {
  yield takeLatest(types.ADD_SCHEDULE_REPORT, saveScheduleReportData)
}

export function* ModifyScheduleReportData(payload) {
  try {
    const response = yield call(defaultAction, payload);
    toast.success("Schedule report Config has been updated successfully")
    const rep = {
      response,
      "action": "UPDATE",
      "id": "application"
    }
    yield put(actions.reportScheduleDataStore(rep));
  } catch (e) {
    logger(e)
  }
}
export function* modifyScheduleReport() {
  yield takeLatest(types.UPDATE_SCHEDULE_REPORT, ModifyScheduleReportData)
}

export default function* root() {
  yield all([fork(fetchScheduleReport), fork(deleteScheduleReportData),
  fork(addScheduleReportData), fork(modifyScheduleReport)])
}
