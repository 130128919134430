import { useEffect } from "react";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";

const Home = (props) => {
  const { oktaAuth, authState } = useOktaAuth();

  const login = async () => {
    oktaAuth.signInWithRedirect();
  };

  useEffect(() => {
    if (authState) {
      if (!authState.isAuthenticated) {
        console.log(
          "check useEffect if authenticated",
          authState.isAuthenticated
        );
        login();
      } else {
        // redirect to dashboard page
        const defaultPage = `/logs/search-logs/list`;
        props.history.push(defaultPage);
      }
    }
  }, [authState]); // Update if authState changes

  return null;
};

Home.propTypes = {
  history: PropTypes.object,
};

export default Home;
