import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import './style.scss';

const CleTabs = ({
    animated,
    defaultActiveTab,
    onTabChangeHandler,
    tabClassName,
    tabItems,
    tabExtraControlContent,
    tabSize,
    tabType,
    activeTab
}) => {
    return (
        <Tabs
            animated={animated}
            size={tabSize}
            defaultActiveKey={defaultActiveTab}
            onChange={onTabChangeHandler}
            className={tabClassName}
            items={tabItems}
            tabBarExtraContent={tabExtraControlContent}
            type={tabType}
            activeKey={activeTab?activeTab:defaultActiveTab}
        />
    )
}

CleTabs.defaultProps = {
    animated: true,
    tabType: 'card',
    tabSize: 'large',
};

CleTabs.propTypes = {
    animated: PropTypes.bool,
    defaultActiveTab: PropTypes.string,
    tabClassName: PropTypes.string,
    onTabChangeHandler: PropTypes.func,
    tabItems: PropTypes.array,
    tabSize: PropTypes.string,
    tabExtraControlContent: PropTypes.node,
    tabType: PropTypes.string,
    activeTab: PropTypes.string
};

export default CleTabs;