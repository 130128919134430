import moment from "moment";
import { default as momentZone } from "moment-timezone";

const COLUMNS = [
  {
    field: "replayID",
    headerName: "ReplayID",
  },
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "createdBy",
    headerName: "CreatedBy",
  },
  {
    field: "createdOn",
    headerName: "CreatedOn",
    filter: "agDateColumnFilter",
    cellRenderer: function dateValueGettter( params ) {
      if ( params?.context?.timeZone ) {
        return momentZone( params.value )
          .tz( window.timeZone )
          .format( "YYYY-MM-DD HH:mm:ss.SSS" );
      }
      return moment( params.value ).local().format( "YYYY-MM-DD HH:mm:ss.SSS" );
    },
  },
];
export default COLUMNS;
