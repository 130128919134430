import * as types from "../../constants/ActionTypes/Admin/userTypes";
import { constructServiceUrl } from "../../services/constructUrl";

export function updateUsersList(usersList) {
  return {
    type: types.USERS_LIST,
    usersList,
  };
}
export function getUsersList() {
  let serviceParams = {};
  serviceParams.apiName = types.GET_USERS_LIST;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_USERS_LIST,
    request: requestParams,
  };
}
export function addUserFunction(newUser) {
  let serviceParams = {};
  serviceParams.payload = newUser;
  serviceParams.apiName = types.ADD_USER;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_USER,
    request: requestParams,
  };
}
export function updateAddUserConfig(userAddResults) {
  return {
    type: types.USER_ADD_RESULTS,
    userAddResults,
  };
}
export function editUserFunction(updatedUser) {
  let serviceParams = {};
  serviceParams.payload = updatedUser;
  serviceParams.apiName = types.EDIT_USER;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.EDIT_USER,
    request: requestParams,
  };
}
export function updateEditUserConfig(userAddResults) {
  return {
    type: types.USER_EDIT_RESULTS,
    userAddResults,
  };
}
export function deleteUser(userId) {
  let serviceParams = {};
  serviceParams.payload = userId;
  serviceParams.apiName = types.DELETE_USER;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_USER,
    request: requestParams,
  };
}
export function updateRolesList(roleList) {
  return {
    type: types.ROLES_LIST,
    roleList,
  };
}
export function getRolesList() {
  let serviceParams = {};
  serviceParams.apiName = types.GET_ROLES_LIST;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_ROLES_LIST,
    request: requestParams,
  };
}
export function deleteRoles(roleId) {
  let serviceParams = {};
  serviceParams.payload = roleId;
  serviceParams.apiName = types.DELETE_ROLE;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_ROLE,
    request: requestParams,
  };
}
export function addRoleFunction(newRole) {
  let serviceParams = {};
  serviceParams.payload = newRole;
  serviceParams.apiName = types.ADD_ROLE;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_ROLE,
    request: requestParams,
  };
}
export function updateAddRoleConfig(roleAddResults) {
  return {
    type: types.ROLE_ADD_RESULTS,
    roleAddResults,
  };
}
export function editRoleFunction(updatedRole) {
  let serviceParams = {};
  serviceParams.payload = updatedRole;
  serviceParams.apiName = types.EDIT_ROLE;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.EDIT_ROLE,
    request: requestParams,
  };
}
export function updateEditRoleConfig(roleAddResults) {
  return {
    type: types.ROLE_EDIT_RESULTS,
    roleAddResults,
  };
}
export function updateRoleUsersList(roleUsersList) {
  return {
    type: types.ROLE_USERS_LIST,
    roleUsersList,
  };
}
export function getRoleUsersList() {
  let serviceParams = {};
  serviceParams.apiName = types.GET_ROLE_USERS_LIST;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_ROLE_USERS_LIST,
    request: requestParams,
  };
}
export function updateApplicationRolesList(applicationRolesList) {
  return {
    type: types.APPLICATION_ROLES_LIST,
    applicationRolesList,
  };
}

export function getRolesApplicationList() {
  let serviceParams = {};
  serviceParams.apiName = types.GET_ROLES_APPLICATION_LIST;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.GET_ROLES_APPLICATION_LIST,
    request: requestParams,
  };
}
export function saveRolesApplicationList(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_ROLES_APPLICATION_LIST;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_ROLES_APPLICATION_LIST,
    request: requestParams,
  };
}

export function updateRolesApplicationList(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_ROLES_APPLICATION_LIST;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.UPDATE_ROLES_APPLICATION_LIST,
    request: requestParams,
  };
}

export function deleteRolesApplicationList(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_ROLES_APPLICATION_LIST;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_ROLES_APPLICATION_LIST,
    request: requestParams,
  };
}

export function updateAddApplicationRoleConfig(applicationRoleAddResults) {
  return {
    type: types.APPLICATION_ROLE_ADD_RESULTS,
    applicationRoleAddResults,
  };
}

export function updateEditApplicationRoleConfig(applicationRoleAddResults) {
  return {
    type: types.ROLE_EDIT_RESULTS,
    applicationRoleAddResults,
  };
}
export function addRoleUsersFunction(newRoleUserRelation) {
  let serviceParams = {};
  serviceParams.payload = newRoleUserRelation;
  serviceParams.apiName = types.ADD_ROLE_USER;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_ROLE_USER,
    request: requestParams,
  };
}
export function updateRoleUsersFunction(newRoleUserRelation) {
  let serviceParams = {};
  serviceParams.payload = newRoleUserRelation;
  serviceParams.apiName = types.UPDATE_ROLE_USER;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_ROLE_USER,
    request: requestParams,
  };
}
export function updateAddRoleUserConfig(roleUserAddResults) {
  return {
    type: types.ROLE_USER_ADD_RESULTS,
    roleUserAddResults,
  };
}
export function deleteRoleUsersFunction(deleteRoleUserRelation) {
  let serviceParams = {};
  serviceParams.payload = deleteRoleUserRelation;
  serviceParams.apiName = types.DELETE_ROLE_USER;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_ROLE_USER,
    request: requestParams,
  };
}
export function updateDeleteRoleUserConfig(roleUserDeleteResults) {
  return {
    type: types.ROLE_USER_DELETE_RESULTS,
    roleUserDeleteResults,
  };
}
export function clearValues() {
  return {
    type: types.EMPTY_VALUE,
  };
}
export function progress() {
  return {
    type: types.PROGRESS_USER_REPORT,
  };
}

export function UserDataStore(data) {
  return {
    type: types.STORE_USER_REPORT,
    data,
  };
}

export const userApi = (status) => ({
  type: types.USER_API_STATUS,
  status,
});
