import * as types from "../../constants/ActionTypes/Admin/solmanStagingTypes";
import objectAssign from "object-assign";
import { formatData } from "../../utils/constIndexReducer";

const initialState = {
  solmanStagingData: [],
  isLoading: true,
};

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is consid  ered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function applicationsConfigReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    // case types.SOLMAN_STAGING_DETAILS:
    //   return objectAssign({}, state, {solmanData: action.solmanData,detectAction:state.detectAction,isLoading:false});
    // case types.DELETE_SOLMAN_STAGING:
    //   return objectAssign({}, state, {detectAction: 'Solman Deleted'});
    // case types.ADD_SOLMAN_STAGING:
    //   return objectAssign({}, state, {detectAction: 'Solman Added'});
    // case types.UPDATE_SOLMAN_STAGING:
    //   return objectAssign({}, state, {detectAction: 'Solman Updated'});

    case types.STORE_SOLMAN_REPORT:
      return objectAssign({}, state, {
        solmanStagingData: formatData(
          action.data,
          [...state.solmanStagingData],
          action.data.index
        ),
        isLoading: false,
      });
    default:
      return state;
  }
}
