import { call, fork, put, all, takeLatest } from "redux-saga/effects";
import * as types from "../../constants/ActionTypes/userAccessTypes";
import { defaultAction } from "../../services/restService";
import * as actions from "../../actions/UserAccess/userAccessActions";
import { createLogger } from "redux-logger";
export function* fetchUserAccessInfo(payload) {
  try {
    const userAccessData = yield call(defaultAction, payload);
    if (userAccessData && userAccessData.roleAccess) {
      if (!userAccessData.roleAccess["COMMONLE"]) {
        userAccessData.roleAccess["COMMONLE"] = ["view"];
      }
    }
    yield put(actions.userAccessInfo(userAccessData));
  } catch (e) {
    createLogger(e);
  }
}
export function* fetchExceptionDetail(payload) {
  try {
    const exceptionData = yield call(defaultAction, payload);
    if (exceptionData) {
      sessionStorage.setItem("exceptionapi", JSON.stringify(exceptionData));
      window.location.href = window.location.origin + "/#"+ "/exceptions/search-exceptions/detail";
    }
    // console.log("exceptionData",exceptionData);
    yield put(actions.exceptionInfo(exceptionData));
  } catch (e) {
    createLogger(e);
  }
}
export function* processUserLogOut(payload) {
  try {
    const redirectURL = yield call(defaultAction, payload);

    yield put(actions.onUserLogOutSuccess(redirectURL));
  } catch (e) {
    createLogger(e);
  }
}
export function* getLoggedInUserAccessInfo() {
  yield takeLatest(types.GET_USER_ACCESS_INFO, fetchUserAccessInfo);
}
export function* getExceptionInfo() {
  yield takeLatest(types.GET_EXCEPTION_DETAIL, fetchExceptionDetail);
}
export function* onUserLogOut() {
  yield takeLatest(types.USER_LOGOUT, processUserLogOut);
}
export default function* root() {
  yield all([
      fork(getLoggedInUserAccessInfo),
      fork(getExceptionInfo),
      fork(onUserLogOut)]
      );
}
