import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import CleTable from "../../CommonComponents/CleTable/CleTable";
import CleFooter from "../../CommonComponents/CleFooter/CleFooter";
import COLUMNS from "../../../constants/ColumnConfig/Admin/SapReportResults";
import Loader from "../../CommonComponents/Loader";
import ConfirmationModalPopup from "../../CommonComponents/Modal/ConfirmationModalPopup";
import { checkIfExistsInArray } from "../../../utils/helpers";
import NewReportConfig from "./reportConfig";
import "./styles.scss";
import { CheckBox } from "../../CommonComponents/FormElement/Buttons/Buttons";
const pageStyle = {
    height: "calc(100vh - 210px)",
    width: "100%",
};
const WeekDay = ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"];
const SapReport = ({ ...props }) => {
    const [showUpsertModal, setShowUpsertModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [isModalEdit, setIsModalEdit] = useState(false);
    const onDeleteHandler = () => {
        props.onDelete([selectedRow[0]]);
        gridApi.deselectAll();
        setSelectedRow([]);
        setShowConfirmModal(false);
    };
    const onSaveDataHandler = (payload, close) => {
        if (isModalEdit) {
            props.updateDetails(payload);
            setIsModalEdit(false);
        } else if (!isModalEdit &&
            !checkIfExistsInArray(props.reponse, payload, ["applicationName", "serviceName"])) {
            props.saveDetails(payload);
        } else {
            toast.error("Report Configuration already exist!");
        }
        if (close) {
            setShowUpsertModal(false);
            gridApi.deselectAll();
            setSelectedRow([]);
        }
    };
    const getAdditionalFrameWorkComponents = (tableParams) => {
        return (
            <div className="d-flex cus_notallowed">
                {tableParams.value && Object.entries(tableParams.value).map(([key, value]) => {
                    return (
                        <div key={key} className="right10 cus_disabled sap-holddays-align">
                            <CheckBox
                                id={key}
                                value={key}
                                label={WeekDay[parseInt(key) - 1]}
                                checked={value}
                                onChange={() => null}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };
    const frameworkComponents = { permissionRenderer: getAdditionalFrameWorkComponents };
    return (<>
        {
            props.isLoading &&
            <Loader />
        }
        {
            !props.isLoading &&
            <>
                <div className={"admin-cle-table-wrapper"} style={pageStyle}>
                    <CleTable
                        rowSelection="single"
                        getRowNodeId={(data) => data.reportConfigId}
                        suppressRowClickSelection
                        columnDefs={COLUMNS}
                        immutableData
                        rowData={props.reponse ? props.reponse : []}
                        onRowClicked={(params) => { params.node.isSelected() ? params.node.setSelected(false) : params.node.setSelected(true); setSelectedRow(params.api.getSelectedRows()); }}
                        onRowSelected={(params) => { setSelectedRow(params.api.getSelectedRows()); }}
                        onGridReady={(params) => setGridApi(params.api)}
                        frameworkComponents={frameworkComponents}
                    />
                </div>
                <CleFooter
                    add onAdd={() => { setIsModalEdit(false); setShowUpsertModal(true); }}
                    modify onModify={() => { setShowUpsertModal(true); setIsModalEdit(true); }}
                    modifyDisabled={selectedRow.length === 0}
                    deleteDisabled={selectedRow.length === 0}
                    onDelete={() => setShowConfirmModal(true)}
                    deleteBtn />
            </>
        }
        {
            showConfirmModal &&
            <ConfirmationModalPopup
                title="Are you sure you want to delete this item?"
                isModalOpen={showConfirmModal}
                onCloseModal={() => setShowConfirmModal(false)}
                onOkModal={() => onDeleteHandler()} />
        }
        {
            showUpsertModal &&
            <NewReportConfig
                editFlag={isModalEdit}
                data={isModalEdit ? selectedRow : []}
                onCloseModal={() => { setShowUpsertModal(false); setIsModalEdit(false); }}
                isModalOpen={showUpsertModal}
                saveData={(payload, close) => onSaveDataHandler(payload, close)}
            />
        }
    </>);
};
SapReport.propTypes = {
    saveDetails: PropTypes.func,
    onDelete: PropTypes.func,
    reponse: PropTypes.any,
    isLoading: PropTypes.bool,
    updateDetails: PropTypes.func,
};
export default SapReport;