import * as  types from '../../constants/ActionTypes/Admin/logStatusTypes';
import objectAssign from 'object-assign';
import { formatData } from '../../utils/constantReducer';

const initialState = {
    logConfigData: [],
    isLoading: true,
    exceptionReportData: [],
    isExceptionLoading: true,
};

export default function logConfigReducer(state = initialState, action) {
    let statekey = '';
    let data = null;
    switch (action.type) {
        case types.STORE_LOG:
            statekey = action.data.page;
            data = formatData(action.data, [...state[statekey]], action.data.id);
            return objectAssign({}, state, {
                [statekey]: data,
                isOverlayLoading: false,
                isLoading:
                state.isLoading ? (statekey === "logConfigData" ? false : true) : false,
                isExceptionLoading: state.isExceptionLoading ?
                (statekey === "exceptionReportData" ? false : true) : false
            });
        default:
            return state;
    }
}