/* eslint-disable no-unused-vars */
import { notification } from "antd";

const { success, info, warning, error } = notification;

export const successNotification = (message, description) => {
    success({
        message: message,
        description: description,
    });
};

export const errorNotification = (message, description) => {
    let errorText = typeof description === 'string' ? description : JSON.stringify(description);
    if (description.message) {
        errorText = description.message;
    }
    error({
        message: message,
        description: errorText,
    });
};