import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import * as actions from '../../actions/Admin/sapReportAction';
import * as types from '../../constants/ActionTypes/Admin/sapReportTypes';
import { defaultAction } from '../../services/restService';
import { toast } from 'react-toastify';
import { logger } from 'redux-logger';
export function* getReport(payload) {
  try {
    const response = yield call(defaultAction, payload);
    const rep = {
      response,
      "action": "NEW",
      "id": "reportConfigId"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    logger(e)
  }
}
export function* fetchReport() {
  yield takeLatest(types.GET_REPORT, getReport)
}

export function* deleteReport(payload) {
  try {
    yield put(actions.reportProcess())
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been deleted successfully")
    const rep = {
      response,
      "action": "DELETE",
      "id": "reportConfigId"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    logger(e)
  }
}
export function* deleteReportData() {
  yield takeLatest(types.DELETE_REPORT, deleteReport)
}

export function* saveReportData(payload) {
  try {
    yield put(actions.reportProcess())
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been saved successfully")
    const rep = {
      response,
      "action": "ADD",
      "id": "reportConfigId"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    logger(e)
  }
}
export function* addReportData() {
  yield takeLatest(types.ADD_REPORT, saveReportData)
}

export function* ModifyReportData(payload) {
  try {
    yield put(actions.reportProcess())
    const response = yield call(defaultAction, payload);
    toast.success("Report Config has been updated successfully")
    const rep = {
      response,
      "action": "UPDATE",
      "id": "reportConfigId"
    }
    yield put(actions.reportDataStore(rep));
  } catch (e) {
    logger(e)
  }
}
export function* modifyReport() {
  yield takeLatest(types.UPDATE_REPORT, ModifyReportData)
}

export default function* root() {
  yield all([fork(fetchReport), fork(deleteReportData),
  fork(addReportData), fork(modifyReport)])
}
