import React from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import './style.scss';
const icons = {
    sortUnSort: '<i class="fas fa-sort" />',
    sortAscending: '<i class="fas fa-sort-alpha-up"></i>',
    sortDescending: '<i class="fas fa-sort-alpha-down"></i>',
    // filter: '<i class="fas fa-search"></i>',
    menu: '<i class="fas fa-filter"></i>',
};
const CleTable = ({ ...props }) => {
    const defaultColDef = {
        unSortIcon: props.unSortIcon || false,
        sortable: true,
        filter: true,
        resizable: true,
        // immutableData: true,
    };
    return (
        <div className="cle-ag-grid-wrapper ag-theme-material" style={{ height: "100%" }}>
            <AgGridReact
                {...props}
                icons={icons}
                defaultColDef={defaultColDef}
                rowHeight={35}
                headerHeight={45}
                onGridReady={(grid) => {
                    if (props.onGridReady) {
                        props.onGridReady(grid);
                    }
                    if (props.isColSizeAutoFit && props.columnDefs.length <= 8)
                        grid.api.sizeColumnsToFit();
                }}
                suppressDragLeaveHidesColumns
                suppressPropertyNamesCheck
                suppressCellSelection
            />
        </div>
    );
}

CleTable.defaultProps = {
    isColSizeAutoFit: true,
}
CleTable.propTypes = {
    unSortIcon: PropTypes.bool,
    onGridReady: PropTypes.func,
    columnDefs: PropTypes.array,
    isColSizeAutoFit: PropTypes.bool,
}
export default CleTable;