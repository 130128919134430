import * as types from '../../constants/ActionTypes/reportTypes';
import { constructServiceUrl } from '../../services/constructUrl';

export function getLogsReportforLastHour(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.LAST_HOUR_LOGS_REPORT;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.LAST_HOUR_LOGS_REPORT,
    request: requestParams
  };
}
export function updateLogReportforLastHour(logReport) {
  window.iosLoader = false;
  return {
    type: types.LAST_HOUR_LOGS_REPORT_RESULT,
    logReport
  };
}
export function getExceptionReportforLastHour(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.LAST_HOUR_EXCEPTION_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.LAST_HOUR_EXCEPTION_REPORT,
    request: requestParams
  };
}
export function updateExceptionReportforLastHour(exceptionReport) {
  window.iosLoader = false;
  return {
    type: types.LAST_HOUR_EXCEPTION_REPORT_RESULT,
    exceptionReport
  };
}
export function getLogReportforPastHour(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.PAST_HOUR_LOGS_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.PAST_HOUR_LOGS_REPORT,
    request: requestParams
  };
}
export function updateLogReportforPastHour(pastHrLogReport) {
  window.iosLoader = false;
  return {
    type: types.PAST_HOUR_LOGS_REPORT_RESULT,
    pastHrLogReport
  };
}
export function getExceptionReportforPastHour(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.PAST_HOUR_EXCEPTION_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.PAST_HOUR_EXCEPTION_REPORT,
    request: requestParams
  };
}
export function updateExceptionReportforPastHour(pastHrExceptionRpt) {
  window.iosLoader = false;
  return {
    type: types.PAST_HOUR_EXCEPTION_REPORT_RESULT,
    pastHrExceptionRpt
  };
}
export function getLogReportforAdmin() {
  let serviceParams = {};
  serviceParams.apiName = types.ADMIN_LOG_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.ADMIN_LOG_REPORT,
    request: requestParams
  };
}
export function updateLogReportForAdmin(logReport) {
  window.iosLoader = false;
  return {
    type: types.ADMIN_LOG_REPORT_RESULT,
    logReport
  };
}
export function getExceptionReportforAdmin() {
  let serviceParams = {};
  serviceParams.apiName = types.ADMIN_EXCEPTION_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.ADMIN_EXCEPTION_REPORT,
    request: requestParams
  };
}
export function updateExceptionReportForAdmin(exceptionReport) {
  window.iosLoader = false;
  return {
    type: types.ADMIN_EXCEPTION_REPORT_RESULT,
    exceptionReport
  };
}
