import AxiosUrl from "../services/axiosInstanceCustom";

export const getLogExceptionCount = async (screenType, payload) => {
    let totalRecordCount = [];
    let url = screenType === "log" ? "/log/count/" : "/exception/count/";
    let axiosUrl = screenType === "log" ? AxiosUrl.logDatainstance : AxiosUrl.exceptionDatainstance;

    let payload_promise_list = [];
    const create_count_api_promise = (request) => {
        return new Promise((resolve, reject) => {
            axiosUrl.post(url, request)
                .then((resp) => {
                    return resolve(resp);
                })
                .catch((error) => {
                    console.log(error);
                    return reject(error);
                });
        });
    };
    payload.forEach((val) => {
        payload_promise_list.push(create_count_api_promise(val));
    });
    await Promise.allSettled(payload_promise_list)
      .then((val) => {
        val.forEach((promise_resp) => {
            totalRecordCount.push(promise_resp.value?.data);
        })
      }).catch((err) => {
        console.log(err);
    });

    return totalRecordCount;
}
