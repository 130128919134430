import React from "react";
import { Select } from 'antd';
import PropTypes from "prop-types";
import "./style.scss";

const SelectDropDown = (props) => {
    return (
        <Select
            {...props}
            showSearch="true"
            style={{ width: '100%' }}
            className={"select-dropdown " + (props.className ? props.className : "")}
        />
    );
}

SelectDropDown.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]),
    disabled: PropTypes.bool
};

export default SelectDropDown;