import {
  openLoader,
  closeLoader,
} from "../components/OverlayLoader/OverlayLoader";
import { openErrorModal } from "../components/ErrorHandle/ErrorHandle";
import { getTokenToReqHeader } from "../utils/helpers";
import $ from "jquery";

export function defaultAction(data) {
  const headers = {
    Accepts: {
      json: "application/json",
      text: "text/plain",
    },
    Authorization: getTokenToReqHeader(),
    "Content-Type": "application/json",
  };
  if (data.request.methodName === "GET") {
    if (data.request.payload) {
      data.request.url = data.request.url + data.request.payload;
    }
  }
  const options = {
    method: data.request.methodName,
    body:
      data.request.methodName === "GET"
        ? undefined
        : JSON.stringify(data.request.payload),
    headers,
    cache: "no-cache",
    redirect: "manual",
    credentials: "same-origin",
  };
  // $("#appOverlayLoader").
  if (!data.request.isBgProcess) {
    if (window.iosLoader) openLoader();
  }
  // openErrorModal();
  // console.log(configureStore().getState())
  const promise = new Promise((resolve, reject) => {
    fetch(data.request.url, options)
      .then((response) => {
        // window.iosLoader = false;
        let responseObj = {};
        //  In case of resp code > 303, response.json() throws error. So use .text() instead.
        const contentType = response.headers.get("content-type");
        const isJson =
          response.status < 303 &&
          contentType &&
          (contentType.indexOf("application/json") !== -1 ||
            contentType.indexOf("application/atom+xml") !== -1);
        responseObj = {
          ok: response.ok,
          status: response.status,
          body: isJson ? response.json() : response.text(),
        };
        responseObj.body.then((val) => {
          if (!data.request.isBgProcess) {
            if (!window.isSearchScreen) {
              // setTimeout(() => {
              closeLoader();
              // }, window.timeLimit)
            }
          }
          return val;
        });

        if (!isJson && response.status === 200) {
          responseObj.body.then((val) => {
            if (val && val.includes("authorization")) {
              closeLoader();
              $("#force-logout-modal").modal("show");
              $("#force-logout-modal").modal({ backdrop: "static" });
              $("#modal-logout-content").html(val);
            }
          });
        }
        if (response.status < 400) {
          if (response.status === 0) {
            //history.push('/dashboard');
            if (window.location.hostname !== "localhost") {
              if (!window.location.origin) {
                window.location.origin =
                  window.location.protocol +
                  "//" +
                  window.location.hostname +
                  (window.location.port ? ":" + window.location.port : "");
              }
              window.location.href = window.location.origin;
            }
            // reject(new Error('Connectivity Error Please contact CLE team'));
          } else {
            resolve(responseObj.body);
          }
        } else {
          if (response.status === 302) {
            // if (window.location.hostname !== 'localhost') {
            //   if (!window.location.origin) {
            //     window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            //   }
            //   window.location.href = window.location.origin;
            // }
            // else {

            // toast.dismiss()
            // toast.error(`Unable to performe the action status 302`);
            responseObj.body.then((val) => {
              window.errorMessage = JSON.stringify(val);
              if (!data.request.isNoError) {
                openErrorModal();
                closeLoader();
              }
            });
            reject(new Error("Network error"));
            // }
          } else if (response.status === 401) {
            openErrorModal(true);
            closeLoader();
            reject(new Error("Network error"));
          } else {
            // toast.dismiss()
            // responseObj.body.then((val) => toast.error(`Oops! Something went wrong ${val}`))
            responseObj.body.then((val) => {
              window.errorMessage = JSON.stringify(val);
              if (!data.request.isNoError) {
                openErrorModal();
                closeLoader();
              }
            });

            reject(new Error("Network error"));
          }
        }
      })
      .catch((error) => {
        if (!data.request.isBgProcess) {
          window.iosLoader = false;
          closeLoader();
        }
        if (error !== "Network error") {
          window.errorMessage = error;
        }
        if (error !== "Network error" && !data.request.isNoError) {
          openErrorModal();
          closeLoader();
        }
        // toast.dismiss()
        // toast.error(`Oops! Something went wrong ${error}`);
        reject(error);
      });
  });
  // try{
  promise.then((response) => response).catch((e) => e);
  // }
  // catch (e) {
  // throw new Error("Test");
  // };
  return promise;
}