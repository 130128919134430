import React from "react";
import PropTypes from "prop-types";
import { Row, Button } from "antd";
import {
    CloseOutlined,
    SaveFilled
} from '@ant-design/icons';
import "./AdminModalFooter.scss";

const AdminModalFooter = ({
    save = false,
    update = false,
    saveAndClose = false,
    close = false,
    onSave,
    onSaveAndClose,
    onClose,
    onUpdate,
    // loading = false,
}) => {
    return (
        <Row justify={"end"} className="modal-footer-row">
            {save &&
                (<Button
                    type="primary"
                    className="save-btn mr5"
                    icon={<SaveFilled />}
                    onClick={onSave}
                >
                    Save
                </Button>)}
            {saveAndClose &&
                (<Button
                    type="primary"
                    className="save-close-btn mr5"
                    icon={<SaveFilled />}
                    onClick={onSaveAndClose}
                >
                    Save & Close
                </Button>)}
            {update &&
                (<Button
                    type="primary"
                    className="save-btn mr5"
                    icon={<SaveFilled />}
                    onClick={onUpdate}
                >
                    Update
                </Button>)}
            {close &&
                (<Button
                    type="primary"
                    className="close-btn mr5"
                    icon={<CloseOutlined />}
                    onClick={onClose}
                    danger
                >
                    Close
                </Button>)}
        </Row>)
}

AdminModalFooter.propTypes = {
    save: PropTypes.bool,
    saveAndClose: PropTypes.bool,
    update: PropTypes.bool,
    close: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onSaveAndClose: PropTypes.func,
    onUpdate: PropTypes.func,
};
export default AdminModalFooter;