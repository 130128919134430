/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, Radio } from "antd";
import { toast } from "react-toastify";
import {
  getRolesApplicationList,
  getUsersList,
  addUserFunction,
  editUserFunction,
} from "../../../actions/Admin/userActions";
import "./UpsertUser.css";
import UserApplicationAccess from "./UserApplicationAccess";
import UserFooter from '../UserFooter';
import { checkIfExistsInArray } from "../../../utils/helpers";
import SelectGroupForCreateUser from "./SelectGroupForCreateUser";
const formItemsLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 15 },
};
const UpsertUser = ({ ...props }) => {
  const dispatch = useDispatch();

  const globalUserState = useSelector((state) => state.users.userResults);
  const globalGroupState = useSelector(
    (state) => state.users.roleApplicationResults
  );

  const [form] = Form.useForm();
  const [userApplicationList, setUserApplicationList] = useState([]);
  const [showSelectGroupModal, setShowSelectGroupModal] = useState(false);
  const [selectGroupModalData, setSelectGroupModalData] = useState([]);
  const [userSelectedRoleGroup, setUserSelectedRoleGroup] = useState([]);
  const [userPayload, setUserPayload] = useState(null);
  const [closeCreateModal, setCloseCreateModal] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);
  const [errorList, setErrorList] = useState(null);

  useEffect(() => {
    if (!globalUserState.data || globalUserState.data.length === 0) {
      dispatch(getUsersList());
    }

    if (
      !globalGroupState.data ||
      globalGroupState.data.length === 0
    ) {
      dispatch(getRolesApplicationList());
    }

    if (props.editFlag) {
      let formData = {
        ...props.editData,
        roleAccess: createRoleAccessData(props.editData.roleAccess),
      };
      form.setFieldsValue(formData);
      setInitialFormData(formData);
    }
  }, []);

  const createRoleAccessData = (roleAccess) => {
    return Object.keys(roleAccess).map((applicationName) => ({
      applicationName: applicationName,
      accessList: roleAccess[applicationName],
    }));
  };

  const updateUserAccessHandler = (control, selectedValue, selectedIndex) => {
    setUserApplicationList([...userApplicationList, selectedValue]);

    let roleAccessList = form.getFieldValue("roleAccess");
    roleAccessList[selectedIndex] = { [control]: selectedValue };
    form.setFieldValue("roleAccess", roleAccessList);
  };
  const generateRolesDataForPayload = (roleAccessList) => {
    let obj = {};
    roleAccessList.forEach((val) => {
      obj[val.applicationName] = val.accessList;
    });
    return obj;
  };

  const onSaveUserHandler = (close) => {
    form
      .validateFields()
      .then(async (values) => {
        if (props.editFlag) {
          saveUserData(values, close);
        } else if (
          !props.editFlag &&
          !checkIfExistsInArray(globalUserState.data, values, "userID") //checking if userID already Exists
        ) {
          saveUserData(values, close);
        } else {
          toast.error("User already exist!");
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorList(err);
      });
  };

  const saveUserData = (formData, closeModal) => {
    let payload = {
      ...formData,
      roleAccess:
        formData.roleAccess && formData.roleAccess.length > 0
          ? generateRolesDataForPayload(formData.roleAccess)
          : {},
    };
    let applicationAccessData = [];
    if (!props.editFlag) {
      Object.keys(payload.roleAccess).forEach((appNm) => {
        payload.roleAccess[appNm].forEach((access) => {
          let obj = {};
          obj.applicationName = appNm;
          obj.accessName = access;
          applicationAccessData.push(obj);
        });
      });
    } else {
      //if any existing application access has changed or new application added
      //need to optimize this code
      const newApplicationAccessObj = {};
      if (payload.roleAccess) {
        Object.keys(payload.roleAccess).forEach((role) => {
          if (
            props.editData &&
            props.editData.roleAccess &&
            props.editData.roleAccess[role]
          ) {
            payload.roleAccess[role].forEach((value) => {
              if (!props.editData.roleAccess[role].includes(value)) {
                if (!newApplicationAccessObj[role]) {
                  newApplicationAccessObj[role] = [value];
                } else {
                  newApplicationAccessObj[role].push(value);
                }
              }
            });
          } else {
            newApplicationAccessObj[role] = payload.roleAccess[role];
          }
        });
      }
      if (Object.keys(newApplicationAccessObj).length > 0) {
        Object.keys(newApplicationAccessObj).forEach((appNm) => {
          newApplicationAccessObj[appNm].forEach((access) => {
            let obj = {};
            obj.applicationName = appNm;
            obj.accessName = access;
            applicationAccessData.push(obj);
          });
        });
      }
    }
    setUserPayload(payload);
    //console.log(applicationAccessData, "----------selected Group Data");
    //finding available groups for each application
    let applicationGroupList = [];
    applicationAccessData.forEach((val) => {
      let finalObj = {};
      let filterData = globalGroupState.data.filter(
        (appData) =>
          appData.applicationName === val.applicationName &&
          val.accessName === appData.accessName
      );
      
      finalObj.applicationName = val.applicationName;
      finalObj.accessName = val.accessName;
      finalObj.groupName = filterData;
      applicationGroupList.push(finalObj);
    });

    let selectGroupDataFromUser = [];
    let selectedRoleGroup = [];
    let isSelectGroupModalOpen = false;
    //selecting group for each application
    applicationGroupList.forEach((applicationAccessElement) => {
      if (applicationAccessElement.groupName.length > 1) {
        selectGroupDataFromUser.push(applicationAccessElement);
        isSelectGroupModalOpen = true;
      }
      if (applicationAccessElement.groupName.length === 1) {
        selectedRoleGroup.push(applicationAccessElement.groupName[0]);
      }
      if (applicationAccessElement.groupName.length === 0) {
        let obj = {};
        obj.accessName = applicationAccessElement.accessName;
        obj.applicationName = applicationAccessElement.applicationName;
        obj.groupName =
          applicationAccessElement.applicationName +
          "_" +
          applicationAccessElement.accessName;
        selectedRoleGroup.push(obj);
      }
    });
    setUserSelectedRoleGroup(selectedRoleGroup);
    setSelectGroupModalData(selectGroupDataFromUser);
    if (isSelectGroupModalOpen) {
      setShowSelectGroupModal(true);
      setCloseCreateModal(closeModal);
    } else {
      //direct save no change in application access
      if (props.editFlag) {
        dispatch(
          editUserFunction({
            ...payload,
            groupAccess: selectedRoleGroup,
            // applicationAccess: props.editData["roleAccess"],
          })
        );
      } else {
        dispatch(
          addUserFunction({
            ...payload,
            groupAccess: selectedRoleGroup,
          })
        );
      }
      if (closeModal) {
        props.onClose();
      }
    }
  };

  const onSaveFromModalHandler = (selectedApplicationGroupList) => {
    setShowSelectGroupModal(false);
    let tempApplicationGroupList = userSelectedRoleGroup;
    selectedApplicationGroupList.forEach((eleInArray) => {
      tempApplicationGroupList.push(eleInArray["selectedGroup"]);
    });
    if (props.editFlag) {
      dispatch(
        editUserFunction({
          ...userPayload,
          groupAccess: tempApplicationGroupList,
          // applicationAccess: props.editData["roleAccess"],
        })
      );
    } else {
      dispatch(
        addUserFunction({
          ...userPayload,
          groupAccess: tempApplicationGroupList,
        })
      );
    }
    if (closeCreateModal) {
      props.onClose();
    }
  };

  return (
    <>
      <>
        <div className="create-user-container">
          <div className="create-user-header">
            {props.editFlag ? "Modify" : "Create"} User
          </div>
          <Form
            id="create-user-form"
            form={form}
            {...formItemsLayout}
            labelAlign="left"
            colon
            initialValues={
              props.editFlag
                ? initialFormData
                : {
                    adminAccess: "N",
                    bulkReply: "N",
                  }
            }>
            <Row justify="space-between" gutter={[24, 8]}>
              <Col span={12}>
                <Form.Item
                  label="Username"
                  name="userID"
                  rules={[
                    { required: true, message: "Username is Mandatory!" },
                  ]}>
                  <Input disabled={props.editFlag}/>
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    { required: true, message: "First Name is Mandatory!" },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Admin Access"
                  name="adminAccess"
                  rules={[
                    { required: true, message: "Admin Access is Mandatory!" },
                  ]}>
                  <Radio.Group>
                    <Radio value="Y">Enable</Radio>
                    <Radio value="N">Disable</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Email is Mandatory!" },
                    {
                      type: "email",
                      message: "Email is not valid!",
                    },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    { required: true, message: "Last Name is Mandatory!" },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Bulk replay Access"
                  name="bulkReply"
                  rules={[
                    {
                      required: true,
                      message: "Bulk replay Access is Mandatory!",
                    },
                  ]}>
                  <Radio.Group>
                    <Radio value="Y">Enable</Radio>
                    <Radio value="N">Disable</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-around">
              <Col span={24}>
                <UserApplicationAccess
                  formRef={form}
                  userApplicationList={userApplicationList}
                  setUserApplicationList={setUserApplicationList}
                  updateUserAccessHandler={updateUserAccessHandler}
                />
              </Col>
            </Row>
          </Form>
        </div>
        <UserFooter
          update={props.editFlag}
          save={!props.editFlag}
          onUpdateSave={() => onSaveUserHandler(false)}
          handleSave={() => onSaveUserHandler(false)}
          saveClose
          handleUserSaveClose={() => onSaveUserHandler(true)}
          reset
          handleReset={() => form.resetFields()}
          showCancel
          onCancel={props.onClose}
        />
      </>
      {showSelectGroupModal && (
        <SelectGroupForCreateUser
          isModalOpen={showSelectGroupModal}
          applicationGroupOptions={selectGroupModalData}
          onCloseModal={() => setShowSelectGroupModal(false)}
          onSaveData={(val) => onSaveFromModalHandler(val)}
        />
      )}
    </>
  );
};
UpsertUser.propTypes = {
  editFlag: PropTypes.bool,
  onClose: PropTypes.func,
  editData: PropTypes.object,
};
export default UpsertUser;
