import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import ConfigureExceptions from "../../../components/Admin/ConfigureExceptions";
import * as actions from "../../../actions/Admin/exceptionConfigAction";

const ConfigureExceptionResults = () => {
  const dispatch = useDispatch();
  const globalExceptionConfigState = useSelector((state) => state.configExcep);
  useEffect(() => {
    if(globalExceptionConfigState.exceptionConfigData?.length===0){
      dispatch(actions.getExceptionConfigDetails());
    }
  }, [])
  const getExpConfigList = () => {
    dispatch(actions.getExceptionConfigDetails());
  };
  const onConfExceptionDelete = (payload) => {
    dispatch(actions.deleteConfException(payload));
    dispatch(actions.getExceptionConfigDetails());
  };
  const onSaveDetails = (payload) => {
    if (payload["exceptionConfigId"]) {
      dispatch(actions.editConfigExcepFunction(payload));
    }
    else {
      if (!checkIfDuplicateEntry(payload)) {
        dispatch(actions.addConfigExcepFunction(payload));
      } else {
        toast.error("Configuration already exist!");
      }
    }
  }
  const checkIfDuplicateEntry = (excpData) => {
    let isDuplicate = false;
    const exceptionConfigData = globalExceptionConfigState.exceptionConfigData;
    for (let i = 0; i < exceptionConfigData.length; i++) {
      if (
        exceptionConfigData[i].applicationName.toUpperCase() ===
        excpData.applicationName.toUpperCase() &&
        exceptionConfigData[i].exceptionCategoryName.toUpperCase() ===
        excpData.exceptionCategoryName.toUpperCase() &&
        exceptionConfigData[i].exceptionSeverityName.toString().toUpperCase() ===
        excpData.exceptionSeverityName.toUpperCase() &&
        exceptionConfigData[i].exceptionTypeName.toUpperCase() ===
        excpData.exceptionTypeName.toUpperCase()
      ) {
        isDuplicate = true;
        break;
      }
    }
    return isDuplicate;
  };
  return (
    <div>
      <ConfigureExceptions
        getExpConfigList={getExpConfigList}
        isExceptionConfigLoading={globalExceptionConfigState.isExceptionConfigLoading}
        exceptionConfigData={globalExceptionConfigState.exceptionConfigData}
        onConfExceptionDelete={onConfExceptionDelete}
        onSaveDetails={(payload) => onSaveDetails(payload)}
      />
    </div>
  );
}
export default ConfigureExceptionResults;
