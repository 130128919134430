import React from "react";

// Hook
const useKeyPress = (targetKey, cb) => {
  /* eslint-disable no-unused-vars*/
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = React.useState(false);

  // If pressed key is our target key then set to true
  const keypress = ({ key }) => {
    if (key === targetKey) {
      cb();
    }
  };

  // If released key is our target key then set to false

  // Add event listeners
  React.useEffect(() => {
    window.addEventListener("keyup", keypress);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keyup", keypress);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
};

export default useKeyPress;
