import axios from "axios";
import { openErrorModal } from "../components/ErrorHandle/ErrorHandle";
import $ from "jquery";
import URL from "./setEndpointURL";
let url = URL.API_URL;

export const apiUrl = () => {
  return url;
};
// let url = "/api/v1";
// if (/localhost/.test(window.location.origin)) {
//   url = "https://cle-web-preprod.apps.pcf.dev.azure.intra.pepsico.com/api/v1";
// }
// if (
//   window.location.hostname ===
//   "cle-react-qa.apps.pcf.dev.azure.intra.pepsico.com"
// ) {
//   url = "https://cle-web-preprod.apps.pcf.dev.azure.intra.pepsico.com/api/v1";
// }
// export const apiUrl = () => {
//   return url;
// };
const instance = axios.create({
  baseURL: url,
  timeout: 120000,
  headers: { "Content-Type": "application/json" },
});

export const outApiCheck = (response) => {
  const contentType = response.headers["content-type"];
  const isJson = contentType.indexOf("application/json") > -1;
  if (!isJson && response.status === 200) {
      // Authorization check
      if (response.data && response.data.includes("authorization")) {
          $("#force-logout-modal").modal("show");
          $("#force-logout-modal").modal({ backdrop: "static" });
          $("#modal-logout-content").html(response.data);
          return "block";
      }
  }
  if (response.status === 0) {
      if (window.location.hostname !== "localhost") {
          window.location.reload();
      }
      return false;
  }
  if (response.status === 200) {
      return true;
  }
  if (response.status === 302) {
      return false;
  }
  if (response.status === 400) {
      return false;
  }
  if (response.status === 401) {
      openErrorModal(true);
      return "block";
  }
  return false;
};

export default instance;
