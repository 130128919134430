import React, { useEffect } from "react";
import { Form, Modal, Input } from "antd";
import PropTypes from 'prop-types';
import { formItemsLayout, formItemsDefaultStyle } from "../../../../constants/AdminModalForm/FormStyleConfig";
import AdminModalFooter from "../../../CommonComponents/Modal/AdminModalFooter";
import SelectDropDown from '../../../CommonComponents/SelectDropDown';

const NewType = ({ ...props }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (props.editFlag) {
            form.setFieldsValue({ ...props.data[0] });
        }
        form.setFieldsValue({ 
            ...form.getFieldsValue(),
            "applicationName":props?.applicationName?.value,
         });
    }, []);
    const onSaveHandler = () => {
        form.validateFields()
            .then(async values => {
                if (props.editFlag) {
                    values = {
                        "typeID": props.data[0]["typeID"],
                        ...values
                    }
                }
                props.saveData(values, "type");
            })
    }
    return (<Modal
            open={props.isModalOpen}
            title={(props.editFlag ? "Edit" : "New") + " Type"}
            onCancel={props.onCloseModal}
            footer={
                (props.editFlag ?
                    <AdminModalFooter
                        close update
                        onUpdate={() => onSaveHandler()}
                        onClose={props.onCloseModal}
                    />
                    :
                    <AdminModalFooter
                        save close
                        onSave={() => onSaveHandler()}
                        onClose={props.onCloseModal}
                    />)
            }
            style={{ minWidth: 800 }}
            className="admin-modal-wrapper"
        >
            <Form
                id="list-type-config"
                form={form}
                {...formItemsLayout}
                labelAlign="left"
                colon
            >
                <Form.Item label="Application Name" name="applicationName"
                    rules={[{ required: true, message: 'Application is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <SelectDropDown
                        options={props.applicationNamesOptions}
                        disabled={props.editFlag}
                    />
                </Form.Item>
                <Form.Item label="Type Name" name="exceptionTypeName"
                    rules={[{ required: true, message: 'Type Name is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
                <Form.Item label="Type Description" name="exceptionTypeDescription"
                    rules={[{ required: true, message: 'Type Description is Mandatory!' }]}
                    style={formItemsDefaultStyle}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>)
}
NewType.propTypes = {
    onCloseModal: PropTypes.func,
    data: PropTypes.any,
    saveData: PropTypes.func,
    applicationName: PropTypes.any,
    editFlag: PropTypes.any,
    applicationNamesOptions: PropTypes.any,
    isModalOpen: PropTypes.bool,
};
export default NewType;