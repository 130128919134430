export const GET_SOLMAN_STAGING = 'GET_SOLMAN_STAGING';
export const SOLMAN_STAGING_DETAILS = 'SOLMAN_STAGING_DETAILS';
export const DELETE_SOLMAN_STAGING='DELETE_SOLMAN_STAGING';
export const ADD_SOLMAN_STAGING='ADD_SOLMAN_STAGING';
export const UPDATE_SOLMAN_STAGING="UPDATE_SOLMAN_STAGING";

export const STORE_SOLMAN_REPORT="STORE_SOLMAN_REPORT";
export const PROGRESS_SOLMAN_REPORT="PROGRESS_SOLMAN_REPORT";
   





