import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReplayConfig from "../../../components/Admin/ConfigureExceptions/ReplayConfig";
import * as actions from "../../../actions/Admin/exceptionConfigAction";
import Loader from "../../../components/Loader/Loader";
import { checkIfExistsInArray, findIfContainsInArray, findIfContainsInArrayUniversalSearch } from '../../../utils/helpers';

const ReplayConfigResults = ({ ...props }) => {
  const dispatch = useDispatch();
  const globalReplayConfigState = useSelector((state) => state.configExcep);

  useEffect(() => {
    if(globalReplayConfigState.replayConfigData?.length===0){
      dispatch(actions.getReplayConfigDetails());
    }
  }, []);

  const onConfReplayDelete = (id) => {
    dispatch(actions.deleteConfReplay([id]));
    dispatch(actions.getReplayConfigDetails());
  };
  const onSaveDetails = (payload) => {
    if (payload["replayID"]) {
      dispatch(actions.editReplayConfigFunction(payload));
    }
    else {
      if (!checkIfExistsInArray(globalReplayConfigState.replayConfigData, payload, ["applicationName", "replayMode"])) {
        dispatch(actions.addReplayConfigFunction(payload));
      }
      else {
        toast.error("Configuration already exist!")
      }
    }
  }
  const filterData = (configData) => {
    let searchFilter = props.filterData?props.filterData.trim().split(","):[];
    let filteredRes = configData;

    if (props.appName && props.appName.length > 0) {
      filteredRes = findIfContainsInArray(configData, props.appName.map(x => ({ ...x, "applicationName": x.value })), ['applicationName']);
    }
    if (searchFilter && searchFilter.length > 0) {
      filteredRes = findIfContainsInArrayUniversalSearch(filteredRes, searchFilter, ['applicationName','applicationDomain','replayHost','replayMode','username','description']);
    }
    return filteredRes;
  }
  return (
    <div>
      {globalReplayConfigState.isReplayConfigLoader ? (
        <Loader />
      ) : (
        <ReplayConfig
          replayConfigData={filterData(globalReplayConfigState.replayConfigData)}
          onConfReplayDelete={onConfReplayDelete}
          onSaveDetails={onSaveDetails}
        />
      )}
    </div>
  );
}
ReplayConfigResults.propTypes = {
  filterData: PropTypes.string,
  appName: PropTypes.any,
};
export default ReplayConfigResults;