const HEADER_COLUMNS = [{
    field: 'JMSDestination',
    headerName: 'JMS Destination'
  },{
    field: 'JMSReplyTo',
    headerName: 'JMSReplyTo'
  }, {
    field: 'JMSDeliveryMode',
    headerName: 'JMSDeliveryMode'
  }, {
    field: 'JMSMessageID',
    headerName: 'JMSMessageId'
  }, {
    field: 'JMSTimestamp',
    headerName: 'JMSTimestamp'
  }, {
    field: 'JMSExpiration',
    headerName: 'JMSExpiration'
  }, {
    field: 'JMSRedelivered',
    headerName: 'JMSRedelivered'
  }, {
    field: 'JMSPriority',
    headerName: 'JMSPriority'
  }, {
    field: 'JMSCorrelationID',
    headerName: 'JMSCorrelationID'
  }, {
    field: 'JMSTypeo',
    headerName: 'JMSType'
  }];
const JMS_COLUMNS = [{
    field: 'JMSXUserID',
    headerName: 'JMSXUserID'
  },{
    field: 'JMSXAppID',
    headerName: 'JMSXAppID'
  }, {
    field: 'JMSXProducerTIXID',
    headerName: 'JMSXProducerTIXID'
  }, {
    field: 'JMSXConsumerTXID',
    headerName: 'JMSXConsumerTXID'
  }, {
    field: 'JMSXRcvTimestamp',
    headerName: 'JMSXRcvTimestamp'
  }, {
    field: 'JMSXDeliveryCount',
    headerName: 'JMSXDeliveryCount'
  }, {
    field: 'JMSXGroupID',
    headerName: 'JMSXGroupID'
  }, {
    field: 'JMSXGroupSeq',
    headerName: 'JMSXGroupSeq'
  }];
  module.exports = {HEADER_COLUMNS, JMS_COLUMNS}
