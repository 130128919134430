import * as types from '../../constants/ActionTypes/Admin/tableConfigTypes';
import objectAssign from 'object-assign';
import { formatData } from '../../utils/constantReducer';

const initialState = {
  indexGetData: [],
  isLoading: true,
};


export default function tableConfigReducer(state = initialState, action) {

  switch (action.type) {
    case types.STORE_DATA:
      return objectAssign({}, state, {
        indexGetData: formatData(action.data, [...state.indexGetData], action.data.id),
        isLoading: false
      });
    default:
      return state;
  }
}