import * as types from '../../constants/ActionTypes/Admin/applicationConfigTypes';
import { constructServiceUrl } from '../../services/constructUrl';

export function getApplicationDetails() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_APPLICATION_DETAILS;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_APPLICATION_DETAILS,
    request: requestParams
  };
}
export function applicatinDataStore(data) {
  return {
    type: types.STORE_APPLICATION_REPORT,
    data
  };
}
export function applicationProgress() {
  return {
    type: types.PROGRESS_APPLICATION_REPORT
  }
}
export function updateApplicationDetails(applicationData) {
  return {
    type: types.APPLICATION_DETAILS,
    applicationData
  };
}
export function deleteApplication(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_APPLICATION;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = true;
  return {
    type: types.DELETE_APPLICATION,
    request: requestParams
  };
}
export function addApplicationDetails(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_NEW_APPLICATION;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = true;
  return {
    type: types.ADD_NEW_APPLICATION,
    request: requestParams
  };
}

export function modifyApplicationconfig(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_APPLICATIONCONFIG;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = true;
  return {
    type: types.UPDATE_APPLICATIONCONFIG,
    request: requestParams
  };
}

export function getLogAndExceptionTable() {
  let serviceParams = {}
  serviceParams.apiName = types.LOG_EXCEPTION_TABLE;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.LOG_EXCEPTION_TABLE,
    request: requestParams
  };
}
export function logAndExceptionTable(tableData) {
  return {
    type: types.LOG_EXCEPTION_TABLE_RESULTS,
    tableData
  };
}

export function getApplicationSectorList() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_APPLICATION_SECTOR_LIST;
  let requestParams = constructServiceUrl(serviceParams)
  window.iosLoader = false;
  return {
    type: types.GET_APPLICATION_SECTOR_LIST,
    request: requestParams
  };
}

export function setApplicationSectorList(data) {
  return {
    type: types.SET_APPLICATION_SECTOR_LIST,
    payload: data
  };
}
