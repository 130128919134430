import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "@ant-design/icons";
import { NavLink, withRouter } from "react-router-dom";
import { Menu, Collapse } from "antd";
import { connect } from "react-redux";
import _ from 'lodash';
const { Panel } = Collapse;

class SiderMenu extends Component {
  state = {
    selectedKeys: { id: "daily" },
    accordionId: "dashBoard1",
  };

  componentDidMount() {
    const path = this.props.location.pathname;
    if (path !== "/sample") {
      this.setMenuKey(path);
    }

    this.props.history.listen((path) => {
      if (path.pathname !== "/sample") {
        this.setMenuKey(path.pathname);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props))
      this.setMenuKey(this.props.location.pathname);
  }

  setMenuKey = (path) => {
    let id = { id: "daily" };
    let newId = null;
    let accordionId = "dashBoard1";
    if (path !== "/") {
      this.props.cleMenu.forEach(({ subOptions }) => {
        if (newId === null || !newId) {
          id = subOptions && subOptions.find((route) => {
            return route !== "/" && route.inRoute.includes(path);
          });
          if (id) accordionId = id.state;
          newId = id;
        }
      });
    } else {
      newId = id;
    }
    this.setState({ selectedKeys: id, accordionId });
  };
  handleCollapse = (key) => {
    setTimeout(() => {
      var elem = document.getElementById("cle-menu");
      elem.scrollTop = 120;
    }, 500);
    this.setState({ accordionId: key });
  };

  /**
   * Method to handle Menu Item Click Navigation.
   * @param {event} evt 
  */
  onMenuItemClick = (evt) => {
    switch (evt.key) {
      case "daily":
        this.props.history.push("/dashboard");
        break;
      case "weekly":
        this.props.history.push("/weekly-dashboard");
        break;
      case "monthy":
        this.props.history.push("/monthly-dashboard");
        break;
      case "applicationConfig":
        this.props.history.push("/admin/application-config");
        break;
      case "costanalysis":
        this.props.history.push("/admin/cost-analysis");
        break;
      case "exceptionConfig":
        this.props.history.push("/admin/configure-exceptions");
        break;
      case "columnAlerts":
        this.props.history.push("/admin/configure-column-alerts");
        break;
      case "solManStagin":
        this.props.history.push("/admin/solman-staging");
        break;
      case "scheduleConfig":
        this.props.history.push("/admin/report-schedule-config");
        break;
      case "messageConfig":
        this.props.history.push("/blob/metrics");
        break;
      case "reportConfig":
        this.props.history.push("/admin/report-config");
        break;
      case "tableConfig":
        this.props.history.push("/admin/table-config");
        break;
      case "userAccess":
        this.props.history.push("/admin/userlist");
        break;
      case "groupAccess":
        this.props.history.push("/admin/usergroup");
        break;
      case "applicationAccess":
        this.props.history.push("/admin/applicationgroup");
        break;
    }
  };

  getItems = (itemData) => {
    return itemData && itemData.map((item) => ({
      key: item.id,
      id: item.id,
      icon: <Icon component={() => <i className={item.icon} />} />,
      label: item.name,
    }));
  };

  render() {
    const { selectedKeys, accordionId } = this.state;
    return (
      <div id="cle-side-menu">
        <Collapse
          expandIconPosition={"end"}
          activeKey={[accordionId]}
          accordion
          onChange={this.handleCollapse}
          ghost
        >
          {this.props.cleMenu.map((item) => {
            return (
              <Panel
                collapsible={item.isNavLink ? 'disabled' : ''}
                header={
                  item.isNavLink ? (
                    <NavLink
                      to={item.path || "#"}
                      className={`${item.className ? item.className : ''} ${this.props.match.path.includes(item.commonPath) ? 'active' : ''} menu-link`}
                    >
                      <Icon
                        component={() => <i className={item.icon} />}
                      />
                      <span className="menu-title">{item.name}</span>
                    </NavLink>
                  ) : (
                    <span>
                      <Icon component={() => <i className={item.icon} />} />
                      <span className="menu-title">{item.name}</span>
                    </span>
                  )}
                key={item.id}
                id={item.id}
                showArrow={false}
              >
                <Menu
                  mode="inline"
                  theme="dark"
                  selectedKeys={[selectedKeys?.id]}
                  onClick={this.onMenuItemClick}
                  items={this.getItems(!item.isNavLink && item.subOptions)}
                />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    );
  }
}

SiderMenu.propTypes = {
  cleMenu: PropTypes.array,
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default connect()(withRouter(SiderMenu));
