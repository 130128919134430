import { getSnowServiceName } from "../../../utils/helpers";

const cellStyles = {
  "text-align": "center",
};
const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPinned: true,
  lockPosition: 'left',
};
export const COLUMNS = [
  fieldHead,
  {
    field: "applicationName",
    headerName: "Application",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "applicationDomain",
    headerName: "Application Domain",
    width: 180,
    filter: false,
    suppressMenu: true,
  },
  {
    field: "exceptionCategoryName",
    headerName: "Category",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "exceptionTypeName",
    headerName: "Type",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "exceptionSeverityName",
    headerName: "Severity",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "replayType",
    headerName: "Replay Type",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "maxAutoReplay",
    headerName: "Max Auto Replay",
    filter: false,
    suppressMenu: true,
    cellStyle: cellStyles,
  },
  {
    field: "description",
    headerName: "Description",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "replyDestination",
    headerName: "Reply Destination",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "replyMode",
    headerName: "Reply Mode",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "emailAddress",
    headerName: "Mail Notification",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "enableMailNotification",
    headerName: "Enable Mail Notification",
    filter: false,
    suppressMenu: true,
    cellStyle: cellStyles,
    cellRenderer: function (params) {
      let val = params.value === 0 ? "N" : "Y";
      return '<span class="text-center">' + val + "</span>";
    },
  },
  {
    field: "enableSnowNotification",
    headerName: "Enable Snow Notification",
    filter: false,
    suppressMenu: true,
    cellStyle: cellStyles,
    cellRenderer: function (params) {
      let val = params.value === 0 ? "N" : "Y";
      return '<span class="text-center">' + val + "</span>";
    },
  },
  {
    field: "snowAssignmentGroup",
    headerName: "Assignment Group",
    filter: false,
    suppressMenu: true,
  },
  {
    field: "autoReplayInterval",
    headerName: "Interval",
    filter: false,
    cellStyle: cellStyles,
    suppressMenu: true,
  },
  {
    field: "enableGroupNotification",
    headerName: "Enable Group Notification",
    filter: false,
    suppressMenu: true,
    cellStyle: cellStyles,
    cellRenderer: function (params) {
      let val = params.value === 0 ? "N" : "Y";
      return '<span class="text-center">' + val + "</span>";
    },
  },
  {
    field: "sysClassName",
    headerName: "Snow Service Name",
    filter: false,
    suppressMenu: true,
    cellRenderer: function (params) {
      return '<span class="text-center">' + getSnowServiceName(params.value) + "</span>";
    },
  },
  {
    field: "snowParam",
    headerName: "Snow Service Value",
    filter: false,
    suppressMenu: true,
  }
];
