export const SEARCH_LOGS = 'SEARCH_LOGS';
export const LOG_SEARCH_SUCCESS = 'LOG_SEARCH_SUCCESS';
export const LOG_SEARCH_FAILURE = 'LOG_SEARCH_FAILURE';
export const GET_LOG_DETAILS = 'GET_LOG_DETAILS';
export const LOG_DETAILS = 'LOG_DETAILS';
export const CLEAR_LOG_DATA = 'CLEAR_LOG_DATA';
export const CLEAR_LOG_DETAILS = 'CLEAR_LOG_DETAILS';
export const PROCESS_FILTER_LOGS = 'PROCESS_FILTER_LOGS';
export const PROCESS_DEFILTER_LOGS = 'PROCESS_DEFILTER_LOGS';
export const FILTER_LOGS_RESULT = 'FILTER_LOGS_RESULT';
export const DEFILTER_LOGS_RESULT = 'DEFILTER_LOGS_RESULT';
export const CLEAR_FILTER_DETAILS = 'CLEAR_FILTER_DETAILS';
export const LOG_SEARCH_FILTER_LOGS = 'LOG_SEARCH_FILTER_LOGS';
export const LOG_SEARCH_FILTER_LOGS_RESULT = 'LOG_SEARCH_FILTER_LOGS_RESULT';
export const PURGE_LOGS = 'PURGE_LOGS';
export const PURGE_LOGS_RESULT = 'PURGE_LOGS_RESULT';
export const GET_FILTERED_LOG_STATUS = 'GET_FILTERED_LOG_STATUS';
export const LOG_FILTER_STATUS = 'LOG_FILTER_STATUS';
export const EMPTY_LOG_DATA = 'EMPTY_LOG_DATA';
export const FILTER_LOGS_PURGE = 'FILTER_LOGS_PURGE';
export const GET_TOTAL_RECORD_COUNT = 'GET_TOTAL_RECORD_COUNT';
export const TOTAL_RECORD_COUNT = 'TOTAL_RECORD_COUNT';
export const LOG_SEARCH_DEFILTER_LOGS = 'LOG_SEARCH_DEFILTER_LOGS';
export const SORT_LOGS = 'SORT_LOGS';
export const SORT_LOGS_SUCCESS = 'SORT_LOGS_SUCCESS';
export const SORT_LOGS_ERROR = 'SORT_LOGS_ERROR';
export const LOGS_ERROR = 'LOGS_ERROR';
export const ALL_SEARCH_LOGS = "ALL_SEARCH_LOGS";
export const GET_LOG_COUNT = 'GET_LOG_COUNT';
export const GET_LOG_COUNT_ALL = "GET_LOG_COUNT_ALL";
export const PROGRESS_LOG_REDUCER = "PROGRESS_LOG_REDUCER";
export const FILTER_DATA = "FILTER_DATA ";