import URL from "../services/setEndpointURL";

const bulkReplayServices = {
    BULK_REPLAY_DATA_UPLOAD: {
        apiName: "BULK_REPLAY_DATA_UPLOAD",
        endPointUrl: "/replay/file/",
        method: "POST",
        mock_json: "",
        isSecured: true,
        server: "Pepsico",
        baseUrlPrefix: URL.REPLAY_DATA_API_URL,
    },
    GET_REPLAY_REPORT_DATA: {
        apiName: "GET_REPLAY_REPORT_DATA",
        endPointUrl: "/replay/file/user/",
        method: "GET",
        mock_json: "",
        isSecured: true,
        server: "Pepsico",
        isStatus: true,
        isNoError: true,
        baseUrlPrefix: URL.REPLAY_DATA_API_URL,
    },
};

export default bulkReplayServices;
