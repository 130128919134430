import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import CleTable from "../../CommonComponents/CleTable/CleTable";
import CleFooter from "../../CommonComponents/CleFooter/CleFooter";
import { COLUMNS } from "../../../constants/ColumnConfig/Admin/configureColumnAlertResults";
import ConfirmationModalPopup from "../../CommonComponents/Modal/ConfirmationModalPopup";
import NewConfigColumnAlert from "./newConfigColumnAlert";
import { checkIfExistsInArray } from "../../../utils/helpers";
import Loader from "../../CommonComponents/Loader";
const pageStyle = {
  height: "calc(100vh - 150px)",
  width: "100%",
};
const ConfigureColumnAlerts = ({ ...props }) => {
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [isModalEdit, setIsModalEdit] = useState(false);
  const onDeleteHandler = () => {
    setShowConfirmModal(false);
    props.onConfigColumnAlertDelete({
      columnAlertId: selectedRow[0]["columnAlertId"],
      columnAlertKey: selectedRow[0]["columnAlertKey"],
    });
    gridApi.deselectAll();
    setSelectedRow([]);
  };
  const onSaveDataHandler = (payload, close) => {
    if (payload["columnAlertId"]) {
      props.saveConfigColumnAlertDetails(payload);
      setIsModalEdit(false);
    } else if (
      payload["columnAlertId"] === undefined &&
      !checkIfExistsInArray(props.columnConfigurationAlertData, payload, [
        "applicationName",
        "exceptionCategoryName",
        "exceptionSeverityName",
        "exceptionTypeName",
        "columnName",
        "columnValue",
      ])
    ) {
      props.saveConfigColumnAlertDetails(payload);
    } else {
      toast.error("Configuration already exist!");
    }
    if (close) {
      setShowUpsertModal(false);
      gridApi.deselectAll();
      setSelectedRow([]);
    }
  };
  return (
    <>
      {props.isLoading && <Loader />}
      {!props.isLoading && (
        <>
          <div className={"admin-cle-table-wrapper"} style={pageStyle}>
            <CleTable
              rowSelection="single"
              getRowNodeId={(data) => data.columnAlertId + data.columnAlertKey}
              suppressRowClickSelection
              columnDefs={COLUMNS}
              immutableData
              rowData={
                props.columnConfigurationAlertData
                  ? props.columnConfigurationAlertData
                  : []
              }
              onRowClicked={(params) => {
                params.node.isSelected()
                  ? params.node.setSelected(false)
                  : params.node.setSelected(true);
                setSelectedRow(params.api.getSelectedRows());
              }}
              onRowSelected={(params) => {
                setSelectedRow(params.api.getSelectedRows());
              }}
              onGridReady={(params) => setGridApi(params.api)}
            />
          </div>
          <CleFooter
            add
            onAdd={() => {
              setIsModalEdit(false);
              setShowUpsertModal(true);
            }}
            modify
            onModify={() => {
              setShowUpsertModal(true);
              setIsModalEdit(true);
            }}
            modifyDisabled={selectedRow.length === 0}
            deleteDisabled={selectedRow.length === 0}
            onDelete={() => setShowConfirmModal(true)}
            deleteBtn
          />
        </>
      )}
      {showConfirmModal && (
        <ConfirmationModalPopup
          title="Are you sure you want to delete this item?"
          isModalOpen={showConfirmModal}
          onCloseModal={() => setShowConfirmModal(false)}
          onOkModal={() => onDeleteHandler()}
        />
      )}
      {showUpsertModal && (
        <NewConfigColumnAlert
          editFlag={isModalEdit}
          data={isModalEdit ? selectedRow : []}
          onCloseModal={() => {
            setShowUpsertModal(false);
            setIsModalEdit(false);
          }}
          isModalOpen={showUpsertModal}
          saveData={(payload, close) => onSaveDataHandler(payload, close)}
        />
      )}
    </>
  );
};
ConfigureColumnAlerts.propTypes = {
  columnConfigurationAlertData: PropTypes.array,
  saveConfigColumnAlertDetails: PropTypes.func,
  onConfigColumnAlertDelete: PropTypes.func,
  isLoading: PropTypes.bool,
};
export default ConfigureColumnAlerts;
