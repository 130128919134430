const API_URL = "cle-web-preprod.apps.pcf.dev.azure.intra.pepsico.com";

//const END_POINT_PREFIX = "/api/v1";

const EXCEPTIONDATA_API_URL =
  window.location.origin + "/exception/data/api/v1";


const LOGDATA_API_URL =
  window.location.origin + "/log/data/api/v1";

const DOWNLOADDATA_API_URL =
  window.location.origin + "/export/api/v1";

const USER_SERVICES_API_URL = window.location.origin + "/user/data/api/v1";

const ADMIN_SERVICES_API_URL =
  window.location.origin + "/admin/data/api/v1";

const REPLAY_DATA_API_URL =
  window.location.origin + "/replay/data/api/v1";

const METRIC_DATA_API_URL =
  window.location.origin + "/metric/data/api/v1";

export default {
  API_URL,
  ADMIN_SERVICES_API_URL,
  EXCEPTIONDATA_API_URL,
  LOGDATA_API_URL,
  DOWNLOADDATA_API_URL,
  METRIC_DATA_API_URL,
  REPLAY_DATA_API_URL,
  USER_SERVICES_API_URL
};
