import URL from "../services/setEndpointURL";

const userServices = {
    GET_USER_ACCESS_INFO: {
        apiName: "GET_USER_ACCESS_INFO",
        endPointUrl: "/users/authenticate/",
        method: "GET",
        mock_json: "",
        isSecured: true,
        baseUrlPrefix: URL.USER_SERVICES_API_URL,
        server: "Pepsico",
    },
    GET_APPLICATION_EXCEPTION_CATEGORY: {
        apiName: "GET_APPLICATION_EXCEPTION_CATEGORY",
        endPointUrl: "/users/category/",
        method: "GET",
        mock_json: "",
        isSecured: true,
        baseUrlPrefix: URL.USER_SERVICES_API_URL,
        server: "Pepsico",
    },
    GET_APPLICATION_EXCEPTION_SEVERITY: {
        apiName: "GET_APPLICATION_EXCEPTION_SEVERITY",
        endPointUrl: "/users/severity/",
        method: "GET",
        mock_json: "",
        isSecured: true,
        baseUrlPrefix: URL.USER_SERVICES_API_URL,
        server: "Pepsico",
    },
    GET_APPLICATION_EXCEPTION_TYPE: {
        apiName: "GET_APPLICATION_EXCEPTION_TYPE",
        endPointUrl: "/users/type/",
        method: "GET",
        mock_json: "",
        isSecured: true,
        baseUrlPrefix: URL.USER_SERVICES_API_URL,
        server: "Pepsico",
    },
}

export default userServices;