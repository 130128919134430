import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Card,
  Col,
} from "antd";
import ConfigureException from "./ConfigurationExcep";
import ReplayConfig from "../../../containers/Admin/ReplayConfigResults";
import MultiSelect from "../../CommonComponents/MultiSelect/MultiSelect";
import CleTabs from "../../CommonComponents/CleTabs/CleTabs";
import { InputBox } from "../../CommonComponents/FormElement/InputBox/InputBox";
import { applicationDataInstance } from "../../../services/applicationDataService";

const ExceptionManagement = ({
  exceptionConfigData,
  isExceptionConfigLoading,
  getExpConfigList,
  onConfExceptionDelete,
  onConfReplayDelete,
  onSaveDetails
}) => {
  const [activeTab, setActiveTab] = useState('exception');
  const [univerSearch, setSearchValue] = useState('');
  const [appName, setAppName] = useState([]);
  const [applicationNames, setApplicationNames] = useState([]);
  useEffect(() => {
    let applicationNameArr = [];
    applicationNameArr = applicationNames.concat(
      applicationDataInstance.getApplicationNames()
    );
    setApplicationNames(applicationNameArr);
  }, []);
  const handleTabChange = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  const handleMultiSelectChange = (selectedOption) => {
    if (selectedOption.constructor.name === "Object")
      selectedOption = [selectedOption];
    setAppName(selectedOption);
  };

  const loader = (
    <div className="text-center loader">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  const tabItems = [
    {
      key: 'exception',
      label: 'Exception Config',
      children: isExceptionConfigLoading ? (
        loader
      ) : (
        <ConfigureException
          filterData={univerSearch}
          appName={appName}
          getExpConfigList={getExpConfigList}
          exceptionConfigData={exceptionConfigData}
          onConfExceptionDelete={onConfExceptionDelete}
          onSaveDetails={onSaveDetails}
        />
      ),
    },
    {
      key: 'replay',
      label: 'Replay Config',
      children: (
        <ReplayConfig
          appName={appName}
          filterData={univerSearch}
          onConfReplayDelete={onConfReplayDelete}
        />
      )
    }
  ];

  return (
    <div>
      <Row>
        <Col span={24}>
          <Card bordered={false}>
            <CleTabs
              activeTab={activeTab}
              defaultActiveTab={"exception"}
              onTabChangeHandler={handleTabChange}
              tabItems={tabItems}
              tabClassName="tab-with-controls"
              tabExtraControlContent={
                <Row gutter={24}>
                  <Col
                    span={15}
                  >
                    <InputBox
                      id="config_search"
                      type="text"
                      placeholder="Search"
                      stclass='search-input'
                      name="exceptionCategory"
                      size="large"
                      onChange={(evt) => {
                        const { currentTarget } = evt;
                        window.setTimeout(() => {
                          setSearchValue(currentTarget.value);
                        }, 2000);
                      }}
                    />
                  </Col>
                  <Col
                    span={9}
                  >
                    <div className="cle-select cle-multi-select">
                      <MultiSelect
                        placeholder="Filter Application"
                        hasSelectAll={
                          applicationNames && applicationNames.length > 2
                            ? true
                            : false
                        }
                        disableSearch={
                          applicationNames && applicationNames.length > 10
                            ? false
                            : true
                        }
                        options={applicationNames}
                        selected={appName}
                        multi
                        clearable
                        onSelectedChanged={(app) => handleMultiSelectChange(app)}
                      />
                    </div>
                  </Col>
                </Row>
              }
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
ExceptionManagement.propTypes = {
  exceptionConfigData: PropTypes.any,
  onConfExceptionDelete: PropTypes.func,
  onConfReplayDelete: PropTypes.func,
  isExceptionConfigLoading: PropTypes.bool,
  getExpConfigList: PropTypes.func,
  onSaveDetails: PropTypes.func,
};

export default ExceptionManagement;
