export const formItemsLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

export const formItemsDefaultStyle = { 
        width: "75%",
        marginBottom:"5px" 
}

export const costFormItemsLayout = {
    labelCol: { span: 20 },
    wrapperCol: { span: 24 },
};