const cellStyles = {
  "text-align": "center",
};
const fieldHead = {
  field: "",
  headerName: "",
  width: 60,
  headerCheckboxSelection: false,
  headerCheckboxSelectionFilteredOnly: false,
  checkboxSelection: true,
  sortable: false,
  pinned: "left",
  filter: false,
  suppressMenu: true,
  lockPosition: 'left',
  lockPinned: true,
};
const COLUMNS = [
  fieldHead,
  {
    field: "applicationName",
    headerName: "Application Name",
  },
  {
    field: "logRetain",
    headerName: "Log Retain",
    cellStyle: cellStyles,
  },
  {
    field: "exceptionRetain",
    headerName: "Exception Retain",
    cellStyle: cellStyles,
  },
  {
    field: "logTopic",
    headerName: "Log Topic",
  },
  {
    field: "exceptionTopic",
    headerName: "Exception Topic",
  },
  {
    field: "appDescription",
    headerName: "Description",
  },
  {
    field: "logTable",
    headerName: "Log Table",
  },
  {
    field: "exceptionTable",
    headerName: "Exception Table",
  },
  {
    field: "appOwner",
    headerName: "App Owner",
  },
  {
    field: "teamDl",
    headerName: "Team DL",
  },
  {
    field: "snowCI",
    headerName: "Snow CI",
  },
  {
    field: "assignmentGroup",
    headerName: "Assignment Group",
  },
  {
    field: "appSector",
    headerName: "Sector",
  },
];
module.exports = { COLUMNS };
