//user getLoggedInUserAccessInfo
export const LAST_HOUR_LOGS_REPORT = 'LAST_HOUR_LOGS_REPORT';
export const LAST_HOUR_LOGS_REPORT_RESULT ='LAST_HOUR_LOGS_REPORT_RESULT';
export const LAST_HOUR_EXCEPTION_REPORT = 'LAST_HOUR_EXCEPTION_REPORT';
export const LAST_HOUR_EXCEPTION_REPORT_RESULT = 'LAST_HOUR_EXCEPTION_REPORT_RESULT';
export const PAST_HOUR_LOGS_REPORT = 'PAST_HOUR_LOGS_REPORT';
export const PAST_HOUR_LOGS_REPORT_RESULT = 'PAST_HOUR_LOGS_REPORT_RESULT';
export const PAST_HOUR_EXCEPTION_REPORT = 'PAST_HOUR_EXCEPTION_REPORT';
export const PAST_HOUR_EXCEPTION_REPORT_RESULT = 'PAST_HOUR_EXCEPTION_REPORT_RESULT';
export const ADMIN_LOG_REPORT = 'ADMIN_LOG_REPORT';
export const ADMIN_LOG_REPORT_RESULT = 'ADMIN_LOG_REPORT_RESULT';
export const ADMIN_EXCEPTION_REPORT = 'ADMIN_EXCEPTION_REPORT';
export const ADMIN_EXCEPTION_REPORT_RESULT = 'ADMIN_EXCEPTION_REPORT_RESULT';
