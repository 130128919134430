import * as types from '../../constants/ActionTypes/Admin/scheduleReportTypes';
import {constructServiceUrl} from '../../services/constructUrl';

export function fetchScheduleReportData() {
  let serviceParams = {}
  serviceParams.apiName = types.GET_SCHEDULE_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_SCHEDULE_REPORT,
    request: requestParams
  };
}
export function updateScheduleData(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.UPDATE_SCHEDULE_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.UPDATE_SCHEDULE_REPORT,
    request: requestParams
  };
}
export function reportScheduleDataStore(data) {
  return {
    type: types.STORE_SCHEDULE_REPORT,
    data
  };
}
export function deleteScheduleDetail(payload) {
  let serviceParams = {}
  serviceParams.payload = payload;
  serviceParams.apiName = types.DELETE_SCHEDULE_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.DELETE_SCHEDULE_REPORT,
    request: requestParams
  };
}
export function addScheduleReport(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.ADD_SCHEDULE_REPORT;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = true;
  return {
    type: types.ADD_SCHEDULE_REPORT,
    request: requestParams
  };
}



