import * as types from '../../constants/ActionTypes/analyticsTypes';
import { constructServiceUrl } from '../../services/constructUrl';

export function getExceptionReport(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.GET_EXCEPTION_ANALYTICS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_EXCEPTION_ANALYTICS,
    request: requestParams
  };
}
export function updateExceptionReport(exceptionReport) {
  window.iosLoader = false;
  return {
    type: types.EXCEPTION_ANALYTICS,
    exceptionReport
  };
}
export function getLogReport(payload) {
  let serviceParams = {};
  serviceParams.payload = payload;
  serviceParams.apiName = types.GET_LOG_ANALYTICS;
  let requestParams = constructServiceUrl(serviceParams);
  window.iosLoader = false;
  return {
    type: types.GET_LOG_ANALYTICS,
    request: requestParams
  };
}
export function updateLogReport(logReport) {
  window.iosLoader = false;
  return {
    type: types.LOG_ANALYTICS,
    logReport
  };
}
