import * as types from '../../constants/GlobalTypes/GlobalTypes';


export function logFilterStatusGlobal(status) {
    window.iosLoader = false;
    return {
        type: types.LOG_FILTER_STATUS,
        status
    };
}
export function logPurgeStatus(status) {
    window.iosLoader = false;
    return {
        type: types.LOG_PURGE_STATUS,
        status
    };
}
export function logExportExcelStatus(status) {
    window.iosLoader = false;
    return {
        type: types.LOG_EXPORTEXCEL_STATUS,
        status
    };
}
export function exceptionFilterStatus(status) {
    window.iosLoader = false;
    return {
        type: types.EXCEPTION_FILTER_STATUS,
        status
    };
}
export function exceptionPurgeStatus(status) {
    window.iosLoader = false;
    return {
        type: types.EXCEPTION_PURGE_STATUS,
        status
    };
}
export function exceptionExportExcelStatus(status) {
    window.iosLoader = false;
    return {
        type: types.EXCEPTION_EXPORTEXCEL_STATUS,
        status
    };
}
export function exceptionResolveStatus(status) {
    window.iosLoader = false;
    return {
        type: types.EXCEPTION_RESOLVE_STATUS,
        status
    };
}