import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

function modalWarning(
  title = "Do you want to delete these items?",
  content,
  onOk,
) {
  return confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      onOk();
    },
  });
}

export default modalWarning;
