/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootReducer from "../reducers";
import rootSaga from "../sagas/rootSaga";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["userAccess"],
};
const sagaMiddleware = createSagaMiddleware();

function configureStoreProd(initialState) {
  const middlewares = [sagaMiddleware];
  const pReducer = persistReducer(persistConfig, rootReducer());
  let store = createStore(
    pReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );
  rootSaga.map((saga) => sagaMiddleware.run(saga));
  return store;
}

function configureStoreDev(initialState) {
  const middlewares = [
    reduxImmutableStateInvariant(),
    sagaMiddleware,
    createLogger(),
  ];
  const pReducer = persistReducer(persistConfig, rootReducer());
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  let store = createStore(
    pReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextReducer = require("../reducers").default;
      // eslint-disable-line global-require
      store.replaceReducer(persistReducer(nextReducer));
    });
  }
  rootSaga.map((saga) => sagaMiddleware.run(saga));
  return store;
}
const configureStore =
  process.env.NODE_ENV === "production"
    ? configureStoreProd
    : configureStoreDev;

export default configureStore;
