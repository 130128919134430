import React from "react";
import PropTypes from "prop-types";
import NewApplication from "../../Admin/ApplicationConfig/Applications/newApplication";
import NewCategory from "../../Admin/ApplicationConfig/Lists/newCategory";
import NewSeverity from "../../Admin/ApplicationConfig/Lists/newSeverity";
import NewType from "../../Admin/ApplicationConfig/Lists/newType";
import NewConfigColumnAlert from "../../Admin/ConfigureColumnAlerts/newConfigColumnAlert";
//import AddConfigExcepContainer from "../../../containers/Admin/ConfigureExceptionResults/addConfigExcep";
//import AddReplayConfigContainer from "../../../containers/Admin/ReplayConfigResults/addReplayConfig";
//import AddLogConfigContainer from "../../../containers/Admin/LogStatus/addLogConfig";
//import AddExceptionContainer from "../../../containers/Admin/ExceptionStatus/addExceptionReport";
import DetailLogConfig from "../../Admin/ConfigureReports/LogStatus/DetailLogConfig";
import NewSolmanStaging from "../../Admin/SolmanStaging/newSolmanStaging";
import NewReportConfig from "../../Admin/SapReport/reportConfig";
import NewScheduleReportConfig from "../../Admin/ScheduleReport/reportConfig";
import ErrorModal from "../ErrorModal";
import $ from "jquery";
//import DetailExceptionConfig from "../../Admin/ConfigureExceptions/ConfigurationExcep/detailConfigExceptions";
import DetailReplayExceptionConfig from "../../Admin/ConfigureExceptions/ReplayConfig/detailReplayConfigModal";
import WarningModal from "./Warning";
import "./styles.scss";

export default class ModalView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
    };
  }
  componentDidMount() {
    window.addEventListener("keydown", this.handleOnKeyDown);
    window.fullLoader = true;
    $("#cleModal").modal("show");
    $("#cleModal").modal({ backdrop: "static" });
    $("#cleModal").on("hidden.bs.modal", this.props.onCloseModal);
  }
  componentWillUnmount() {
    window.fullLoader = false;
    $("#cleModal").modal("hide");
    window.removeEventListener("keydown", this.handleOnKeyDown);
  }
  handleOnKeyDown = (e) => {
    if (e.key === "Escape") {
      $("#cleModal").modal("hide");
      window.fullLoader = false;
      this.props.onCloseModal();
    }
  };
  getComponent = () => {
    let comp = null;
    switch (this.props.view) {
      case "Application":
        comp = (
          <NewApplication
            editFlag={this.props.editFlag}
            saveData={(appData, close) => {
              this.props.saveData(appData, close);
            }}
            s
            data={this.props.data}
          />
        );
        break;
      case "Category":
        comp = (
          <NewCategory
            editFlag={this.props.editFlag}
            saveData={this.props.saveData}
            data={this.props.data}
            applicationName={this.props.applicationName}
          />
        );
        break;
      case "Type":
        comp = (
          <NewType
            editFlag={this.props.editFlag}
            saveData={this.props.saveData}
            data={this.props.data}
            applicationName={this.props.applicationName}
          />
        );
        break;
      case "Severity":
        comp = (
          <NewSeverity
            editFlag={this.props.editFlag}
            saveData={this.props.saveData}
            data={this.props.data}
            applicationName={this.props.applicationName}
          />
        );
        break;
      case "ColumnConfigurationAlertPopUp":
        comp = (
          <NewConfigColumnAlert
            saveData={(data, close) => this.props.saveData(data, close)}
            data={this.props.editFlag ? this.props.data : null}
            editFlag={this.props.editFlag}
          />
        );
        break;
      // case "AddConfigExcepContainer":
      //   comp = (
      //     <AddConfigExcepContainer
      //       editFlag={this.props.editFlag}
      //       data={this.props.data}
      //       clearData={this.props.clearData}
      //     />
      //   );
      //   break;
      // case "AddReplayConfigContainer":
      //   comp = (
      //     <AddReplayConfigContainer
      //       editFlag={this.props.editFlag}
      //       data={this.props.data}
      //       clearData={this.props.clearData}
      //     />
      //   );
      //   break;
      case "SolmanStaging":
        comp = (
          <NewSolmanStaging
            editFlag={this.props.editFlag}
            data={this.props.data}
            saveData={(data, close) => this.props.saveData(data, close)}
          />
        );
        break;
      case "NewReportConfig":
        comp = (
          <NewReportConfig
            editFlag={this.props.editFlag}
            data={this.props.data}
            saveData={(data, close) => this.props.saveData(data, close)}
          />
        );
        break;
      // case "AddLogConfigContainer":
      //   comp = (
      //     <AddLogConfigContainer
      //       editFlag={this.props.editFlag}
      //       data={this.props.data}
      //       saveData={(data, close) => this.props.saveData(data, close)}
      //       clearData={this.props.clearData}
      //     />
      //   );
      //   break;
      // case "AddExceptionContainer":
      //   comp = (
      //     <AddExceptionContainer
      //       editFlag={this.props.editFlag}
      //       data={this.props.data}
      //       saveData={(data, close) => this.props.saveData(data, close)}
      //       clearData={this.props.clearData}
      //     />
      //   );
      //   break;
      case "NewScheduleReportConfig":
        comp = (
          <NewScheduleReportConfig
            editFlag={this.props.editFlag}
            data={this.props.data}
            saveData={(data, close) => this.props.saveData(data, close)}
          />
        );
        break;
      case "ErrorModal":
        comp = (
          <ErrorModal
            onCloseModal={this.props.onCloseModal}
            isUnauth={this.props.isUnauth}
          />
        );
        break;
      // case "DetailExceptionConfig":
      //   comp = (
      //     <DetailExceptionConfig
      //       onCloseModal={this.props.onCloseModal}
      //       data={this.props.data}
      //     />
      //   );
      //   break;
      case "DetailReplayExceptionConfig":
        comp = (
          <DetailReplayExceptionConfig
            onCloseModal={this.props.onCloseModal}
            data={this.props.data}
          />
        );
        break;
      case "DetailLogConfig":
        comp = (
          <DetailLogConfig
            onCloseModal={this.props.onCloseModal}
            data={this.props.data}
          />
        );
        break;
      case "WarningModal":
        comp = (
          <WarningModal
            onCloseModal={this.props.onCloseModal}
            data={this.props.data}
          />
        );
        break;
      default:
        comp = null;
    }

    return comp;
  };
  render() {
    return (
      <div
        className="modal fade cleModal"
        role="dialog"
        id="cleModal"
        data-backdrop="static"
      >
        <div
          className={`modal-dialog modal-${
            this.props.size ? this.props.size : "md"
          } modal-dialog-centered ${this.props.isErrModal ? "errorModal" : ""}`}
        >
          <div className="modal-content">{this.getComponent()}</div>
        </div>
      </div>
    );
  }
}
ModalView.propTypes = {
  onCloseModal: PropTypes.func,
  view: PropTypes.string,
  data: PropTypes.any,
  saveData: PropTypes.func,
  editFlag: PropTypes.bool,
  applicationName: PropTypes.any,
  size: PropTypes.string,
  // clearData: PropTypes.func,
  isUnauth: PropTypes.any,
  isErrModal: PropTypes.bool,
};
