import React from "react";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";
import PropTypes from "prop-types";
import xmlFormat from 'xml-formatter';
import moment from "moment";
import { default as momentZone } from "moment-timezone";
import _ from 'lodash';

import { saveAs } from "file-saver";
import { Button, Col, Collapse, Row, message } from "antd";
import { userAccessValidator } from "../../utils/userAccessValidator";
import {
  HEADER_COLUMNS,
  JMS_COLUMNS,
} from "../../constants/ColumnConfig/exceptionDetails";
import CleDetail from "../CommonComponents/CleDetail/CleDetail";
import ReadMore from "../CommonComponents/ReadMore/ReadMore";
import exceptionDetailColumn from "../../constants/CommonTable/exceptionDetailColumn";
import logDetailColumn from "../../constants/CommonTable/logDetailColumn";
import useKeyPress from "../hooks/KeyPressed";
import "../Admin/MessageMatricsComponent/style.scss";

const { Panel } = Collapse;
const jsonFormatter = require('json-string-formatter');

const testJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    let datax = JSON.parse(text);
    if (typeof datax === "object") return true;
    return false;
  } catch (error) {
    return false;
  }
};
const testXml = (xmlStr) => {
  var domParser = new DOMParser();
  var dom = domParser.parseFromString(xmlStr, "text/xml");
  if (dom.getElementsByTagName("parsererror").length > 0) {
    return false;
  }
  return true;
  // print the name of the root element or error message
};
const BlobDetail = (props) => {
  // console.log(props, ':::::::BlobDetail');
  let transRef = null;
  //let data = null;
  useKeyPress("ArrowLeft", () => {
    props.handleMove("prev");
  });
  useKeyPress("ArrowRight", () => {
    props.handleMove("next");
  });

  const jsonParse = (data) => {
    let json = false;
    let resp = JSON.parse(JSON.stringify(data));
    while (!json) {
      resp = JSON.parse(resp);
      json = typeof resp === "object";
    }
    return resp;
  };

  const convertData = (data, enableEdit, id) => {
    if (data && data !== "") {
      if (testXml(data)) {
        return (
          <div
            contentEditable={enableEdit}
            suppressContentEditableWarning
            style={{ outline: 0 }}
            id={id}
          >
            <XMLViewer xml={data} />
          </div>
        );
      } else if (testJSON(data)) {
        return !enableEdit ? (
          <ReactJson
            name={false}
            enableClipboard={false}
            src={jsonParse(data)}
            displayObjectSize={false}
            displayDataTypes={false}
            id={id}
          />
        ) : (
          <div
            contentEditable={enableEdit}
            className="out-line-none"
            suppressContentEditableWarning
            id={id}
          >
            {data}
          </div>
        );
      }
    }
    return (
      <pre
        contentEditable={enableEdit}
        className="out-line-none"
        suppressContentEditableWarning
        style={{ whiteSpace: "pre-wrap" }}
        id={id}
      >
        {data}
      </pre>
    );
  };
  let panelFirstDataAvail = false;
  let panelSecondDataAvail;
  let panelFirstDataSize;
  let panelSecondDataSize;
  if (
    props.selectedRow[props.type === "log" ? "trans_before" : "trans_data"] &&
    props.selectedRow[props.type === "log" ? "trans_before" : "trans_data"] !==
    ""
  ) {
    panelFirstDataAvail = true;
    panelFirstDataSize =
      props.selectedRow[props.type === "log" ? "trans_before" : "trans_data"]
        .length;
  }
  if (
    props.selectedRow[props.type === "log" ? "trans_after" : "dump_analysis"] &&
    props.selectedRow[
    props.type === "log" ? "trans_after" : "dump_analysis"
    ] !== ""
  ) {
    panelSecondDataAvail = true;
    panelSecondDataSize =
      props.selectedRow[props.type === "log" ? "trans_after" : "dump_analysis"]
        .length;
  }
  let jsmData =
    props.selectedRow.jms_header &&
      props.selectedRow.jms_header !== "" &&
      props.selectedRow.jms_header !== "null"
      ? JSON.parse(props.selectedRow.jms_header)
      : null;
  const downloadData = (panelName) => {
    if (panelName === "panel1") {
      saveAs(
        new Blob(
          [
            props.selectedRow[
            props.type === "log" ? "trans_before" : "trans_data"
            ],
          ],
          {
            type: "text/plain;charset=utf-8",
          }
        ),
        props.type === "log"
          ? "cle_log_trans_before"
          : "cle_exception_trans_data" + ".txt"
      );
    } else {
      saveAs(
        new Blob(
          [
            props.selectedRow[
            props.type === "log" ? "trans_after" : "dump_analysis"
            ],
          ],
          {
            type: "text/plain;charset=utf-8",
          }
        ),
        props.type === "log"
          ? "cle_log_trans_after"
          : "cle_exception_dump_data" + ".txt"
      );
    }
  };
  const [transEdit, setTransEdit] = React.useState(false);
  // const [transEdit, setTransEdit] = React.useState(false);
  const handleTransSave = () => {
    props.saveContent(transRef.textContent);
    setTransEdit(false);
  };
  const checkAccess = () => {
    return userAccessValidator.validJsmEditAccess(
      props.selectedRow?.application_nm,
      props.userAccessInfo
    );
  };

  const copyToClipBoardFunction = (dataToBeCopied) => {
    if (testXml(dataToBeCopied)) {
      navigator.clipboard.writeText(xmlFormat(dataToBeCopied));
    }
    else if (testJSON(dataToBeCopied)) {
      navigator.clipboard.writeText(jsonFormatter.format(dataToBeCopied));
    }
    else {
      navigator.clipboard.writeText(dataToBeCopied);
    }
  };

  const displayUserNames = (key) => {
    let userFound = props.usersList.filter((item) => item.userID === key);
    return (userFound.length > 0) ? userFound[0]?.firstName + ' ' + userFound[0]?.lastName : null;
  };

  const timeZoneConvert = (time) => {
    if (time) {
      if (props.timeZone)
        return momentZone(time)
          .tz(window.timeZone)
          .format("MMM Do YYYY, h:mm:ss a");
      return moment(time).local().format("MMM Do YYYY, h:mm:ss a");
    }
    return time;
  };

  return (
    <div>
      <CleDetail
        schema={props.type === "log" ? logDetailColumn : exceptionDetailColumn}
        data={props.selectedRow}
        timeZone={props.timeZone}
        timeConvert
      />
      <Collapse
        id="sel_detail_panels"
        defaultActiveKey={["1", "2"]}
        ghost
        expandIconPosition="end"
      >
        {props.auditstatus !== "Pending" && props.auditUserGroups !== ""
          && Object.keys(props.auditUserGroups).length > 0 && (
            <Panel
              id="sel_detail_panel_0111"
              header="Audit Log"
              key="audit_log"
              className="collapse-header"
            >
              {Object.entries(props.auditUserGroups).map(([key, value]) => {
                return (
                  <div key={key}>
                    {Object.entries(value).map(([i, item]) => {
                      item = _.orderBy(item, "timemark", "asc");
                      return (
                        <div key={i}>
                          <div className="audit-log">
                            {i == "system" ? <span>Automatic Replay</span> : <span>Request Replay triggered by {displayUserNames(i)} ({i})</span>}
                          </div>
                          <div style={{ paddingLeft: 20 }}>
                            <table className="tbl-blob-blue audit-table">
                              <thead>
                                <tr>
                                  <th style={{ textAlign: "left" }}>Timemark</th>
                                  <th
                                    style={{ textAlign: "left", padding: "4px 25px" }}
                                  >
                                    Status
                                  </th>
                                  <th
                                    style={{ textAlign: "left", padding: "4px 25px" }}
                                  >
                                    Description
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.map((element, initial) => {
                                  return (
                                    <tr key={initial}>
                                      <td>{timeZoneConvert(element.timemark)}</td>
                                      <td>{element.status}</td>
                                      <td>{element.description}</td>
                                    </tr>);
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                  </div>);
              })}
            </Panel>
          )}

        <Panel
          header={props.type === "log" ? "Before Data" : "Transaction Data"}
          key="1"
          id="sel_detail_panel_01"
          className="collapse-header"
          extra={
            panelFirstDataAvail ? (
              <div className="d-flex">
                {transEdit && (
                  <Button
                    type="primary"
                    shape="round"
                    id="sel_detail_exception_modal_save"
                    className="secondary mr10"
                    // icon={<DownloadOutlined />}
                    onClick={(event) => {
                      // If you don't want click extra trigger collapse, you can prevent this:
                      event.stopPropagation();
                      handleTransSave();
                    }}
                  >
                    Save
                  </Button>
                )}
                {!transEdit &&
                  checkAccess() &&
                  panelFirstDataSize < window.MAX_LENGTH &&
                  props.type !== "log" && (
                    <Button
                      type="primary"
                      className="mr10 mid-black"
                      shape="round"
                      id="sel_detail_exception_modal_edit"
                      onClick={(event) => {
                        event.stopPropagation();
                        setTransEdit(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                {panelFirstDataSize > window.MAX_LENGTH && (
                  <Button
                    type="primary"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      downloadData("panel1");
                    }}
                  >
                    Download
                  </Button>
                )}
              </div>
            ) : (
              false
            )
          }
        >
          {panelFirstDataAvail ? (
            <div className="collapse-content" ref={(ref) => (transRef = ref)}>
              {panelFirstDataSize > window.MAX_LENGTH ? (
                <ReadMore
                  data={
                    props.selectedRow[
                    props.type === "log" ? "trans_before" : "trans_data"
                    ]
                  }
                  onDownload={() => downloadData("panel1")}
                />
              ) : (
                convertData(
                  props.selectedRow[
                  props.type === "log" ? "trans_before" : "trans_data"
                  ],
                  transEdit,
                  "sel_details_first_text"
                )
              )}
              <Button
                className="num-white-btn copy-clip-board"
                icon={<i className="far fa-clipboard mr10" />}
                onClick={() => {
                  copyToClipBoardFunction(props.selectedRow[
                    props.type === "log" ? "trans_before" : "trans_data"
                  ]);
                  message.success("Copied text to clipboard");
                }}
              >
                Copy to clipboard
              </Button>
            </div>
          ) : (
            <div className="no-data-msg">No Data Found</div>
          )}
        </Panel>
        <Panel
          id="sel_detail_panel_02"
          header={props.type === "log" ? "After Data" : "Dump Analysis"}
          key="2"
          className="collapse-header"
          extra={
            panelSecondDataAvail && panelSecondDataSize > window.MAX_LENGTH ? (
              <Button
                type="primary"
                shape="round"
                onClick={(event) => {
                  event.stopPropagation();
                  downloadData("panel2");
                }}
              >
                Download
              </Button>
            ) : (
              false
            )
          }
        >
          {panelSecondDataAvail ? (
            <div className="collapse-content">
              {panelSecondDataSize > window.MAX_LENGTH ? (
                <ReadMore
                  data={
                    props.selectedRow[
                    props.type === "log" ? "trans_after" : "dump_analysis"
                    ]
                  }
                  onDownload={() => downloadData("panel2")}
                />
              ) : (
                convertData(
                  props.selectedRow[
                  props.type === "log" ? "trans_after" : "dump_analysis"
                  ],
                  false,
                  "sel_details_second_text"
                )
              )}
              <Button
                className="num-white-btn copy-clip-board"
                icon={<i className="far fa-clipboard mr10" />}
                onClick={() => {
                  copyToClipBoardFunction(
                    props.selectedRow[
                    props.type === "log" ? "trans_after" : "dump_analysis"
                    ]
                  );
                  message.success("Copied text to clipboard");
                }}
              >
                Copy to clipboard
              </Button>
            </div>
          ) : (
            <div className="no-data-msg">No Data Found</div>
          )}
        </Panel>

        {props.selectedRow?.msg_pairs && props.selectedRow?.msg_pairs !== "" && (
          <Panel
            className="collapse-header"
            header="Message Properties"
            key="3"
            id="sel_detail_panel_03"
          >
            <div style={{ paddingLeft: 20 }}>
              <table className="tbl-blob-blue">
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>Name</th>
                    <th style={{ textAlign: "left", padding: "4px 25px" }}>
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.selectedRow?.msg_pairs?.map((column, i) => {
                    return (
                      <tr key={i}>
                        <th>{column.Name || column.name}</th>
                        <td>{column.Value || column.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Panel>
        )}
        {jsmData?.MessageProperties && jsmData?.MessageProperties !== "" && (
          <Panel
            className="collapse-header"
            header="Application Properties"
            id="sel_detail_panel_04"
            key="5"
          >
            <div style={{ paddingLeft: 20 }}>
              <table className="tbl-blob-blue">
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>Name</th>
                    <th style={{ textAlign: "left", padding: "4px 25px" }}>
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {jsmData?.MessageProperties?.map((column, i) => {
                    return (
                      <tr key={i}>
                        <th>{column.Name}</th>
                        <td>{column.Value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Panel>
        )}
        <Panel
          className="collapse-header"
          header="JMS Properties"
          id="sel_detail_panel_05"
          key="4"
        >
          <div style={{ paddingLeft: 20 }}>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <table className="tbl-blob-blue">
                  <thead>
                    <tr>
                      <th colSpan="2">JMS Header</th>
                    </tr>
                  </thead>
                  <tbody>
                    {HEADER_COLUMNS.map((column, i) => {
                      return (
                        <tr key={i}>
                          <th>{column.headerName}</th>
                          <td>{jsmData?.JMSHeader?.[column.field]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
              <Col span={12}>
                <table className="tbl-blob-blue">
                  <thead>
                    <tr>
                      <th colSpan="2">JMS Properties</th>
                    </tr>
                  </thead>
                  <tbody>
                    {JMS_COLUMNS.map((column, i) => {
                      return (
                        <tr key={i}>
                          <th>{column.headerName}</th>
                          <td>{jsmData?.JMSProperties?.[column.field]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Panel>
      </Collapse>
      ,
    </div>
  );
};

BlobDetail.propTypes = {
  type: PropTypes.string,
  timeZone: PropTypes.bool,
  selectedRow: PropTypes.object,
  saveContent: PropTypes.func,
  userAccessInfo: PropTypes.object,
  handleMove: PropTypes.func,
  auditstatus: PropTypes.string,
  auditUserGroups: PropTypes.any,
  usersList: PropTypes.array,
};
export default React.memo(BlobDetail);
