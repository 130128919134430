import React, { useState } from "react";
import { Form, Row, Col, Checkbox, Tooltip } from "antd";
import PropTypes from "prop-types";

import SelectDropDown from "../../../components/CommonComponents/SelectDropDown";
import { applicationDataInstance } from "../../../services/applicationDataService";
import "./UpsertUser.css";

const userAccessRolesList = [
  { label: "View", value: "view" },
  { label: "Replay", value: "replay" },
  { label: "Edit Header", value: "edit_JMS_Header" },
  { label: "Edit Payload", value: "edit_JMS_Payload" },
];

const UserApplicationAccess = ({ ...props }) => {
  const applicationNames = applicationDataInstance.getApplicationNames();
  const [editIndex, setEditIndex] = useState(-1);
  const [applicationList, setApplicationList] = useState([]);
  const onApplicationNameChangeHandler = (selectedApplication, index) => {
    setApplicationList([...applicationList, selectedApplication])
    props.updateUserAccessHandler(
      "applicationName",
      selectedApplication,
      index
    );
  };
  const onRemoveHandler = (selectedIndex) => {
    applicationList.splice(selectedIndex, 1)
    props.setUserApplicationList(applicationList);
  };

  return (
      <Form.List name="roleAccess">
        {(roleAccess, { add, remove }) => {
          return (
            <>
              <div className="d-flex justify-content-between application_access_conatiner">
                <div className="line-item-header">Application access</div>
                <div
                  className="pointer add-item-container"
                  onClick={() => {
                    add();
                    setEditIndex(roleAccess.length);
                  }}
                >
                  <i
                    className="fa fa-plus-circle add-item-button"
                    aria-hidden="true"
                  />
                  <span className="add-item-txt">Add Item</span>
                </div>
              </div>
              <div className="role-access-wrapper">
                {roleAccess.length == 0 && (
                  <div className="no_record_role">No Data Found.</div>
                )}
                {roleAccess.map((accessElement, index) => (
                  <Row
                    justify="space-between"
                    key={"accessElement-" + index}
                    className={
                      "line-item-row " +
                      (props.formRef.getFieldsError().filter(
                          (fieldErr) =>
                            fieldErr.errors.length > 0 &&
                            fieldErr.name.includes("roleAccess") &&
                            fieldErr.name.includes(index)
                        ).length > 0
                        ? "line-item-error "
                        : "")
                    }
                  >
                    <Col span={8}>
                      <Form.Item
                        name={[index, "applicationName"]}
                        rules={[
                          {
                            required: true,
                            message: "Application Name is Mandatory!",
                          },
                        ]}
                        style={{ margin: "0px" }}
                        className="user-application-select"
                      >
                        {editIndex === index && (
                          <SelectDropDown
                            placeholder={"Select Application"}
                            className="cle-select"
                            value={props.formRef &&
                              props.formRef.getFieldValue("roleAccess")[index]
                                ? props.formRef.getFieldValue("roleAccess")[index]["applicationName"]
                                : null}
                            options={
                              applicationNames &&
                              applicationNames.filter(
                                (applicationElement) =>
                                  !props.userApplicationList.includes(
                                    applicationElement.value
                                  )
                              )
                            }
                            onChange={(e) =>
                              onApplicationNameChangeHandler(e, index)
                            }
                          />
                        )}
                        {editIndex !== index && (
                          <>
                            {props.formRef &&
                            props.formRef.getFieldValue("roleAccess")[index]
                              ? props.formRef.getFieldValue("roleAccess")[index]["applicationName"]
                              : "Select Application"}
                          </>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12} justify="space-between">
                      <Form.Item
                        name={[index, "accessList"]}
                        rules={[
                          { required: true, message: "Access is Mandatory!" },
                        ]}
                        style={{ minWidth: "25vw", margin: "0px" }}
                      >
                        <Checkbox.Group
                          options={userAccessRolesList}
                          className="user-access-checkbox-group"
                          disabled={editIndex !== index}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      {editIndex !== index && (
                        <Tooltip title="Edit">
                          <span
                            className="action-icon-edit pointer"
                            onClick={() => setEditIndex(index)}
                          >
                            <i
                              className="fas fa-pencil-alt"
                              aria-hidden="true"
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Col>
                    <Col span={1}>
                      <Tooltip title="Delete">
                        <span
                          className="action-icon-delete pointer"
                          onClick={() => {
                            remove(accessElement.name);
                            onRemoveHandler(index);
                          }}
                        >
                          <i className="far fa-trash-alt" aria-hidden="true" />
                        </span>
                      </Tooltip>
                    </Col>
                  </Row>
                ))}
              </div>
            </>
          );
        }}
      </Form.List>
  );
};

UserApplicationAccess.propTypes = {
  userApplicationList: PropTypes.array,
  setUserApplicationList: PropTypes.func,
  updateUserAccessHandler: PropTypes.func,
  formRef: PropTypes.any,
  errorList: PropTypes.object
};
export default UserApplicationAccess;
