import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Form } from "antd";
import { useDispatch } from 'react-redux';
import UserFooter from '../UserFooter';
import ApplicationAccessItem from './ApplicationAccessItem';
import { findDifferenceFromTwoList } from '../../../utils/helpers';
import { saveRolesApplicationList, updateRolesApplicationList, deleteRolesApplicationList } from "../../../actions/Admin/userActions";

const GroupApplicationTab = ({ ...props }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formAppNameList = Form.useWatch('appAccessList', form);

    const [initialFormData, setInitialFormData] = useState(null);
    const [, setErrorList] = useState(null);

    useEffect(() => {
        form.setFieldsValue({
            appAccessList: props.initialAppAccessList
        })
        setInitialFormData({ appAccessList: props.initialAppAccessList });
    }, [props.initialAppAccessList]);

    const onSaveAccessHandler = () => {
        form.validateFields()
            .then(async (values) => {
                let differenceObj = findDifferenceFromTwoList(props.initialAppAccessList,values["appAccessList"],"applicationName","accessName");
                if (differenceObj.added && differenceObj.added.length > 0) {
                    dispatch(saveRolesApplicationList(differenceObj.added));
                }
                
                if (differenceObj.deleted && differenceObj.deleted.length > 0) {
                    dispatch(deleteRolesApplicationList(differenceObj.deleted));
                }
                
                if (differenceObj.updated && differenceObj.updated.length > 0) {
                    dispatch(updateRolesApplicationList(differenceObj.updated));
                }
            }).catch((err) => {
                console.log(err);
                setErrorList(err);
            });
    }

    return (<>
        <Form
            id="group-application-form"
            form={form}
            initialValues={initialFormData}>
            <ApplicationAccessItem
                formRef={form}
                selectedGroup={props.selectedGroup}
                formAppNameList={formAppNameList}
            />
        </Form>
        <UserFooter
            update
            reset
            onUpdateSave={onSaveAccessHandler}
            handleReset={() => { form.resetFields() }}
        />
    </>);
}
GroupApplicationTab.propTypes = {
    selectedGroup: PropTypes.object,
    initialAppAccessList: PropTypes.array,
    applicationAccessList: PropTypes.array,
};
export default GroupApplicationTab;