import React from "react";
import { Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const appDetailSheetUrl = 'https://pepsico.sharepoint.com/:x:/t/NextGenCLE/EU3vKAYybQdMsur3BoWg7FwB0kx6y_3hSsvoakSrD25NEg?e=1QGK4I';
const guideForAssignGroup = 'https://pepsico.sharepoint.com/:w:/t/NextGenCLE/EcBIVtykWvxLvCZ5ZJbbQ-wBTQqnMZhnqDFonUB_xVZgxg?e=FWAfOO';
const guideForApplicationService = 'https://pepsico.sharepoint.com/:w:/t/NextGenCLE/ESRmsbSOaB5MvQOXT2WFo_gBFz2x5BXnH6LA3F7-IZutcg?e=GPlRml';

const SectorDetailWarningModal = ({
  appNames,
  // isAdminAccess,
  isModalOpen,
  onCloseModal,
  onProceed
}) => {
  return (
    <Modal
      centered
      title={<div style={{ textAlign: 'center', color: 'red' }}>
        **Sector Details missing for your application: <span className="bold-text">{appNames.join('').replace(' ', ',  ')}</span>**
      </div>}
      open={isModalOpen}
      footer={(
        <div className="text-center">
          <Button onClick={onProceed} type="primary">Proceed to Search</Button>
        </div>
      )}
      width={'80%'}
      closeIcon={<div style={{ color: 'red' }} onClick={onCloseModal}>
        <CloseOutlined />
      </div>}
    >
      <div>
        <h6 style={{ textTransform: 'initial', fontWeight: 'bold' }}>
          CLE now mandates every application to have the following data to
          organize your application data in our database.
        </h6>
        <div>
        Your application details are not configured in CLE. Please provide the below details in this &nbsp;
          <a target="_blank" href={appDetailSheetUrl} rel="noreferrer">
            <i>spreadsheet</i>
          </a>
        </div>
        <div className="mb-2">
          1.Sector
          <br />
          2.Application Owner
          <br />
          3.Assignment Group
          <br />
          4.Application CI
          <br />
          5.Team DL
          <br />
        </div>
        <p>
          <i>
            Please ensure your team updates the spreadsheet by{" "}
            <b> March 28th 2024</b> to avoid potential access issues with logs
            and exceptions.
          </i>&nbsp;<b>Not having the correct Application Service CIs would impact the CLE Alerts that you receive upon exceptions going forward.</b>
        </p>
        <p style={{ marginBottom: '2px' }}>Note : Please go through the help documents attached. </p>
        <ol>
          <li>Please search for your assignment groups here to validate: &nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://pepsico.service-now.com/now/nav/ui/classic/params/target/u_alert_assignment_group_mapping_list.do">
              Alert Assignment Group Mappings | myServices (service-now.com)
            </a>
            <br />
            <span><i>
              <a target="_blank" rel="noreferrer" href={guideForAssignGroup}>
                Read more about how to find Assignment Group in servicenow</a>
            </i></span>
          </li>
          <li>Please search for your Application Service CI here: &nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://pepsico.service-now.com/now/nav/ui/classic/params/target/cmdb_ci_service_auto_list.do"
            >Application Services | myServices (service-now.com)
            </a>
            <br />
            <span>&nbsp;<i>
              <a target="_blank" rel="noreferrer" href={guideForApplicationService}>
                Read more about how to find application service CI in servicenow</a>
            </i>
            </span>
          </li>
        </ol>
        <p><i>Thank you for your cooperation. Incase you are not able to access the sheet, please reachout to us - ITNEXTGENCLE@pepsico.com </i></p>
        <div><i>Note: This warning popup will be removed as soon as data collected. Thank you for your patience!</i></div>
      </div>
    </Modal >
  );
};

SectorDetailWarningModal.propTypes = {
  appNames: PropTypes.array.isRequired,
  // isAdminAccess: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onProceed: PropTypes.func,
};

export default SectorDetailWarningModal;
